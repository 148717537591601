<template>
  <div style="height: 100%">
    <div class="activity-setup">
      <div class="setup-outcome">
        <div class="outcome-body">
          <div class="outcome-phone">
            <iframe id="actH5" ref="actH5" :src='state.currentSettingAct.h5Url' frameborder='0' width='100%'
                    height='100%'></iframe>
          </div>
        </div>
        <div class='outcome-btn'>
          <el-button size='medium' class='refesh-btn' @click="refreshH5">刷新</el-button>
          <el-button type="primary" size='medium' @click='publishMask =true'>发布活动</el-button>
        </div>
      </div>
      <div class="setup-body">
        <div class="setup-nav">
          <ul>
            <li v-for="(item,index) in actSetMenu"
                :class="{'active':state.currentSettingAct.secondMenu==item.name,'line':item.line}" :key="index"
                @click="chooseActSettingSecondMenu(item.name)">
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="setup-pane">
          <el-tabs v-model="state.currentSettingAct.threeMenu" @tab-click="chooseThreeMenu"
                   v-if="state.currentSettingAct&&state.currentSettingAct.threeMenuList">
            <el-tab-pane v-bind:label="item.title" v-bind:name="item.name"
                         v-for="(item) in state.currentSettingAct.threeMenuList" v-bind:key="item.id"></el-tab-pane>
          </el-tabs>
          <router-view></router-view>
        </div>
      </div>
    </div>
    <!--发布活动弹窗-->
    <act-publish :visible.sync="publishMask" :url="state.currentSettingAct.h5Url"/>
  </div>
</template>

<script>
import store from '@/store/store'
import {ActivityUtil} from "@/js/commonUtil";
import {StorageUtil} from "@/js/auth";
import {config} from "@/js/config";
import ActPublish from "@/components/publish.vue";
import {messages} from "@/js/messages";

export default {
  name: 'Setup',
  data() {
    return {
      state: store.state,
      actSetMenu: config.actSetMenu,
      activity: '',
      isPreview: true,
      publishMask: false,
      top: 0,
    }
  },
  components: {
    ActPublish,
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'ActivityList') {
      let actListQueryParams = StorageUtil.getObject('actListQueryParams');
      if (actListQueryParams) {
        actListQueryParams.query = true;
        StorageUtil.storeObject('actListQueryParams', actListQueryParams);
      }
      StorageUtil.removeObject('topicListQueryParams');
    } else if (to.name === 'Topic') {
      let topicListQueryParams = StorageUtil.getObject('topicListQueryParams');
      if (topicListQueryParams) {
        topicListQueryParams.query = true;
        StorageUtil.storeObject('topicListQueryParams', topicListQueryParams);
      }
      StorageUtil.removeObject('actListQueryParams');
    } else if (to.name !== 'Qrcode' && to.name !== 'OperateLog') {
      StorageUtil.removeObject('actListQueryParams');
      StorageUtil.removeObject('topicListQueryParams');
    }
    next()
  },
  beforeMount() {
    store.updateCurrentSettingAct(true);
    ActivityUtil.get(ActivityUtil.getCurrentSettingActId(), data => {
      this.activity = data;
      store.state.currentSettingAct.h5Url = data.h5Url;
    });
  },
  beforeDestroy() {
    //离开时清除活动设置相关菜单选择
    store.updateCurrentSettingAct(false);
    let routerRenderEl = document.getElementById('routerRender');
    if (routerRenderEl) {
      routerRenderEl.removeEventListener('scroll', this.handleScroll)
    }
  },
  mounted() {
    document.getElementById('routerRender').addEventListener('scroll', this.handleScroll);
    // let ele = document.getElementById('actH5').contentWindow.document;
    // ele.body.style.width = '350px;'
  },
  watch: {
    'state.currentSettingAct.refreshH5Time': {
      handler(newValue, oldValue) {
        if (this.isPreview && this.state.currentSettingAct.refreshH5Time) {
          document.getElementById('actH5').src = this.state.currentSettingAct.h5Url;
        }
      },
    }
  },
  methods: {
    //选择活动设置的二级菜单(活动设置,页面设置,选手管理,投票设置,投票统计,票数排行)
    chooseActSettingSecondMenu(menu) {
      let {id, name} = this.activity
      ActivityUtil.toSettingAct(menu, id, {activityId: id, actName: name});
    },
    refreshH5() {
      ActivityUtil.refreshH5()
    },

    chooseThreeMenu(tab) {
      let {id, name} = this.activity
      const role = this.state.user.role;
      //会员等级不足
      if (tab.name === 'LogVote' && role !== 2) {
        messages.showErrorMsg("会员等级不足，请联系客服");
        return
      }
      ActivityUtil.toSettingAct(tab.name, id, {activityId: id, actName: name});
    },
    handleScroll() {
      let ele = document.getElementById('routerRender').scrollTop;
      this.top = ele
    }
  }
}
</script>
<style lang="scss" scoped>
.activity-setup {
  display: flex;

  .setup-outcome {
    width: 40%;
    min-width: 400px;

    .outcome-body {
      left: 0;
      right: 0;
      top: 40px;
      margin: auto;
      z-index: 99;
      width: 340px;
      height: 660px;
      background-image: url(../../../../assets/user/activity/as-phone.png);
      background-size: 100% 100%;
      position: relative;

      .outcome-phone {
        width: 275px;
        height: 538px;
        position: absolute;
        top: 79px;
        left: 32px;
        border-bottom-left-radius: 19px;
        border-bottom-right-radius: 19px;
        overflow: hidden;
      }
    }

    .outcome-btn {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .setup-body {
    width: 100%;
    height: calc(100vh - 110px);
    min-width: 1400px;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    margin: 15px 0;
    display: flex;

    .setup-nav {
      width: 140px !important;
      height: 100%;
      box-shadow: 1px 0 0 0 #eef3f4;
      border-radius: 6px 0 0 6px;
      padding-top: 24px;
      box-sizing: border-box;

      ul {
        list-style: none;

        li {
          margin: 0 auto;
          text-align: center;
          width: 100px;
          height: 30px;
          line-height: 30px;
          color: #333;
          margin-bottom: 16px;
          border-radius: 4px;
          cursor: pointer;
          background-color: #fff;
        }

        .active {
          background-color: #409EFF;
          color: #fff !important;
        }

        .line {
          position: relative;
          margin-bottom: 32px;
        }

        .line:after {
          content: "";
          position: absolute;
          bottom: -16px;
          left: 10px;
          width: 80px;
          height: 1px;
          background-color: #eef3f4;
        }
      }
    }

    .setup-pane {
      width: 100%;
      overflow-y: auto;
      padding: 15px 30px;

      ::v-deep .el-tabs__nav-wrap::after {
        background-color: #e4e7ed !important;
      }
    }
  }
}
</style>
