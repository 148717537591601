import {Message} from 'element-ui'

//初始化消息提示框的顶部偏移量,并监听窗口尺寸变化时重新计算偏移量,从而达到提示语居中的效果
let offset=20;
initOffset();
let addListener=false;
if(!addListener){
    addListener=true;
    window.addEventListener("resize", initOffset);
}
function initOffset() {
    let height=getHeight();
    offset = (height-50)/2;
}
function getHeight() {
    let winHeight=0;
    //获取窗口高度
    if (window.innerHeight){
        winHeight = window.innerHeight;
    } else if ((document.body) && (document.body.clientHeight)){
        winHeight = document.body.clientHeight;
    }
    //通过深入Document内部对body进行检测，获取窗口大小
    if (document.documentElement  && document.documentElement.clientHeight && document.documentElement.clientWidth) {
        winHeight = document.documentElement.clientHeight;
    }
    return winHeight;
}
const messages = {
    error:{
        login:'用户名或密码错误',
        loginCode:'验证码错误',
        validateCodeSend: '验证码发送失败,请稍后再试',
        registerAgreement: '请先阅读并同意用户登陆注册协议',
        register: '注册失败',
        registerExist:'用户已存在',
        reset: '重置密码失败',
        delete: '删除失败',
        operation: '操作失败',
        upload: '上传失败',
        server: '服务异常',
        deleteGroup: '一个活动至少要保留一个分组',
        copy: '复制失败',
    },

    success:{
        validateCodeSend:'验证码发送成功',
        register:'注册成功',
        reset: '修改密码成功',
        update:'修改成功',
        delete:'删除成功',
        topicAdd: '创建专题活动成功，请设置',
        actAdd: '创建活动成功，请设置',
        actCopy: '复制活动成功',
        operation: '操作成功',
        upload: '上传成功',
        copy: '复制成功',
    },

    codes:[
        {code:2003, message:'用户已存在'},
        {code:1053, message:'无效的验证码'},
        {code:1054, message:'无效的验证码'},
        {code:1056, message:'无效的手机号或密码'},
        {code:2000, message:'无效的手机号或密码'},
        {code:2002, message:'用户不存在'},
        {code:1201, message:'创建专题活动失败'},
        {code:1203, message:'暂无可用活动，请先创建活动'},
        {code:3001, message:'活动不存在'},
        {code:3004, message:'活动已删除'},
        {code:3005, message:'活动已关闭'},
        {code:3008, message:'IP数量超限制'},
        {code:3018, message:'活动已结束太久，不可修改活动信息，请联系客服'},
    ],

    getMsgByCode: function (code) {
        for (var i = 0; i < this.codes.length; i++) {
            if (code === this.codes[i].code) {
                return this.codes[i].message;
            }
        }
        return null;
    },

    showErrorMsg: function (msg) {
        if(msg==null || msg==''){
            msg = '操作失败'
        }
        Message({
            offset:offset,
            message: msg,
            center: true,
            type:"error",
            duration:1500
        });
    },
    warning: function (msg) {
        if(msg==null || msg==''){
            msg = '警告'
        }
        Message({
            offset:offset,
            message: msg,
            center: true,
            type:"warning",
            duration:1500
        });
    },
    showSuccessMsg: function (msg) {
        if(msg==null || msg==''){
            msg = '操作成功'
        }
        Message({
            offset:offset,
            message: msg,
            center: true,
            type:"success",
            duration:1500
        });
    },
    showErrorMsgByCode: function (errorCode) {
        var msg = this.getMsgByCode(errorCode);
        if(msg==null || msg==''){
            msg = '操作失败'
        }
        Message({
            offset:offset,
            message: msg,
            center: true,
            type:"error",
            duration:1500
        });
    },
}

export {messages}
