<template>
  <el-dialog title="发布活动" class="act-publish" :visible.sync="dialogVisible" width="900px">
    <div class="publish">
      <div class="content">
        <div class="title">本活动网址</div>
        <div class='url'>
          <el-input :disabled="true" :readonly="true" v-model="url" class='input'>
          </el-input>
          <el-button type="primary" class='btn' @click="copyUrl">复制链接</el-button>
        </div>
        <p class='desc'>请将本网址放到公众号自定义菜单或微信文章的阅读原文处发布活动</p>
      </div>
      <div class="code">
        <img :src="getQrCode()" alt="">
        <span>微信扫一扫</span>
      </div>
    </div>
    <div class="poster">
      <div class="title">活动分享海报</div>
      <div class="list">
        <div :class="{item:true,active:1===activeIndex}" @click="activeIndex=1">
          <div id="poster1" class="poster1">
            <div class="header">
              <img class="banner" :src="actBanner" alt="">
              <div class='name'>{{ activity.name }}</div>
            </div>
            <div class='code'>
              <img :src="getQrCode()" alt="">
              <p>识别二维码参与活动</p>
            </div>
            <div class='time'>
              <p><i class="el-icon-time"></i> 活动开始: {{ activity.startTime | convertTime }}</p>
              <p><i class="el-icon-time"></i> 活动结束: {{ activity.endTime | convertTime }}</p>
            </div>
          </div>
          <div class="hover">
            <div class="download" @click.stop='bindDownChange("poster1")'>下载</div>
          </div>
        </div>
        <div :class="{item:true,active:2===activeIndex}" @click="activeIndex=2">
          <div id="poster2" class="poster2">
            <div class="header">
              <img class="banner" src="@/assets/user/activity/as-poster2.png" alt="">
              <div class='name'>{{ activity.name }}</div>
            </div>
            <div class="content">
              <div class='time'>
                <p><i class="el-icon-time"></i> 活动开始: {{ activity.startTime | convertTime }}</p>
                <p><i class="el-icon-time"></i> 活动结束: {{ activity.endTime | convertTime }}</p>
              </div>
              <div class='code'>
                <img :src="getQrCode()" alt="">
                <p>扫码查看活动</p>
              </div>
            </div>
          </div>
          <div class="hover">
            <div class="download" @click.stop='bindDownChange("poster2")'>下载</div>
          </div>
        </div>
        <div :class="{item:true,active:3===activeIndex}" @click="activeIndex=3">
          <div id="poster2" class="poster2">
            <div class="header">
              <img class="banner" src="@/assets/user/activity/as-poster3.jpg" alt="">
              <div class='name'>{{ activity.name }}</div>
            </div>
            <div class="content">
              <div class='time'>
                <p><i class="el-icon-time"></i> 活动开始: {{ activity.startTime | convertTime }}</p>
                <p><i class="el-icon-time"></i> 活动结束: {{ activity.endTime | convertTime }}</p>
              </div>
              <div class='code'>
                <img :src="getQrCode()" alt="">
                <p>扫码查看活动</p>
              </div>
            </div>
          </div>
          <div class="hover">
            <div class="download" @click.stop='bindDownChange("poster2")'>下载</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {QrCode} from "@/js/qrcodeUtil";
import {messages} from '@/js/messages';
import {ActivityUtil} from "@/js/commonUtil";
import Canvas2Image from "@/js/Canvas2Image";
import html2canvas from 'html2canvas'
import {config} from "@/js/config";

export default {
  name: 'actPublish',
  data() {
    return {
      qrUrl: "",
      activity: {},
      activeIndex: 1,
      actBanner: ''
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  beforeMount() {
    let that = this
    ActivityUtil.get(ActivityUtil.getCurrentSettingActId(), data => {
      that.activity = data;
      let shufflingImageUrl = data.settingShufflingImages[0].shufflingImageUrl;
      this.getBase64(config.ossHost + shufflingImageUrl, (data) => {
        that.$set(that, 'actBanner', data)
      })
    });
  },
  methods: {
    copyUrl() {
      this.$copyText(this.url).then(function (e) {
        messages.showSuccessMsg(messages.success.copy)
      }, function (e) {
        messages.showErrorMsg(messages.error.copy)
      })
    },
    getQrCode() {
      this.qrUrl = QrCode.url(this.url, 300);
      return this.qrUrl;
    },
    bindDownChange(id) {
      let elementArr = document.getElementById(id)
      this.onCanvas(elementArr)
    },
    onCanvas(elem) {
      let width = elem.offsetWidth;
      let height = elem.offsetHeight;
      let canvas = document.createElement("canvas");
      let scale = 4;
      canvas.width = width * scale;
      canvas.height = height * scale;
      let opts = {
        scale: scale,
        dpi: 300,
        canvas: canvas,
        logging: true,
        width: width,
        height: height,
        allowTaint: false,
        useCORS: true,//允许加载跨域的图片
      }
      html2canvas(elem, opts).then((canvas) => {
        Canvas2Image.saveAsPNG(canvas, undefined, undefined, '活动海报')
      })
    },
    getBase64(url, callback) {
      let Img = new Image(),
          dataURL = '';
      Img.src = url + "?v=" + Math.random();
      Img.setAttribute("crossOrigin", 'Anonymous')
      Img.onload = function () {
        let canvas = document.createElement("canvas"),
            width = Img.width,
            height = Img.height;
        canvas.width = width*4;
        canvas.height = height*4;
        canvas.getContext("2d").drawImage(Img, 0, 0, width*4, height*4);
        dataURL = canvas.toDataURL('image/jpeg');
        callback ? callback(dataURL) : null;
      };
    },
  }
}
</script>
<style lang="scss" scoped>
.act-publish {
  .title {
    color: #333;
    font-size: 14px;
    margin-bottom: 20px;
    padding-left: 7px;
    border-left: 2px solid #0076ff;
  }

  .publish {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
    margin-bottom: 20px;

    .content {
      width: 500px;
      box-sizing: border-box;

      .url {
        display: flex;
        align-items: center;

        .btn {
          background-color: #409eff;
        }
      }

      .desc {
        font-size: 14px;
        color: rgb(153, 153, 153);
        line-height: 24px;
        padding-top: 5px;
      }
    }

    .code {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;

      img {
        width: 150px;
        height: 150px;
      }
    }
  }

  .poster {
    .list {
      display: flex;
      justify-content: space-between;

      .item {
        background-color: #fff;
        width: 234px;
        height: 426px;
        overflow: hidden;
        position: relative;
        border-radius: 4px;
        border: 1px solid #fff;
        box-sizing: border-box;
        white-space: normal;
        cursor: pointer;
        transition: .5s;

        .hover {
          display: none;
        }

        .header {
          width: 100%;

          .banner {
            width: 100%;
            height: auto;
            display: block;
          }

          .name {
            color: #666;
            font-size: 9px;
            line-height: 16px;
            padding: 8px;
          }
        }

        .content {
          width: 100%;
          box-sizing: border-box;
          padding: 0 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .code {
            background: hsla(0, 0%, 100%, .15);
            box-shadow: 0 6px 16px 0 hsla(0, 0%, 90%, .4);
            border-radius: 6px;
            text-align: center;

            img {
              width: 60px;
            }

            p {
              color: #666;
              font-size: 9px;
            }
          }

          .time {
            text-align: center;
            color: #666;
            font-size: 9px;
            line-height: 18px;
            padding-bottom: 10px;
          }
        }
      }

      .item.active {
        border: 1px solid #f93a3a;
      }

      .item.active:before {
        content: "";
        background: url(../assets/user/activity/as-paper.png);
        width: 62px;
        height: 62px;
        position: absolute;
        background-size: cover;
        z-index: 99;
        left: -4px;
        top: -4px;
      }

      .item:hover {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .05);
        transform: translateY(-6px);

        .hover {
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .39);

          .download {
            text-align: center;
            width: 90px;
            height: 40px;
            line-height: 40px;
            background: #0076ff;
            opacity: 1;
            border-radius: 30px;
            color: #fff;
            font-size: 16px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer
          }
        }
      }

      .poster1 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .header {
          width: 100%;

          .banner {
            width: 100%;
            height: auto;
            max-height: 210px;
            display: block;
          }

          .name {
            text-align: center;
            color: #666;
            font-size: 9px;
            line-height: 16px;
            padding: 8px;
            border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
          }
        }

        .code {
          background: hsla(0, 0%, 100%, .15);
          box-shadow: 0 6px 16px 0 hsla(0, 0%, 90%, .4);
          border-radius: 6px;
          text-align: center;

          img {
            width: 100px;
          }

          p {
            color: #666;
            font-size: 9px;
          }
        }

        .time {
          text-align: center;
          color: #666;
          font-size: 9px;
          padding-bottom: 10px;
        }
      }

      .poster2 {
        width: 100%;
        height: 100%;
      }

      .poster3 {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
