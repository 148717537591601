<template>
  <div class="screen-set">
    <div class='content'>
      <div>
        <el-form hide-required-asterisk label-position="left" label-width="120px" ref="screenForm"
                 :model="activity.settingScreen" :rules="screenRules">
          <el-form-item label="现场大屏">
            <el-switch v-model="activity.settingScreen.status" @change="change"></el-switch>
          </el-form-item>
          <template v-if="activity.settingScreen.status">
            <el-form-item label="大屏活动名称" prop="title">
              <el-input @change="change" placeholder="请输入大屏活动名称" maxlength="200"
                        v-model.trim="activity.settingScreen.title">
              </el-input>
            </el-form-item>
            <el-form-item label="选手数量" prop="playerNum">
              <el-input @change="change" placeholder="请输入大屏活动名称" maxlength="200"
                        v-model.trim="activity.settingScreen.playerNum">
              </el-input>
            </el-form-item>
            <el-form-item v-if="false" label="大屏滚动">
              <el-switch v-model="activity.settingScreen.hasRoll" @change="change"></el-switch>
            </el-form-item>
            <el-form-item label="大屏风格" prop="type">
              <el-radio-group v-model="activity.settingScreen.type" @change="change">
                <el-radio :label="0">通用</el-radio>
                <el-radio :label="1">科技</el-radio>
                <el-radio :label="2">政务</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-button type="primary" size="small" @click="bindScreenJumpChange">进入大屏</el-button>
          </template>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {ActivityUtil, CommonUtil} from '@/js/commonUtil'

export default {
  name: 'screenSet',
  data() {
    return {
      activity: {
        id: 0,
        settingScreen: {
          status: false,
          title: null,
          type: 0,
          playerNum: 10,
          hasRoll: false,
        },
      },
      screenRules: {
        //activityShareTitle: [{required: true, message: '请输入活动分享标题', trigger: 'blur'}],
        //shareContent: [{required: true, message: '请输入分享内容', trigger: 'blur'}]
      },
      isShareImg: false,
      isCase: false
    }
  },
  beforeMount() {
    this.getActivity();
  },
  methods: {
    getActivity() {
      ActivityUtil.get(ActivityUtil.getCurrentSettingActId(), data => {
        let settingScreen = data.settingScreen;
        if (!settingScreen) {
          data.settingScreen = this.activity.settingScreen
        }
        CommonUtil.copyProperty(data, this.activity);
      })
    },
    change() {
      this.$refs["screenForm"].validate(valid => {
        if (valid) {
          ActivityUtil.update(this.activity, ['settingScreen'], null, () => {
            ActivityUtil.refreshH5();
          });
        }
      })
    },
    bindScreenJumpChange() {
      let routeData = this.$router.resolve({path: '/screen', query: {activityId: this.activity.id}})
      window.open(routeData.href, '_blank');
    }
  }
}
</script>
<style lang="scss" scoped>
.screen-set {
  width: 100%;

  .content {
    box-sizing: border-box;
    padding-top: 10px;
    max-width: 800px;
  }
}

.case-title {
  font-size: 18px;

  span {
    font-size: 14px;
    margin-left: 10px;
  }
}

.case-img1 {
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 20px 0;
  box-sizing: border-box;
  padding: 20px 30px;

  img {
    width: 100%;
    height: auto;
  }
}

.case-img2 {
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 20px 0;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 30px;

  img {
    width: 350px;
    height: auto;
    margin-top: 20px;
  }

  .friend {

  }
}
</style>
