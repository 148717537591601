<template>
  <div class="home-content">
    <div class="banner">
      <div class="cont">
        <img src="@/assets/official/index/banner_txt.png" alt="" class="txt">
        <a href="#" class="a" @click="createActivity">创建活动</a>
        <img src="@/assets/official/index/banner_img.png" alt="" class="img"></div>
    </div>
    <div class="unveil-one w1">
      <div class="list" v-for="(item,index) in banners" :key="index">
        <img :src="item.img" alt="" srcset="">
        <span class="t1">{{ item.title }}</span>
        <span class="t2">{{ item.blurb }}</span>
      </div>
    </div>
    <div class="unveil-advantage">
      <div class="title"><p class="t1">核心优势</p>
        <div class="t2">专业微信投票平台，更懂安全、更懂客户，为客户提供优质服务</div>
      </div>
      <div class="old_content w1">
        <div :class="'old_list item'+index" v-for="(item,index) in unveilAdvantage" :key="index">
          <div class="actualImg">
            <img :src="item.img1"
                 alt="" class="imgs">
            <img :src="item.img2" alt="" class="active">
          </div>
          <div class="actualText">
            <div class="old-1">{{ item.title }}</div>
            <div class="old-2">{{ item.blurb }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="unveil-banner">
      <div class="title"><p class="t1">安全、稳定、专业的微信投票平台</p>
        <div class="t2">多种投票类型，多种投票逻辑，自定义配置模板，多种场景模板随心选择</div>
      </div>
      <el-carousel :interval="4000" arrow="never" indicator-position="outside">
        <el-carousel-item v-for="(item,index) in unveilBanners" :key="index">
          <div class="bannerMin">
            <div class="text">
              <p class="t1 f5">{{ item.title }}</p>
              <div class="t2">
                {{ item.blurb }}
              </div>
              <a href="/act/theme" class="">立即查看</a></div>
            <div class="img">
              <img :src="item.img" alt="" srcset=""></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="unveil-type">
      <div class="title">
        <p class="t1">全场景覆盖,多模式支持的投票活动发起平台</p>
        <div class="t2">专业微信投票平台，更懂安全、更懂客户，为客户提供优质服务</div>
      </div>
      <div class="diamond-box">
        <div class="diamond">
          <div class="text">
            <img src="../../../assets/official/index/home-type1.png" alt="">
            <div class="t1">微信投票</div>
            <div class="t2">在微信内部进行投票<br>通过微信分享引流<br>提高企业知名度</div>
          </div>
        </div>
        <div class="diamond">
          <div class="text"><img src="../../../assets/official/index/home-type2.png" alt="">
            <div class="t1">公众号投票</div>
            <div class="t2">将投票活动嵌入到公众号<br>为公众号拉新引流<br>提高公众号关注量</div>
          </div>
        </div>
        <div class="diamond">
          <div class="text"><img src="../../../assets/official/index/home-type3.png" alt="">
            <div class="t1">专题活动</div>
            <div class="t2">可以将多个相关联的活动<br>放在同一个页面入口<br>进入评选</div>
          </div>
        </div>
        <div class="diamond">
          <div class="text"><img src="../../../assets/official/index/home-type4.png" alt="">
            <div class="t1">朋友圈投票</div>
            <div class="t2">将海报或链接分享到朋友圈<br>帮助企业品牌快速传播<br>提升品牌影响力</div>
          </div>
        </div>
        <div class="diamond">
          <div class="text"><img src="../../../assets/official/index/home-type5.png" alt="">
            <div class="t1">微信群投票</div>
            <div class="t2">将投票活动发送到微信群<br>一键转发、快速涨粉<br>加速用户裂变</div>
          </div>
        </div>
        <div class="diamond">
          <div class="text"><img src="../../../assets/official/index/home-type6.png" alt="">
            <div class="t1">营销活动</div>
            <div class="t2">拉新促活有效工具<br>有趣活动吸睛利器<br>实现爆发式增长</div>
          </div>
        </div>
      </div>
    </div>
    <div class="unveil-fuwu">
      <p>服务超过十万家</p>
      <ul>
        <li><p>1,000,000+</p>
          <div>发起活动</div>
        </li>
        <li><p>500,000+</p>
          <div>服务客户</div>
        </li>
        <li><p>30,000+</p>
          <div>合作企业</div>
        </li>
      </ul>
    </div>
    <div v-if="false" class="unveil-scene">
      <div class="title">
        <p class="t1">适用场景</p>
        <div class="t2">服务于数万家大小企业，为多个行业，多家企业提供可行的解决方案</div>
      </div>
      <div class="scene-box w1">
        <ul class="list_title">
          <li :class="{'active':index===unveilScene.active}"
              v-for="(item,index) in unveilScene.list" :key="index"
              @click="unveilScene.active=index">
            <img class="icon" :src="item.img2" alt="">
            <p>{{ item.title }}</p>
            <div class="blurb">{{ item.blurb }}</div>
          </li>
        </ul>
        <div class="list_cont">
          <div class="cont-sj">
            <img :src="unveilScene.list[unveilScene.active].img1" alt="" srcset="">
          </div>
          <div class="cont-anl">案例</div>
          <div class="cont-text">
            <img :src="unveilScene.list[unveilScene.active].img2"
                 alt="" class="icon">
            <p class="t1">{{ unveilScene.list[unveilScene.active].title }}</p>
            <div class="t2">
              {{ unveilScene.list[unveilScene.active].blurb }}
            </div>
            <a href="/solution" class="">
              查看详情
              <img src="../../../assets/official/index/arrow-right.png" alt="">
            </a>
            <div class="t3">
              <img :src="unveilScene.list[unveilScene.active].img3" alt="">
              <p>微信扫码预览活动</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="false" class="unveil-anl">
      <div class="title">
        <p class="t1">精选案例</p>
        <div class="t2">活动数量200万+，服务用户100万+</div>
      </div>
      <ul class="w1">
        <li v-for="(item,index) in unveilAnl" :key="index">
          <div class="a">
            <img :src="item.img1" alt="">
            <div class="codeBox">
              <div class="code">
                <img :src="item.img2" alt="">
                <p>扫码查看案例</p>
              </div>
              <a href="/act/theme" class="p1">创建活动</a>
              <a :href="item.jump" target="_blank" class="p2">模板详情</a>
            </div>
          </div>
          <a class="jump" :href="item.jump" target="_blank"></a>
          <div class="blurb">{{ item.title }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'websiteIndex',

  data() {
    return {
      activeIndex: '1',
      banners: [
        {
          title: '安全性',
          blurb: '系统服务器依托阿里云强大的云计算和CDN加速等服务防破解。',
          img: require('@/assets/official/index/home-icon1.a72e0800.png')
        },
        {
          title: '稳定性',
          blurb: '云服务器集群+云数据库，支持高并发的大型活动稳定举行。',
          img: require('@/assets/official/index/home-icon2.aa121f5d.png')
        },
        {
          title: '防刷票',
          blurb: '数据建模投票行为，大数据计算，可有效防止各种刷票作弊行为。',
          img: require('@/assets/official/index/home-icon3.3e6ca502.png')
        },
      ],
      unveilAdvantage: [
        {
          title: '功能强大',
          blurb: '现场大屏、多种排序方式、多种展现方式、多种投票逻辑等',
          img1: require('@/assets/official/index/home-old11.png'),
          img2: require('@/assets/official/index/home-old12.png')
        },
        {
          title: '安全保障',
          blurb: '自主研发的加密算法机制，保证数据传输安全',
          img1: require('@/assets/official/index/home-old21.png'),
          img2: require('@/assets/official/index/home-old22.png')
        },
        {
          title: '数据统计',
          blurb: '每次投票都有详细的微信、投票地区、时间、IP等记录',
          img1: require('@/assets/official/index/home-old31.png'),
          img2: require('@/assets/official/index/home-old32.png')
        },
        {
          title: '经验丰富',
          blurb: '为100万+的活动提供技术、商务等服务支持',
          img1: require('@/assets/official/index/home-old41.png'),
          img2: require('@/assets/official/index/home-old42.png')
        },
        {
          title: '超强防刷',
          blurb: '平台云数据建模、自研加密算法、多重智能防刷机制',
          img1: require('@/assets/official/index/home-old51.png'),
          img2: require('@/assets/official/index/home-old52.png')
        },
        {
          title: '操作简单',
          blurb: '多种展现方式、批量添加选手、丰富的模板等',
          img1: require('@/assets/official/index/home-old61.png'),
          img2: require('@/assets/official/index/home-old62.png')
        },
        {
          title: '服务支持',
          blurb: '为主办方提供活动策划、活动宣传、广告投放等服务',
          img1: require('@/assets/official/index/home-old71.png'),
          img2: require('@/assets/official/index/home-old72.png')
        },
        {
          title: '随心定制',
          blurb: '根据活动内容，定制您的专属投票',
          img1: require('@/assets/official/index/home-old81.png'),
          img2: require('@/assets/official/index/home-old82.png')
        },
        {
          title: '免费创建',
          blurb: '平台可免费创建多种不同风格的活动',
          img1: require('@/assets/official/index/home-old91.png'),
          img2: require('@/assets/official/index/home-old92.png')
        },
      ],
      unveilBanners: [
        {
          title: '多种投票类型',
          blurb: '根据客户的行业类型及营销诉求，提供全方位的解决方案。',
          img: require('@/assets/official/index/home-banner1.png')
        },
        {
          title: '自定义配置模板',
          blurb: '投票类型多样，界面元素丰富，功能满足企业多种需求。',
          img: require('@/assets/official/index/home-banner2.png')
        },
        {
          title: '多种场景模板选择',
          blurb: '活动类型丰富，功能全面，操作简单，多种场景模版随心换。',
          img: require('@/assets/official/index/home-banner3.png')
        }
      ],
      unveilScene: {
        active: 0,
        list: [
          {
            title: '通用方案',
            blurb: '图文投票活动、视频投票活动、多分组投票活动、在线自助报名活动、关注投票活动、礼物投票活动、多赛区投票活动、晋级赛投票活动、专题投票活动',
            img1: require('@/assets/official/index/home-banner3.png'),
            img2: require('@/assets/official/index/home-scene1.png'),
            img3: require('@/assets/official/index/home-banner3.png'),
          },
          {
            title: '事业单位',
            blurb: '智慧监管典型案例评选、建筑企业100强评选、最美一条街评选、“奥迪杯”少儿钢琴大赛评选、十佳服务窗口、十佳服务之星、最美劳动者、十大最美村镇、十大最美医师评选、感动人物评选活动、优秀教师评选',
            img1: require('@/assets/official/index/home-banner3.png'),
            img2: require('@/assets/official/index/home-scene2.png'),
            img3: require('@/assets/official/index/home-banner3.png'),
          },
          {
            title: '集团企业',
            blurb: '社会责任感企业(企业家)”宣传评选活动、十大优秀民营企业家评选、年度优秀物业服务企业、全国金属板材优质加工企业评选活动、全民海选十佳重质量优秀医药企业、年度风云杭商、新锐杭商网络评选活动',
            img1: require('@/assets/official/index/home-banner3.png'),
            img2: require('@/assets/official/index/home-scene3.png'),
            img3: require('@/assets/official/index/home-banner3.png'),
          },
          {
            title: '金融行业',
            blurb: '十大经济年度人物、经济影响力人物评选、新三板最具投资价值评选、年度跨境电商行业最具人气企业网络投票、十佳银行理财师大赛、理财经理“最美形象”评选、金融业十佳宣传手',
            img1: require('@/assets/official/index/home-banner3.png'),
            img2: require('@/assets/official/index/home-scene4.png'),
            img3: require('@/assets/official/index/home-banner3.png'),
          },
          {
            title: '行业协会',
            blurb: '中国食文化节约型餐饮企业投票评选、工会摄影摄像大赛、计划生育协会 “最美协会人”评选、地区商会十佳商户评选、十佳品牌评选、行业协会年度领军企业评选',
            img1: require('@/assets/official/index/home-banner3.png'),
            img2: require('@/assets/official/index/home-scene5.png'),
            img3: require('@/assets/official/index/home-banner3.png'),
          },
        ]
      },
      unveilAnl: [
        {
          title: '1111111',
          jump: '',
          img1: require('@/assets/official/index/home-banner3.png'),
          img2: require('@/assets/official/index/home-banner3.png'),
        },
        {
          title: '1111111',
          jump: '',
          img1: require('@/assets/official/index/home-banner3.png'),
          img2: require('@/assets/official/index/home-banner3.png'),
        },
        {
          title: '1111111',
          jump: '',
          img1: require('@/assets/official/index/home-banner3.png'),
          img2: require('@/assets/official/index/home-banner3.png'),
        },
        {
          title: '1111111',
          jump: '',
          img1: require('@/assets/official/index/home-banner3.png'),
          img2: require('@/assets/official/index/home-banner3.png'),
        }
      ],
    }
  },
  methods: {
    createActivity() {
      this.$router.push('/login')
    },
  }
}
</script>
<style src="./index.scss" lang="scss" scoped>

</style>
