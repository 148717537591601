<template>
  <div>
    <div class="activity-operate" v-show="isOperate">
      <div class="operate-body">
        <div class="operate-item">
          <div class="item-img">
            <img src="@/assets/user/am-select.png" alt="">
          </div>
          <div class="item-blurb">
            <div class="blurb-title"><span>1.</span>选择模板</div>
            <div class="blurb-content">海量模版分门别类，全行业覆盖多风格展示</div>
          </div>
        </div>
        <div class="operate-arrow">
          <img src="@/assets/user/am-arrow.png" alt="">
        </div>
        <div class="operate-item">
          <div class="item-img">
            <img src="@/assets/user/am-create.png" alt="">
          </div>
          <div class="item-blurb">
            <div class="blurb-title"><span>2.</span>创建活动</div>
            <div class="blurb-content">点击模板即可预览模板样式，一键创建活动</div>
          </div>
        </div>
        <div class="operate-arrow">
          <img src="@/assets/user/am-arrow.png" alt="">
        </div>
        <div class="operate-item">
          <div class="item-img">
            <img src="@/assets/user/am-edit.png" alt="">
          </div>
          <div class="item-blurb">
            <div class="blurb-title"><span>3.</span>编辑活动</div>
            <div class="blurb-content">自定义更换模板类型、页面设置及活动内容</div>
          </div>
        </div>
        <div class="operate-arrow">
          <img src="@/assets/user/am-arrow.png" alt="">
        </div>
        <div class="operate-item">
          <div class="item-img">
            <img src="@/assets/user/am-push.png" alt="">
          </div>
          <div class="item-blurb">
            <div class="blurb-title"><span>4.</span>发布活动</div>
            <div class="blurb-content">设置活动，添加参与选手相关信息，一键发布活动</div>
          </div>
        </div>
      </div>
      <div class="operate-close" @click="isOperate=false"><i class="el-icon-close"></i></div>
    </div>
    <div class="content-wrapper">
      <activityModelList :canSearch="true" batName="创建活动" @clickModel="clickModel"></activityModelList>
      <actModelDialog :visible.sync="createActivityDialog" :actModel="operateModel"></actModelDialog>
    </div>
  </div>
</template>

<script>
import activityModelList from '@/components/actiivityModelList.vue'
import actModelDialog from '@/components/actModelDialog.vue'

export default {
  name: 'templateCenter',
  components: {
    activityModelList, actModelDialog
  },
  data() {
    return {
      createActivityDialog: false,
      isOperate: true,
      operateModel: {
        id: 0,
        name: '',
        activityH5Url: '',
        functionalDesc: '',
        applicableScenario: '',
        modelActivityId: 0
      }
    }
  },

  methods: {
    clickModel(model) {
      this.operateModel = model;
      this.createActivityDialog = true;
    }
  }
}
</script>
<style lang="scss">
.activity-operate {
  border-radius: 6px;
  background: #fff;
  min-width: 1400px;
  width: 72%;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  margin: 15px auto;
  position: relative;

  .operate-body {
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .operate-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item-img {
        padding: 8px;

        img {
          height: 32px;
          width: 32px;
        }
      }

      .item-blurb {
        .blurb-title {
          font-size: 18px;
          line-height: 36px;
          color: black;

          span {
            font-weight: bolder;
          }
        }
      }
    }

    .operate-arrow {
      padding: 15px;

      img {
        height: 32px;
        width: 32px;
      }
    }
  }

  .operate-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;

    i {
      font-size: 20px;
    }
  }

  .operate-close:hover {
    i {
      color: #409EFF;
    }
  }
}
</style>
