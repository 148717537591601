<template>
  <el-row :style="{width: width+'px'}">
    <el-form hide-required-asterisk ref="dateFrom" :model="{startValue:startValue,endValue:endValue}">
      <el-form-item style="display: inline-block;" :rules="startRules" prop="startValue">
        <el-date-picker :style="{width:dateWidth?dateWidth+'px':((width-sepWidth)/2)+'px'}" @change="change(0)"
                        v-model="startValue" type="datetime" :placeholder="startPlaceholder"></el-date-picker>
      </el-form-item>
      <div :style="{display: 'inline-block',textAlign:'center',width:sepWidth?sepWidth+'px':(width-2*dateWidth)+'px'}">{{sep}}</div>
      <el-form-item style="display: inline-block;" :rules="endRules" prop="endValue">
        <el-date-picker :style="{width:dateWidth?dateWidth+'px':((width-sepWidth)/2)+'px'}" @change="change(1)"
                        v-model="endValue" type="datetime" :placeholder="endPlaceholder">
        </el-date-picker>
      </el-form-item>
    </el-form>
  </el-row>
</template>
<script>
export default {
  name: 'dateRange',//日期范围组件,通过start.sync和end.sync进行双向绑定
  props:{
        start:null,
        end:null,
        sep:{
          type:String,
          default:'至'
        },
        startPlaceholder:{
            type: String,
            default: '开始时间'
        },
        endPlaceholder:{
          type: String,
          default: '结束时间'
        },
        width:{
          type:Number,
          default:683
        },
        dateWidth:{
          type:Number,
          default:0
        },
        sepWidth:{
          type:Number,
          default:25
        },
        startEmptyMsg:{
          type:String,
          default:'请选择开始时间'
        },
        startErrorMsg:{
          type:String,
          default:'开始时间必须小于结束时间'
        },
        endEmptyMsg:{
          type:String,
          default:'请选择结束时间'
        },
        endErrorMsg:{
          type:String,
          default:'结束时间必须大于开始时间'
        },
  },
  watch: {
        'startEmptyMsg': {
          handler(newValue, oldValue) {
            this.initRules(['start'])
          },
        },
        'startErrorMsg': {
          handler(newValue, oldValue) {
            this.initRules(['start'])
          },
        },
        'endEmptyMsg': {
          handler(newValue, oldValue) {
            this.initRules(['end'])
          },
        },
        'endErrorMsg': {
          handler(newValue, oldValue) {
            this.initRules(['end'])
          },
        },
  },
  computed: {
    startValue: {
      get() {
        return this.start
      },
      set(val) {
        this.$emit('update:start', val?val.getTime():null)
      }
    },
    endValue: {
      get() {
        return this.end
      },
      set(val) {
        this.$emit('update:end', val?val.getTime():null)
      }
    }
  },
  data(){
    return {
        startRules: [],
        endRules:[]
    }
  },
  beforeMount() {
    this.initRules(['start','end'])
  },
  methods:{
      initRules(keys){
          //设置起始日期的校验规则
          if(keys&&keys.indexOf('start')>-1){
            let newStartRules=[];
            if(this.startEmptyMsg){
              newStartRules.push({required: true, message: this.startEmptyMsg, trigger: 'blur'});
            }
            newStartRules.push({ validator: (rule, value, callback) => {
                if (value&&this.endValue&&value>=this.endValue) {
                  callback(new Error(this.startErrorMsg));
                } else {
                  callback();
                }
              }, trigger: 'blur' })
            this.startRules=newStartRules;
          }
          //设置结束日期的校验规则
          if(keys&&keys.indexOf('end')>-1){
            let endStartRules=[];
            if(this.endEmptyMsg){
              endStartRules.push({required: true, message: this.endEmptyMsg, trigger: 'blur'});
            }
            endStartRules.push({ validator: (rule, value, callback) => {
                if (value&&this.startValue&&value<=this.startValue) {
                  callback(new Error(this.endErrorMsg));
                } else {
                  callback();
                }
              }, trigger: 'blur' })
            this.endRules=endStartRules;
          }
      },
      change(index){
        this.validate(()=>{
          this.$emit('change',[this.start,this.end],index)
        })
      },
      validate(successCallback,failCallback){
        this.$refs["dateFrom"].validate(valid => {
          if (valid) {
            if(successCallback){
              successCallback([this.start,this.end])
            }
          }else if(failCallback){
            failCallback([this.start,this.end])
          }
        })
      }
  }
}
</script>
