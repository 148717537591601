<template>
    <div class="actSet-wrapper">
        <div class='content'>
            <!--分享设置-->
            <div>
                <el-form hide-required-asterisk label-position="left" label-width="120px" ref="shareForm" :model="activity.settingShare" :rules="shareRules">
                    <el-form-item label="分享图片">
                        <imageContainer :isEditVisible.sync="isShareImg" :height="80" :width="80" :maxImageNum="1"
                                        shadowDes="去设置" :plusSize="16" plusColor="#eeeeee" btnName1="查看示例" btnColor1=""
                                        :ratioScaling=false :images="[showShareImage()]" @clickBtn1='isCase = true'></imageContainer>
                    </el-form-item>
                    <el-form-item label="活动分享标题" prop="activityShareTitle">
                        <el-input type="textarea" :rows="2" @change="change"
                                  placeholder="请输入活动分享标题，最多40个字，默认为：活动标题" maxlength="40"
                                  v-model.trim="activity.settingShare.activityShareTitle">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="选手分享标题">
                        <el-input type="textarea" :rows="2" @change="change"
                                placeholder="请输入选手分享标题，最多50个字，默认为：活动标题" maxlength="50"
                                v-model.trim="activity.settingShare.playerShareTitle">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="分享内容" prop="shareContent">
                        <el-input type="textarea" :rows="2" @change="change"
                                  placeholder="请输入分享内容，默认为：活动链接" maxlength="254"
                                  v-model.trim="activity.settingShare.shareContent">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <resourceDialog title="分享图片选择" :visible.sync="isShareImg" :resourceType="1" @changeResource="changeResource"></resourceDialog>
        <!--查看案例-->
        <el-dialog
            title="案例展示"
            :visible.sync="isCase"
            width="900px"
            >
            <p class='case-title'>
                设置<span>可自定义更符合活动的文案</span>
            </p>
            <div class='case-img1'><img :src="'files/images/userwebfront/share1.png'|appendImgUrl"></div>
            <p class='case-title'>
                效果展示<span>玩家分享到朋友圈或微信会话的内容即为自定义文案</span>
            </p>
             <div class='case-img2'>
                 <div>
                     <p>分享给好友</p>
                     <img :src="'files/images/userwebfront/share2.png'|appendImgUrl" alt="">
                 </div>
                 <div>
                      <p>分享到朋友圈</p>
                      <img :src="'files/images/userwebfront/share3.jpg'|appendImgUrl" alt=""  class='friend'>
                 </div>    
             </div>
        </el-dialog>
    </div>
</template>

<script>
    import {ActivityUtil, CommonUtil} from '@/js/commonUtil'
    import resourceDialog from '@/components/resourceDialog.vue'
    import imageContainer from '@/components/imageContainer.vue'
    export default {
        name: 'shareSet',
        data(){
            return {
                activity:{
                    id:0,
                    settingShare:{
                        shareImageUrl:null,
                        activityShareTitle:null,
                        playerShareTitle:null,
                        shareContent:null
                    },
                    shufflingImages:[],
                },
                shareRules:{
                    //activityShareTitle: [{required: true, message: '请输入活动分享标题', trigger: 'blur'}],
                    //shareContent: [{required: true, message: '请输入分享内容', trigger: 'blur'}]
                },
                isShareImg:false,
                isCase:false
            }
        },
        components:{
            resourceDialog,
            imageContainer
        },
        beforeMount(){
            this.getActivity();
        },
        methods:{
            getActivity(){
                ActivityUtil.get(ActivityUtil.getCurrentSettingActId(),data=>{
                    CommonUtil.copyProperty(data, this.activity);
                })
            },
            change(){
                this.$refs["shareForm"].validate(valid => {
                    if (valid) {
                        ActivityUtil.update(this.activity,['settingShare'],null,()=>{
                            ActivityUtil.refreshH5();
                        });
                    }
                })
            },
            changeResource(resource){
                this.activity.settingShare.shareImageUrl=resource.url;
                this.change();
            },
            showShareImage(){
              //如果设置了活动分享图标,则使用设置的图标
              if(this.activity.settingShare.shareImageUrl){
                return this.activity.settingShare.shareImageUrl;
              }
              //如果未设置分享图标,则优先使用第一张轮播图
              if(this.activity.shufflingImages&&this.activity.shufflingImages.length>0){
                return this.activity.shufflingImages[0];
              }
              //如果无轮播图,则显示默认分享图片
              return '';
            }
        }
    }
</script>
<style lang="scss" scoped>
    .actSet-wrapper{
        width:100%;
        .content{
            box-sizing: border-box;
            padding-top: 10px;
            max-width: 800px;
        }
    }
    .case-title{
         font-size: 18px; 
         span{
             font-size: 14px;
             margin-left: 10px;
         }
    }
    .case-img1{
      width: 100%;
      height: 300px;
      border: 1px solid #ccc;
      border-radius: 10px;
      margin: 20px 0;
      box-sizing: border-box;
      padding: 20px 30px;
      img{
          width: 100%;
          height: auto;
      }
    }
    .case-img2{
      width: 100%;
      height: 300px;
      border: 1px solid #ccc;
      border-radius: 10px;
      margin: 20px 0;
      box-sizing: border-box;
      display: flex;
      align-items:flex-start;
      justify-content: space-between;
      padding: 20px 30px;
      img{
           width: 350px;
          height: auto;
          margin-top: 20px;
      }
      .friend{
         
      }
    }
</style>
