<template>
  <div class="content-wrapper" style='padding-top: 20px;overflow-y:auto;'>
    <div class="customized-box">
      <div class="head"><p class="name">专属定制</p>
        <p class="desc">提供专业顾问对接，全程协助举办活动</p>
      </div>
      <div class="body">
        <div class="step"><img src="@/assets/user/customize/step1.png" alt="">
          <span>咨询客服</span>
        </div>
        <div class="line">
          <p>咨询客服，描述您的需求</p>
        </div>
        <div class="step">
          <img src="@/assets/user/customize/step2.png" alt="" class="two">
          <span>提交工单</span>
        </div>
        <div class="line">
          <p>提交需求文档，等待平台客服估价</p>
        </div>
        <div class="step">
          <img src="@/assets/user/customize/step3.png" alt="" class="three">
          <span>平台估价</span>
        </div>
        <div class="line"><p>付款，等待为您定制开发</p></div>
        <div class="step">
          <img src="@/assets/user/customize/step4.png"
               alt="" class="four">
          <span>定制开发</span>
        </div>
      </div>
      <div class="bottom">
        <div class="custom consult1">
          <p>个性化投票页面设计</p>
          <p>根据您的需求产出个性化模板</p>
          <p>全流程活动发布</p>
          <button type="button" class="el-button el-button--primary button" style="width: 140px;">
            <span>咨询客服</span></button>
        </div>
        <div class="custom consult2">
          <p>全自定义投票页面设计</p>
          <p>根据您的需求产出个性化功能</p>
          <p>全流程活动发布</p>
          <button type="button" class="el-button el-button--primary button" style="width: 140px;">
            <span>咨询客服</span></button>
        </div>
        <div class="custom consult3">
          <p>协助批量上传选手资料</p>
          <p>根据您的需求搭建投票活动</p>
          <p>全流程活动发布</p>
          <button type="button" class="el-button el-button--primary button" style="width: 140px;">
            <span>咨询客服</span></button>
        </div>
        <div class="custom consult4">
          <p>投票活动轮播图、装饰元素设计</p>
          <p>根据您的需求产出个性化页面样式</p>
          <p>全流程活动发布</p>
          <button type="button" class="el-button el-button--primary button" style="width: 140px;">
            <span>咨询客服</span></button>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showDialog" top="20vh" :close-on-click-modal="false">
      <div slot="title" style="color: #666;font-size: 16px;">咨询客服</div>
      <div class="content">
        <div class="item item-phone">
          <img class="item_icn" src="@/assets/phone.png">
          <p class="title">客服电话</p>
          <div class="phone">
            <p class="phone-name"> 客服: <span>{{ customerService.phone }}</span></p>
          </div>
        </div>
        <div class="item item-wx">
          <img class="item_icn" src="@/assets/wx.png">
          <p class="title">微信客服</p>
          <div class="wx-list">
            <div class="wx-item">
              <div><img :src="customerService.weixinCode" alt=""></div>
              <div class="name">客服</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {config} from '@/js/config';

export default {
  name: 'customService',
  data() {
    return {
      showDialog: false,
      customerService: config.customerService
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>

.customized-box {

  height: 100%;
  background-color: #fff;
  padding: 30px;
  box-sizing: border-box;


  .route-wrap-box > div {
    width: 100%;
    padding: 20px;
    box-sizing: border-box
  }

  .head {
    text-align: center
  }

  .head .name {
    color: #333;
    font-size: 36px
  }

  .head .desc {
    margin-top: 15px;
    color: #999;
    font-size: 16px
  }

  .body {
    width: 100%;
    height: 120px;
    margin: 40px auto 0 auto;
    background-size: 100% 100%;
    background-image: url(../../../assets/user/customize/customer-bg.png);
    display: flex;
    align-items: center;
    padding: 0 138px;
    box-sizing: border-box
  }

  .body .step {
    height: 24px;
    position: relative
  }

  .body .step img {
    width: 24px;
    height: 24px
  }

  .body .step span {
    white-space: nowrap;
    position: absolute;
    font-size: 16px;
    color: #251d1d;
    left: 50%;
    transform: translateX(-50%);
    bottom: -28px
  }

  .body .line {
    width: 300px;
    height: 1px;
    background: #fc564a;
    opacity: .5;
    margin: 0 3px
  }

  .body .line p {
    font-size: 14px;
    color: #666;
    margin-top: -30px;
    text-align: center
  }

  .bottom {
    width: 100%;
    height: 450px;
    margin: 30px auto 0 auto;
    display: flex;
    justify-content: space-between;

    .custom {
      width: 22%;
      height: 450px;
      padding-top: 250px;
      box-sizing: border-box;
      border-radius: 4px;
      text-align: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;

      p {
        color: #333;
        font-size: 14px;
        line-height: 30px
      }

      .button {
        width: 140px;
        margin: 30px auto 56px auto
      }

      .el-button {
        padding: 9px 0;
        display: inline-block;
        font-size: 14px;
        min-height: 35px;
        min-width: 100px
      }

      .el-button span {
        display: inline-flex;
        align-items: center
      }
    }

    .custom:hover {
      cursor: pointer;
      margin-top: -10px;
      transition: margin-top .5s;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .05);
      border: 1px solid #efecff
    }

    .consult1 {
      background-image: url("../../../assets/user/customize/custom1.png");
    }

    .consult2 {
      background-image: url("../../../assets/user/customize/custom2.png");
    }

    .consult3 {
      background-image: url("../../../assets/user/customize/custom3.png");
    }

    .consult4 {
      background-image: url("../../../assets/user/customize/custom4.png");
    }
  }


}


.pri-msg {
  background: #eee;
  border-radius: 5px;
  color: #fa7a5f;
  padding: 15px 20px;
}

.advice-card-box {
  margin-top: 30px;
  min-width: 1200px;

  .advice-card {
    width: 300px;
    margin-right: 25px;
    margin-bottom: 30px;

    .image {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

::v-deep .el-dialog {
  width: 640px;
}

.content {
  width: 100%;
  display: flex;
  padding: 40px 25px;

  .item {
    position: relative;
    width: 250px;
    height: 254px;
    border-radius: 4px;
    margin-left: 50px;

    .item_icn {
      width: 64px;
      position: absolute;
      left: calc(50% - 32px);
      top: -32px;
    }
  }

  .title {
    text-align: center;
    color: #666;
    font-size: 16px;
    padding-top: 68px;
  }

  .item-phone {
    background: #fff8f0;
    margin-left: 0;

    .phone {
      text-align: center;
      color: #666;
      line-height: 30px;
      padding-top: 30px;
    }
  }

  .item-wx {
    background: #f4fcf9;

    .wx-list {
      text-align: center;
      padding-top: 30px;

      .wx-item {
        display: inline-block;
        width: 64px;
        text-align: center;

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
