<template>
  <div>
    <ul class='color-wrapper'>
      <li v-for="(item,index) in colorList" :key='index' :style='`background:${item}`' @click="changeColor(item)">
        <i class='el-icon-check' v-if='item == color'></i>
      </li>
      <li><el-color-picker v-model="thisColor" size="small"></el-color-picker></li>
      <li class='checkBtn'  v-if="btnName" @click="clickBtn">{{btnName}}</li>
    </ul>
  </div>
</template>

<script>
import {CommonUtil} from '@/js/commonUtil'
export default {
  name: 'colorChoose',
  props: {
    color:{
      type: String,
      default: 'wight'
    },
    colorList:{
      type: Array,
      default: ()=>['#f75757','#B8860B','#f78780','#DEB887','#D2691E','#6495ED','#7cfcde','#f7567c']
    },
    btnName:{
      type: String,
      default: '默认'
    }
  },
  computed: {
    thisColor: {
      get () {
        return this.color
      },
      set (val) {
        this.changeColor(val);
      }
    }
  },
  methods: {
    changeColor(newColor){
      newColor = newColor?newColor:'';
      if(!CommonUtil.compare(this.color,newColor)){
        this.$emit('changeColor', newColor);
      }
    },
    clickBtn(){
      this.$emit('clickBtn')
    }
  }
}
</script>
<style lang="scss" scoped>
  .color-wrapper{
    width:100%;
    height:40px;
    li{
      width: 30px;
      height:30px;
      border-radius: 4px;
      float:left;
      margin-right: 5px;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
      color:#fff;
    }
    .checkBtn{
      cursor: pointer;
      margin-left: 40px;
      color: #409EFF;
    }
    ::v-deep .el-color-picker__trigger .el-icon-arrow-down:before{
      content: "自定义" !important;
      font-size: 15px;
    }
    ::v-deep .el-color-picker__trigger{
        width:60px !important;
        padding:0px;
    }
    ::v-deep .el-color-picker__color {
      border:none;
    }
    ::v-deep .el-color-picker__empty{
      width:100%;
      height:100%;
      top: 69%;
    }
    ::v-deep .el-icon-close::before{
      content:'自定义';
      font-size: 14px !important;
      color:#409EFF;
    }
  }

</style>
