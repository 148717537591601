<template>
  <div>
    <div class="user-banner">
      <div>尊敬的用户，欢迎创建微信投票评选活动</div>
      <div>平台支持图文投票、视频投票、分组投票和专题投票等多种投票形式，请点击【活动模版】选择您心仪的主题模版进行活动创建
      </div>
    </div>
    <div class="user-info user-body">
      <div class="info-item">
        <div class="item-icon">
          <el-avatar :size="40" :src="state.user.wxAvatar" v-if='state.user.wxAvatar'></el-avatar>
          <el-avatar icon="el-icon-user-solid" v-else></el-avatar>
        </div>
        <div class="item-body">
          <div class="item-result">
            {{ state.user.phone }}
          </div>
          <div class="item-content">
          </div>
        </div>
      </div>
      <div class="info-item">
        <div class="item-icon">
          <img src="@/assets/user/user-pay.png" alt="">
        </div>
        <div class="item-body">
          <div class="item-result">
            免费版
          </div>
          <div class="item-content">
            <div class="item-title">会员等级</div>
            <div class="item-operate">
              立即绑定>
            </div>
          </div>

        </div>
      </div>
      <div class="info-item">
        <div class="item-icon">
          <img src="@/assets/user/user-wechat.png" alt="">
        </div>
        <div class="item-body">
          <div class="item-result">
            未绑定
          </div>
          <div class="item-content">
            <div class="item-title">绑定微信</div>
            <div class="item-operate">立即绑定></div>
          </div>

        </div>
      </div>
      <div class="info-item">
        <div class="item-icon">
          <img src="@/assets/user/user-public.png" alt="">
        </div>
        <div class="item-body">
          <div class="item-result">
            未授权
          </div>
          <div class="item-content">
            <div class="item-title">公众号授权</div>
            <div class="item-operate">立即授权></div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-wrapper user-body" style="top: -35px;">
      <div class="wrapper-title">
        活动管理
      </div>
      <div class="activity-list">
        <div class="activity-item" @click="bindActivityChange(0)">
          <img src="@/assets/user/user-a-all.png" alt="">
          <div class="item-info">
            <div class="item-result">{{ userInfo.totalActNum }}</div>
            <div>全部活动</div>
          </div>
        </div>
        <div class="activity-item" @click="bindActivityChange(2)">
          <img src="@/assets/user/user-a-afoot.png" alt="">
          <div class="item-info">
            <div class="item-result">{{ userInfo.activateActNum }}</div>
            <div>进行中活动</div>
          </div>
        </div>
        <div class="activity-item" @click="bindActivityChange(1)">
          <img src="@/assets/user/user-a-wait.png" alt="">
          <div class="item-info">
            <div class="item-result">{{ userInfo.waitActNum }}</div>
            <div>未开始活动</div>
          </div>
        </div>
        <div class="activity-item" @click="bindActivityChange(3)">
          <img src="@/assets/user/user-a-end.png" alt="">
          <div class="item-info">
            <div class="item-result">{{ userInfo.endActNum }}</div>
            <div>已结束活动</div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-wrapper user-body">
      <div class="wrapper-title">
        快捷入口
      </div>
      <div class="inlet-list">
        <div class="inlet-item" @click="bindInletChange('Activity')">
          <img src="@/assets/user/user-a-all.png" alt="">
          <div class="item-title">
            活动管理
          </div>
        </div>
      </div>
    </div>
    <div class="user-body user-help">
      <div class="user-wrapper help-item">
        <div class="wrapper-title help-title">
          <div>常见问题</div>
          <div class="help-operate" @click="moreHelp">更多></div>
        </div>
        <div class="help-plan">
          <ul v-if="helpList.length">
            <li v-for="item in helpList" :key='item.id' @click="selectNew(item)">
              <div class="t1">{{ item.title }}</div>
              <div class="t2">{{ item.createTime | convertDate }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="user-wrapper help-item">
        <div class="wrapper-title help-title">
          <div>平台动态</div>
          <div class="help-operate" @click="moreHelp">更多></div>
        </div>
        <div class="help-plan">
          <ul v-if="newsList.length">
            <li v-for="item in newsList" :key='item.id' @click="selectNew(item)">
              <div class="t1">{{ item.title }}</div>
              <div class="t2">{{ item.createTime | convertDate }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ajaxUtil} from '@/js/ajaxUtil.js'
import {api} from '@/js/api.js'
import store from '@/store/store.js'

export default {
  name: 'UserCenter',
  data() {
    return {
      state: store.state,
      userInfo: {},
      helpList: [],
      newsList: [],
    }
  },
  beforeCreate() {
    //获取用户详情
    let customerId = store.state.user.id;
    ajaxUtil.ajaxJson(api.customer.getDetail.replace('{id}', customerId), null, 'GET', false).then((data) => {
      this.userInfo = data;
    })

    //获取动态类型
    ajaxUtil.ajaxJson(api.systemnews.allTypes, null, 'GET').then((data) => {
      //获取对应类型为‘常见问题’和‘平台动态’的动态列表各6条
      let helpTypeId = 1;
      let platformNewTypeId = 1;
      for (var index in data) {
        if (data[index].name === '常见问题') {
          helpTypeId = data[index].id;
        } else if (data[index].name === '平台动态') {
          platformNewTypeId = data[index].id;
        }
      }

      let helpNewUrl = api.systemnews.query.replace('{type}', helpTypeId).replace('{searchKey}', '').replace('{pageNo}', 1).replace('{pageCount}', 6);
      ajaxUtil.ajaxJson(helpNewUrl, null, 'GET', false).then(data => {
        this.helpList = data.resultList;
      })

      let systemNewsUrl = api.systemnews.query.replace('{type}', platformNewTypeId).replace('{searchKey}', '').replace('{pageNo}', 1).replace('{pageCount}', 6);
      ajaxUtil.ajaxJson(systemNewsUrl, null, 'GET', false).then(data => {
        this.newsList = data.resultList;
      })
    })
  },
  methods: {

    bindInletChange(name){
      this.$router.push({name: name})
    },

    moreHelp() {
      this.$router.push({name: "Help"})
    },

    bindActivityChange(status){
      this.$router.push({name: "ActivityList", params: {status: status.toString()}})
    },

    selectNew(row) {
      this.$router.push({path: '/helpInfo', query: {id: row.id}})
    }
  }
}
</script>
<style lang="scss" scoped>

.user-banner {
  background-image: url(../../../assets/user/user-banner.png);
  background-repeat: no-repeat;
  height: 177px;
  background-size: cover;
  color: #fff;
  font-size: 13px;
  line-height: 36px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 20px;
  z-index: -1;

  div:first-child {
    font-size: 18px;
  }
}

.user-info {
  height: 140px;
  background: rgba(243, 248, 252, .75);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
  border-radius: 6px;
  border: 1px solid #FFFFFF7F;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  top: -70px;
  display: flex;
  padding: 0 30px;
  box-sizing: border-box;
  justify-content: space-between;

  .info-item {
    display: flex;
    align-items: center;

    .item-icon {
      width: 55px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .item-body {
      padding: 0 10px;

      .item-result {
        color: black;
        height: 32px;
        line-height: 32px;
        font-size: 16px;
      }

      .item-content {
        height: 32px;
        line-height: 32px;
        display: flex;
        align-items: center;

        .item-title {
          width: 80px;
        }

        .item-operate {
          color: #409EFF;
          cursor: pointer;
        }
      }
    }


  }

}

.user-body {
  margin: 0 auto;
  position: relative;
  min-width: 1400px;
  width: 72%;
}

.user-wrapper {
  background: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0 20px;
  box-sizing: border-box;

  .wrapper-title {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #eee;
    color: #333;
    font-size: 14px;
    line-height: 48px;
    padding-left: 13px;
    position: relative;
    box-sizing: border-box;
  }

  .wrapper-title:after {
    content: "";
    width: 2px;
    height: 15px;
    background: #409EFF;
    border-radius: 1px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.activity-list {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  .activity-item {
    width: 25%;
    height: 120px;
    display: flex;
    padding-left: 30px;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    cursor: pointer;

    img {
      width: 60px;
    }

    .item-info {
      padding-left: 20px;

      .item-result {
        color: black;
        font-size: 18px;
        line-height: 36px;
      }
    }
  }

  .activity-item:hover{
    background-color: #f5f7fb;
  }

  .activity-item:after {
    content: "";
    position: absolute;
    left: 0;
    width: 1px;
    height: 46px;
    background: #eee;
  }

  .activity-item:first-child:after {
    content: "";
    background: #fff;
  }
}

.inlet-list{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;

  .inlet-item{
    width: calc(20% - 15px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background-color: #e6eef8;
    cursor: pointer;
    img{
      width: 40px;
      height: 40px;
    }
    .item-title{
      color: black;
      margin-left: 20px;
    }
  }

  .inlet-item:hover{
    background-color: #d4e6fc;
  }
}

.user-help {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  top: 35px;
  padding-bottom: 20px;

  .help-item {
    min-width: 690px;
    width: 45%;
    padding-bottom: 20px;

    .help-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .help-operate {
        color: #409EFF;
        cursor: pointer;
      }
    }

    .help-plan {
      height: 300px;
      overflow-y: auto;

      ul {
        height: 100%;
      }

      li {
        height: 48px;
        line-height: 48px;
        display: flex;
        padding-left: 12px;
        border-top: 1px dashed #eee;
        position: relative;
        cursor: pointer;

        .t1 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #666;
          font-size: 14px;
          width: 81%;
        }

        .t2 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #999;
          width: 19%;
          text-align: center;
        }
      }

      li:after {
        content: "";
        width: 4px;
        height: 4px;
        background: #bbb;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 100%;
      }

      li:hover {
        .t1 {
          color: #409EFF;
        }
      }
    }
  }


}
</style>
