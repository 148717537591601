<template>
    <div class="actSet-wrapper">
        <div class='content'>
            <!--活动动态 start-->
            <div class='basic'>
                <el-button type="primary" size='medium' @click='clickAddNews' style="margin-bottom: 20px">新增动态</el-button>
                <el-table ref="newsRef" :data="page.resultList" style="width: 100%" align="center" @selection-change="handleSelectionChange"
                        @select-all="()=>this.page.resultList.length<=0?this.$refs.newsRef.clearSelection():''">
                    <el-table-column align="center" type="selection" width="55"></el-table-column>
                    <el-table-column label="标题" width="200">
                      <template slot-scope="scope">
                        <el-row class="cursorPointer" @click.native="clickUpdateNews(scope.row)">
                          <el-col><span>{{scope.row.title}}</span></el-col>
                        </el-row>
                      </template>
                    </el-table-column>
                    <el-table-column label="显示" align="center" width="100">
                        <template slot-scope="scope">
                            <el-switch  v-model="scope.row.show" @change="updateShowStatus(scope.row.show,[scope.row.id])"> </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间"  width="180">
                        <template slot-scope="scope">{{scope.row.createTime|convertTime}}</template>
                    </el-table-column>
                    <el-table-column
                            label="操作" align="center">
                        <template slot-scope="scope">
                            <el-dropdown class="cursorPointer" placement='bottom' trigger="click">
                                <span class="el-dropdown-link">设置<i class="el-icon-arrow-down el-icon--right"></i></span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="clickUpdateNews(scope.row)" >编辑</el-dropdown-item>
                                    <el-dropdown-item @click.native="batchDel([scope.row.id])">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
                <div class='marTop20' >
                    <el-button type="primary"  size='mini' v-show="selectionIds.length>0"
                               @click="updateShowStatus(true,selectionIds)">显示</el-button>
                    <el-button type="primary" size='mini' v-show="selectionIds.length>0"
                               @click="updateShowStatus(false,selectionIds)">隐藏</el-button>
                    <el-button type="primary"  size='mini' v-show="selectionIds.length>0"
                               @click="batchDel(selectionIds)">删除</el-button>
                    <el-pagination class='fr' background layout="prev, pager, next" smallC
                                   :current-page.sync="pageNo" :page-size="page.pageCount" :total="page.totalCount"
                                   @current-change="queryActivityNews">
                    </el-pagination>
                </div>
            </div>
        </div>
        <adDynamic :visible.sync = 'dialogDynamic' :news="operateNews" @submit="submitNews"></adDynamic>
    </div>
</template>

<script>
    import adDynamic from '@/components/adDynamic.vue'
    import store from '@/store/store'
    import {ajaxUtil} from "@/js/ajaxUtil";
    import {api} from "@/js/api";
    import {CommonUtil,ActivityUtil} from "@/js/commonUtil";
    import {messages} from "@/js/messages";
    export default {
        name: 'newsSet',
        data(){
            return {
                dialogDynamic:false,
                pageNo:1,
                page:{
                  pageNo:1,
                  pageCount:5,
                  totalPage:0,
                  totalCount:0,
                  resultList:[]
                },
                operateNews:{
                  id:0,
                  title:null,
                  imageUrl:null,
                  content:null,
                  contentUrl:null,
                  isOutLink:false
                },
                selectionIds:[]
            }
        },
        components:{
            adDynamic
        },
        beforeMount(){
            this.queryActivityNews();
        },
        methods:{
            queryActivityNews(){
              this.selectionIds=[];
              let url = api.activityNews.query
                  .replace('{activityId}', ActivityUtil.getCurrentSettingActId()).replace('{showType}',2);
              url+='&pageNo='+this.pageNo+'&pageCount='+this.page.pageCount;
              ajaxUtil.ajaxPage(url,null,'GET',null,data=>{
                  CommonUtil.copyProperty(data,this.page);
                  if(this.page.resultList.length<=0){
                    this.$refs.newsRef.clearSelection();
                  }
              });
            },
            handleSelectionChange(val){
                //若选择的数组为空，即未选择任何项
                let ids=[];
                if(val&&val.length>0){
                    val.forEach(item=>{
                        ids.push(item.id);
                    })
                }
                this.selectionIds=ids;
            },
            clickAddNews(){
                //点击新增动态
                this.operateNews.id=0;
                this.operateNews.title=null;
                this.operateNews.imageUrl=null;
                this.operateNews.content=null;
                this.operateNews.contentUrl=null;
                this.operateNews.isOutLink=false;
                this.dialogDynamic=true;
            },
            clickUpdateNews(row){
                //点击修改动态
                ajaxUtil.ajaxJson(api.activityNews.get.replace('{id}',row.id),null,'GET').then(data=>{
                    CommonUtil.copyProperty(data,this.operateNews);
                    this.operateNews.isOutLink=this.operateNews.contentUrl!=null&&this.operateNews.contentUrl!='';
                    this.dialogDynamic=true;
                })
            },
            submitNews(news){
                if(news.id){
                    //提交修改动态
                    news.updateBy=store.state.user.id;
                    ajaxUtil.ajaxJson(api.activityNews.update,news,'PUT',null,true).then(data=>{
                        if(data.change){
                            messages.showSuccessMsg(messages.success.operation);
                            this.queryActivityNews();
                            ActivityUtil.refreshH5(false);
                        }
                    })
                }else {
                    //提交新增动态
                    news.activityId=ActivityUtil.getCurrentSettingActId();
                    news.createBy=store.state.user.id;
                    ajaxUtil.ajaxJson(api.activityNews.add,news,'POST',null,true).then(data=>{
                        messages.showSuccessMsg(messages.success.operation);
                        this.pageNo=1;
                        this.queryActivityNews();
                        ActivityUtil.refreshH5(false);
                    })
                }
            },
            batchDel(ids, activityId){
                if((!ids||ids.length<=0)&&!activityId){
                    return false;
                }
                this.$confirm('是否确认删除', '提示', {
                    type: 'warning'
                }).then(() => {
                    let body={
                        ids:ids?ids:null,
                        activityId:activityId?activityId:0,
                        updateBy:store.state.user.id
                    };
                    ajaxUtil.ajaxJson(api.activityNews.batchDelete, body, 'DELETE',null,true).then(data=>{
                        if(data.change){
                            messages.showSuccessMsg(messages.success.delete);
                            this.queryActivityNews();
                            ActivityUtil.refreshH5(false);
                        }
                    })
                }).catch(() => {
                    console.log("已取消")
                });
            },
            updateShowStatus(show, ids, activityId){
                if((!ids||ids.length<=0)&&!activityId){
                    return false;
                }
                let body={
                    show: show,
                    ids:ids?ids:null,
                    activityId:activityId?activityId:0,
                    updateBy:store.state.user.id
                };
                ajaxUtil.ajaxJson(api.activityNews.updateShowStatus, body, 'PUT',null,true).then(data=>{
                    if(data.change){
                        messages.showSuccessMsg(messages.success.operation);
                        this.queryActivityNews();
                        ActivityUtil.refreshH5(false);
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .actSet-wrapper{
        width:100%;
        .content{
            box-sizing: border-box;
            padding-top: 10px;
        }
    }
    .nameSetWrapper{
        li{
            width:50%;
            float:left;
            margin-bottom: 15px;
            .input-with-select{
                width: 200px;
            }
        }
    }
    .indrouceBasic{
        overflow: hidden;
        // width:650px;
        text-align: center;
    }
    .shareBasic{
        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 14px;
            color: #8c939d;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background:#eee;
        }
        .avatar {
            width: 60px;
            height: 60px;
            display: block;
        }
        .checkBtn{
            cursor: pointer;
            font-size: 14px;
            line-height: 60px;
            margin-left: 10px;
        }
    }
</style>
