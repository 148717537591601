import OSS from 'ali-oss'
import {api} from '../js/api'
import {ajaxUtil} from '../js/ajaxUtil'
import {encode} from '../js/aesencryp.js'
import {messages} from '../js/messages'
import {AuthUtil} from "../js/auth"
import * as imageConversion from 'image-conversion'

var baseDir = 'public/user/'; //文件上传的目录

var OssFileUploadUtil = {
    uploadImg: function (file, options, successCallback, failCallback) {
        let regUuid = AuthUtil.getSpecifyUuid("reg");
        let validateToken = encode(regUuid);
        let requestData = {validateToken: validateToken};
        ajaxUtil.ajaxJson(api.oss.getTempToken, requestData, 'POST').then(data => {
            let client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou,
                //后台返回了regionId: 如 cn-hangzhou, 所以这里拼接上oss-
                region: 'oss-' + data.region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: data.accessKeyId,
                accessKeySecret: data.accessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: data.stsToken,
                // 填写Bucket名称。
                bucket: data.bucket
            });

            //上传文件
            let fileName = this.generateNewFileUri(file.name);
            // 压缩图片至100KB以内
            imageConversion.compressAccurately(new Blob([file]), 300).then(res => {
                res = new File([res], file.name, {
                    type: res.type,
                    lastModified: Date.now()
                });
                client.put(fileName, res).then((res) => {
                    if (successCallback != null) {
                        successCallback(res, fileName);
                    }
                }).catch(err => {
                    if (failCallback != null) {
                        failCallback(err);
                    } else {
                        messages.showErrorMsg(messages.error.upload);
                    }
                })
            }).catch(err => {
                if (failCallback != null) {
                    failCallback(err);
                } else {
                    messages.showErrorMsg(messages.error.upload);
                }
            })
        }, err => {
            if (failCallback != null) {
                failCallback(err);
            } else {
                messages.showErrorMsg(messages.error.upload);
            }
        })
    },

    generateNewFileUri: function (filename) {
        let suffix = this.get_suffix(filename);
        var randomString = new Date().getTime() + "";
        var newFileName = randomString + suffix;
        var split = randomString.split("");
        var newUri = baseDir + split[split.length - 2] + "/" + split[split.length - 1] + "/" + newFileName;
        return newUri
    },

    get_suffix: function (filename) {
        let suffix = '';
        if (filename == null) {
            return suffix;
        }
        var pos = filename.lastIndexOf('.');
        if (pos != -1) {
            suffix = filename.substring(pos)
        }
        return suffix;
    }
}

export {OssFileUploadUtil};
