<template>
  <el-dialog :visible.sync="dialogVisible" :width="width" :top="top" :close-on-click-modal="false">
    <div slot="title">
      <div class="span-box align-left">
        <span class="dialog-title marRight6">{{title}}</span>
        <span style="color: red">{{msg}}</span>
      </div>
    </div>
    <el-row class="dialog-row-name">
      <el-col><p class='topic-name'>{{content}}</p></el-col>
    </el-row>
    <el-row class="dialog-foot marTop50">
      <el-button @click="dialogVisible = false" class="button" >取 消</el-button>
      <el-button type="primary" @click="del" class="button">确 定</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: 'topicAct',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width:{
      type: String,
      default: '30%'
    },
    top:{
      type: String,
      default: '30vh'
    },
    delItem: {
      type: Object,//绑定需要删除的对象
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    msg: {
      type: String,
      default: ''
    },
    content:{
      type: String,
      default: ''
    }
  },
  computed: {
    dialogVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    del(){
      this.$emit('del',this.delItem)
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-row-name{
  margin-bottom: 40px;
  font-size: 16px;
}
.dialog-title{
  font-size: 16px;
  color: #303133;
}
.dialog-foot{
  text-align: center;
  .button{
    width:98px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
.topic-name{
  text-align: center;
  font-size: 20px;
}
</style>
