<template>
  <div class="votesRank-wrapper">
    <div class='rank-header'>
      <h2>{{activity.name}} - 操作记录</h2>
    </div>

    <div class='votes-table'>
      <el-table :data="page.resultList" style="width: 100%">
        <el-table-column prop="describe" label="日志"></el-table-column>
        <el-table-column align="center" prop="createTime" label="时间" width="250">
          <template slot-scope="props">{{props.row.createTime|convertTime}}</template>
        </el-table-column>
        <el-table-column align="center" prop="ip" width="250" label="IP"></el-table-column>
        <el-table-column align="center" prop="address" width='200' label="地区"></el-table-column>
      </el-table>
    </div>
    <div class='pagination-box'>
      <el-pagination background layout="total,prev, pager, next"
                     :current-page.sync="pageNo" :page-size="page.pageCount" :total="page.totalCount"
                     @current-change="getLogs">
      </el-pagination>
    </div>

  </div>
</template>

<script>
    import {ajaxUtil} from "@/js/ajaxUtil";
    import {api} from "@/js/api";
    import {ActivityUtil, CommonUtil} from "@/js/commonUtil";
    import {StorageUtil} from "@/js/auth";
export default {
  name: 'operateLog',
  data(){
    return {
     activity:{
       name:null
     },
     queryParam:{
       activityId:'',
       playerId:''
     },
     pageNo:1,
     page:{
       pageNo:1,
       pageCount:4,
       totalCount:0,
       totalPage:0,
       resultList:[
       ]
     }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'ActivityList') {
      let actListQueryParams=StorageUtil.getObject('actListQueryParams');
      if(actListQueryParams){
        actListQueryParams.query=true;
        StorageUtil.storeObject('actListQueryParams',actListQueryParams);
      }
    }else if(!to.path.startsWith('/activity/')){
      StorageUtil.removeObject('actListQueryParams');
    }
    next()
  },
  beforeMount(){
    let params = this.$route.query;
    if(params){
      this.queryParam.activityId=params.activityId;
      this.queryParam.playerId=params.playerId;
    }
    this.getActivity();
    this.research();
  },
  methods:{
    getActivity(){
      if(this.queryParam.activityId){
        ActivityUtil.get(this.queryParam.activityId,data=>{
          CommonUtil.copyProperty(data, this.activity);
        })
      }
    },
    research(){
      this.pageNo=1;
      this.getLogs();
    },
    getLogs(){
      this.queryParam.pageNo=this.pageNo;
      this.queryParam.pageCount=this.page.pageCount;
      let url=api.player.log+'?'+CommonUtil.getAllProperty(this.queryParam);
      ajaxUtil.ajaxPage(url, null, 'GET', null, data=>{
        CommonUtil.copyProperty(data,this.page);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.votesRank-wrapper{
  .rank-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h2{
    font-size: 16px;
    font-weight: normal;
    color:#333;
  }
  .votes-table{
    margin-top: 30px;
  }
  .rankNUmberBox{
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
  }
  $bgColor: #EBCB85,#F5E5C2,#D8D8D8;
  $fontColor:#9A6C20,#9A6C20,#666666;
  @for $i from 1 to 4{
    $item: nth($bgColor, $i);
    $col: nth($fontColor,$i);
    .rankNumber#{$i}{
        width:30px;
        height:30px;
        background: $item;
        color:$col;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        display: inline-block;
      }
  }
  .pagination-box{
    text-align:right;
    margin-top: 30px;
  }
}

</style>
