<template>
  <div class="postStyle-wrapper" id='poster0'>
    <img :src="activity.settingShufflingImages[0].shufflingImageUrl | appendImgUrl" alt="" class='poster-img'>
    <h2 class='act-name'>{{activity.name}}</h2>
    <div class='code-wrapper'>
        <img :src="getQrCode()" alt="">
        <p>识别二维码参与活动</p>
    </div>
    <div class='act-time'>
      <p class=''> 活动开始: {{activity.startTime | convertTime}}</p>
      <p class=' '> 活动结束: {{activity.endTime | convertTime}}</p>
    </div>
  
  </div>
</template>

<script>
  import {QrCode} from '@/js/qrcodeUtil'
export default {
  name: 'posterDefalut',
  data(){
    return {
    }
  },
    props:['activity','h5Url'],
  methods:{
      getQrCode() {
          return QrCode.url(this.h5Url, 159);
      },
  }
}
</script>
<style lang="scss" scoped>
.postStyle-wrapper{
  width: 100%;
  text-align: center;
  background:#fff;
  padding-bottom: 15px;
  height: 667px;
  position:relative;
  .poster-img{
    width: 376px;
    height: 235px;
  }
  .act-name{
    font-size: 14px;
    font-weight: normal;
    color:#666;
    text-align: left;
    text-indent: 10px;
    padding:10px 0;
    margin:0 20px;
    line-height: 24px;
    border-bottom:1px solid #EEEEEE
  }
   
  .code-wrapper{
    width:159px;
    height:auto;
    margin-top: 45px;
    margin-left: 108px;
    img{
      width: 159px;
      height:159px;
    }
    p{
      margin-top: 10px;
    }
  }
  .act-time{
    position:absolute;
    bottom:0;
    left:0;
    width: 100%;
    height:91px;
    background: #F5F5F5;
    font-size: 13px;
    box-sizing: border-box;
    padding-top: 21px;
    color:#999;
    line-height: 25px;
  }
}
</style>
