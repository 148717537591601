<template>
  <el-drawer class="player-statistics" :visible.sync="visible"
             direction="rtl" :before-close="bindCloseChange" :size="800">
    <template slot="title">
      <div class="player">
        <div class="avatar">
          <el-avatar v-if="player.imageUrl" size="medium" :src="player.imageUrl|appendImgUrl"></el-avatar>
          <el-avatar v-else size="medium" icon="el-icon-user-solid"></el-avatar>
        </div>
        <div class="info">
          <div class="num">{{ player.num }}号</div>
          <div class="name">{{ player.name }}</div>
        </div>
      </div>
    </template>
    <div class="content">
      <el-tabs v-model="active" @tab-click="bindTabChange">
        <el-tab-pane label="投票统计" name="count">
          <div class="count">
            <div class="chart">
              <div class="header">
                <div class="title">
                  <span>活动票数/天</span>
                  <span class="num">(当日票数：{{ countData.todayVoteNum }}票)</span>
                </div>
                <div class="date">
                  <el-date-picker @change="getVoteData"
                                  v-model="countData.voteDate"
                                  size='small'
                                  align="right"
                                  type="date"
                                  placeholder="选择日期"
                                  :clearable=false
                                  :picker-options="countData.pickerOptions">
                  </el-date-picker>
                </div>
              </div>
              <div id="playerVote" class="content"></div>
            </div>
            <div class="chart">
              <div class="header">
                <div class="title">
                  <span>活动访问量/周</span>
                  <span class="num">(当日访问量：{{ countData.todayViewNum }})</span>
                </div>
                <div class="date">
                  <el-date-picker @change="getViewData"
                                  v-model="countData.viewDate"
                                  size='small'
                                  align="right"
                                  type="date"
                                  placeholder="选择日期"
                                  :clearable=false
                                  :picker-options="countData.pickerOptions">
                  </el-date-picker>
                </div>
              </div>
              <div id="playerVisit" class="content"></div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="投票日志" name="vote">
          <div class="vote">
            <div class="list">
              <el-table size="small" :data="voteData.resultList">
                <el-table-column label="序号" width="80" align="center">
                  <template v-slot="{$index}">{{ (voteData.pageNo - 1) * voteData.pageCount + $index + 1 }}</template>
                </el-table-column>
                <el-table-column label="微信">
                  <template v-slot="{row}">
                    <div class="wx">
                      <div class="avatar">
                        <img src="@/assets/wx.png" alt="">
                      </div>
                      <div class="name">{{ row.userWxNickName }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="选手">
                  <template v-slot="{row}">
                    <div class="player">
                      <div class="num">{{ row.playerNum }}号</div>
                      <div class="name">{{ row.playerName }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="ip" width="110" label="IP"></el-table-column>
                <el-table-column prop="address" label="地区"></el-table-column>
                <el-table-column label="投票时间" width="150">
                  <template v-slot="{row}">{{ row.createTime|convertTime }}</template>
                </el-table-column>
              </el-table>
            </div>
            <div class="pagination-box">
              <el-pagination background layout="total,prev, pager, next"
                             :current-page.sync="voteData.pageNo"
                             :page-size="voteData.pageCount"
                             :total="voteData.totalCount"
                             @current-change="getVoteLogList">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="operate">
          <div class="operate">
            <div class='list'>
              <el-table size="small" :data="operateData.resultList">
                <el-table-column prop="describe" label="操作内容"></el-table-column>
                <el-table-column align="center" prop="createTime" label="时间" width="200">
                  <template v-slot="{row}">{{ row.createTime|convertTime }}</template>
                </el-table-column>
                <el-table-column align="center" prop="ip" width="110" label="IP"></el-table-column>
                <el-table-column align="center" prop="address" width='150' label="地区"></el-table-column>
              </el-table>
            </div>
            <div class='pagination-box'>
              <el-pagination background layout="total,prev, pager, next"
                             :current-page.sync="operateData.pageNo" :page-size="operateData.pageCount"
                             :total="operateData.totalCount"
                             @current-change="getOperateLogList">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>

</template>

<script>
import * as echarts from 'echarts/core';
import {GridComponent, TooltipComponent} from 'echarts/components';//TooltipComponentOption
import {LineChart} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {CanvasRenderer} from 'echarts/renderers';
import moment from 'moment';
import {ajaxUtil} from "@/js/ajaxUtil";
import {api} from "@/js/api";
import {ActivityUtil, CommonUtil} from "@/js/commonUtil";

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition, TooltipComponent]);

export default {
  name: 'votesCount',
  data() {
    return {
      active: 'count',
      actName: '',
      actId: ActivityUtil.getCurrentSettingActId(),
      countData: {
        todayVoteNum: 0,//当日的票数
        voteDate: new Date(),
        todayViewNum: 0,//当日访问量
        viewDate: new Date(new Date().getTime() - 3600 * 1000 * 24 * 6),
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        }
      },
      voteData: {
        pageNo: 1,
        pageCount: 8,
        totalCount: 0,
        totalPage: 0,
        resultList: [],
      },
      operateData: {
        pageNo: 1,
        pageCount: 8,
        totalCount: 0,
        totalPage: 0,
        resultList: [],
      },
    }
  },
  props: ['visible', 'player'],
  watch: {
    visible(val) {
      if (val) {
        this.active = 'count';
        this.getVoteData();
        this.getViewData();
      }
    },
  },
  beforeMount() {
    this.createAvtBar();
    this.createVisitBar();
  },
  methods: {
    bindTabChange(tab, event) {
      switch (tab.name) {
        case 'count':
          this.getVoteData();
          this.getViewData()
          break;
        case 'vote':
          this.getVoteLogList()
          break;
        case 'operate':
          this.getOperateLogList()
          break;
      }
    },
    getVoteData() {
      let day = moment(this.countData.voteDate.getTime()).format('YYYY-MM-DD');
      let url = api.statistics.getPlayerVoteStatistics
          .replace('{playerId}', this.player.id).replace('{day}', day);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        let {currentDayVoteNum, statistics} = data
        this.countData.todayVoteNum = currentDayVoteNum;
        this.createAvtBar(statistics);
      })
    },
    getViewData() {
      let day = moment(this.countData.viewDate.getTime()).format('YYYY-MM-DD');
      let url = api.statistics.getPlayerViewStatistics
          .replace('{playerId}', this.player.id).replace('{day}', day);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        let {statistics} = data
        let today = moment(new Date().getTime()).format('YYYY-MM-DD');
        for (let i = 0; i < statistics.length; i++) {
          if (statistics[i].day == today) {
            this.countData.todayViewNum = statistics[i].viewNum;
            break;
          }
        }
        this.createVisitBar(statistics);
      })
    },
    createAvtBar(data) {
      let chartDom = document.getElementById('playerVote');
      if (chartDom) {
        let myChart = echarts.init(chartDom);
        let hours = [];
        let hoursData = [];
        if (data && data.length) {
          data.forEach(item => {
            hours.push(item.hour);
            hoursData.push(item.voteNum);
          });
        } else {
          for (let i = 0; i < 24; i++) {
            hours.push(i);
            hoursData.push(0);
          }
        }
        let option = this.echartsOption(hours, hoursData, '票数');
        option && myChart.setOption(option);
      }
    },
    createVisitBar(data) {
      let chartDom = document.getElementById('playerVisit');
      if (chartDom) {
        let myChart = echarts.init(chartDom);
        let days = [];
        let daysData = [];
        if (data && data.length) {
          data.forEach(item => {
            days.push(item.day);
            daysData.push(item.viewNum);
          });
        } else {
          let time = this.countData.viewDate.getTime();
          for (let i = 0; i < 7; i++) {
            let day = moment(time + 3600 * 1000 * 24 * i).format('YYYY-MM-DD');
            days.push(day);
            daysData.push(0);
          }
        }
        let option = this.echartsOption(days, daysData, '访问量');
        option && myChart.setOption(option);
      }
    },
    echartsOption(xAxisData, seriesData, valueKey) {
      return {
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: seriesData,
            type: 'line'
          }
        ],
        tooltip: {
          textStyle: {
            align: 'left',
            color: '#fff',
            fontSize: 14
          },
          backgroundColor: '#888888',
          padding: [2, 10, 5, 10],
          trigger: 'axis',
          //自定义echarts tooltip的显示位置
          position: function (position) {
            return [position[0] + 5, position[1]];
          },
          axisPointer: {
            type: 'line',
            lineStyle: {
              type: 'solid',
              width: 1,
              color: '#999'
            }
          },
          formatter: function (params) {
            let name = params[0].name;
            let value = params[0].value;
            return name + '<br>' + valueKey + '：' + value;
          }
        }
      };
    },

    getVoteLogList() {
      let url = api.statistics.getVoteLogList +
          '?activityId=' + this.actId + '&playerId=' + this.player.id +
          '&pageNo=' + this.voteData.pageNo + '&pageCount=' + this.voteData.pageCount;
      ajaxUtil.ajaxPage(url, null, 'GET', null, data => {
        this.voteData = data
      })
    },

    getOperateLogList() {
      let url = api.player.log +
          '?activityId=' + this.actId + '&playerId=' + this.player.id +
          '&pageNo=' + this.operateData.pageNo + '&pageCount=' + this.operateData.pageCount;
      ajaxUtil.ajaxPage(url, null, 'GET', null, data => {
        this.operateData = data
      })
    },

    bindCloseChange() {
      this.$emit('close')
    },
  }
}
</script>
<style lang="scss" scoped>
.player-statistics {
  .player {
    display: flex;
    justify-items: center;
    color: #a1a1a1;

    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .info {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 0 5px;

      .num {
        width: 60px;
      }

      .name {
        color: #000;
      }
    }
  }

  .content {
    box-sizing: border-box;
    padding: 0 20px;

    .count {

      .operate {

      }

      .count {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item {
          width: 22%;
          padding: 30px 0;
          text-align: center;
          background-color: #f1fbff;
          border-radius: 10px;

          .num {
            color: #409eff;
            font-size: 20px;
            line-height: 40px;
            height: 40px;
          }

          .title {
            color: #afafaf;
            font-size: 16px;
          }
        }
      }


      .chart {
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
        }

        .title {
          color: #000;

          .num {
            color: #afafaf;
            margin-left: 10px;
          }
        }

        .content {
          width: 100%;
          height: 400px;
        }
      }
    }

    .vote {
      .list {
        margin: 20px 0;

        .wx {
          display: flex;
          align-items: center;

          .avatar {
            width: 50px;

            img {
              width: 100%;
              display: block;
            }
          }

          .name {
            padding: 5px;
          }
        }
      }
    }

    .operate {
      .list {
        margin: 20px 0;
      }
    }

    .pagination-box {
      display: flex;
      justify-content: right;
    }
  }
}


</style>
