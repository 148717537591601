<template>
  <div class="product-content">
    <div class="banner">
      <div class="w1">
        <img src="@/assets/official/product/banner_img.fd5375dc.png" alt="" srcset="" class="img">
        <img src="@/assets/official/product/banner_txt.8337b4e3.png" alt="" srcset="" class="txt">
        <a href="/act/theme" class="a">创建活动</a>
      </div>
    </div>
    <div class="flow-path">
      <div class="title"><p class="t1">创建流程</p>
        <div class="t2">方便快捷，零成本学习创建活动，分分钟搞定您的活动需求</div>
      </div>
      <img class="all" src="@/assets/official/product/lc2.d81542a6.png" alt="">
      <div class="list">
        <div class="row">
          <div class="item">
            <img class="icon" src="@/assets/official/product/flow_img1.png" alt="">
            <div class="t1">1.选择模板</div>
            <div class="t2">海量模板选择，全行业覆盖风格展示。</div>
          </div>
          <div class="arrow-right">
            <img src="@/assets/official/product/flow_arrow.png" alt="">
          </div>
          <div class="item">
            <img class="icon" src="@/assets/official/product/flow_img2.png" alt="">
            <div class="t1">2.创建活动</div>
            <div class="t2">点击模板预览模板样式，一键创建活动</div>
          </div>
        </div>
        <div class="arrow-down">
          <img src="@/assets/official/product/flow_arrow.png" alt="">
        </div>
        <div class="row">
          <div class="item">
            <img class="icon" src="@/assets/official/product/flow_img4.png" alt="">
            <div class="t1">4.发布活动</div>
            <div class="t2">设置活动，添加选手，一键发布活动。</div>
          </div>
          <div class="arrow-left">
            <img src="@/assets/official/product/flow_arrow.png" alt="">
          </div>
          <div class="item">
            <img class="icon" src="@/assets/official/product/flow_img3.png" alt="">
            <div class="t1">3.编辑活动</div>
            <div class="t2">自定义更换模板、页面设置及活动内容。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mcability">
      <div class="w1">
        <div class="title"><p class="t1">平台功能</p>
          <div class="t2">活动类型丰富，投票类型多样，功能全面，操作简单</div>
        </div>
        <ul>
          <li v-for="(item,index) in mcability" :key="index">
            <img :src="item.img" alt="">
            <div class="t1">{{ item.title }}</div>
            <div class="t2">{{ item.blurb }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="unveil-banner">
      <div class="title"><p class="t1">服务支持</p>
        <div class="t2">提供给活动举办方或合作方营销策划及市场推广等支持</div>
      </div>
      <el-carousel :interval="4000" arrow="never" indicator-position="outside">
        <el-carousel-item v-for="(item,index) in unveilBanner" :key="index">
          <div class="bannerMin">
            <div class="text">
              <p class="t1 f5">{{ item.title }}</p>
              <div class="t2">{{ item.blurb }}</div>
            </div>
            <div class="img">
              <img :src="item.img" alt="">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="advantage">
      <div class="w1">
        <div class="title"><p class="t1">平台优势</p>
          <div class="t2">数十万用户信赖，举办活动数超100万+；服务数万家企业机构</div>
        </div>
        <div class="list">
          <div class="advantage_container">
            <div class="img">
              <img src="@/assets/official/product/advantage-img1.png" alt="">
            </div>
            <div class="t1">稳定性</div>
            <div class="t2">阿里云集群服务器架构<br>平台云数据建模投票行为，加入微信云黑名单<br
            >支持高并发流量大型评选活动<br>平台扩展性强，可以对大型活动实时增加服务器配置
            </div>
          </div>
          <div class="advantage_container">
            <div class="img">
              <img src="@/assets/official/product/advantage-img2.png" alt="">
            </div>
            <div class="t1">安全性</div>
            <div class="t2">多重智能防刷机制自主研发的加密防破解算法<br>系统自动对选手票数，增长速度进行实时监测，判断选手是否有刷票嫌疑<br
            >平台云数据建模投票行为，加入微信云黑名单<br>投票地区、投票IP、投票时间段、投票验证码等限制投票机制<br
            >详细投票日志、投票统计查看
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="customer">
      <div class="title">
        <p class="t1">客户说</p>
        <div class="t2">我们致力于客户服务，拥有50万+的客户群体，得到了他们的一致好评</div>
      </div>
      <div class="listData">
        <div class="list" v-for="(item,index) in customer" :key="index">
          <div class="img">
            <img :src="item.img" alt="">
          </div>
          <div class="t1">{{ item.title }}</div>
          <div class="t2">{{ item.blurb }}</div>
        </div>
      </div>
    </div>
    <div class="enterprise">
      <div class="title"><p class="t1">合作企业</p>
        <div class="t2">数十万用户的信赖,为数万家企业和机构提供服务,成功举办活动数超100万+</div>
      </div>
      <img src="@/assets/official/product/gs.2506f790.png" alt="" srcset="" class="all w1">
      <el-carousel class="list" :interval="4000" arrow="never" indicator-position="outside">
        <el-carousel-item v-for="(item,index) in enterprise" :key="index">
          <div class="img">
            <img :src="item.img" alt="">
            <!--            <img src="@/assets/official/product/enterprise1.png" alt="">-->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>

export default {
  name: 'product',
  data() {
    return {
      mcability: [
        {
          title: '图文投票活动',
          blurb: '以图片的方式进行选手展示',
          img: require('@/assets/official/product/mcability-img1.png')
        },
        {
          title: '视频投票活动',
          blurb: '以视频的方式进行选手展示',
          img: require('@/assets/official/product/mcability-img2.png')
        },
        {
          title: '多分组投票活动',
          blurb: '可以将选手分成多组，设置多分组联合投票，分组之间排斥逻辑投票等多种投票逻辑',
          img: require('@/assets/official/product/mcability-img3.png')
        },
        {
          title: '在线自助报名活动',
          blurb: '选手在线报名，主办方只需在后台审核即可',
          img: require('@/assets/official/product/mcability-img4.png')
        },
        {
          title: '送礼物投票活动',
          blurb: '将礼物送给选手的投票活动；礼物价值将影响选手排名',
          img: require('@/assets/official/product/mcability-img5.png')
        },
        {
          title: '专题活动',
          blurb: '可以将多个关联的活动放在同一个页面评选',
          img: require('@/assets/official/product/mcability-img6.png')
        },
        {
          title: '晋级赛投票活动',
          blurb: '淘汰赛机制，可举办多轮晋级赛活动',
          img: require('@/assets/official/product/mcability-img7.png')
        },
        {
          title: '关注投票活动',
          blurb: '先关注微信公众号才可以进行投票或报名参与活动',
          img: require('@/assets/official/product/mcability-img8.png')
        },
        {
          title: '多赛区投票活动',
          blurb: '以单位、地区或者其他因素对选手分区，多个赛区共同评选的联合投票',
          img: require('@/assets/official/product/mcability-img9.png')
        },
        {
          title: '现场大屏',
          blurb: '提供给活动举办方展示在线下的活动现场，现场大屏展示数据与线上同步',
          img: require('@/assets/official/product/mcability-img10.png')
        },
        {
          title: '多种投票逻辑',
          blurb: '单选、多选、必选投票多分组联合、多分组互斥投票',
          img: require('@/assets/official/product/mcability-img11.png')
        },
        {
          title: '广告系统',
          blurb: '开屏广告、动态、轮播图、底部导航、投票成功提示弹窗都可设置主办方宣传内容',
          img: require('@/assets/official/product/mcability-img12.png')
        },
        {
          title: '快速创建',
          blurb: '一键创建活动',
          img: require('@/assets/official/product/mcability-img13.png')
        },
        {
          title: '实时预览',
          blurb: '提供流畅的用户体验',
          img: require('@/assets/official/product/mcability-img14.png')
        },
        {
          title: '数据保护',
          blurb: '力保活动数据真实有效，活动公平公正，保障投票真实性',
          img: require('@/assets/official/product/mcability-img15.png')
        },
        {
          title: '多种活动方案',
          blurb: '提供多场景，多行业的投票活动解决方案',
          img: require('@/assets/official/product/mcability-img16.png')
        },
      ],
      unveilBanner: [
        {
          title: '活动策划',
          blurb: '为举办方提供详细的活动策划方案，根据活动类型设计活动页面，定制专属的服务器配置',
          img: require('@/assets/official/product/lunbo1.png')
        },
        {
          title: '活动宣传',
          blurb: '提供举办方的活动宣传物料支持，可在权威媒体或广播电视台进行宣传',
          img: require('@/assets/official/product/lunbo2.png')
        },
        {
          title: '广告投放',
          blurb: '可为活动举办方需要宣传的产品进行多媒体广告投放，便于活动举办方达到活动宣传目的',
          img: require('@/assets/official/product/lunbo3.png')
        },
      ],
      customer: [
        {
          title: '王先生',
          blurb: '服务热枕到位，专属客服提供创建投票活动、上传活动选手等指导服务，且技术团队随时在跟进。',
          img: require('@/assets/official/product/custom1.png')
        },
        {
          title: '李女士',
          blurb: '多种风格主题任意挑选，可自定义搭配主题元素，一键创建属于我自己的独一无二的投票活动。',
          img: require('@/assets/official/product/custom2.png')
        },
        {
          title: '孙女士',
          blurb: '功能强大，操作简单。可制作多种投票类型。还可以定制专属投票活动，满足我们的各种活动需求。',
          img: require('@/assets/official/product/custom3.png')
        },
        {
          title: '赵先生',
          blurb: '专业的技术团队，提供优质的技术支持，拥有优秀的防刷能力，保障活动公平公正的开展。',
          img: require('@/assets/official/product/home-banner1.png')
        },
      ],
      enterprise: [
        {img: require('@/assets/official/product/enterprise1.png')},
        {img: require('@/assets/official/product/enterprise2.png')},
        {img: require('@/assets/official/product/enterprise3.png')},
        {img: require('@/assets/official/product/enterprise4.png')},
        {img: require('@/assets/official/product/enterprise5.png')},
        {img: require('@/assets/official/product/enterprise6.png')},
        {img: require('@/assets/official/product/enterprise7.png')},
        {img: require('@/assets/official/product/enterprise8.png')},
        {img: require('@/assets/official/product/enterprise9.png')},
        {img: require('@/assets/official/product/enterprise10.png')},
        {img: require('@/assets/official/product/enterprise11.png')},
        {img: require('@/assets/official/product/enterprise12.png')},
      ],
    }
  },
  methods: {
    createActivity() {
      this.$router.push('/login')
    },
  }
}
</script>
<style src="./product.scss" lang="scss" scoped>

</style>
