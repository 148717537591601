<template>
  <div :class="['vote-screen',typeClass]">
    <div class="screen-header">
      <div class="header-title" v-if="activity.settingScreen">
        {{ activity.settingScreen.title }}
      </div>
      <div class="header-countdown">
        <template v-if="new Date().getTime()>endTime||hasEnd">
          <span>活动已结束</span>
        </template>
        <template v-else>
          <div class="countdown-label">倒计时</div>
          <div class="countdown-time">{{ countdown[0] }}</div>
          <div class="countdown-time">{{ countdown[1] }}</div>
          <div class="countdown-label">天</div>
          <div class="countdown-time">{{ countdown[2] }}</div>
          <div class="countdown-time">{{ countdown[3] }}</div>
          <div class="countdown-label">时</div>
          <div class="countdown-time">{{ countdown[4] }}</div>
          <div class="countdown-time">{{ countdown[5] }}</div>
          <div class="countdown-label">分</div>
          <div class="countdown-time">{{ countdown[6] }}</div>
          <div class="countdown-time">{{ countdown[7] }}</div>
          <div class="countdown-label">秒</div>
        </template>

      </div>
    </div>
    <div class="screen-pane">
      <div class="screen-rank">
        <div class="rank-item" v-for="(item,index) in rankList" :key="index">
          <div class="player-info">
            <div class="player-num">
              {{ item.num }}号
            </div>
            <div class="player-cover">
              <img :src="avatarFilter(item.imageUrl)" alt="">
            </div>
            <div class="player-name">
              {{ item.name }}
            </div>
            <div class="player-vote">
              {{ item.voteNum }}票
            </div>
          </div>
        </div>
      </div>
      <div class="screen-reveal">
        <div class="screen-count">
          <div class="reveal-title">数据统计</div>
          <div class="reveal-pane">
            <div class="count-item">
              <div class="count-item-label">总票数</div>
              <div class="count-item-value">{{ voteNum }}<span class="value-unit">票</span></div>
            </div>
            <div class="count-item">
              <div class="count-item-label">选手数</div>
              <div class="count-item-value">{{ playerNum }}<span class="value-unit">位</span></div>
            </div>
            <div class="count-item">
              <div class="count-item-label">访问量</div>
              <div class="count-item-value">{{ viewNum }}<span class="value-unit">次</span></div>
            </div>
          </div>
        </div>
        <div class="reveal-space"></div>
        <div class="screen-code">
          <div class="reveal-title">活动二维码</div>
          <div class="reveal-pane">
            <div class="count-item">
              <img :src="getQrCode()" alt="">
              <div>扫一扫，参与投票</div>
              <div><span class="copyright">元点评选</span> 提供技术支持</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {ajaxUtil} from "@/js/ajaxUtil";
import {api} from "@/js/api";
import {QrCode} from '@/js/qrcodeUtil'
import {config} from "@/js/config";

export default {
  name: 'screen',
  data() {
    return {
      timer: null,
      typeClass: '',
      activity: {},
      endTime: null,
      hasEnd: false,
      countdown: [0, 0, 0, 0, 0, 0, 0, 0],
      rankList: [],
      voteNum: 0,
      viewNum: 0,
      playerNum: 0,
    }
  },
  beforeMount() {
    let {activityId} = this.$route.query;
    if (activityId) {
      this.getAct(activityId)
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 清除计时器
    }
  },
  methods: {
    remainderEvent() {
      const now = new Date().getTime();
      const distance = this.endTime - now;
      if (distance < 0) {
        clearInterval(this.timer);
        this.hasEnd = false
        return
      }
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      days = (days < 10 ? '0' : '') + days
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      hours = (hours < 10 ? '0' : '') + hours
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      minutes = (minutes < 10 ? '0' : '') + minutes
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (seconds % 5 === 0) {
        const {id, settingScreen} = this.activity;
        this.getStatics(id)
        this.getRanking(id, settingScreen.playerNum)
      }
      seconds = (seconds < 10 ? '0' : '') + seconds
      this.countdown = [days[0], days[1], hours[0], hours[1], minutes[0], minutes[1], seconds[0], seconds[1]]
    },
    getAct(activityId) {
      let url = api.activity.get + '?activityId=' + activityId
      ajaxUtil.ajaxPage(url, null, 'GET', null, data => {
        if (data && data.settingScreen) {
          const {status, playerNum, type} = data.settingScreen
          if (status) {
            this.activity = data
            this.endTime = Number(data.endTime)
            this.hasEnd = new Date().getTime() > Number(data.endTime)
            this.typeClass = 'type' + type
            if (!this.hasEnd) {
              this.timer = setInterval(() => {
                // 强制Vue重新计算timeLeft
                this.remainderEvent();
              }, 1000);
            }
            this.getRanking(activityId, playerNum)
            this.getStatics(activityId)
          }
        }
      });
    },
    getRanking(activityId, pageCount) {
      let url = api.player.ranking.replace('{activityId}', activityId) + '&pageCount=' + pageCount
      ajaxUtil.ajaxPage(url, null, 'GET', null, data => {
        this.rankList = data.resultList
      });
    },
    getStatics(activityId) {
      let url = api.activity.getStatics.replace("{activityId}", activityId);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        this.voteNum = data.voteNum + data.virtualVoteNum;
        this.viewNum = data.viewNum + data.virtualViewNum;
        this.playerNum = data.playerNum;
      })
    },
    avatarFilter(val) {
      if (val) {
        return config.ossHost + val;
      }
      return require('../../assets/avatar-def.png')
    },
    getQrCode() {
      return QrCode.url(this.activity.h5Url, 159);
    },
  }
}
</script>
<style scoped lang="scss">
.vote-screen {
  width: 100%;
  height: 100%;

  .screen-header {
    .header-title {
      text-align: center;
      font-size: 36px;
      height: 90px;
      line-height: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .header-countdown {
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        margin-right: 10px;
      }

      div:last-child {
        margin-right: 0;
      }

      .countdown-label {
        font-size: 20px;
      }

      .countdown-time {
        display: inline-block;
        text-align: center;
        font-size: 48px;
        line-height: 80px;
        width: 60px;
        height: 80px;
        background: hsla(0, 0%, 100%, .1);
      }
    }
  }

  .screen-pane {
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;

    .screen-rank {
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      overflow: auto; /* 需要滚动时保持这个属性 */
      scrollbar-width: none; /* 对于Firefox */

      .rank-item {
        width: 20%;
        min-width: 220px;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
        padding-right: 20px;
        padding-bottom: 20px;
      }
    }

    .screen-rank::-webkit-scrollbar {
      display: none; /* 对于Chrome, Safari和Opera */
    }

    .screen-reveal {
      width: 500px;
    }
  }
}

.vote-screen.type0 {
  background-color: #101528;

  //头信息
  .screen-header {
    background-size: 100% 100%;
    background-image: url('../../assets/screen/type0/head-bg.png');
    height: 139px;

    .header-title {
      color: #dfe0e2;
    }

    .header-countdown {
      .countdown-label {
        color: #4da0ff;
      }

      .countdown-time {
        color: #4da0ff;
      }
    }
  }

  .screen-pane {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 50px 50px 20px 30px;
    height: calc(100vh - 139px);

    //排行
    .screen-rank {
      overflow-y: auto;
      padding: 0 30px 0 20px;

      .rank-item {
        .player-info {
          width: 100%;
          box-sizing: border-box;
          font-size: 14px;
          border: 1px solid rgba(77, 160, 255, .4);
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          background-color: #171f42;


          .player-num {
            position: absolute;
            left: 0;
            top: 0;
            width: 68px;
            text-align: center;
            background-color: rgba(0, 0, 0, .3);
            color: #fff;
            height: 23px;
            line-height: 23px;
            background-size: 90px 23px;
          }

          .player-cover {
            margin-top: 32px;
            width: 200px;
            height: 240px;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .player-name {
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            text-align: center;
            line-height: 32px;
            color: #fff;
          }

          .player-vote {
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            background-color: #4da0ff;
            color: #fff;
            padding: 10px 10px;
            font-size: 18px;
          }
        }
      }
    }

    //数据
    .screen-reveal {
      //公共title
      .reveal-title {
        font-size: 18px;
        color: #aab6e8;
        line-height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px dashed #3d486f;
      }

      //统计
      .screen-count {
        position: relative;
        width: 100%;
        background-image: url("../../assets/screen/type0/reveal-bg.png");
        min-height: 300px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-bottom: 20px;

        .reveal-pane {
          padding: 0 40px;

          .count-item {
            font-size: 18px;
            color: #4da0ff;
            border-bottom: 1px solid #3d486f;
            line-height: 80px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .count-item-label {
              font-size: 18px;
              color: #fff;
            }

            .count-item-value {
              font-size: 18px;
              color: #4da0ff;
              font-weight: bold;

              .value-unit {
                font-size: 16px;
                margin-left: 10px;
              }
            }
          }

          .count-item:last-child {
            border-bottom: none;
          }
        }
      }

      .reveal-space {
        height: 40px;
      }

      .screen-code {
        position: relative;
        width: 100%;
        background-image: url("../../assets/screen/type0/reveal-bg.png");
        min-height: 300px;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .reveal-pane {
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;

          .count-item {
            color: #fff;
            font-size: 18px;
            text-align: center;
            line-height: 36px;

            .copyright {
              color: #4da0ff;
            }

            img {
              border: 1px solid #4da0ff;
              background-color: #fff;
            }
          }

        }
      }
    }
  }
}

.vote-screen.type1 {
  background-color: #06103c;

  //头信息
  .screen-header {
    background-size: 100% 100%;
    background-image: url('../../assets/screen/type1/head-bg.png');
    height: 139px;

    .header-title {
      color: #4da0ff;
    }

    .header-countdown {
      .countdown-label {
        color: #4da0ff;
      }

      .countdown-time {
        color: #6a51ff;
        background-color: #322e5d;
      }
    }
  }

  .screen-pane {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 50px 50px 20px 30px;
    height: calc(100vh - 139px);

    //排行
    .screen-rank {
      overflow-y: auto;
      padding: 0 30px 0 20px;

      .rank-item {
        .player-info {
          width: 100%;
          box-sizing: border-box;
          font-size: 14px;
          border: 1px solid rgba(77, 160, 255, .4);
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          box-shadow: inset 0 0 5px 1px hsla(0, 0%, 100%, .3);


          .player-num {
            width: 100%;
            background-image: url("../../assets/screen/type1/player-num.png");
            background-repeat: no-repeat;
            background-position: 50%;
            text-align: center;
            color: #4da0ff;
            height: 23px;
            line-height: 23px;
            background-size: 90px 23px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .player-cover {
            margin-top: 32px;
            width: 200px;
            height: 240px;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .player-name {
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            text-align: center;
            line-height: 32px;
            color: #4da0ff;
          }

          .player-vote {
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            background-color: rgba(63, 113, 255, .2);
            color: #4da0ff;
            padding: 10px 10px;
            font-size: 18px;
          }
        }
      }
    }

    //数据
    .screen-reveal {
      //公共title
      .reveal-title {
        font-size: 18px;
        color: #4da0ff;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .reveal-title:before {
        content: "";
        width: 47px;
        height: 11px;
        background-size: cover;
        background-image: url('../../assets/screen/type1/title-bg.png');
        display: inline-block;
        margin: 0 10px;
      }

      .reveal-title:after {
        content: "";
        width: 47px;
        height: 11px;
        transform: rotateY(180deg);
        background-size: cover;
        background-image: url('../../assets/screen/type1/title-bg.png');
        display: inline-block;
        margin: 0 10px;
      }

      //统计
      .screen-count {
        position: relative;
        width: 100%;
        background-image: url("../../assets/screen/type1/reveal-bg.png");
        min-height: 300px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-bottom: 20px;

        .reveal-pane {
          padding: 0 40px;

          .count-item {
            font-size: 18px;
            color: #4da0ff;
            border-bottom: 1px solid #3d486f;
            line-height: 80px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .count-item-label {
              font-size: 18px;
              color: #4da0ff;
            }

            .count-item-value {
              font-size: 18px;
              color: #a5caf4;
              font-weight: bold;

              .value-unit {
                font-size: 16px;
                margin-left: 10px;
              }
            }
          }

          .count-item:last-child {
            border-bottom: none;
          }
        }
      }

      .reveal-space {
        height: 40px;
      }

      .screen-code {
        position: relative;
        width: 100%;
        background-image: url("../../assets/screen/type1/reveal-bg.png");
        min-height: 300px;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .reveal-pane {
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;

          .count-item {
            color: #568dd3;
            font-size: 18px;
            text-align: center;
            line-height: 36px;

            .copyright {
              color: #00deff;
            }

            img {
              border: 1px solid #4da0ff;
              background-color: #fff;
            }
          }

        }
      }
    }
  }
}

.vote-screen.type2 {
  background-color: #c21a21;
  background-image: url('../../assets/screen/type2/screen-bg.png');
  background-size: 100% 100%;

  .screen-header {
    background-size: 100% 100%;
    background-image: url('../../assets/screen/type2/head-bg.png');
    height: 139px;

    .header-title {
      color: #f5e9e9;
    }

    .header-countdown {
      color: #fedc70;
    }
  }

  .screen-pane {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 50px 50px 20px 30px;
    height: calc(100vh - 139px);

    .screen-rank {
      overflow-y: auto;
      padding: 0 30px 0 20px;

      .rank-item {
        .player-info {
          width: 100%;
          box-sizing: border-box;
          padding: 16px;
          font-size: 14px;
          border: 1px solid;
          background-color: #f5e9e9;
          border-color: rgba(208, 0, 14, .4);
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;


          .player-num {
            width: 100%;
            background-image: url("../../assets/screen/type2/player-num.png");
            background-repeat: no-repeat;
            background-position: 50%;
            text-align: center;
            color: #d0000e;
            height: 23px;
            line-height: 23px;
            background-size: 90px 23px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .player-cover {
            margin-top: 16px;
            width: 200px;
            height: 240px;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .player-name {
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            text-align: center;
            line-height: 28px;
            color: #333;
          }

          .player-vote {
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            background-color: #b71b21;
            color: #fedc70;
            padding: 10px 10px;
            font-size: 18px;
          }
        }
      }
    }

    .screen-reveal {
      .reveal-title {
        font-size: 18px;
        color: #333;
        line-height: 74px;
        border-bottom: 1px dashed #d5b2b3;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .reveal-title:before {
        content: "";
        width: 47px;
        height: 11px;
        background-size: cover;
        background-image: url('../../assets/screen/type2/title-bg1.png');
        display: inline-block;
        margin: 0 10px;
      }

      .reveal-title:after {
        content: "";
        width: 47px;
        height: 11px;
        background-size: cover;
        background-image: url('../../assets/screen/type2/title-bg2.png');
        display: inline-block;
        margin: 0 10px;
      }

      .screen-count {
        position: relative;
        width: 100%;
        background-color: hsla(0, 0%, 100%, .9);
        min-height: 300px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-bottom: 20px;

        .reveal-pane {
          padding: 0 40px;

          .count-item {
            font-size: 18px;
            color: #fff;
            border-bottom: 1px solid #d5b2b3;
            line-height: 80px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .count-item-label {
              font-size: 18px;
              color: #333;
            }

            .count-item-value {
              font-size: 18px;
              color: #9c191f;
              font-weight: bold;

              .value-unit {
                font-size: 16px;
                margin-left: 10px;
              }
            }
          }

          .count-item:last-child {
            border-bottom: none;
          }
        }
      }

      .reveal-space {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 50px;
      }

      .reveal-space:before {
        content: "";
        width: 13px;
        height: 78px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAABPCAMAAAAHpb8pAAAA5FBMVEUAAAC/AAC7HCC/HCC8Gx+8GyG9GiG8GiG9GiC9GiC9GyG8GiG9GiG9GiC9GyG9GyG+GyG+HCG+HCK/HCK/HSLAHSLAHiLBHiPCHyPDICTEICTFISTGISXGIiXHIyXIIybJJCbKJSfLJSfMJifNJyjOKCjPKCnQKSnRKinSKyrTKyrULCvVLSvWLizXLizYLyzZMC3aMS3bMS7cMi7dMy7eNC/fNTDgNjDhNjDiNzHjODHkODLlOTLmOjLmOjPnOzPoOzPpPDTqPTTrPjXsPjXsPzXtPzXtPzbtQDbuQDbvQDbvQTaxj5hdAAAAD3RSTlMABEBAQZmam6anq+jo/P7Fb58fAAAA0UlEQVQ4y82T22rDMBAFZy+OGgr9/y9t7Vja0wfHbgolhFJK9kEwaJlFWo4BfgqvcSkwLM8GoI+uoG2ATRoejb1axDkOMnPnqzwNwKEASwEJeAdlBezdlUvaTlp8zGBmZjCPZOXFATSvJLrUG8B733TdAfpVfjifj+yfJ/xi3u367t/90KmHqR6mv7E8PqGelby4noFNrydJokTQzpaS1CeNjHa8qPVsdlispd84t2z2b9nUuAbQ62a15cvxRWgJadpp7kHVlnLNKwFjANWXFT4Bm49qHNWfBZMAAAAASUVORK5CYII=);
        background-size: cover;
        display: inline-block;
        z-index: 99;
      }

      .reveal-space:after {
        content: "";
        width: 13px;
        height: 78px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAABPCAMAAAAHpb8pAAAA5FBMVEUAAAC/AAC7HCC/HCC8Gx+8GyG9GiG8GiG9GiC9GiC9GyG8GiG9GiG9GiC9GyG9GyG+GyG+HCG+HCK/HCK/HSLAHSLAHiLBHiPCHyPDICTEICTFISTGISXGIiXHIyXIIybJJCbKJSfLJSfMJifNJyjOKCjPKCnQKSnRKinSKyrTKyrULCvVLSvWLizXLizYLyzZMC3aMS3bMS7cMi7dMy7eNC/fNTDgNjDhNjDiNzHjODHkODLlOTLmOjLmOjPnOzPoOzPpPDTqPTTrPjXsPjXsPzXtPzXtPzbtQDbuQDbvQDbvQTaxj5hdAAAAD3RSTlMABEBAQZmam6anq+jo/P7Fb58fAAAA0UlEQVQ4y82T22rDMBAFZy+OGgr9/y9t7Vja0wfHbgolhFJK9kEwaJlFWo4BfgqvcSkwLM8GoI+uoG2ATRoejb1axDkOMnPnqzwNwKEASwEJeAdlBezdlUvaTlp8zGBmZjCPZOXFATSvJLrUG8B733TdAfpVfjifj+yfJ/xi3u367t/90KmHqR6mv7E8PqGelby4noFNrydJokTQzpaS1CeNjHa8qPVsdlispd84t2z2b9nUuAbQ62a15cvxRWgJadpp7kHVlnLNKwFjANWXFT4Bm49qHNWfBZMAAAAASUVORK5CYII=);
        background-size: cover;
        display: inline-block;
        z-index: 99;
      }

      .screen-code {
        position: relative;
        width: 100%;
        background-color: hsla(0, 0%, 100%, .9);
        min-height: 300px;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .reveal-pane {
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;

          .count-item {
            color: #333;
            font-size: 18px;
            text-align: center;
            line-height: 36px;

            .copyright {
              color: #9c191f;
            }

            img {
              border: 1px solid #9c191f;
              background-color: #fff;
            }
          }

        }
      }
    }
  }
}
</style>
