import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/store'
import {AuthUtil} from './js/auth'
import ElementUI from 'element-ui'
import VueClipboard from 'vue-clipboard2'
import './js/vueFilter'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/element-ui.scss' // element-ui修改样式
import "@/styles/font.css"
import VueDND from 'awe-dnd' //拖拽组件
Vue.use(VueDND)
Vue.config.productionTip = false
Vue.use(ElementUI).use(VueClipboard)
function onscrollTop(){
    window.scrollTo(0,0);
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
}

router.beforeEach((to,from,next)=>{
    store.setState();
    store.setCurrentRoute(null,to.name);
    onscrollTop();
    if(to.path !== '/login'&&to.path !== '/index'&&to.path !== '/about'&&to.path !== '/product'&&to.path !== '/templateCenter'){
        if(AuthUtil.isLogin()){
            next();
        }else{
            next('/login');
        }
    }else{
        next();
    }
    next()

})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
// store,
