<template>
    <div class="actSet-wrapper">
        <div class='content'>
            <div class='firstbasic'>
                <el-form hide-required-asterisk label-position="left" label-width="170px" ref="basicForm" :model="activity" :rules="basicRules">
                    <el-form-item label="活动名称" prop="name">
                        <el-input placeholder="请输入活动名称" v-model.trim='activity.name' maxlength="40" @change="change"></el-input>
                    </el-form-item>
                    <el-form-item label="投票时间">
                      <dateRange ref="dateRange" :width="630" :start.sync="activity.startTime" :end.sync="activity.endTime" @change="change"></dateRange>
                    </el-form-item>
                    <el-form-item label="活动公告">
                        <el-switch v-model = 'activity.hasNotice'  active-color="#409EFF" @change="change(!activity.hasNotice)">
                        </el-switch>
                        <div v-if="activity.hasNotice">
                          <el-form-item prop="notice">
                            <el-input v-model.trim='activity.notice' maxlength="100" @change="change"></el-input>
                          </el-form-item>
                        </div>
                    </el-form-item>
                </el-form>
                <el-form label-position="left" label-width="170px" ref="elementForm"  :model="activity.settingShowElement" :rules="elementRules">
                  <el-form-item label="称谓设置">
                    <ul class='nameSetWrapper'>
                      <li>
                        <div class="span-box align-left">
                          <span class="marRight6">选手名称</span>
                          <i title="最多三个中文，用于显示选手的称谓，如：选手，企业，作品等" class="el-icon-question" style="color: #cccccc"></i>
                        </div>
                        <el-form-item prop="playerAppellation">
                          <el-input v-model.trim="activity.settingShowElement.playerAppellation" placeholder="请输入内容"
                                    @change="change" maxlength="3" class="input-with-select">
                            <el-select value="" @change="selectPlayerAppellation" slot="append" placeholder="请选择">
                              <el-option label="选手" value="选手"></el-option>
                              <el-option label="企业" value="企业"></el-option>
                              <el-option label="作品" value="作品"></el-option>
                            </el-select>
                          </el-input>
                        </el-form-item>
                      </li>
                      <li>
                        <div class="span-box align-left">
                          <span class="marRight6">选手单位</span>
                          <i title="最多一个中文，用于显示选手数量的单位，如个，位，副等" class="el-icon-question" style="color: #cccccc"></i>
                        </div>
                        <el-form-item prop="playerUnit">
                          <el-input v-model.trim="activity.settingShowElement.playerUnit" placeholder="请输入内容" maxlength="1"
                                    @change="change" class="input-with-select">
                            <el-select value="" @change="selectPlayerUnit" slot="append" placeholder="请选择">
                              <el-option label="个" value="个"></el-option>
                              <el-option label="位" value="位"></el-option>
                              <el-option label="副" value="副"></el-option>
                            </el-select>
                          </el-input>
                        </el-form-item>
                      </li>
                      <li>
                        <div class="span-box align-left">
                          <span class="marRight6">按钮名称</span>
                          <i title="最多两个中文，用于显示投票按钮的名称，如投票，点赞，加油等" class="el-icon-question" style="color: #cccccc"></i>
                        </div>
                        <el-form-item prop="votingButtonName">
                          <el-input v-model.trim="activity.settingShowElement.votingButtonName" placeholder="请输入内容"
                                    @change="change" maxlength="2" class="input-with-select">
                            <el-select value="" @change="selectVotingButtonName" slot="append" placeholder="请选择">
                              <el-option label="投票" value="投票"></el-option>
                              <el-option label="点赞" value="点赞"></el-option>
                              <el-option label="加油" value="加油"></el-option>
                            </el-select>
                          </el-input>
                        </el-form-item>
                      </li>
                      <li>
                        <div class="span-box align-left">
                          <span class="marRight6">票数单位</span>
                          <i title="最多一个中文，用于显示投票数量的单位，如票，赞等" class="el-icon-question" style="color: #cccccc"></i>
                        </div>
                        <el-form-item prop="votesUnit">
                          <el-input v-model.trim="activity.settingShowElement.votesUnit" placeholder="请输入内容"
                                    @change="change" maxlength="1" class="input-with-select">
                            <el-select value="" @change="selectVotesUnit" slot="append" placeholder="请选择">
                              <el-option label="票" value="票"></el-option>
                              <el-option label="赞" value="赞"></el-option>
                            </el-select>
                          </el-input>
                        </el-form-item>
                      </li>
                    </ul>
                  </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store/store'
    import dateRange from '@/components/dateRange.vue'
    import {ActivityUtil, CommonUtil} from '@/js/commonUtil'
    import {messages} from "@/js/messages";
    export default {
        name: 'basicSet',
        components:{
          dateRange
        },
        data(){
            return {
                activity:{
                    id:0,
                    name:null,
                    startTime:null,
                    endTime:null,
                    notice:null,
                    hasNotice:false,
                    settingShowElement:{
                        playerAppellation:null,
                        playerUnit:null,
                        votingButtonName:null,
                        votesUnit:null
                    },
                    settingShare:{
                        activityShareTitle:null
                    }
                },
                basicRules: {
                  name: [{required: true, message: '请输入活动名称', trigger: 'blur'}],
                  notice: [{required: true, message: '请输入活动公告', trigger: 'blur'}]
                },
                elementRules:{
                  playerAppellation: [{required: true, message: '请输入选手名称', trigger: 'blur'}],
                  playerUnit: [{required: true, message: '请输入选手单位', trigger: 'blur'}],
                  votingButtonName: [{required: true, message: '请输入按钮名称', trigger: 'blur'}],
                  votesUnit: [{required: true, message: '请输入票数单位', trigger: 'blur'}]
                }
            }
        },
        beforeMount(){
            this.getActivity();
        },
        methods:{
            getActivity(){
                ActivityUtil.get(ActivityUtil.getCurrentSettingActId(),data=>{
                    CommonUtil.copyProperty(data, this.activity);
                })
            },
            change(submit){
              if(submit!=null&&submit!=undefined&&!submit){
                return false;
              }
              //先校验活动名称和活动时间
              this.$refs["basicForm"].validate(valid => {
                if (valid) {
                  this.$refs["dateRange"].validate(() => {
                    //再校验活动公告
                    if(this.activity.hasNotice && (this.activity.notice==null||this.activity.notice=='')){
                      messages.showErrorMsg('活动公告不能为空');
                    }else {
                      //最后校验称谓设置,若通过校验则提交请求
                      if(!this.activity.hasNotice){
                        this.activity.notice='';
                      }
                      this.$refs["elementForm"].validate(v => {
                        if (v) {
                          let updateKeys=['name','startTime','endTime','notice','settingShowElement'];
                          ActivityUtil.update(this.activity, updateKeys, null, ()=>{
                            ActivityUtil.refreshH5();
                          });
                        }
                      })
                    }
                  })
                }
              })
            },
            selectPlayerAppellation(newVal){
                if(newVal && newVal!=this.activity.settingShowElement.playerAppellation){
                  this.activity.settingShowElement.playerAppellation=newVal;
                  this.change();
                }
            },
            selectPlayerUnit(newVal){
                if(newVal && newVal!=this.activity.settingShowElement.playerUnit){
                  this.activity.settingShowElement.playerUnit=newVal;
                  this.change();
                }
            },
            selectVotingButtonName(newVal){
                if(newVal && newVal!=this.activity.settingShowElement.votingButtonName){
                  this.activity.settingShowElement.votingButtonName=newVal;
                  this.change();
                }
            },
            selectVotesUnit(newVal){
                if(newVal && newVal!=this.activity.settingShowElement.votesUnit){
                  this.activity.settingShowElement.votesUnit=newVal;
                  this.change();
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .actSet-wrapper{
        width:100%;
        .content{
            box-sizing: border-box;
            padding-top: 10px;
            max-width: 800px;
        }
    }
    .nameSetWrapper{
        li{
            width:50%;
            float:left;
            margin-bottom: 15px;
            .input-with-select{
                width: 150px;
            }
        }
    }
    .indrouceBasic{
        overflow: hidden;
        // width:650px;
        text-align: center;
    }
    .shareBasic{
        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 14px;
            color: #8c939d;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background:#eee;
        }
        .avatar {
            width: 60px;
            height: 60px;
            display: block;
        }
        .checkBtn{
            cursor: pointer;
            font-size: 14px;
            line-height: 60px;
            margin-left: 10px;
        }
    }
</style>
