<template>
  <div class="entry-set">
    <el-form hide-required-asterisk label-position="left" label-width="100px">
      <el-form-item label="在线报名">
        <div>
          <el-switch disabled v-model="activity.settingEntry.status" @change="saveEntryEvent"></el-switch>
          <span style="margin-left: 20px;">应相关部门要求，报名功能需审核才能开启，请联系客服</span>
        </div>
        <template v-if="activity.settingEntry.status">
          <el-date-picker
              v-model="activity.settingEntry.startTime"
              type="datetime"
              @change="saveEntryEvent"
              placeholder="报名开始时间">
          </el-date-picker>
          至
          <el-date-picker
              v-model="activity.settingEntry.endTime"
              type="datetime"
              @change="saveEntryEvent"
              placeholder="报名结束时间">
          </el-date-picker>
        </template>
      </el-form-item>
      <el-form-item label="视频报名">
        <el-switch v-model="activity.settingEntry.hasVideo" @change="saveEntryEvent"></el-switch>
      </el-form-item>
      <el-form-item label="图片报名">
        <div>
          <el-switch v-model="activity.settingEntry.hasImage" @change="saveEntryEvent"></el-switch>
        </div>
        <div v-if="activity.settingEntry.hasImage">
          <div class="entry-form-item">
            <div class="item-title">图片名称</div>
            <el-input v-model="activity.settingEntry.imageTitle" placeholder="请输入内容" @change="saveEntryEvent"/>
          </div>
          <div class="entry-form-item">
            <div class="entry-form-item">
              <div class="item-title">图片最少</div>
              <el-input type="number" v-model="activity.settingEntry.imageMinNum" placeholder="请输入内容"
                        oninput="value=value.replace(/^[^\d]$+/g,'')"
                        @change="saveEntryEvent">
                <template slot="append">张</template>
              </el-input>
            </div>
            <div class="entry-form-item">
              <div class="item-title">图片最多</div>
              <el-input type="number" v-model="activity.settingEntry.imageMaxNum" placeholder="请输入内容"
                        oninput="value=value.replace(/^[^\d]+/g,'')"
                        @change="saveEntryEvent">
                <template slot="append">张</template>
              </el-input>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="报名表单" class="entry-custom-form">
        <div v-for="(item,index) in customForm" :key="index">
          <div class="custom-form-item" v-if="!typeCode.includes(item[0])">
            <div class="item-title">
              <el-input size="small" v-model="item[1]" placeholder="请输入内容" @change="saveEntryEvent"/>
            </div>
            <div class="item-type">
              <el-select :disabled="item[0]==='name'" size="small" v-model="item[2]" @change="saveEntryEvent"
                         placeholder="请选择">
                <el-option :value="0" label="单行文本"/>
                <el-option :value="1" label="多行文本"/>
              </el-select>
            </div>
            <div class="item-layout" v-if="item[0]!=='name'">
              <el-radio-group v-model="item[3]" size="small" @input="saveEntryEvent">
                <el-radio-button :label="0">选填</el-radio-button>
                <el-radio-button :label="1">必填</el-radio-button>
                <el-radio-button :label="2">隐藏</el-radio-button>
              </el-radio-group>
            </div>
            <div class="item-status" v-if="index>2">
              <el-checkbox v-model="item[4]" :true-label="1" :false-label="0"
                           @change="saveEntryEvent">公开
              </el-checkbox>
            </div>
            <div class="item-del" v-if="index>2">
              <el-button size="small" type="danger" plain
                         @click="bindDelCustomChange(index)">删除
              </el-button>
            </div>
          </div>
        </div>
        <el-button icon="el-icon-plus" size="medium" type="primary"
                   @click="bindAddCustomChange">添加表单
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {ActivityUtil, CommonUtil} from "@/js/commonUtil"

export default {
  name: 'entrySet',
  data() {
    return {
      customForm: [],
      typeCode: ['group', 'video', 'image'],
      activity: {
        id: 0,
        settingEntry: {
          status: false,
          startTime: null,
          endTime: null,
          hasVideo: false,
          hasImage: false,
          imageTitle: '图片',
          imageMinNum: 0,
          imageMaxNum: 1,
          customForm: '',
        }
      }
    }
  },
  beforeMount() {
    this.getActivity();
  },
  methods: {
    getActivity() {
      ActivityUtil.get(ActivityUtil.getCurrentSettingActId(), data => {
        let {customForm} = data.settingEntry
        if (customForm) {
          this.customForm = JSON.parse(customForm)
        } else {
          this.customForm = [
            ["group", "报名分组", 0, 2, 1, ""],
            ["name", "姓名", 0, 1, 1, ""],
            ["blurb", "简介", 0, 0, 1, ""],
            ["image", "图片", 0, 0, 1, ""],
            ["video", "视频", 0, 0, 2, ""],
          ]
        }
        CommonUtil.copyProperty(data, this.activity);
      })
    },

    saveEntryEvent() {
      this.activity.settingEntry.customForm = JSON.stringify(this.customForm)
      let body = CommonUtil.copyObject(this.activity);
      ActivityUtil.update(body, ['settingEntry'], null, () => {
        ActivityUtil.refreshH5();
      })
    },
    bindDelCustomChange(index) {
      if (index > 1) {
        this.customForm.splice(index, 1)
        this.saveEntryEvent()
      }
    },
    bindAddCustomChange() {
      let length = this.customForm.length;
      this.customForm.push(["custom", "默认", 0, 0, 1])
      this.saveEntryEvent()
    },
  }
}
</script>
<style lang="scss" scoped>
.entry-set {

  .entry-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 20px;

    .item-title {
      width: 80px;
    }

    .el-input {
      width: 200px;
    }
  }

  .entry-custom-form {
    .custom-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .item-title {
        width: 130px;
      }

      .item-type {
        margin-left: 20px;
        width: 130px;
      }

      .item-layout {
        margin-left: 20px;
      }

      .item-status {
        margin-left: 20px;
      }

      .item-del {
        margin-left: 20px;
      }
    }
  }
}
</style>
