<template>
    <el-form hide-required-asterisk ref="numFrom" v-if="variable" :model="{val:numberValue}">
        <el-form-item :rules="rules" prop="val">
            <el-input ref="elInput" v-model.number="numberValue" @input="replaceNumber" :style="styleObj" :maxlength="maxlength" autocomplete="off" @change="change"></el-input>
        </el-form-item>
    </el-form>
    <el-form-item v-else :rules="rules" :prop="prop">
        <el-input ref="elInput" v-model.number="numberValue" :style="styleObj" @input="replaceNumber" :maxlength="maxlength" autocomplete="off" @change="change"></el-input>
    </el-form-item>
</template>
<script>
export default {
  name: 'numberInput',//数字输入框组件,已限制只能输入数字,且可设置动态的最大最小值规则,通过value.sync进行双向绑定
  props:{
        value:null,
        maxlength:{
            type: Number,
            default: 11
        },
        replaceMin:{
          type: Number,
          default: null
        },
        replaceMax:{
          type: Number,
          default: null
        },
        styleObj:null,
        rule:{
            max:{
                value: null,
                message: '超出最大限制',
                trigger: 'blur'
            },
            min:{
                value: null,
                message: '超出最小限制',
                trigger: 'blur'
            },
            required:{
                value: false,
                message: '输入不能为空',
                trigger: 'blur'
            }
        },
        variable:{
            type:Boolean,
            default:true
        },
        prop:{
            type:String,
            default:null
        }
  },
  watch: {
        'rule': {
            handler(newValue, oldValue) {
                this.initRules();
            },
        }
  },
  computed: {
        numberValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('update:value', val)
            }
        }
  },
  data(){
    return {
        rules: []
    }
  },
  methods:{
      initRules(){
          let newRules=[];
          if(this.rule.required&&this.rule.required.value){
              newRules.push({required: true, message: this.rule.required.message, trigger: this.rule.required.trigger});
          }
          if(this.rule.min&&this.rule.min.value!=null){
              newRules.push({ validator: (rule, value, callback) => {
                      if (value<this.rule.min.value) {
                          callback(new Error(this.rule.min.message));
                      } else {
                          callback();
                      }
                  }, trigger: this.rule.min.trigger })
          }
          if(this.rule.max&&this.rule.max.value!=null){
              newRules.push({ validator: (rule, value, callback) => {
                      if (value>this.rule.max.value) {
                          callback(new Error(this.rule.max.message));
                      } else {
                          callback();
                      }
                  }, trigger: this.rule.max.trigger })
          }
          this.rules=newRules;
      },
      change(val){
          if(this.variable){
              this.$refs["numFrom"].validate(valid => {
                  if (valid) {
                      this.$emit('change', this.value)
                  }
              })
          }else {
              this.$emit('change', this.value)
          }
      },
      replaceNumber(val){
          let newVal=val.replace(/[^0-9]/g, '');
          if(newVal.length>0&&this.replaceMin!=null&&newVal<this.replaceMin){
            newVal=this.replaceMin;
          }
          if(newVal.length>0&&this.replaceMax!=null&&newVal>this.replaceMax){
            newVal=this.replaceMax;
          }
          if(newVal!=val){
              this.$refs.elInput.getInput().value=newVal;
              this.$emit('update:value', newVal);
          }
      }
  }
}
</script>
