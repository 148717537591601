<template>
  <div class="search-wrapper">
     <el-input v-model.trim="keyword" placeholder="搜索内容" @change="onSearch" maxlength="100">
         <el-button slot="append" icon="el-icon-search" @click='onSearch'></el-button>
     </el-input>
  </div>
</template>

<script>
export default {
  name: 'search',
  data(){
    return {
      keyword:''
    }
  },
  methods:{
    onSearch(){
      this.$emit('search',this.keyword)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
