import { render, staticRenderFns } from "./tinymce.vue?vue&type=template&id=563d458a&scoped=true"
import script from "./tinymce.vue?vue&type=script&lang=js"
export * from "./tinymce.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563d458a",
  null
  
)

export default component.exports