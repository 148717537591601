<template>
  <div class="dcss" v-html="richText"></div>
</template>

<script>
export default {
  name: 'showRichText',
  props: {
    richText: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.dcss {
  all: initial; //将字号、字体颜色等所有属性,恢复为初始值
  ::v-deep ul li {
    all: revert; //将ul li样式还原为浏览器默认样式
  }

  ::v-deep ul, p, h1, h2 {
    all: revert; //将ul p h1 h2样式还原为浏览器默认样式
  }
}
</style>
