import QRCode from "qrcode";

const QrCode = {
    /**
     * 生成二维码
     * @param value
     * @param size 二维码尺寸(单位px,无单位时默认为px)
     * @returns {生成的二维码图片url}
     */
    url(value, size) {
        let options = {margin: 1};
        if (size) {
            options.width = size;
        }
        return this.urlByOption(value, options);
    },
    urlByOption(value, options) {
        //详细参数options见https://www.npmjs.com/package/qrcode#qr-code-options
        if (!options) {
            options = {margin: 0};
        }
        let result = null;
        QRCode.toDataURL(value, options, (error, url) => {
            if (!error) {
                result = url;
            }
        });
        return result;
    }
};

export {QrCode};
