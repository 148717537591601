import {ajaxUtil} from './ajaxUtil'
import {api} from './api'
import store from '@/store/store'
import router from '@/router/index.js';
import {messages} from "@/js/messages";

const CommonUtil = {
    copyObject: function (source) {
        return JSON.parse(JSON.stringify(source));
    },
    /**
     * 复制属性值
     * @param source 原属性对象
     * @param target 目标属性对象
     * @param copyAllSource true:只复制原属性对象存在的属性,false:只复制目标属性对象存在的属性
     */
    copyProperty: function (source, target, copyAllSource) {
        if (copyAllSource) {
            for (let Key in source) {
                let sourceValue = source[Key];
                let targetValue = target[Key];
                if (this.isKeyValueObj(sourceValue) && this.isKeyValueObj(targetValue)) {
                    this.copyProperty(sourceValue, targetValue);
                } else {
                    target[Key] = sourceValue;
                }
            }
        } else {
            for (let Key in target) {
                let sourceValue = source[Key];
                let targetValue = target[Key];
                if (this.isKeyValueObj(sourceValue) && this.isKeyValueObj(targetValue)) {
                    this.copyProperty(sourceValue, targetValue);
                } else {
                    target[Key] = sourceValue;
                }
            }
        }
    },
    isKeyValueObj: function (obj) {
        if (obj == null || obj == undefined) {
            return false;
        }
        if (Object.prototype.toString.call(obj) === '[Object String]') {
            return false;
        }
        let objJson = JSON.stringify(obj);
        return objJson.length >= 2 && objJson.startsWith('{') && objJson.endsWith('}');
    },
    /**
     * 获取一个对象的所有属性字符串
     * @param obj
     * @returns {*对象的属性字符串(格式:key1=value1&key2=value2.....,如果没有则返回'')}
     */
    getAllProperty: function (obj) {
        if (obj == null || obj == undefined) {
            return '';
        }
        let propertys = '';
        for (let Key in obj) {
            let value = obj[Key];
            if (value == null || value == undefined || value.length == 0) {
                continue;
            }
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    if (propertys.length == 0) {
                        propertys = Key + '=' + value[i];
                    } else {
                        propertys += '&' + Key + '=' + value[i];
                    }
                }
            } else {
                if (propertys.length == 0) {
                    propertys = Key + '=' + value;
                } else {
                    propertys += '&' + Key + '=' + value;
                }
            }
        }
        return propertys;
    },
    isIE: function () {
        return !!window.ActiveXObject || "ActiveXObject" in window || window.navigator.userAgent.indexOf("MSIE") >= 1;
    },
    isChrome: function () {
        return navigator.userAgent.indexOf("Chrome") > -1;
    },
    isFirefox: function () {
        return navigator.userAgent.indexOf("Firefox") > -1;
    },
    compare: function (var1, var2) {
        if (var1 == null || var1 == undefined || var1.length == 0) {
            return var2 == null || var2 == undefined || var2.length == 0;
        }
        return var1 == var2;
    },
    generateUuid: function () {//生成UUId
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        var uuid = s.join("");
        return uuid;
    },
    objToCheckboxGroupArr(obj, keys, excludeKeys) {
        let checkboxGroupModel = [];
        for (let Key in obj) {
            if (excludeKeys && excludeKeys.indexOf(Key) >= 0) {
                continue;
            }
            let value = obj[Key];
            if (keys) {
                if (keys.indexOf(Key) >= 0 && (value == 1 || value == true)) {
                    checkboxGroupModel.push(Key);
                }
            } else if ((typeof value === 'boolean') && value) {
                checkboxGroupModel.push(Key);
            }
        }
        return checkboxGroupModel;
    },
    checkboxGroupArrToObjBol(obj, keys, checkboxGroupArr) {
        for (let i = 0; i < keys.length; i++) {
            obj[keys[i]] = checkboxGroupArr.indexOf(keys[i]) >= 0;
        }
    },
    removeFromArray: function (arr, obj) {
        var newArr = [];
        for (var i = 0; i < arr.length; i++) {
            if (JSON.stringify(arr[i]) != JSON.stringify(obj)) {
                newArr.push(arr[i]);
            }
        }
        arr = newArr;
        return newArr;
    },
    /**
     * 计算日期
     * @param date 原日期
     * @param plusVal 需要加减的值
     * @param dateUnit 需要加减的单位(1:年,2:月,3:日,4:时,5:分,6:秒,7:毫秒)
     */
    plusDate(date, plusVal, weeHours = false, dateUnit = 3) {
        if (!date || !plusVal) {
            return date;
        }
        let newDate = new Date(date.getTime());
        if (dateUnit == 1) {
            newDate.setFullYear(date.getFullYear() + plusVal)
        } else if (dateUnit == 2) {
            newDate.setMonth(date.getMonth() + plusVal)
        } else if (dateUnit == 3) {
            newDate.setDate(date.getDate() + plusVal)
        } else {
            if (dateUnit == 4) {
                plusVal = plusVal * 60 * 60 * 1000;
            } else if (dateUnit == 5) {
                plusVal = plusVal * 60 * 1000;
            } else if (dateUnit == 6) {
                plusVal = plusVal * 1000;
            }
            newDate = new Date(date.getTime() + plusVal);
        }
        if (weeHours) {
            newDate.setHours(0);
            newDate.setMinutes(0);
            newDate.setSeconds(0);
            newDate.setMilliseconds(0);
        }
        return newDate;
    }
};

const ActivityUtil = {
    get(activityId, successCallback, resetCache, showDefaultMsg = true) {
        let url = api.activity.get + '?activityId=' + activityId;
        if (resetCache) {
            url += '&cacheMechanism=UPDATE_CACHE';
        }
        ajaxUtil.ajaxJson(url, null, 'GET', null, showDefaultMsg).then(data => {
            if (data.settingShufflingImages != null) {
                data.shufflingImages = data.settingShufflingImages.map(item => item.shufflingImageUrl);
            }
            data.hasNotice = data.notice ? true : false;
            successCallback(data);
        })
    },
    update(activity, updateKeys, excludeKeys, successCallback) {
        let body = {};
        for (let Key in activity) {
            let value = activity[Key];
            if (Key == 'id') {
                body[Key] = value;
                continue;
            }
            if (updateKeys) {
                if (updateKeys.indexOf(Key) > -1) {
                    body[Key] = value;
                }
                continue;
            }
            if (excludeKeys && excludeKeys.indexOf(Key) > -1) {
                continue;
            }
            body[Key] = value;
        }
        body.updateBy = store.state.user.id;
        ajaxUtil.ajaxJson(api.activity.update, body, 'PUT', null, true).then(data => {
            if (data.change) {
                messages.showSuccessMsg(messages.success.update)
                successCallback(data);
            }
        })
    },
    add(body, successCallback, successMsg) {
        body.createBy = store.state.user.id;
        ajaxUtil.ajaxJson(api.activity.add, body, 'POST', null, true).then(data => {
            messages.showSuccessMsg(successMsg ? successMsg : messages.success.actAdd)
            successCallback(data);
        })
    },
    toSettingAct(routerName, actId, query) {
        if (!routerName) {
            routerName = 'BasicSet';
        }
        store.setCurrentSettingAct(routerName, actId);
        let rout = {name: routerName};
        if (query) {
            rout.query = query;
        }
        router.push(rout)
    },
    getCurrentSettingActId() {
        if (!store.state.currentSettingAct || !store.state.currentSettingAct.actId) {
            router.push({name: 'ActivityList', params: {status: '0'}})
        }
        return store.state.currentSettingAct.actId;
    },
    refreshH5(refreshActSetting = true, callbackact) {
        if (refreshActSetting) {
            this.get(this.getCurrentSettingActId(), (data) => {
                store.state.currentSettingAct.refreshH5Time = new Date().getTime();
                if (callbackact) {
                    callbackact(data);
                }
            }, true)
        } else {
            store.state.currentSettingAct.refreshH5Time = new Date().getTime();
        }
    }
};
export {CommonUtil, ActivityUtil};
