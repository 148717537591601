<template>
  <div v-if="dialogVisible">
    <el-dialog :visible.sync="dialogVisible" :width="width" :close-on-click-modal="false"
               custom-class="previewDialog">
      <div class='create-activity-wrapper'>
        <div class='act-iframe'>
          <iframe :src='actModel.activityH5Url' id='iframe' frameborder="0" scrolling="auto" height='100%'
                  width='100%'></iframe>
        </div>
        <div class='model-info'>
          <div class="base-blurb">
            <h2>功能描述</h2>
            <div>{{ actModel.functionalDesc }}</div>
            <h2>适用场景</h2>
            <div>{{ actModel.applicableScenario }}</div>
          </div>
          <div class="model-operate">
            <el-button type="primary" size='medium' @click="addActivity">创建活动</el-button>

            <div class="base-preview">
              <img :src="getQrCode()" alt="">
              <div>手机扫一扫看看效果</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {ActivityUtil} from "@/js/commonUtil";
import {QrCode} from '@/js/qrcodeUtil.js';
import store from '@/store/store';

export default {
  name: 'actModelDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '800px'
    },
    actModel: {
      type: Object,
      default: () => {
        return {
          id: 0,
          name: '',
          activityH5Url: '',
          functionalDesc: '',
          applicableScenario: '',
          modelActivityId: 0
        }
      }
    },
    canCreate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    getQrCode() {
      return QrCode.url(this.actModel.activityH5Url, 300);
    },
    addActivity() {
      this.$emit('update:visible', false);
      if (this.canCreate && store.state.user && store.state.user.id) {
        if (this.actModel.modelActivityId) {
          ActivityUtil.add({sourceActivityId: this.actModel.modelActivityId}, data => {
            ActivityUtil.toSettingAct('BasicSet', data.id);
          })
        }
      } else {
        this.$router.push({name: 'Login'});
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.create-activity-wrapper {
  overflow: hidden;
  width: 100%;
  display: flex;
  height: 600px;

  .act-iframe {
    width: 330px;
    height: 100%;
    overflow: hidden;
    border: 1px solid #eee;
  }

  .model-info {
    width: 400px;
    display: inline-block;
    padding: 40px 30px;

    .base-blurb {
      min-height: 400px;

      h2 {
        color: #999999;
      }

      div {
        font-size: 16px;
        line-height: 32px;
        margin: 10px 0;
      }
    }

    .model-operate {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .base-preview {
        width: 150px;

        img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }

  .act-indrouce {
    width: 51%;
    margin-left: 30px;
    height: 100%;
    position: relative;
    padding-top: 10%;

    h2 {
      font: 14px "Microsoft YaHei";
      font-weight: normal;
      padding-bottom: 10px;
      color: #606266;
    }

    p {
      word-wrap: break-word;
      word-break: break-all;
      font: 12px "Microsoft YaHei";
      color: #999999;
      padding-bottom: 30px;
    }

    .act-btn-code {
      height: 140px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 130px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .act-btn {
        float: left;
        height: 100%;
        position: relative;
        top: 40%;
      }

      .code-div {
        float: right;
        width: 120px;
        height: 100%;
        font: 12px "Microsoft YaHei";
        color: #606266;
        text-align: center;

        .code {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
}

#iframe {
  padding-right: 20px;
}
</style>
