<template>
  <div class="content-wrapper">
    <div class="list-header">
      <el-tabs v-model="state.actListQuery.status">
        <el-tab-pane v-bind:label="item.title" v-bind:name="item.name" v-for="(item) in actManageMenu"
                     v-bind:key=item.id></el-tab-pane>
      </el-tabs>
      <div>
        <el-input placeholder="请输入活动名称"
                  suffix-icon="el-icon-search"
                  maxlength="100"
                  v-model.trim="actName"
                  @change="state.actListQuery.name=actName">
        </el-input>
      </div>
    </div>
    <div class="list-body">
      <el-table :data="page.resultList" :empty-text="'暂无活动'">
        <template slot="empty">
              <span v-if="(state.actListQuery.status!='0'||state.actListQuery.name!='')&&page.totalCount==0"
                    style="color: #969799;">暂无活动</span>
        </template>
        <el-table-column label="活动名称" width="700">
          <template v-slot="props">
            <div class="activity-name">
              <div @click="toSettingAct('BasicSet',props.row.id)">
                <img v-if="props.row.bannerUrl" :src="props.row.bannerUrl|appendImgUrl" alt="">
                <img v-else src="@/assets/file-def.png" alt="">
              </div>

              <div class="activity-info">
                <div class="activity-title" @click="toSettingAct('BasicSet',props.row.id)">
                  {{ props.row.name }}
                </div>
                <div class="activity-time">创建时间：{{ props.row.createTime|convertTime }}</div>
                <div class="activity-time">投票时间：{{ props.row.startTime|convertTime }} -
                  {{ props.row.endTime|convertTime }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="活动状态">
          <template v-slot="scope">
              <span v-bind:class="scope.row.status|convertActStatusColorClass(scope.row.startTime,scope.row.endTime)">
                {{ scope.row.status | convertActStatus(scope.row.startTime,scope.row.endTime) }}
              </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="总票数" prop="voteNum">
          <template v-slot="{row}">
            {{ row.voteNum + row.virtualVoteNum }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="选手数" prop="playerNum"></el-table-column>
        <el-table-column align="center" label="访问量" prop="viewNum">
          <template v-slot="{row}">
            {{ row.viewNum + row.virtualViewNum }}
          </template>
        </el-table-column>
        <el-table-column
            label="操作" align="center">
          <template v-slot="{row}">
            <el-dropdown class="cursorPointer" placement='top' trigger="click">
              <span style="color: #409EFF;">设置<i class="el-icon-arrow-down el-icon--right"></i></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="toSettingAct('BasicSet',row.id)">编辑</el-dropdown-item>
                <el-dropdown-item @click.native="handlePublish(row)">查看活动</el-dropdown-item>
                <el-dropdown-item divided @click.native="toSettingAct('Statistics',row.id)">投票统计
                </el-dropdown-item>
                <el-dropdown-item @click.native="toSettingAct('Ranking',row.id)">票数排行</el-dropdown-item>
                <el-dropdown-item divided @click.native="handleDuplicate(row)">复制活动</el-dropdown-item>
                <el-dropdown-item v-if="false" @click.native="handleVirtualDate(row)">虚拟数据</el-dropdown-item>
                <el-dropdown-item v-if="row.startTime>new Date().getTime()||row.endTime<new Date().getTime()" divided
                                  @click.native="handleClear(row)">清空数据</el-dropdown-item>
                <el-dropdown-item v-if="state.user.id" @click.native="handleDelete(row)">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class='pagination-wrapper' v-if="page.totalCount>0">
        <el-pagination background layout="total,prev, pager, next"
                       :current-page.sync="pageNo" :page-size="page.pageCount" :total="page.totalCount"
                       @current-change="query">
        </el-pagination>
      </div>
      <!--无数据时 -->
      <div v-if="state.actListQuery.status=='0'&&state.actListQuery.name==''&&page.totalCount==0" class='no-data'>
        <el-empty :image-size="200" description="暂无活动">
          <el-button icon="el-icon-plus" type="primary" @click="createActivity">创建活动</el-button>
        </el-empty>
      </div>
    </div>
    <!--复制活动弹窗 -->
    <el-dialog
        title="复制活动"
        :visible.sync="duplicateDialogVisible"
        width="30%"
        top="30vh"
        :close-on-click-modal="false">
      <el-row class="dialog-row-name">
        <el-col>{{ operateAct.name }}</el-col>
      </el-row>

      <el-row style="width: 83%">
        <el-col :span="6"><span>复制选项</span></el-col>
        <el-col :span="8">
          <el-checkbox v-model="copyActParam.copyActivityData" disabled>活动数据</el-checkbox>
        </el-col>
        <el-col :span="8">
          <el-checkbox v-model="copyActParam.copyPlayerData">选手数据</el-checkbox>
        </el-col>
      </el-row>
      <el-row class="dialog-foot marTop50">
        <el-button @click="duplicateDialogVisible = false" class="button">取 消</el-button>
        <el-button type="primary" @click="copyAct" class="button">确 定</el-button>
      </el-row>
    </el-dialog>

    <!--虚拟数据弹窗 -->
    <el-dialog
        title="虚拟数据"
        :visible.sync="virtualDataDialogVisible"
        width="30%"
        top="30vh"
        :close-on-click-modal="false">
      <!--<el-row class="dialog-row-name">
          <el-col :span="5"><span style='font-size: 14px;'>活动标题  </span></el-col>
          <el-col :span='18'> <span>{{operateAct.name}}</span>  </el-col>
      </el-row>-->

      <el-row style="width: 70%">
        <el-form style="margin-bottom: 0px">
          <el-form-item label-width="110px" label-position="left">
            <div slot="label">
              <div class="span-box align-left">
                <span class="marRight6">虚拟总票数</span>
                <i title="评选页面上总票数=真实总票数+虚拟总票数" class="el-icon-question" style="color: #cccccc"></i>
              </div>
            </div>
            <el-input v-model.trim="updateActVirtualDataParam.virtualVoteNum" maxlength="13"
                      @input="val => updateActVirtualDataParam.virtualVoteNum = val.replace(/[^0-9]/g, '')"></el-input>
          </el-form-item>
          <el-form-item label-width="110px" label-position="left">
            <div slot="label">
              <div class="span-box align-left">
                <span class="marRight6">虚拟访问量</span>
                <i title="评选页面上访问量=真实访问量+虚拟访问量" class="el-icon-question" style="color: #cccccc"></i>
              </div>
            </div>
            <el-input v-model.trim="updateActVirtualDataParam.virtualViewNum" maxlength="13"
                      @input="val => updateActVirtualDataParam.virtualViewNum = val.replace(/[^0-9]/g, '')"></el-input>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row class="dialog-foot marTop20">
        <el-button @click="virtualDataDialogVisible = false" class="button">取 消</el-button>
        <el-button type="primary" @click="updateActVirtualData" class="button">确 定</el-button>
      </el-row>
    </el-dialog>

    <!--清空数据弹窗 -->
    <el-dialog :visible.sync="clearDialogVisible" width="30%" top="30vh" :close-on-click-modal="false">
      <div slot="title">
        <div class="span-box align-left">
          <span class="dialog-title marRight6">清空数据</span>
          <span style="color: red">该操作将清空本活动上勾选的相关数据</span>
        </div>
      </div>
      <el-row style="width: 83%">
        <el-col :span="6"><span>清空选项</span></el-col>
        <el-col :span="8">
          <el-checkbox v-model="clearActDataParam.clearVoteNum">选手票数</el-checkbox>
        </el-col>
        <el-col :span="8">
          <el-checkbox v-model="clearActDataParam.clearViewNum">选手浏览量</el-checkbox>
        </el-col>
      </el-row>
      <el-row class="dialog-foot marTop50">
        <el-button @click="clearDialogVisible = false" class="button">取 消</el-button>
        <el-button type="primary" @click="clearActData" class="button">确 定</el-button>
      </el-row>
    </el-dialog>
    <!--删除活动弹窗 -->
    <deleteDialog :visible.sync="deleteDialogVisible" title="删除活动" :delItem="operateAct"
                  msg="该操作将删除本活动及相关数据" :content="operateAct.name" @del="delAct"></deleteDialog>
    <!-- 发布活动弹窗-->
    <publishDialog :visible.sync="publishDialogVisible" :title="operateAct.name"
                   :url="operateAct.h5Url" publishObj="活动"></publishDialog>
  </div>
</template>

<script>

import publishDialog from '@/components/publishDialog.vue'
import deleteDialog from '@/components/deleteDialog.vue'
import {ajaxUtil} from '@/js/ajaxUtil'
import {api} from '@/js/api'
import {ActivityUtil, CommonUtil} from '@/js/commonUtil'
import store from '@/store/store'
import {messages} from "@/js/messages";
import {StorageUtil} from "@/js/auth";
import {config} from "@/js/config";

export default {
  name: 'ActivityList',
  components: {
    publishDialog,
    deleteDialog
  },
  data() {
    return {
      state: store.state,
      actManageMenu: config.actManageMenu,
      actName: '',
      duplicateDialogVisible: false,
      virtualDataDialogVisible: false,
      clearDialogVisible: false,
      deleteDialogVisible: false,
      publishDialogVisible: false,
      pageNo: 1,
      page: {
        pageNo: 1,
        pageCount: 5,
        totalCount: -1,
        totalPage: 0,
        resultList: []
      },
      operateAct: {},
      copyActParam: {
        sourceActivityId: 0,
        copyActivityData: true,
        copyPlayerData: false,
        copyIpBlacklists: false,
        initPlayerNum: 0
      },
      updateActVirtualDataParam: {
        activityId: 0,
        virtualVoteNum: 0,
        virtualViewNum: 0,
        updateBy: store.state.user.id
      },
      clearActDataParam: {
        activityId: 0,
        clearVoteNum: true,
        clearViewNum: false,
        address: null,
        ip: null,
        updateBy: store.state.user.id
      },
      actListQueryParams: null
    }
  },
  watch: {
    'state.actListQuery.status': {
      handler(newValue, oldValue) {
        this.pageNo = 1;
        this.query();
      },
    },
    'state.actListQuery.name': {
      handler(newValue, oldValue) {
        this.pageNo = 1;
        this.query();
      },
    }
  },
  mounted() {
    let lastTimeQueryParams = StorageUtil.getObject('actListQueryParams');
    StorageUtil.removeObject('actListQueryParams');
    if (lastTimeQueryParams && lastTimeQueryParams.query) {
      store.setActListQuery({name: lastTimeQueryParams.name, status: lastTimeQueryParams.status});
      this.pageNo = lastTimeQueryParams.pageNo;
      this.page.pageCount = lastTimeQueryParams.pageCount;
      //特别注意,在页面返回重新查询时,直接赋值pageNo会导致分页组件的页面双向绑定失败
      //该分页组件时第一次只能查询第一页,无法在第一页查询返回totalCount之前直接给pageNo赋其它值查询,直接查询会导致双向绑定失效
      //为解决该问题,故在离开页面时存储一下总页码,这里查询之前先给totalCount赋值一下
      this.page.totalCount = lastTimeQueryParams.totalCount;
    } else {
      let params = this.$route.params;
      store.setActListQuery(params);
      this.pageNo = 1;
    }
    this.query();
  },
  methods: {
    query() {
      this.page.totalCount = -1;
      let queryBody = {
        customerUserId: this.state.user.id,
        status: this.state.actListQuery.status,
        name: this.state.actListQuery.name,
        pageNo: this.pageNo,
        pageCount: this.page.pageCount
      };
      if (queryBody.customerUserId == 0) {
        //当前登录用户id为0时,则为特殊账号,用于设置模板活动,故只查询模板活动(该特殊账号屏蔽活动创建功能,屏蔽专题相关功能)
        queryBody.modelActivity = true;
      }
      ajaxUtil.ajaxPage(api.activity.query, queryBody, 'POST', null, data => {
        this.pageNo = this.pageNo > data.totalPage ? data.pageNo : this.pageNo;
        CommonUtil.copyProperty(data, this.page);
        queryBody.totalCount = this.page.totalCount;
        this.actListQueryParams = queryBody;
      });
    },
    createActivity() {
      this.$router.push({path: '/activitymodel'})
    },
    toSettingAct(routerName, actId) {
      if (this.actListQueryParams) {
        StorageUtil.storeObject('actListQueryParams', this.actListQueryParams);
      }
      ActivityUtil.toSettingAct(routerName, actId);
    },
    handlePublish(act) {
      this.operateAct = act;
      this.publishDialogVisible = true;
    },
    handleDuplicate(act) {
      this.copyActParam.copyActivityData = true;
      this.copyActParam.copyPlayerData = false;
      this.operateAct = act;
      this.duplicateDialogVisible = true;
    },
    handleVirtualDate(act) {
      this.updateActVirtualDataParam.virtualViewNum = act.virtualViewNum;
      this.updateActVirtualDataParam.virtualVoteNum = act.virtualVoteNum;
      this.operateAct = act;
      this.virtualDataDialogVisible = true;
    },
    handleClear(act) {
      this.operateAct = act;
      this.clearActDataParam.clearVoteNum = true;
      this.clearActDataParam.clearViewNum = false;
      this.clearDialogVisible = true;
    },
    handleDelete(act) {
      this.operateAct = act;
      this.deleteDialogVisible = true;
    },
    copyAct() {
      if (this.operateAct.id) {
        this.duplicateDialogVisible = false;
        this.copyActParam.sourceActivityId = this.operateAct.id;
        ActivityUtil.add(this.copyActParam, () => {
          this.pageNo = 1;
          this.query();
        }, messages.success.actCopy)
      }
    },
    updateActVirtualData() {
      if (this.operateAct.id) {
        if (this.updateActVirtualDataParam.virtualVoteNum == '') {
          this.updateActVirtualDataParam.virtualVoteNum = 0;
        }
        if (this.updateActVirtualDataParam.virtualViewNum == '') {
          this.updateActVirtualDataParam.virtualViewNum = 0;
        }
        this.virtualDataDialogVisible = false;
        this.updateActVirtualDataParam.activityId = this.operateAct.id;
        ajaxUtil.ajaxJson(api.activity.updateVirtualData, this.updateActVirtualDataParam, 'PUT', null, true).then(data => {
          if (data.change) {
            messages.showSuccessMsg(messages.success.operation);
            this.operateAct.virtualVoteNum = parseInt(this.updateActVirtualDataParam.virtualVoteNum);
            this.operateAct.virtualViewNum = parseInt(this.updateActVirtualDataParam.virtualViewNum);
          }
        })
      }
    },
    clearActData() {
      if (this.operateAct.id) {
        if (!this.clearActDataParam.clearViewNum && !this.clearActDataParam.clearVoteNum) {
          messages.showErrorMsg('请选择清除项');
        } else {
          this.clearDialogVisible = false;
          this.clearActDataParam.activityId = this.operateAct.id;
          ajaxUtil.ajaxJson(api.activity.clearPlayerData, this.clearActDataParam, 'PUT', null, true).then(data => {
            if (data.change) {
              messages.showSuccessMsg(messages.success.operation);
              this.query();
            }
          })
        }
      }
    },
    delAct(act) {
      this.deleteDialogVisible = false;
      let body = {id: act.id, status: 0, updateBy: store.state.user.id};
      ajaxUtil.ajaxJson(api.activity.updateStatus, body, 'PUT', null, true).then(data => {
        messages.showSuccessMsg(messages.success.delete);
        ActivityUtil.get(act.id, null, true, false);
        this.query();
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 0 30px;
}

.list-body {
  padding: 0 30px 20px 30px;

  .activity-name {
    display: flex;
    align-items: center;

    img {
      width: 125px;
      height: 80px;
      border-radius: 5px;
    }

    .activity-info {
      padding-left: 20px;

      .activity-title {
        line-height: 32px;
        cursor: pointer;
      }

      .activity-title:hover {
        color: #409EFF;
      }

      .activity-time {
        color: #999999;
      }
    }
  }
}


.dialog-title {
  font-size: 16px;
  color: #303133;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  display: table;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  .thead {
    th {
      height: 40px;
      background: #fff;
      text-align: left;
      border-bottom: 1px solid #eee;
    }
  }

  .tbody1 {
    tr {
      height: 40px;
    }
  }

  .w250 {
    width: 250px;
  }

  .textRight {
    text-align: right !important;
  }
}

.row-name {
  margin-bottom: 15px;
}

.row-time {
  color: #999999;
}

.dialog-span {
  margin-bottom: 20px;
}

.dialog-row-name {
  margin-bottom: 40px;
  font-size: 16px;
}

.dialog-foot {
  text-align: center;

  .button {
    width: 98px;
    margin-right: 20px;
    margin-left: 20px;
  }
}

.pagination-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}
</style>
