<template>
  <div class="content-wrapper-help">
    <div class="content-wrapper help-body">
      <div class="help-nav">
        <el-tabs tabPosition="left" v-model="currentTypeName" @tab-click="handleChangeType">
          <el-tab-pane
              v-for="(item, index) in systemNewsTypes"
              :key="index"
              :label="item.name"
              :name="item.name"
              :typeId="item.id"
          >
          </el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <div>
          <el-input placeholder="请输入内容"
                    prefix-icon="el-icon-search"
                    v-model.trim="key"
                    clearable
                    @change="onSearchKeyChange">
          </el-input>
        </div>
        <div>
          <helpItem v-for="item in systemNewsList" :key="item.id" :itemData="item"></helpItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpItem from '@/components/helpItem.vue'
import {ajaxUtil} from '@/js/ajaxUtil.js'
import {api} from '@/js/api.js'
import store from '@/store/store.js'

export default {
  name: 'help',
  components: {
    helpItem
  },
  data() {
    return {
      systemNewsTypes: [],
      systemNewsList: [],
      currentTypeName: '',
      currentTypeId: 0,
      key: '',
      nodataVisible: false,
    }
  },
  beforeMount() {
    //初始化数据
    this.initData();

  },
  methods: {
    initData() {
      this.key = '';
      //获取所有动态类型并默认加载第一种动态:'常见问题'
      ajaxUtil.ajaxJson(api.systemnews.allTypes, null, 'GET', false).then((data) => {

        this.systemNewsTypes = data;
        this.currentTypeId = data[0].id
        this.currentTypeName = data[0].name;
        this.getSystemNews(this.currentTypeId, this.key);
      })
    },

    handleChangeType(tab) {
      this.currentTypeId = tab.$attrs.typeId;
      this.getSystemNews(this.currentTypeId, this.key);
    },

    onSearchKeyChange() {
      this.getSystemNews(this.currentTypeId, this.key);
    },

    getSystemNews(typeId, searchKey) {
      let helpNewUrl = api.systemnews.query.replace('{type}', typeId).replace('{searchKey}', searchKey).replace('{pageNo}', 1).replace('{pageCount}', 1000); //pagecount设置大点获取全部
      ajaxUtil.ajaxJson(helpNewUrl, null, 'GET').then(data => {
        this.systemNewsList = data.resultList;
        if (this.systemNewsList.length == 0) {
          this.nodataVisible = true;
        } else {
          this.nodataVisible = false;
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.help-body {
  display: flex;
  box-sizing: border-box;
  padding: 30px 20px;

  .help-nav {
    width: 140px;
  }
}


.content-wrapper-help {
  min-height: 100%;

  .help-head-box {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 0 0 0 30px;
    height: 60px;
  }

  ::v-deep .el-tabs__item {
    height: 60px;
    line-height: 60px;
  }

  ::v-deep .el-tabs__nav-prev {
    line-height: 60px;
  }

  ::v-deep .el-tabs__nav-next {
    line-height: 60px;
  }

  .help-head-nav {
    width: 74%
  }

  .help-head-search {
    width: 25%;
    height: 60px;
    margin-top: 10px;
    box-sizing: border-box;
    padding-right: 20px;
    //  margin-right: 20px;
    // ::v-deep .el-input__inner{
    //   height: 60px;
    //   line-height: 60px;
    //   border-top:none;
    //   border-bottom: none;
    //   border-right:none;
    //   padding-left: 55px;
    // }
    // ::v-deep .el-input__prefix{
    //   left: 30px;
    // }
  }
}

.help-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap; /**todo flex-wrap在IE存在兼容问题,暂未处理**/
  justify-content: flex-start;
  padding: 0 30px;
}

.no-data {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 50px;
}
</style>
