<template>
  <div class="playerSet-wrapper">
    <div class="tip">
      <i class="el-icon-chat-line-round"></i> 鼠标点击分组名称不松开,可拖动排序
    </div>
    <div class='player-mg-header'>
      <el-button type="primary" icon="el-icon-plus" size='small' @click='clickAddGroup'>添加分组</el-button>
      <el-table class="move" :data="groupsList" row-key="id" style="width: 100%">
        <el-table-column label="分组名称">
          <template slot-scope="item">
            <el-row @click.native="clickUpdateGroup(item.row)">
              <el-col><span class="cursorPointer clickEle" style="padding:5px 10px 5px 10px">{{ item.row.name }}</span>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column align="center" label="选手数" width="200">
          <template slot-scope="item">
            <el-row @click.native="toPlayerPage(item.row)">
              <el-col><span class="cursorPointer clickEle"
                            style="padding:5px 10px 5px 10px">{{ item.row.voteItemNum }}</span></el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="" width='150'>
          <template slot-scope="item">
            <el-button class="clickEle" type="text" icon="el-icon-plus" @click='addPlayer(item.row)'>添加选手
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width='150'>
          <template slot-scope="item">
            <el-dropdown placement="bottom" class="clickEle">
                                    <span class="cursorPointer el-dropdown-link">
                                        设置<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native='clickUpdateGroup(item.row)'>编辑</el-dropdown-item>
                <el-dropdown-item @click.native='clickDeleteGroup(item.row)'>删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <addPlayer :dialogVisible=addPlayerShow :options='playerDialogOption' @close='handleCloseAddPlayer'></addPlayer>
    <!--添加分组弹出框-->
    <el-dialog :visible.sync="isAddGroups" width="400px" top="30vh"
               :close-on-click-modal="false" center :before-close="beforeAddGroupClose">
      <div slot="title">
        <div class="span-box align-left">
          <span class="dialog-title marRight6">{{ editGroupDialogTitle }}</span>
        </div>
      </div>
      <div class="demo-input-suffix clear">
        <el-form hide-required-asterisk :model="addGroupFormData" ref="addGroupForm" :rules="addGroupFormRules"
                 class="demo-form-inline" onsubmit="return false;">
          <el-form-item label="分组名称" prop="groupName" label-width="80px" label-position="left">
            <el-input maxlength="20" placeholder="请输入分组名称" v-model.trim="addGroupFormData.groupName"
                      style="width:260px"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="closeAddGroupDialog">取 消</el-button>
                <el-button type="primary" @click="addGroup">确 定</el-button>
            </span>
    </el-dialog>
    <!--删除分组弹出框-->
    <el-dialog :visible.sync="isDeleteGroup" width="540px" top="30vh"
               :close-on-click-modal="false" center>
      <div slot="title">
        <div class="span-box align-left">
          <span class="dialog-title marRight6">删除分组</span>
        </div>
      </div>
      <div class="demo-input-suffix clear">
        <el-row class="row-name">
          <el-col :span="4"><span>分组名称</span></el-col>
          <el-col :span="12"><span>{{ toDeleteGroupName }}</span></el-col>
        </el-row>
        <el-row class="row-name" style='line-height:40px;'>
          <el-col :span="4"><span>删除选项</span></el-col>
          <el-col :span="7">
            <el-radio v-model="deleteOnlyRadio" :label="true">仅删除分组</el-radio>
          </el-col>
          <el-col :span="5" v-if="deleteOnlyRadio&&moveToGroupList.length>0"><span>将选手移至</span></el-col>
          <el-col :span="8" v-if="deleteOnlyRadio&&moveToGroupList.length>0">
            <el-select v-model="moveToGroupId" placeholder="请选择">
              <el-option
                  v-for="item in moveToGroupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="12" style="margin-left: 82px">
            <span><el-radio v-model="deleteOnlyRadio" :label="false">删除分组及选手</el-radio></span>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="closeDelGroupDialog">取 消</el-button>
                <el-button type="primary" @click="deleteGroup">确 定</el-button>
            </span>
    </el-dialog>
    <div id="mouseMsg"
         style="display:none;fontSize:12px;color:#c5c5c5;padding:5px 10px;position:fixed;background:#eee;">
      长按鼠标拖动可调整分组顺序
    </div>
  </div>
</template>

<script>
import addPlayer from '@/components/addPlayer.vue'
import store from '@/store/store'
import {config} from '@/js/config'
import {ajaxUtil} from '@/js/ajaxUtil'
import {api} from '@/js/api'
import {ActivityUtil} from '@/js/commonUtil'
import {messages} from '@/js/messages'
import Sortable from 'sortablejs'

export default {
  name: 'playerGroupSet',
  data() {
    return {
      activityId: ActivityUtil.getCurrentSettingActId(),
      isAddGroups: false,
      addPlayerShow: false,
      activeName: 'first',
      editGroupDialogTitle: '',
      selectedGroupId: 0,
      addGroupFormData: {
        groupName: '',
      },
      addGroupFormRules: {
        groupName: [
          {required: true, message: '请输入1-20个字符的分组名称', trigger: 'blur'}
        ]
      },
      isDeleteGroup: false,
      toDeleteGroupName: '',
      deleteOnlyRadio: true,
      moveToGroupId: 0,
      moveToGroupList: [],
      playerDialogOption: {},
      groupsList: []
    }
  },
  components: {
    addPlayer
  },
  beforeMount() {
    //初始化group列表
    this.getGroups();
    this.$nextTick(() => {
      this.rowDrop();
    })
  },
  methods: {
    initMouseMsg() {
      let msg = document.getElementById('mouseMsg');
      let clickEles = document.getElementsByClassName('clickEle');
      clickEles.forEach(item => {
        item.onmouseover = function () {
          msg.style.display = "none";
        };
        item.onmouseout = function () {
          msg.style.display = "block";
        };
      });
      let tbody = document.getElementsByTagName('tbody')[0];
      tbody.onmouseenter = function () {
        msg.style.display = "block";
      };
      tbody.onmouseleave = function () {
        msg.style.display = "none";
      };
      tbody.onmousemove = function (ev) {
        let e = ev || window.event;
        msg.style.left = e.clientX + 5 + 'px';
        msg.style.top = e.clientY + 5 + 'px';
      }
    },
    rowDrop() {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        onEnd({newIndex, oldIndex}) {
          const currRow = _this.groupsList.splice(oldIndex, 1)[0]
          _this.groupsList.splice(newIndex, 0, currRow)
          _this.updateSorts()
        }
      })
    },
    updateSorts() {
      if (this.groupsList && this.groupsList.length > 1) {
        const sortArr = this.groupsList.map(item => item.sortNum).sort((item1, item2) => item1 - item2);
        for (let i = 0; i < sortArr.length; i++) {
          this.groupsList[i].sortNum = sortArr[i];
        }
        let sorts = [];
        this.groupsList.forEach(item => sorts.push({id: item.id, sort: item.sortNum}));
        let body = {activityId: this.activityId, sorts: sorts, updateBy: store.state.user.id};
        ajaxUtil.ajaxJson(api.player.groupSorts, body, 'PUT', null, true).then(data => {
          messages.showSuccessMsg(messages.success.operation);
          ActivityUtil.refreshH5(false);
        });
      }
    },
    getGroups() {
      let url = api.player.groupList.replace('{activityId}', this.activityId);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        this.groupsList = data;
        this.$nextTick(() => {
          this.initMouseMsg();
        })
      })
    },
    clickAddGroup() {
      this.editGroupDialogTitle = '新增分组';
      this.selectedGroupId = 0;
      this.addGroupFormData.groupName = '';
      this.isAddGroups = true;
    },

    addGroup() {
      this.$refs["addGroupForm"].validate(valid => {
        if (!valid) {
          return false;
        }

        if (this.editGroupDialogTitle == '新增分组') {
          //验证通过后调用后台新增分组
          let requestData = {
            activityId: this.activityId, name: this.addGroupFormData.groupName,
            createBy: store.state.user.id, updateBy: store.state.user.id
          };
          ajaxUtil.ajaxJson(api.player.addGroup, requestData, 'POST', null, true).then(data => {
            //成功后提示，隐藏弹出框，并刷新分组列表
            messages.showSuccessMsg(messages.success.operation);
            this.isAddGroups = false;
            ActivityUtil.refreshH5(false);
            this.getGroups();
          })
        } else {
          //修改分组
          let requestData = {
            id: this.selectedGroupId, activityId: this.activityId,
            name: this.addGroupFormData.groupName, createBy: store.state.user.id, updateBy: store.state.user.id
          };
          ajaxUtil.ajaxJson(api.player.updateGroup, requestData, 'PUT', null, true).then(data => {
            //成功后提示，隐藏弹出框，并刷新分组列表
            messages.showSuccessMsg(messages.success.update);
            this.isAddGroups = false;
            ActivityUtil.refreshH5(false);
            this.getGroups();
          })
        }
      })
    },

    beforeAddGroupClose(done) {
      this.$refs["addGroupForm"].clearValidate();
      done();
    },
    closeAddGroupDialog() {
      this.$refs["addGroupForm"].clearValidate();
      this.isAddGroups = false;
    },

    clickUpdateGroup(row) {
      this.editGroupDialogTitle = '编辑分组';
      this.selectedGroupId = row.id;
      this.addGroupFormData.groupName = row.name;
      this.isAddGroups = true;
    },

    toPlayerPage(row) {
      ActivityUtil.toSettingAct('PlayerSet',
          ActivityUtil.getCurrentSettingActId(), {groupId: row.id});
    },

    clickDeleteGroup(row) {
      this.moveToGroupList = [];
      for (var i in this.groupsList) {
        if (this.groupsList[i].id == row.id) {
          continue;
        }
        this.moveToGroupList.push(this.groupsList[i]);
      }
      this.toDeleteGroupName = row.name;
      this.selectedGroupId = row.id;
      this.moveToGroupId = this.moveToGroupList.length > 0 ? this.moveToGroupList[0].id : 0;
      this.deleteOnlyRadio = true;
      this.isDeleteGroup = true;
    },

    closeDelGroupDialog() {
      this.isDeleteGroup = false;
    },

    //删除group
    deleteGroup() {
      let requestData = {
        id: this.selectedGroupId, activityId: this.activityId,
        onlyDeleteGroup: this.deleteOnlyRadio, newGroupId: 0, updateBy: store.state.user.id
      }

      if (this.deleteOnlyRadio) {
        requestData.newGroupId = this.moveToGroupId;
      }
      ajaxUtil.ajaxJson(api.player.deleteGroup, requestData, 'DELETE', null, true).then(data => {
        messages.showSuccessMsg(messages.success.operation);
        this.getGroups();
        this.isDeleteGroup = false;
        ActivityUtil.refreshH5(false);
      })

    },
    addPlayer(item) {
      this.playerDialogOption = {type: 0, groupId: item.id}
      this.addPlayerShow = true;
    },
    handleCloseAddPlayer() {
      this.addPlayerShow = false
      this.getGroups();
    }
  }
}
</script>
<style lang="scss" scoped>
.move {
  cursor: move;
}

.playerSet-wrapper {
  padding-bottom: 20px;
  .tip{
    width: 100%;
    box-sizing: border-box;
    padding: 10px 30px;
    border-radius: 5px;
    border: #409eff 1px solid;
    background: rgba(64, 158, 255, 0.1);
    color: #409eff;
  }

  .player-mg-header {
    margin-top: 10px;
    padding-bottom: 15px;

    .header-right {
      line-height: 40px;

      .el-dropdown {
        margin-right: 10px;
      }
    }
  }

  .searchbox {
    width: 140px;
  }

  .playerInfo {
    .desc {
      font-size: 14px;
      vertical-align: top;
      line-height: 36px;
      margin-left: 10px;
    }
  }

  .player-mg-foot {
    margin-top: 20px;
  }

  .dialog-foot {
    text-align: center;

    .button {
      width: 98px;
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  .dialog-title {
    font-size: 16px;
    color: #303133;
  }

  .row-name {
    margin-bottom: 30px;
  }

  #msgBOX {

  }
}
</style>
