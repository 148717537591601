<template>
  <div class='template'>
    <div class="banner">
      <img src="@/assets/official/template/banner_txt.d72fae9f.png" alt="" srcset="">
      <a href="/act/theme" @click='createActivity' class="">创建活动</a>
    </div>
    <div class="title">
      <p class="t1">模板中心</p>
      <div class="t2">各种精选模板任您选择，还可随心定制个性化模板</div>
    </div>
    <div class="nav-list">
      <div @click='onTab(item)'
           :class="[currentIndustryIdValue==item.value?'active':'','item']"
           v-for='(item) in industryLabels' :key='item.value'>
        <div class="name">{{ item.des }}</div>
      </div>
    </div>
    <div class='search-box'>
      <div class='search'>
        <input v-model.trim="keyword" type="text" maxlength="50" placeholder="请输入模板关键词进行查找"
               @blur="changeKeyword">
        <button class="cursorPointer" @click="changeKeyword">搜索</button>
      </div>
    </div>
    <div class='template-box'>
      <div class="tab">
        <div class="body">
          <div @click='onTab(item)' class="cursorPointer"
               :class="[currentIndustryIdValue==item.value?'bar-active':'bar-defalut','bar-item']"
               v-for='(item) in industryLabels' :key='item.value'>{{ item.des }}
          </div>
        </div>
      </div>
      <div class='right-item-box' v-infinite-scroll="search" infinite-scroll-throttle-delay="500"
           infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div class='item' v-for="(item,index) in resultList" :key='index'>
          <div class="cover">
            <img :src="item.coverImageUrl|appendImgUrl" alt="">
            <div class='item-mask'>
              <button class="cursorPointer" @click='createActivity'>创建活动</button>
            </div>
          </div>
        </div>
        <div class='no-data-office' v-if="resultList.length==0">
          <el-empty :image-size="200"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ajaxUtil} from '@/js/ajaxUtil'
import {api} from '@/js/api'
import {CommonUtil} from '@/js/commonUtil'
import infiniteScroll from 'vue-infinite-scroll'
import {QrCode} from '@/js/qrcodeUtil.js';

export default {
  name: 'templateCenter',
  data() {
    return {
      createActivityDialog: false,
      industryLabels: [{des: '全部', value: 0}],
      currentIndustryIdValue: 0,
      busy: false,//是否禁用滚动加载事件,用于控制滚动加载组件的重复触发问题
      resultList: [],
      keyword: '',
      queryParam: {
        keyword: null,
        activityModelIndustryId: 0,
        activityModelStyleId: 0,
        needAggregation: false,
        supportSearchAfter: true,
        pageCount: 20,
        pagingType: 1,
        searchBeforeAfter: null,
        resetOrderBy: null,
        showOfficial: true
      },
      operateModel: {
        id: 0,
        name: '',
        activityH5Url: '',
        functionalDesc: '',
        applicableScenario: '',
        modelActivityId: 0
      }
    }
  },
  components: {},
  mounted() {
    this.getLabels();
    this.research();
  },
  methods: {
    onscrollTop() {
      window.scrollTo(0, 760);
      // chrome
      document.body.scrollTop = 760
      // firefox
      document.documentElement.scrollTop = 760
      // safari
      window.pageYOffset = 760
    },
    getLabels() {
      ajaxUtil.ajaxJson(api.activitymodel.labels, null, "GET").then(data => {
        if (data.industryLabels && data.industryLabels.length > 0) {
          this.industryLabels = [{des: '全部', value: 0}];
          this.industryLabels.push(...data.industryLabels);
        }
      })
    },
    getQrCode(activityH5Url) {
      return QrCode.url(activityH5Url, 170);
    },
    createActivity() {
      this.$router.push('/login');
    },
    checkDetail(actModel) {
      this.operateModel = actModel;
      this.createActivityDialog = true;
    },
    changeKeyword() {
      if (!CommonUtil.compare(this.keyword, this.queryParam.keyword)) {
        this.queryParam.keyword = this.keyword;
        this.research()
      }
    },
    onTab(item) {
      if (this.currentIndustryIdValue != item.value) {
        this.currentIndustryIdValue = item.value;
        this.research();
      }
    },
    research() {
      this.queryParam.pagingType = 1;
      this.search();
    },
    search() {
      this.busy = true;//设置为true,禁止滚动加载事件,防止重复触发
      this.queryParam.activityModelIndustryId = this.currentIndustryIdValue;
      if (!this.queryParam.resetOrderBy) {
        this.queryParam.resetOrderBy = 'hot-desc;id-asc-unique';
      }
      let bol = this.queryParam.pagingType == 1;
      let paramStr = CommonUtil.getAllProperty(this.queryParam);
      ajaxUtil.ajaxJson(api.activitymodel.query + '?' + paramStr, null, "GET").then(page => {
        if (paramStr == CommonUtil.getAllProperty(this.queryParam)) {
          //查询完毕后先检查一次参数是否变化,若未变化才进行处理,防止重复的查询请求导致数据异常
          if (page.resultList.length > 0) {
            this.queryParam.pagingType = 3;
            this.queryParam.searchBeforeAfter = page.searchAfter;
          }
          if (bol) {
            this.resultList = page.resultList;
          } else {
            this.resultList.push(...page.resultList);
            if (this.resultList.length == 0) {
              this.queryParam.pagingType = 1;
              this.queryParam.searchBeforeAfter = null;
            }
          }
          //若为最后一页,则设置为true,不再继续假装,防止一直触发无效的查询请求
          //若不是最后一页,则设置为false,则可继续触发滚动加载事件
          this.busy = page.resultList.length < page.pageCount;
          this.onscrollTop()
        } else {
          this.busy = false
        }
      }, err => {
        this.busy = false
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.template {
  .banner {
    width: 100%;
    height: 760px;
    background: url("../../../assets/official/template/banner.d8ca8087.png") 50% center / 100% 760px;
    text-align: center;

    img {
      width: 680px;
      height: 281px;
      margin: 180px auto 0;
    }

    a {
      width: 234px;
      height: 61px;
      background: rgb(255, 255, 255);
      border-radius: 4px;
      color: rgb(35, 130, 197);
      font-size: 22px;
      line-height: 61px;
      display: block;
      margin: 70px auto 0px;
      text-align: center;
      text-decoration: none;
    }

  }


  .title {
    display: none;
  }

  .nav-list {
    display: none;
  }

  .search-box {
    width: 100%;
    height: 160px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .search {
      width: 800px;
      height: 48px;
      display: flex;
      justify-content: flex-start;
      background: #EFF2F6;

      input {
        width: 680px;
        height: 48px;
        border: none;
        background: #EFF2F6;
        border-radius: 6px 0 0 6px;
        text-indent: 30px;
        color: #333;
        font-size: 16px;
        outline: none;

        &::placeholder {
          color: #999;
          font-size: 16px;
        }
      }

      button {
        width: 120px;
        height: 48px;
        background: #3B69FF;
        color: #fff;
        border: none;
        line-height: 48px;
        border-radius: 6px 6px 6px 6px;
        font-size: 16px;
        outline: none;
      }
    }
  }

  .template-box {
    margin: auto;
    width: 1200px;
    padding: 40px 0;
    box-sizing: border-box;
    position: relative;
    min-height: 500px;

    .tab {
      position: absolute;
      left: -150px;
      height: 100%;

      .body {
        position: sticky;
        width: 120px;
        height: 500px;
        background: #fff;
        border-radius: 6px;
        top: 100px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

        .bar-item {
          width: 100%;
          height: 48px;
          text-align: center;
          line-height: 48px;

          &:nth-of-type(1) {
            border-radius: 6px 6px 0 0;
          }

          &:nth-last-of-type(1) {
            border-radius: 0px 0px 6px 6px;
          }
        }

        .bar-defalut {
          color: #333;
        }

        .bar-active {
          background: #3B69FF;
          color: #fff;
        }
      }

    }

    .right-item-box {
      min-height: 400px;
      box-sizing: border-box;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: calc(25% - 20px);
        min-height: auto;
        height: auto;
        background: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px;


        .cover {
          position: relative;
          width: 100%;

          img {
            border-radius: 8px;
            display: block;
            width: 100%;
            height: 100%;
          }

          .item-mask {
            border-radius: 8px;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .4);
            z-index: 9;
            display: none;

            .code-box {
              width: 170px;
              height: 170px;
              background: #fff;
              position: absolute;
              top: 133px;
              left: 45px;
              text-align: center;

              img {
                width: 95px;
                height: 95px;
                margin-top: 20px;
              }

              p {
                font-size: 14px;
                color: #666;
                margin-top: 4px;
              }
            }

            button {
              width: 120px;
              height: 40px;
              background: #3B69FF;
              color: #fff;
              font-size: 14px;
              border: none;
              border-radius: 4px;
              outline: none;
            }
          }
        }

        .cover:hover {
          .item-mask {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .no-data-office {
        display: block;
      }
    }
  }

  .create-activity-wrapper {
    overflow: hidden;
    width: 100%;
    height: 530px;

    .act-iframe {
      width: 41%;
      height: 100%;
      border: 1px solid #eee;
    }

    .act-indrouce {
      width: 51%;
      margin-left: 30px;
      height: 100%;
      position: relative;
      padding-top: 10%;

      h2 {
        font: 14px "Microsoft YaHei";
        font-weight: normal;
        padding-bottom: 10px;
        color: #606266;
      }

      p {
        word-wrap: break-word;
        word-break: break-all;
        font: 12px "Microsoft YaHei";
        color: #999999;
        padding-bottom: 30px;
      }

      .act-btn-code {
        height: 140px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 130px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .act-btn {
          float: left;
          height: 100%;
          position: relative;
          top: 40%;
        }

        .code-div {
          float: right;
          width: 120px;
          height: 100%;
          font: 12px "Microsoft YaHei";
          color: #606266;
          text-align: center;

          .code {
            width: 120px;
            height: 120px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 996px) {
  .template {
    background-color: #fff;

    .title {
      display: block;
      text-align: left;
      padding: 36px 16px 0 25px;

      .t1 {
        position: relative;
        font-weight: bold;
        font-size: 18px;
        color: #1a1a1a;
      }

      .t1:before {
        position: absolute;
        content: "";
        width: 6px;
        height: 18px;
        background: linear-gradient(90deg, #eef7ff, #489edb);
        top: calc(50% - 9px);
        left: -10px;
      }

      .t2 {
        color: #666;
        font-size: 14px;
        margin-top: 6px;
      }
    }

    .banner {
      width: 100%;
      height: 350px;
      background: url("../../../assets/official/template/banner.d8ca8087.png") 50% center / 100% 350px;

      img {
        width: 90%;
        height: 120px;
        margin: 65px auto 0;
      }

      a {
        width: 100px;
        height: 32px;
        font-size: 16px;
        line-height: 32px;
        margin: 20px auto 0;
      }
    }

    .nav-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px 5px;
      box-sizing: border-box;
      width: 100%;

      .item {
        box-sizing: border-box;
        width: 25%;
        padding: 5px;

        .name {
          height: 30px;
          text-align: center;
          color: #333;
          background: #f4f4f4;
          border-radius: 4px;
          line-height: 30px;
        }
      }

      .item.active {
        .name {
          color: #fff;
          background: #409eff;
          font-weight: bold;
        }
      }
    }

    .search-box {
      width: 100%;
      height: auto;
      padding: 10px;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      .search {
        width: 100%;
        height: auto;
        background-color: transparent;
        box-sizing: border-box;

        input {
          width: 70%;
          height: 32px;
          padding: 5px 0;
          text-indent: 30px;
          font-size: 16px;

          &::placeholder {
            font-size: 16px;
          }
        }

        button {
          width: 30%;
          height: 42px;
          background: #409eff;
          line-height: 42px;
          border-radius: 0 6px 6px 0;
          font-size: 16px;
        }
      }
    }

    .template-box {
      margin: auto;
      width: 100%;
      padding: 20px 0 60px 0;
      position: relative;
      min-height: 500px;

      .tab {
        display: none;
      }

      .right-item-box {
        min-height: 400px;
        box-sizing: border-box;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;

        .item {
          width: calc(50% - 20px);
          min-height: auto;
          height: auto;
          background: #fff;
          border-radius: 10px;
          box-sizing: border-box;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
          padding: 10px;
          margin: 10px;


          .cover {
            position: relative;
            width: 100%;

            img {
              border-radius: 8px;
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }

        .no-data-office {
          display: block;
        }
      }
    }
  }
}
</style>
