<template>
  <div class="image-list">
    <div class="image-item" v-for="(item, index) in images" :key="index" v-bind:style="{
        height: height+'px',
        width: width+'px',
      }" @mouseenter="showChangeImg(index)" @mouseleave="showChangeImg(-1)">
      <div v-if="item" class="img">
        <img v-if="item.startsWith('@/')" v-bind:style="ratioScaling?{}:{width:'100%',height:'100%'}"
             :src="require('@/'+item.substring(2))">
        <img v-else :src="item | appendImgUrl" v-bind:style="ratioScaling?{}:{width:'100%',height:'100%'}"/>
      </div>
      <div v-else-if="showChangeImgIndex!=index" class='img-active noneBackground'>
        <div class="change" @click='changeImg(index)'>
          <p v-if="noneDes">{{ noneDes }}</p>
        </div>
      </div>
      <div class='img-active shadow' v-show='showChangeImgIndex==index'>
        <div v-if="del" @click='delImg(index)' class="icon"><i class="el-icon-delete"></i></div>
        <div class="change" @click='changeImg(index)'>
          <p v-if="shadowDes">{{ shadowDes }}</p>
        </div>
      </div>
    </div>
    <div v-if="!images||maxImageNum>images.length" class="plus-img" v-bind:style="{
        height: (height-2*plusBorder)+'px',
        width: (width-2*plusBorder)+'px',
        textAlign: 'center',
        border: plusBorder+'px dashed #e8e5e5',
        backgroundColor: plusColor,
        cursor: 'pointer'
      }" @click='addImg'>
      <i class="el-icon-plus avatar-uploader-icon" :style="{
        fontSize: plusSize+'px',
        color: '#8c939d',
        lineHeight: height+'px'
      }"></i>
    </div>
    <p class='fl checkBtn' v-if="btnName1" v-bind:style="{color: btnColor1}" @click="clickBtn1">{{ btnName1 }}</p>
    <p class='fl checkBtn' v-if="btnName2" v-bind:style="{color: btnColor2}" @click="clickBtn2">{{ btnName2 }}</p>
  </div>
</template>

<script>
export default {
  name: 'imageContainer',
  props: {
    isEditVisible: {
      type: Boolean,
      default: null
    },
    ratioScaling: {//是否等比例缩放,为true时按原图比例进行缩放,不会导致图片变形,但可能致使图片无法铺满;为false时则直接按指定尺寸显示,可能导致图片变形
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 200
    },
    width: {
      type: Number,
      default: 200
    },
    plusSize: {
      type: Number,
      default: 36
    },
    plusBorder: {
      type: Number,
      default: 0
    },
    plusColor: {
      type: String,
      default: ''
    },
    del: {
      type: Boolean,
      default: false
    },
    noneDes: {
      type: String,
      default: '去设置'
    },
    shadowDes: {
      type: String,
      default: '去设置'
    },
    images: {
      type: Array,
      default: null
    },
    maxImageNum: {
      type: Number,
      default: 1
    },
    btnName1: {
      type: String,
      default: null,
    },
    btnName2: {
      type: String,
      default: null,
    },
    btnColor1: {
      type: String,
      default: '#409EFF',
    },
    btnColor2: {
      type: String,
      default: '#409EFF',
    },
    showContent: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      showChangeImgIndex: -1
    }
  },
  methods: {
    changeImg(index) {
      if (this.isEditVisible != null) {
        this.$emit('update:isEditVisible', true)
      }
      this.$emit('changeImg', index)
    },
    addImg() {
      if (this.isEditVisible != null) {
        this.$emit('update:isEditVisible', true)
      }
      this.$emit('addImg')
    },
    delImg(index) {
      this.$emit('delImg', index)
    },
    clickBtn1() {
      this.$emit('clickBtn1')
    },
    clickBtn2() {
      this.$emit('clickBtn2')
    },
    showChangeImg(index) {
      this.showChangeImgIndex = index;
    }
  }
}
</script>
<style lang="scss" scoped>
.image-list {
  display: flex;
  flex-wrap: wrap;

  .image-item {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid transparent;
    background-position: 50%;
    border-radius: 4px;
    overflow: hidden;
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f6f8;
  text-align: center;

  img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
  }
}

.shadow {
  background: rgba(48, 38, 38, 0.5);
  color: #fff;
}

.noneBackground {
  background: #f5f6f8;
}

.img-active {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  font-size: 14px;
  text-align: center;
  display: table;
  cursor: pointer;

  .change {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .icon {
    height: 30px;
    position: absolute;
    text-align: right;
    right: 12px;
    top: 0;
    z-index: 99999999999999;
  }
}

.checkBtn {
  cursor: pointer;
  margin-left: 20px;
}
</style>
