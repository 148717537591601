<template>
  <div class="add-player-dialog">
    <!--添加选手弹出框-->
    <el-dialog id='addDialogId' :title="options.type?'编辑选手':'新增选手'"
               :visible.sync="dialogOpen" width="1050px" :close-on-click-modal="false"
               :before-close="handleClose" top="2vh">
      <el-form hide-required-asterisk :model="addPlayerFormData" ref="addPlayerForm" :rules="addPlayerFormRules"
               label-position="left" label-width="80px">
        <el-form-item label="选手图片">
          <div class="cover-type">
            <el-radio-group v-model="addPlayerFormData.coverType">
              <el-radio :label="1">图片封面</el-radio>
              <el-radio :label="2">视频封面</el-radio>
            </el-radio-group>
            <div class="video-tip">如何获取视频通用代码?</div>
          </div>
          <div class="cover-list">
            <div class='upload-img' v-if="addPlayerFormData.coverType===1">
              <div class="cover-tip">
                <img src="@/assets/user/activity/as-cover-tip.png" alt="">
              </div>
              <el-upload
                  action="#"
                  :show-file-list="false"
                  accept="image/png, image/jpeg"
                  :http-request="uploadAvatar">
                <div class='upload-preview'>
                  <img v-if="addPlayerFormData.imageUrl" :src="addPlayerFormData.imageUrl | appendImgUrl" alt="">
                  <img v-else src="@/assets/avatar-def.png" alt="">
                </div>
                <div class="upload-operate">
                  <div class="file-btn">重新上传</div>
                  <div v-if="addPlayerFormData.imageUrl"
                       class="file-del" @click.stop="bindDelCoverChange">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </el-upload>
            </div>
            <div class="cover-video" v-if="addPlayerFormData.coverType===2">
              <div class="cover-tip">
                <img src="@/assets/user/activity/as-cover-tip.png" alt="">
              </div>
              <el-input placeholder="请输入内容" type="textarea" :row='4'
                        maxlength="500" v-model="addPlayerFormData.videoCode">
              </el-input>
            </div>
            <div class="cover-other">
              <el-upload ref="uploadImages" action="#" list-type="picture-card"
                         class="upload-other"
                         :multiple="true"
                         :on-remove="onImgRemove" :on-change="onImgChange"
                         :limit="imgUrlListLimit"
                         :fileList="imgUrlList"
                         accept="image/png, image/jpeg"
                         :http-request="uploadImage">
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <div class="row">
          <el-form-item label="选手名称" prop="name">
            <el-input placeholder="请输入选手简介"
                      v-model="addPlayerFormData.name" type="textarea" :autosize="{ minRows: 2, maxRows: 9}"
                      maxlength="60" show-word-limit/>
          </el-form-item>
          <el-form-item label="选手编号" prop="num">
            <el-input placeholder="请输入选手编号" maxlength="10" v-model.trim="addPlayerFormData.num"
                      @input="val => addPlayerFormData.num = val.replace(/[^0-9]/g, '')"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="选手简介">
            <el-input placeholder="请输入选手简介"
                      v-model="addPlayerFormData.intro" type="textarea" :autosize="{ minRows: 2, maxRows: 9}"
                      maxlength="200" show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item v-if="groups.length>1" label="选手分组">
            <el-select v-model="selectedGroupId" placeholder="请选择">
              <el-option
                  v-for="item in groups"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="选手详情">
          <el-checkbox v-model="addPlayerFormData.detailsType">外部链接</el-checkbox>
          <div class='edit-flex' v-show='!addPlayerFormData.detailsType'>
            <tiny-editor :width="600" :height="400" ref="playerTinymce"
                         v-model="addPlayerFormData.content"></tiny-editor>
            <editStylebox :width="300" :height=400 @chooseRichTextModel="selectRichTextModel"
                          :richTextType=1></editStylebox>
          </div>
        </el-form-item>
        <el-form-item prop="jumpUrl" v-show='addPlayerFormData.detailsType'>
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入选手详情链接"
                    v-model="addPlayerFormData.jumpUrl" maxlength="200" show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="foot-operate">
        <el-button :disabled="isLoading" type="primary" @click="submit('')">修 改</el-button>
        <el-tooltip class="item" effect="dark" content="保存当前内容并继续编辑上一个选手" placement="top">
          <el-button :disabled="!this.options.existUp||isLoading" type="primary" @click="submit('up')">上一个
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="保存当前内容并继续编辑上一个选手" placement="top">
          <el-button :disabled="!this.options.existNext||isLoading" type="primary" @click="submit('next')">下一个
          </el-button>
        </el-tooltip>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TinyEditor from "@/components/tinymce.vue"
import editStylebox from '@/components/editStylebox.vue'
import {api} from '@/js/api'
import {ajaxUtil} from '@/js/ajaxUtil'
import {messages} from '@/js/messages'
import {ActivityUtil, CommonUtil} from '@/js/commonUtil'
import {OssFileUploadUtil} from '@/js/ossFileUploadUtil'
import {config} from '@/js/config'
import store from '@/store/store'
import ElButton from "../../node_modules/element-ui/packages/button/src/button";


export default {
  name: 'addPlayer',
  props: ['dialogVisible', 'options'],
  components: {
    ElButton,
    TinyEditor,
    editStylebox
  },
  data() {
    let checkInputEmpty = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('输入不能为空'));
      } else {
        callback();
      }
    };

    return {
      activityId: ActivityUtil.getCurrentSettingActId(),
      dialogOpen: false,
      showGroup: false,
      isLoading: false,
      selectedGroupId: 0,
      addPlayerFormData: {
        id: 0,
        name: '',
        num: 1,
        imageUrl: '',
        imageUrls: [],
        groupId: 0,
        intro: '',
        detailsType: 0,
        content: '',
        coverType: 1,
        videoCode: '',
        jumpUrl: '',
      },
      addPlayerFormRules: {
        name: [{validator: checkInputEmpty, trigger: 'blur'}],
        num: [{validator: checkInputEmpty, trigger: 'blur'}, {
          pattern: /^[1-9]+\d*$/,
          message: '请输入数字编号',
          trigger: 'blur'
        }],
        jumpUrl: [{required: true, message: '输入不能为空', trigger: 'blur'}]
      },
      groups: [],
      imgUrlList: [],
      imgUrlListLimit: 30,
      percentage: 0,
      isProcess: false,
    }
  },
  watch: {
    dialogVisible(val) {
      this.$nextTick(() => {
        this.dialogOpen = val;
        document.getElementById('addDialogId').scrollTop = 0;
      })
    },
    options: {
      handler: function (newVal, oldVal) {
        this.onOpen()
      },
      deep: true
    },
  },
  created() {
  },
  methods: {
    initNewPlayer() {
      this.addPlayerFormData = {
        id: 0,
        name: '',
        num: 1,
        imageUrl: '',
        imageUrls: [],
        groupId: 0,
        intro: '',
        detailsType: 0,
        content: '',
        coverType: 1,
        videoCode: '',
        jumpUrl: '',
      }
    },
    onOpen() {
      this.isLoading = true
      let url = api.player.groupList.replace('{activityId}', this.activityId);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        this.groups = data;
        //初始化图片列表为空
        this.imgUrlList = [];
        const {type,playerId,groupId}  = this.options;
        if (type == 0) { //0 表示新增，同时又分两种情况，一种是指定group，一种是不指定
          this.initNewPlayer();
          if (groupId) {//指定group
            this.selectedGroupId = groupId;
          } else if (data.length) {
            this.selectedGroupId = data[0].id
          }
          //获取下一个选手编号
          this.getNextPlayerNum();
        } else {// 1表示修改
          //获取选手信息
          this.getPlayerById(playerId);
        }
      }, err => {
        this.isLoading = false
      })

    },

    getPlayerById(id) {
      this.isLoading = true
      let url = api.player.info.replace('{id}', id);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        CommonUtil.copyProperty(data, this.addPlayerFormData, false);
        this.addPlayerFormData.detailsType = data.detailsType == 0 ? false : true;
        this.selectedGroupId = data.groupId;
        let imageUrls = data.imageUrls;
        for (var i in imageUrls) {
          this.imgUrlList.push({name: imageUrls[i], url: config.ossHost + imageUrls[i]})
        }
        this.checkFileListLimit(this.imgUrlList);
        this.isLoading = false
      }, err => {
        this.isLoading = false
        messages.showErrorMsg(messages.error.server);
        this.handleClose();
      });
    },

    getGroups() {
      let url = api.player.groupList.replace('{activityId}', this.activityId);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        this.groups = data;
        if (data.length > 1) {
          this.showGroup = true;
          if (!this.options.groupId > 0) {
            this.selectedGroupId = data[0].id;
          }
        } else {
          if (data.length == 1) {
            this.selectedGroupId = data[0].id;
          }
          this.showGroup = false;
        }
      })
    },
    getNextPlayerNum() {
      this.isLoading = true
      ajaxUtil.ajaxJson(api.player.getNextPlayerNum.replace('{activityId}', this.activityId), null, 'GET').then(data => {
        this.addPlayerFormData.num = data;
        this.isLoading = false
      }, err => {
        if (err.data != null && err.data.errorCode != null) {
          messages.showErrorMsgByCode(err.data.errorCode);
        } else {
          messages.showErrorMsg(messages.error.server);
        }
        this.isLoading = false
        this.$emit('close')
      })
    },

    uploadAvatar(option) {
      this.isProcess = true;
      //上传文件
      OssFileUploadUtil.uploadImg(option.file, {
        progress: (p, checkpoint) => {
          // checkpoint参数用于记录上传进度，断点续传上传时将记录的checkpoint参数传入即可。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
          this.percentage = p * 100;
        },
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
      }, (res) => {//成功回调
        this.isProcess = false;
        this.percentage = 0;
        this.addPlayerFormData.imageUrl = res.name;
      }, (err) => {//失败回调
        //错误提示
        this.isProcess = false;
        this.percentage = 0;
        messages.showErrorMsg(messages.error.upload);
      });
    },

    bindDelCoverChange() {
      let {coverType} = this.addPlayerFormData;
      if (coverType === 1) {
        this.addPlayerFormData.imageUrl = ''
      } else if (coverType === 2) {
        this.addPlayerFormData.videoCode = ''
      }
    },


    uploadImage(option) {
      //上传文件
      OssFileUploadUtil.uploadImg(option.file, {
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
      }, (res) => {//成功回调
        option.file.url = res.name;
        this.addPlayerFormData.imageUrls.push(res.name);
        option.onSuccess('')
      }, (err) => {//失败回调
        //错误提示
        messages.showErrorMsg(messages.error.upload);
        //触发elupload on error去除文件列表中上传失败的文件
        option.onError('');
      });
    },

    checkFileListLimit(fileList) {
      //如果超出数量限制，则不显示添加文件框
      let uploadImagesDom = this.$refs['uploadImages'];
      if (fileList.length === this.imgUrlListLimit) {
        uploadImagesDom.$children[1].$el.style.display = 'none';
      } else {
        uploadImagesDom.$children[1].$el.style.display = '';
      }
    },

    onImgRemove(file, fileList) {
      //检查是否超出限制
      this.checkFileListLimit(fileList);
      //修改相应要提交的列表数据
      let url = file.raw == null ? file.name : file.raw.url;
      this.addPlayerFormData.imageUrls = CommonUtil.removeFromArray(this.addPlayerFormData.imageUrls, url)
    },

    onImgChange(file, fileList) {
      //检查是否超出限制
      this.checkFileListLimit(fileList);
    },

    selectRichTextModel(val) {
      this.$nextTick(() => {
        let content = this.addPlayerFormData.content;
        this.addPlayerFormData.content = (content ? content : '') + val;
      })
    },

    submit(currentType) {
      this.isLoading = true
      let detailsType = this.addPlayerFormData.detailsType;
      if (detailsType) {
        this.$refs["addPlayerForm"].validate(valid => {
          if (!valid) {
            return false
          } else {
            this.savePlayer(currentType);
          }
        });
      } else {
        this.$refs["addPlayerForm"].validateField('name', errMsg => {
          if (errMsg) {
            return;
          } else {
            this.savePlayer(currentType);
          }
        });
      }
    },

    savePlayer(currentType) {
      let request = this.addPlayerFormData;
      request.activityId = this.activityId;
      request.groupId = this.selectedGroupId;
      request.createBy = store.state.user.id;
      request.updateBy = store.state.user.id;
      request.detailsType = request.detailsType ? 1 : 0
      //新增选手
      if (this.options.type == 0) {
        ajaxUtil.ajaxJson(api.player.add, request, 'POST', null, true).then(data => {
          messages.showSuccessMsg(messages.success.operation);
          //清空数据继续操作
          ActivityUtil.refreshH5(false);
          this.initNewPlayer();
          this.getNextPlayerNum();
          this.imgUrlList = [];
        })
      } else {//修改选手
        ajaxUtil.ajaxJson(api.player.update, request, 'PUT', null, true).then(data => {
          ActivityUtil.refreshH5(false);
          messages.showSuccessMsg(messages.success.update);
          if (currentType) {
            let index = this.options.index;
            this.$emit('continue', {currentPlayerIndex: index, continueType: currentType})
          } else {
            this.handleClose();
          }
        })
      }
    },

    handleClose() {
      this.$refs["addPlayerForm"].clearValidate()
      this.initNewPlayer();
      this.imgUrlList = [];
      this.checkFileListLimit(this.imgUrlList);
      this.$emit('close')
    },
  }
}
</script>
<style lang="scss" scoped>
.add-player-dialog {
  ::v-deep .el-dialog__body {
    padding-top: 10px;
  }

  .cover-type {
    display: flex;
    align-items: center;

    .video-tip {
      color: #409eff;
      margin-left: 20px;
    }
  }

  .cover-list {
    display: flex;

    .cover-tip {
      position: absolute;
      top: 0;
      left: 0;
      width: 35px;
      height: 31px;
      z-index: 9;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .upload-img {
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      width: 100px;
      height: 100px;

      .upload-preview {
        width: 100px;
        height: 100px;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .upload-operate {
        display: none;
      }
    }

    .upload-img:hover {
      .upload-operate {
        position: absolute;
        color: #fff;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 16px;

        .file-del {
          margin-top: 5px;
          padding: 5px;
        }

        .file-del:hover {
          color: #409eff;
        }

        .file-btn:hover {
          color: #409eff;
        }
      }
    }

    .cover-video {
      position: relative;
      width: 400px;
      height: 100px;

      ::v-deep .el-textarea__inner {
        min-height: 100px !important;
      }
    }

    .cover-other {
      margin-left: 10px;
      box-sizing: border-box;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;

      .upload-other {
        display: flex;
        flex-wrap: nowrap;
      }

      ::v-deep .el-upload--picture-card {
        width: 100px;
        min-width: 100px;
        height: 100px;
        line-height: 100px;
      }

      ::v-deep .el-upload-list--picture-card {
        display: flex;
        flex-wrap: nowrap;
      }

      ::v-deep .el-upload-list__item {
        width: 100px !important;
        height: 100px !important;
        margin: 0 8px 0 0;
        display: flex;
        align-items: center;

        img {
          display: block;
          height: auto;
        }
      }
    }
  }

  .row {
    display: flex;

    .el-form-item:nth-child(1) {
      width: 480px;
    }

    .el-form-item:nth-child(2) {
      margin-left: 20px;
      width: 280px;
    }
  }

  .foot-operate {
    display: flex;
    justify-content: right;
  }
}

</style>
