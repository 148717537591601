<template>
  <div class="act-statistics">
    <div class='operate'>
      <el-button size='small' type="primary" @click="exportActData">下载活动数据</el-button>
    </div>
    <div class="count">
      <div class="item">
        <div class="num">{{ statisticsData.currentDayVoteNum }}</div>
        <div class="title">今日投票数</div>
      </div>
      <div class="item">
        <div class="num">{{ statisticsData.voteNum }}</div>
        <div class="title">总票数</div>
      </div>
      <div class="item">
        <div class="num">{{ statisticsData.currentDayViewNum }}</div>
        <div class="title">今日访问量</div>
      </div>
      <div class="item">
        <div class="num">{{ statisticsData.viewNum }}</div>
        <div class="title">总访问量</div>
      </div>
    </div>
    <div class="chart">
      <div class="header">
        <div class="title">
          <span>活动票数/天</span>
          <span class="num">(当日票数：{{ statisticsData.voteData.currentDayVoteNum }}票)</span>
        </div>
        <div class="date">
          <el-date-picker @change="getVoteData"
                          v-model="voteStatisticsDay"
                          align="right"
                          type="date"
                          placeholder="选择日期"
                          :clearable=false
                          :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </div>
      <div id="actVote" class="content"></div>
    </div>
    <div class="chart">
      <div class="header">
        <div class="title">
          <span>活动访问量/周</span>
          <span class="num">(当日访问量：{{ statisticsData.currentDayViewNum }})</span>
        </div>
        <div class="date">
          <el-date-picker @change="getViewData"
                          v-model="viewStatisticsDay"
                          align="right"
                          type="date"
                          placeholder="选择日期"
                          :clearable=false
                          :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </div>
      <div id="actVisit" class="content"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core';
import {GridComponent, TooltipComponent} from 'echarts/components'; //TooltipComponentOption
import {LineChart} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {CanvasRenderer} from 'echarts/renderers';
import moment from 'moment';
import {ajaxUtil} from "@/js/ajaxUtil";
import {api} from "@/js/api";
import {ActivityUtil} from "@/js/commonUtil";
import * as XLSX from 'xlsx';
import store from "@/store/store";
import {messages} from "@/js/messages";

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition, TooltipComponent]);

export default {
  name: 'votesCount',
  data() {
    return {
      actName: '',
      statisticsData: {
        voteNum: 0,//总票数
        currentDayVoteNum: 0,//今天的票数
        viewNum: 0,//总访问量
        currentDayViewNum: 0,//今天的访问量
        voteData: {
          currentDayVoteNum: 0,//查询目标日期的当日票数
          statistics: []//查询目标日期的小时票数
        },
        viewData: {
          currentDayViewNum: 0,//查询目标日期的当日访问量
          weeklyViewNum: 0,//查询目标日期往后一周的总访问量
          statistics: []//查询目标日期往后一周的每日访问量
        }
      },
      voteStatisticsDay: new Date(),
      viewStatisticsDay: new Date(new Date().getTime() - 3600 * 1000 * 24 * 6),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
    }
  },
  beforeMount() {
  },
  mounted() {
    this.createAvtBar();
    this.createVisitBar();
    this.getVoteData();
    this.getViewData();
  },
  methods: {
    getVoteData() {
      let day = moment(this.voteStatisticsDay.getTime()).format('YYYY-MM-DD');
      let url = api.statistics.getActivityVoteStatistics
          .replace('{activityId}', ActivityUtil.getCurrentSettingActId()).replace('{day}', day);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        let {name, voteNum, currentDayVoteNum, statistics} = data
        this.actName = name
        this.statisticsData.voteNum = voteNum;
        if (day == moment(new Date().getTime()).format('YYYY-MM-DD')) {
          this.statisticsData.currentDayVoteNum = currentDayVoteNum;
        }
        this.statisticsData.voteData.currentDayVoteNum = currentDayVoteNum;
        this.statisticsData.voteData.statistics = statistics;
        this.createAvtBar();
      })
    },
    getViewData() {
      let day = moment(this.viewStatisticsDay.getTime()).format('YYYY-MM-DD');
      let url = api.statistics.getActivityViewStatistics
          .replace('{activityId}', ActivityUtil.getCurrentSettingActId()).replace('{day}', day);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        let {name, viewNum, weeklyViewNum, currentDayVoteNum, statistics} = data
        this.actName = name
        this.statisticsData.viewNum = viewNum;
        this.statisticsData.viewData.weeklyViewNum = weeklyViewNum;
        this.statisticsData.viewData.currentDayVoteNum = currentDayVoteNum;
        this.statisticsData.viewData.statistics = statistics;
        let today = moment(new Date().getTime()).format('YYYY-MM-DD');
        for (let i = 0; i < statistics.length; i++) {
          if (statistics[i].day == today) {
            this.statisticsData.currentDayViewNum = statistics[i].viewNum;
            break;
          }
        }
        this.createVisitBar();
      })
    },
    createAvtBar() {
      let chartDom = document.getElementById('actVote');
      let myChart = echarts.init(chartDom);
      let hours = [];
      let hoursData = [];
      if (this.statisticsData.voteData.statistics.length <= 0) {
        for (let i = 0; i < 24; i++) {
          hours.push(i);
          hoursData.push(0);
        }
      } else {
        this.statisticsData.voteData.statistics.forEach(item => {
          hours.push(item.hour);
          hoursData.push(item.voteNum);
        });
      }
      let option = this.echartsOption(hours, hoursData, '票数');
      option && myChart.setOption(option);
    },
    createVisitBar() {
      let chartDom = document.getElementById('actVisit');
      let myChart = echarts.init(chartDom);
      let days = [];
      let daysData = [];
      if (this.statisticsData.viewData.statistics.length <= 0) {
        for (let i = 0; i < 7; i++) {
          let day = moment(this.viewStatisticsDay.getTime() + 3600 * 1000 * 24 * i).format('YYYY-MM-DD');
          days.push(day);
          daysData.push(0);
        }
      } else {
        this.statisticsData.viewData.statistics.forEach(item => {
          days.push(item.day);
          daysData.push(item.viewNum);
        });
      }
      let option = this.echartsOption(days, daysData, '访问量');
      option && myChart.setOption(option);
    },
    echartsOption(xAxisData, seriesData, valueKey) {
      return {
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: seriesData,
            type: 'line'
          }
        ],
        tooltip: {
          textStyle: {
            align: 'left',
            color: '#fff',
            fontSize: 14
          },
          backgroundColor: '#888888',
          padding: [2, 10, 5, 10],
          trigger: 'axis',
          //自定义echarts tooltip的显示位置
          position: function (position) {
            return [position[0] + 5, position[1]];
          },
          axisPointer: {
            type: 'line',
            lineStyle: {
              type: 'solid',
              width: 1,
              color: '#999'
            }
          },
          formatter: function (params) {
            let name = params[0].name;
            let value = params[0].value;
            return name + '<br>' + valueKey + '：' + value;
          }
        }
      };
    },
    //导出活动数据
    exportActData() {
      const headers = ['选手编号', '选手名称', '选手简介', '选手分组', '选手票数', '选手浏览量']
      let actId = ActivityUtil.getCurrentSettingActId();
      let actName = this.actName
      let url = api.player.ranking.replace('{activityId}', actId);
      ajaxUtil.ajaxPage(url, null, 'GET', null, data => {
        const table = data.resultList.map(
            item => [item.num, item.name, item.intro, item.groupName, item.voteNum, item.viewNum]
        )
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...table])
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
        XLSX.writeFile(workbook, actId + '_' + actName + '_活动数据.xlsx')
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.act-statistics {

  .operate {

  }

  .count {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item {
      width: 22%;
      padding: 30px 0;
      text-align: center;
      background-color: #f1fbff;
      border-radius: 10px;

      .num {
        color: #409eff;
        font-size: 20px;
        line-height: 40px;
        height: 40px;
      }

      .title {
        color: #afafaf;
        font-size: 16px;
      }
    }
  }


  .chart {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0;
    }

    .title {
      color: #000;

      .num {
        color: #afafaf;
        margin-left: 10px;
      }
    }

    .content {
      width: 100%;
      height: 450px;
    }
  }
}
</style>
