<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {ajaxUtil} from "@/js/ajaxUtil";
import {api} from "@/js/api";
import store from "@/store/store";
import {ActivityUtil} from "@/js/commonUtil";

export default {
  data() {
    return {}
  },
  created() {
    this.grantLogin()
  },
  methods: {
    grantLogin() {
      let {grant, actId} = this.$route.query;
      if (grant && actId) {
        ajaxUtil.ajaxLogin(api.customer.login, {grant: grant}, 'POST').then((data => {
          //保存token
          store.setToken(data);
          //获取登陆用户信息
          ajaxUtil.ajaxJson(api.customer.getLoginCustomerInfo, null, 'GET').then((data) => {
            store.setUser(data);
            //跳转首页
            ActivityUtil.toSettingAct('BasicSet', actId);
          })
        }), (err => {
        }))
      }

    }
  }
}
</script>
<style lang="scss">
#app {
  height: 100%;
  width: 100%;
}

.tox-tinymce-aux {
  z-index: 9999 !important;
}
</style>
