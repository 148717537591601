import store from '../store/store'
let StorageUtil = {
    storeString: function (key, value) {
        sessionStorage.setItem(key, value);
    },

    storeObject: function (key, object) {
        sessionStorage.setItem(key, JSON.stringify(object));
    },

    getString: function (key) {
        return sessionStorage.getItem(key);
    },

    getObject: function (key) {
        return JSON.parse(sessionStorage.getItem(key));
    },

    removeObject:function (key) {
      sessionStorage.removeItem(key);
    }
}
let LocalStorageUtil = {
  storeString: function (key, value) {
    localStorage.setItem(key, value);
  },

  storeObject: function (key, object) {
    localStorage.setItem(key, JSON.stringify(object));
  },

  getString: function (key) {
    return localStorage.getItem(key);
  },

  getObject: function (key) {
    return JSON.parse(localStorage.getItem(key));
  },

  removeObject:function (key) {
    localStorage.removeItem(key);
  }
}
let AuthUtil = {
   getToken:function(){
      return store.state.token
   },
   setToken:function(val){
      store.setToken(val)
   },
   removeToken: function(){
      store.removeToken()
   },
   getUuid: function () { //获取全局uuid
       return store.state.uuid
   },

    getSpecifyUuid: function (type) { //获取特殊指定业务的uuid，在全局uuid的基础上加上type
        return store.state.uuid + type;
    },
    
    isLogin: function () {
        var token = store.state.token;
        if (token == null || token === ''){
            return false;
        } else{
            return true;
        }
    }
}
export{StorageUtil,LocalStorageUtil,AuthUtil};
