<template>
  <div class="act-log-vote">
    <div class="list">
      <el-table :data="resultList">
        <el-table-column label="序号" width="80" align="center">
          <template v-slot="{$index}">{{ (queryParam.pageNo - 1) * queryParam.pageCount + $index + 1 }}</template>
        </el-table-column>
        <el-table-column label="微信">
          <template v-slot="{row}">
            <div class="wx">
              <div class="avatar">
                <img src="@/assets/wx.png" alt="">
              </div>
              <div class="name">{{ row.userWxNickName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="选手">
          <template v-slot="{row}">
            <div class="player">
              <div class="num">{{ row.playerNum }}号</div>
              <div class="name">{{ row.playerName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ip" label="IP"></el-table-column>
        <el-table-column prop="address" label="地区"></el-table-column>
        <el-table-column label="投票时间">
          <template v-slot="{row}">{{ row.createTime|convertTime }}</template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,prev, pager, next"
                   :current-page.sync="queryParam.pageNo"
                   :page-size="queryParam.pageCount"
                   :total="queryParam.totalCount"
                   @current-change="getList">
    </el-pagination>
  </div>
</template>

<script>
import {ajaxUtil} from "@/js/ajaxUtil";
import {api} from "@/js/api";
import {CommonUtil} from "@/js/commonUtil";
import {messages} from "@/js/messages";

export default {
  name: 'logVote',
  data() {
    return {
      resultList: [],
      queryParam: {
        activityId: '',
        playerId: '',
        pageNo: 1,
        pageCount: 8,
        totalCount: 0,
        totalPage: 0,
      },
    }
  },
  beforeMount() {
    let params = this.$route.query;
    if (params) {
      this.queryParam.activityId = params.activityId;
      this.queryParam.playerId = params.playerId;
    }
    this.getList();
  },
  methods: {
    getList() {
      let url = api.statistics.getVoteLogList + '?' + CommonUtil.getAllProperty(this.queryParam);
      ajaxUtil.ajaxJson(url, null, 'GET', null).then(res => {
        let {pageNo, resultList, totalCount, totalPage} = res
        this.queryParam.pageNo = pageNo
        this.queryParam.totalCount = totalCount
        this.queryParam.totalPage = totalPage
        this.resultList = resultList
      }).catch((err) => {
        this.resultList = []
        messages.showErrorMsg("系统繁忙，稍后重试");
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.act-log-vote {
  .list {
    margin: 20px 0;

    .wx {
      display: flex;
      align-items: center;

      .avatar {
        width: 50px;

        img {
          width: 100%;
          display: block;
        }
      }

      .name {
        padding: 5px;
      }
    }
  }
}
</style>

