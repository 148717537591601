import {StorageUtil} from '@/js/auth'
import {CommonUtil} from '@/js/commonUtil'
import {config} from '@/js/config'

const store = {
    state:{
        user:'',//用户信息
        currentRoute:{
            firstPath:'',//当前的一级页面菜单路径
            routeName:'',//当前页面的routeName
        },
        token:'',//token,
        sideBar:[],
        uuid: CommonUtil.generateUuid(), //全局uuid，进入即生成
        actListQuery: {status:'0',name:''},//活动列表页面的参数对象(header头部状态和条件栏页面和body活动列表页面共享该参数)
        currentSettingAct:{//活动详情设置的共享参数,存储活动id,及当前的二级菜单,三级菜单
            actId:0,//当前活动设置页的id
            secondMenu:'',//当前的活动设置二级导航菜单
            threeMenu:'',//当前的活动设置三级导航菜单
            threeMenuList:null,//当前的活动设置三级导航菜单集合
            refreshH5Time:0,//刷新H5页面的时间戳,在开启预览的情况下,变更该时间则自动刷新H5页面
            h5Url:'',
            currentPage:false
        },
        isLoginPage: false,// 是否在登录页面
    },
    setLoginPage(bol){
       this.state.isLoginPage = bol;
       storeState('isLoginPage',this.state.isLoginPage);
    },
     //活动管理： 二级菜单active
    setActListQuery(val){
        if(val){
            if(val.status==null||val.status==undefined){
                val.status='0';
            }
            if(val.name==null||val.name==undefined){
                val.name='';
            }
            this.state.actListQuery.status = val.status;
            this.state.actListQuery.name = val.name;
            storeState('actListQuery',this.state.actListQuery);
        }
    },
    //活动设置： 二级菜单active, targetMenu为config.actSetMenu的name值(即目标设置页的路由name),可直接传某个子级设置页的路由菜单
    setCurrentSettingAct(targetMenu, actId){
        let bol=false;
        for (let i = 0; i < config.actSetMenu.length; i++) {
            let secondMenuObj=config.actSetMenu[i];
            if(secondMenuObj.name==targetMenu){
                this.state.currentSettingAct.secondMenu=secondMenuObj.name;
                this.state.currentSettingAct.threeMenu=secondMenuObj.sonMenus&&secondMenuObj.sonMenus.length>0?secondMenuObj.name:'';
                this.state.currentSettingAct.threeMenuList=secondMenuObj.sonMenus;
                bol=true;
            }else if(secondMenuObj.sonMenus&&secondMenuObj.sonMenus.length>0){
                for (let j = 0; j < secondMenuObj.sonMenus.length; j++) {
                    let threeMenuObj=secondMenuObj.sonMenus[j];
                    if(threeMenuObj.name==targetMenu){
                        this.state.currentSettingAct.secondMenu=secondMenuObj.name;
                        this.state.currentSettingAct.threeMenu=threeMenuObj.name;
                        this.state.currentSettingAct.threeMenuList=secondMenuObj.sonMenus;
                        bol=true;
                        break;
                    }
                }
            }
            if(bol){
                break;
            }
        }
        if(actId!=null&&actId!=undefined){
            this.state.currentSettingAct.actId=actId;
            bol=true;
        }
        this.state.currentSettingAct.currentPage=this.state.currentSettingAct.actId>0;
        if(bol){
            storeState('currentSettingAct',this.state.currentSettingAct);
        }
    },
    updateCurrentSettingAct(currentPage){
        this.state.currentSettingAct.currentPage=currentPage;
        storeState('currentSettingAct',this.state.currentSettingAct);
    },
    //设置当前展示页面的路径名称
    setCurrentRoute(firstPath,routeName){
       if(firstPath){
           this.state.currentRoute.firstPath = firstPath;
       }
       if(routeName){
           this.state.currentRoute.routeName = routeName;
       }
       storeState('currentRoute',this.state.currentRoute);
    },
    //设置token的值
    setToken(val){
        this.state.token = val;
        storeState('token',this.state.token);
    },
    //清除token的值
    removeToken(){
        this.state.token = '';
        storeState('token',this.state.token);
    },
    //由于刷新页面数据会丢失，因此需要重新赋值一遍
    setState(){
        let data = StorageUtil.getObject('state');
        for(let dataKey in data){
            this.state[dataKey] = data[dataKey]
        }
    },
    setUser(val){
        this.state.user = val;
        storeState('user',this.state.user);
    },
    removeUser(){
        this.state.user = '';
        storeState('user',this.state.user);
    }
}
//由于刷新页面store里面的值会丢失，因此在
//浏览器存储一份，防止state里面数据丢失
function storeState(name,data){
    let store ={};
    if(StorageUtil.getObject('state')){
      store = StorageUtil.getObject('state');
    }
    store[name] = data;
    StorageUtil.storeObject('state',store)
}
export default store
