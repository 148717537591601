<template>
  <div class="act-content">
    <div class='content-body'>
      <tiny-editor :width="500" :height="650" ref="tinymce" v-model="activity.introduce"></tiny-editor>
      <edit-style-box :width="400" :height=650 :richTextType=2 @chooseRichTextModel="chooseRichTextModel"></edit-style-box>
    </div>
    <el-button type="primary" style='margin-top: 20px' @click="change">保存</el-button>
  </div>
</template>

<script>


import TinyEditor from "@/components/tinymce.vue"
import editStyleBox from '@/components/editStylebox.vue'
import {ActivityUtil, CommonUtil} from '@/js/commonUtil'
import {messages} from '@/js/messages'

export default {
  name: 'contentSet',
  data() {
    return {
      activity: {
        id: 0,
        introduce: null
      }
    }
  },
  components: {
    TinyEditor,
    editStyleBox,
  },
  beforeMount() {
    this.getActivity();
  },
  methods: {
    getActivity() {
      ActivityUtil.get(ActivityUtil.getCurrentSettingActId(), data => {
        CommonUtil.copyProperty(data, this.activity);
      })
    },
    change() {
      if (this.activity.introduce == null || this.activity.introduce == '') {
        messages.showErrorMsg('活动介绍不能为空');
      } else {
        ActivityUtil.update(this.activity, ['introduce'], null, () => {
          ActivityUtil.refreshH5();
        });
      }
    },
    chooseRichTextModel(richText) {
      this.$nextTick(() => {
        this.activity.introduce = (this.activity.introduce ? this.activity.introduce : '') + richText;
        this.$refs["tinymce"].resetValue(this.activity.introduce);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.act-content {
  .content-body {
    display: flex;
  }
}
</style>
