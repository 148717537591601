<template>
  <div class="help-item" @click="showInfo">
    <div class="helpTitle">
      <p>{{itemData.title}}</p>
    </div>
    <div class="bottomData">
      <p class="time"><img src="../assets/time.png">{{itemData.createTime |convertDate}}</p>
      <p class="num"><img src="../assets/num.e02778d6.png">{{itemData.readTimes}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'helpItem',
  props: ['itemData'],
  methods: {
    showInfo(){
      this.$router.push({ path: '/helpInfo', query:{id:this.itemData.id} })
    }
  }
}
</script>
<style lang="scss" scoped>
.help-item{
  width: 275px;
  height: 115px;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-top: 25px;
  margin-right: 50px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  transition: .5s;
  p {
    display: block;
    margin-block-start: 10px;
    margin-block-end: 10px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .helpTitle{
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #e5e5e5;
    color: #333333;
    font-size: 16px;
  }
    .bottomData {
      display: flex;
      height: 32px;
      align-items: center;
      .time {
        width: 60%;
        img {
          width: 12px;
          top: 0.5px;
          position: relative;
          padding-right: 6px;
        }
      }
      .num {
        width: 40%;
        text-align: right;
        img {
          width: 15px;
          top: 2px;
          position: relative;
          padding-right: 6px;
        }
      }
    }
}
</style>
