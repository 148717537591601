<template>
  <div class="app-wrapper">
    <appHeader></appHeader>
    <div class='app-main'>
      <div class='router-render scrollStyle' id='routerRender'>
        <router-view></router-view>
      </div>
    </div>
    <div class='contact-box'>
      <el-popover
          placement="left"
          trigger="hover">
        <div class='contact-code-wrapper'>
          <img :src="customerService.weixinCode" alt="" class='contact-code' style='width: 150px;height:150px;'>
          <p>客服</p>
          <p>7*24小时</p>
        </div>
        <el-button slot="reference" class='contact-code-btn'>
          <span class='contactCodeBtn'></span>
        </el-button>
      </el-popover>
      <el-popover
          placement="left"
          trigger="hover"
      >
        <div class='contact-tell-box'>
          <p>客服电话</p>
          <p>客服：<span>{{ customerService.phone }}</span></p>
        </div>
        <el-button slot="reference" class='contact-code-btn'>
          <span class='contactTellBtn'></span>
        </el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>
import appHeader from '@/components/Header.vue'
import {config} from '@/js/config'
import store from '@/store/store'
import {ajaxUtil} from "@/js/ajaxUtil";
import {api} from "@/js/api";
import {messages} from "@/js/messages";
import list from "@/views/user/activity/list/list.vue";

export default {
  name: 'Home',
  components: {
    appHeader
  },
  data() {
    return {
      customerService: config.customerService,
    }
  },
  created() {
    // let data = {
    //   richTextType: 1,
    //   list: this.list
    // }
    // this.adddata(data)
  },
  methods: {
    adddata(data) {
      ajaxUtil.ajaxJson('', data, 'POST').then((data) => {
        console.log(data)
      }, (err) => {
        messages.showErrorMsg(messages.getMsgByCode(err.data.errorCode));
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.app-wrapper {
  width: 100%;
  height: 100%;
}

.app-main {
  height: 100%;
  width: 100%;
  padding-top: 80px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}

.router-render {
  width: 100%;
  height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.contact-box {
  width: 46px;
  height: 120px;
  position: fixed;
  right: 0;
  top: 49%;

  ::v-deep button {
    width: 50px;
    height: 50px;
    border: none;
    box-shadow: 0 0 5px #ccc;
    margin-top: 10px;
    padding: 0;
  }

  .contact-code-img {
    width: 40px;
    height: 40px;
  }
}

.contact-code-wrapper {
  p {
    font-size: 14px;
    text-align: center;
    line-height: 24px;

    &:nth-of-type(1) {
      color: #3a8ee6;
    }
  }
}

.contact-code-btn {
  .contactCodeBtn {
    width: 40px;
    height: 40px;
    background: url("../../assets/codeContact.png") no-repeat center center;
    background-size: 100% 100%;
    display: block;
    margin-left: 4px;
  }

  &:hover {
    .contactCodeBtn {
      width: 40px;
      height: 40px;
      display: block;
      margin-left: 4px;
      background: url("../../assets/codeContact-active.png") no-repeat center center;
      background-size: 100% 100%;
    }
  }

  .contactTellBtn {
    width: 40px;
    height: 40px;
    background: url("../../assets/contact-tell.png") no-repeat center center;
    background-size: 100% 100%;
    display: block;
    margin-left: 4px;
  }

  &:hover {
    .contactTellBtn {
      width: 40px;
      height: 40px;
      display: block;
      margin-left: 4px;
      background: url("../../assets/contact-tell-active.png") no-repeat center center;
      background-size: 100% 100%;
    }
  }
}

.contact-tell-box {
  font-size: 14px;
  line-height: 32px;

  span {
    color: #3a8ee6;
  }
}
</style>
