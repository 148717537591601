<template>
  <div class="act-rank">
    <div class='header'>
      <el-select style="width: 180px;margin-right: 10px;" size='small' v-show="playerGroups.length>1"
                 v-model="currentGroupId" placeholder="请选择分组"
                 @change="research">
        <el-option
            v-for="item in playerGroups"
            :key="item.value"
            :label="item.des"
            :value="item.value">
        </el-option>
      </el-select>
    </div>

    <div class='content'>
      <el-table :data="page.resultList">
        <el-table-column label="排行" width="80" align="center">
          <template v-slot="{row}">
            <div class="index" v-if="row.rank>3">
              {{ row.rank }}
            </div>
            <div class="index" v-else>
              <img :src="topFilter(row.rank)" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="选手">
          <template v-slot="{row}">
            <div class="player">
              <div class="avatar">
                <el-avatar v-if="row.imageUrl" size="medium" :src="row.imageUrl|appendImgUrl"></el-avatar>
                <el-avatar v-else size="medium" icon="el-icon-user-solid"></el-avatar>
              </div>
              <div class="info">
                <div class="base">
                  <div class="num">{{ row.num }}号</div>
                  <div class="name">{{ row.name }}</div>
                </div>
                <div class="blurb">{{ row.intro }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="playerGroups.length>2" align="center" prop="groupName"
                         label="选手分组"></el-table-column>
        <el-table-column align="center" prop="voteNum" width="150" label="票数"></el-table-column>
        <el-table-column align="center" prop="viewNum" width='150' label="浏览量"></el-table-column>
      </el-table>
    </div>
    <div class='pagination-box'>
      <el-pagination background layout="total,prev, pager, next"
                     :current-page.sync="pageNo" :page-size="page.pageCount" :total="page.totalCount"
                     @current-change="getRanking">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import {ajaxUtil} from "@/js/ajaxUtil";
import {api} from "@/js/api";
import {ActivityUtil, CommonUtil} from "@/js/commonUtil";

export default {
  name: 'votesRank',
  data() {
    return {
      playerGroups: [],
      currentGroupId: 0,
      pageNo: 1,
      page: {
        pageNo: 1,
        pageCount: 10,
        totalCount: 0,
        totalPage: 0,
        resultList: []
      }
    }
  },
  beforeMount() {
    this.getPlayerGroups();
    this.research();
  },
  methods: {
    topFilter(rank) {
      switch (rank) {
        case 1:
          return require('@/assets/user/activity/as-top1.png');
        case 2:
          return require('@/assets/user/activity/as-top2.png');
        case 3:
          return require('@/assets/user/activity/as-top3.png');
      }
    },
    getPlayerGroups() {
      let url = api.player.groupSimpleList.replace('{activityId}', ActivityUtil.getCurrentSettingActId());
      ajaxUtil.ajaxPage(url, null, 'GET', null, data => {
        if (data.length > 0) {
          this.playerGroups = [{value: 0, des: '全部'}];
          this.playerGroups.push(...data);
        }
      })
    },
    research() {
      this.pageNo = 1;
      this.getRanking();
    },
    getRanking() {
      let url = api.player.ranking.replace('{activityId}', ActivityUtil.getCurrentSettingActId());
      if (this.currentGroupId) {
        url += '&groupId=' + this.currentGroupId;
      }
      url += '&pageNo=' + this.pageNo + '&pageCount=' + this.page.pageCount;
      ajaxUtil.ajaxPage(url, null, 'GET', null, data => {
        const {pageNo, pageCount} = data;
        data.resultList.forEach(function (item, index) {
          item.rank = (pageNo - 1) * pageCount + index + 1
        })
        CommonUtil.copyProperty(data, this.page);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.act-rank {
  .header {

  }

  .content {
    margin: 20px 0;

    .index {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 20px;
        display: block;
      }
    }

    .player {
      display: flex;
      justify-items: center;

      .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .info {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
        color: #a1a1a1;

        .base {
          display: flex;

          .num {
            width: 60px;
          }

          .name {
            color: #000;
          }
        }
      }
    }
  }

  .pagination-box {
    display: flex;
    justify-content: right;
  }
}
</style>
