<template>
  <div class="postStyle-wrapper-three" id='poster3'>
    <div class='post-inner'>
      <div class='act-detail'>
         <h2 class='act-name'>{{activity.name}}</h2>
          <p class='act-time'> 活动开始: {{activity.startTime | convertTime}}</p>
          <p class='act-time'> 活动结束: {{activity.endTime | convertTime}}</p>
      </div>
      <div class='code-wrapper fr'>
        <img :src="getQrCode()" alt="">
        <p>扫码参与活动</p>
      </div>
    </div>
  </div>
</template>

<script>
    import {QrCode} from '@/js/qrcodeUtil'
export default {
  name: 'posterThree',
  data(){
    return {
     
    }
  },
    props:['activity','h5Url'],
  methods:{
      getQrCode() {
          return QrCode.url(this.h5Url, 92);
      },
  }
}
</script>
<style lang="scss" scoped>
.postStyle-wrapper-three{
  width: 100%;
  text-align: center;
  background:url('#{$ossHost}files/images/userwebfront/posterbg4.png') no-repeat center center;
  background-size: 100% 100%;
  padding-bottom: 15px;
  height: 667px;
  position:relative;
  .post-inner{
     position:absolute;
     width: calc( 100% - 20px);
     height: 167px;
     bottom: 10px;
     left: 10px;
     right: 10px;
     background: #fff;
     
     border-radius: 5px;
     overflow: hidden;
     box-sizing: border-box;
     padding: 15px 20px 13px 15px; 
  }
  
  .act-detail{
    overflow: hidden;
    box-sizing: border-box;
    float:left;
    .act-name{
      font-size: 14px;
      font-weight: normal;
      text-align: left;
      height: 100px;
      width: 200px;
      line-height: 22px;
      color:#333;
    }
    .act-time{
      font-size: 13px;
      line-height: 20px;
       text-align: left;
       color:#999;
    }
  }
  .code-wrapper{
    float:right;
    margin-top: 14px;
    img{
      width: 92px;
      height:92px;
      border-radius: 7px;
    }
    p{
      margin-top: 5px;
      font-size: 13px;
      color:#333;
    }
    } 
}
</style>
