var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-list"},[_vm._l((_vm.images),function(item,index){return _c('div',{key:index,staticClass:"image-item",style:({
      height: _vm.height+'px',
      width: _vm.width+'px',
    }),on:{"mouseenter":function($event){return _vm.showChangeImg(index)},"mouseleave":function($event){return _vm.showChangeImg(-1)}}},[(item)?_c('div',{staticClass:"img"},[(item.startsWith('@/'))?_c('img',{style:(_vm.ratioScaling?{}:{width:'100%',height:'100%'}),attrs:{"src":require('@/'+item.substring(2))}}):_c('img',{style:(_vm.ratioScaling?{}:{width:'100%',height:'100%'}),attrs:{"src":_vm._f("appendImgUrl")(item)}})]):(_vm.showChangeImgIndex!=index)?_c('div',{staticClass:"img-active noneBackground"},[_c('div',{staticClass:"change",on:{"click":function($event){return _vm.changeImg(index)}}},[(_vm.noneDes)?_c('p',[_vm._v(_vm._s(_vm.noneDes))]):_vm._e()])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChangeImgIndex==index),expression:"showChangeImgIndex==index"}],staticClass:"img-active shadow"},[(_vm.del)?_c('div',{staticClass:"icon",on:{"click":function($event){return _vm.delImg(index)}}},[_c('i',{staticClass:"el-icon-delete"})]):_vm._e(),_c('div',{staticClass:"change",on:{"click":function($event){return _vm.changeImg(index)}}},[(_vm.shadowDes)?_c('p',[_vm._v(_vm._s(_vm.shadowDes))]):_vm._e()])])])}),(!_vm.images||_vm.maxImageNum>_vm.images.length)?_c('div',{staticClass:"plus-img",style:({
      height: (_vm.height-2*_vm.plusBorder)+'px',
      width: (_vm.width-2*_vm.plusBorder)+'px',
      textAlign: 'center',
      border: _vm.plusBorder+'px dashed #e8e5e5',
      backgroundColor: _vm.plusColor,
      cursor: 'pointer'
    }),on:{"click":_vm.addImg}},[_c('i',{staticClass:"el-icon-plus avatar-uploader-icon",style:({
      fontSize: _vm.plusSize+'px',
      color: '#8c939d',
      lineHeight: _vm.height+'px'
    })})]):_vm._e(),(_vm.btnName1)?_c('p',{staticClass:"fl checkBtn",style:({color: _vm.btnColor1}),on:{"click":_vm.clickBtn1}},[_vm._v(_vm._s(_vm.btnName1))]):_vm._e(),(_vm.btnName2)?_c('p',{staticClass:"fl checkBtn",style:({color: _vm.btnColor2}),on:{"click":_vm.clickBtn2}},[_vm._v(_vm._s(_vm.btnName2))]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }