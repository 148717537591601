<template>
  <div v-if="dialogVisible" class="dynamic-dialog">
     <!--新增动态弹出框-->
     <el-dialog :title="news.id>0?'编辑动态':'新增动态'" :visible.sync="dialogVisible"  width="800px">
        <el-form hide-required-asterisk ref="newsRef" label-position="left" label-width="120px" :model="news">
            <el-form-item label="动态标题" prop="title" :rules="[{required: true, message: '请输入动态标题', trigger: 'blur'}]">
                <el-input v-model.trim="news.title" maxlength="50" placeholder="请输入动态标题"></el-input>
            </el-form-item>
            <el-form-item label="动态封面">
                <div class="imgDev">
                    <el-upload ref="upload"
                            class="avatar-uploader"
                            action="#"
                            accept="image/png, image/jpeg"
                            :show-file-list="false" :limit="1"
                            :before-upload="beforeAvatarUpload"
                            :http-request="uploadFile">
                        <img v-if="news.imageUrl" :src="news.imageUrl|appendImgUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <p v-show="news.imageUrl" class='fl checkBtn' @click="clearImage">清除</p>
                </div>
            </el-form-item>
            <el-form-item label="动态内容">
                <el-checkbox v-model="news.isOutLink">外部链接</el-checkbox>
                <div v-if='news.isOutLink'>
                    <el-input type="textarea" :rows="4" placeholder="请输入内容" maxlength="254"
                              v-model.trim="news.contentUrl"></el-input>
                </div>
                <div class='edit-flex' v-else>
                    <Tinymce ref="tinymce" v-model="news.content"  :height=520 :width=380 />
                    <editStylebox :height=600 :richTextType=3 @chooseRichTextModel="chooseRichTextModel"></editStylebox>
                </div>
            </el-form-item>
        </el-form>
         <el-row slot="footer" class="dialog-foot">
             <el-button @click="dialogVisible=false">取 消</el-button>
             <el-button type="primary" @click="submit">确 定</el-button>
         </el-row>
    </el-dialog>
  </div>
</template>

<script>
import Tinymce from '@/components/tinymce'
import editStylebox from '@/components/editStylebox.vue'
import {OssFileUploadUtil} from '@/js/ossFileUploadUtil'
import Vue from 'vue';
import {messages} from "../js/messages";
export default {
  name: 'adDynamic',//新增动态或编辑动态的弹出窗
  components:{Tinymce,editStylebox},
  props:{
      visible: {
          type: Boolean,
          default: false
      },
      news:{
          id:0,
          title:null,
          imageUrl:null,
          content:null,
          contentUrl:null,
          isOutLink:false
      }
  },
  computed: {
      dialogVisible: {
          get () {
              return this.visible
          },
          set (val) {
              this.$emit('update:visible', val)
          }
      }
  },
  methods:{
      submit(){
          this.$refs["newsRef"].validate(valid => {
              if (valid) {
                  if(this.news.isOutLink){
                      this.news.content=null;
                  }else {
                      this.news.contentUrl=null;
                  }
                  this.$emit('submit',this.news);
                  Vue.nextTick(()=>{
                      this.$emit('update:visible', false);
                  });
              }
          });
      },
      uploadFile(option){
          //上传文件
          OssFileUploadUtil.uploadImg(option.file, {
              progress: (p, checkpoint) => {
                  // checkpoint参数用于记录上传进度，断点续传上传时将记录的checkpoint参数传入即可。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
                  console.log(p)
              },
              parallel: 4,
              // 设置分片大小。默认值为1 MB，最小值为100 KB。
              partSize: 1024 * 1024,
          }, (res) => {//成功回调
              this.news.imageUrl=res.name;
              messages.showSuccessMsg(messages.success.upload);
          }, (err) => {//失败回调
              //错误提示
              messages.showErrorMsg(messages.error.upload);
          });
      },
      chooseRichTextModel(richText){
          this.$nextTick(()=>{
              this.news.content=(this.news.content?this.news.content:'')+richText;
              this.$refs["tinymce"].resetValue(this.news.content);
          })
      },
      beforeAvatarUpload(file) {

      },
      clearImage(){
        this.news.imageUrl=null;
        this.$refs["upload"].clearFiles();
      }
  }
}
</script>
<style lang="scss" scoped>
.checkBtn{
    cursor: pointer;
    margin-left: 20px;
    color: #409EFF;
}
.imgDev{
    height: 140px;
    display: flex;
    flexWrap: wrap;
}
.dialog-foot{
    text-align: center;
    .button{
        width:98px;
        margin-right: 20px;
        margin-left: 20px;
    }
}
.avatar-uploader {
    width:178px;
    border: 1px solid #d9d9d9 !important;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    height: 140px
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height:140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 140px;
    display: block;
  }
</style>
