<template>
     <router-view></router-view>
</template>

<script>
export default {

  name: 'act',
  data(){
    return {

    }
  },

  methods:{}
}
</script>
<style lang="scss" scoped>

</style>
