<template>
  <div class="content-wrapper">
    <div class="topic-header" v-if="pageVisible">
      <el-button icon="el-icon-plus" size='small' type="primary" @click="clickCreateTopic">添加专题</el-button>
    </div>
    <div class='special-item-box' v-for="item in topicPage.resultList" :key='item.id'>
      <div class='special-item-head clear'>
        <p class='title fl cursorPointer' @click="handleEditTopic(item)">{{ item.title }}</p>
        <p class='time fl'>创建时间：{{ item.createTime | convertTime }}</p>
        <el-dropdown class='fr cursorPointer' trigger="click">
          <span class="el-dropdown-link">
            设置<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleEditTopic(item)">编辑专题</el-dropdown-item>
            <el-dropdown-item @click.native="handlePublish(item)">发布专题</el-dropdown-item>
            <el-dropdown-item @click.native="clickDeleteTopic(item)">删除专题</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" class='fr' size='small' @click='handleAddActivity(item)'>添加活动</el-button>
      </div>
      <div class='special-act-list'>
        <el-table :data="item.activities" style="width: 100%">
          <el-table-column label="活动名称">
            <template slot-scope="scope">
              <div class='act-info'>
                <el-row class="row-name cursorPointer" @click.native="toSettingAct(scope.row.activity.id)">
                  <el-col><span>{{ scope.row.activity.name }}</span></el-col>
                </el-row>
                <el-row class="row-time">
                  <el-col><span>创建时间：{{ scope.row.activity.createTime | convertTime }}</span></el-col>
                </el-row>
                <el-row class="row-time">
                  <el-col><span>投票时间：{{ scope.row.activity.startTime |convertTime }} - {{
                      scope.row.activity.endTime |convertTime
                    }}</span>
                  </el-col>
                </el-row>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="活动状态" width="140" align="center">
            <template slot-scope="scope">
              <span
                  v-bind:class="scope.row.activity.status |convertActStatusColorClass(scope.row.activity.startTime,scope.row.activity.endTime)">
                {{
                  scope.row.activity.status |convertActStatus(scope.row.activity.startTime,scope.row.activity.endTime)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="显示" width="140" align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.showActivity" @change="switchActShow(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="总票数" width="80" align="center" prop="activity.voteNum">
          </el-table-column>
          <el-table-column prop="activity.playerNum" label="选手数" width="80" align="center"></el-table-column>
          <el-table-column label="访问量" width="80" align="center" prop="activity.viewNum">
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button @click="removeAct(scope.row)" type="text" size="small">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--分页-->
    <div class='pagination-wrapper' v-if="pageVisible">
      <el-pagination
          background
          :current-page.sync="pageNo"
          :page-size="pageCount"
          layout="total,prev, pager, next"
          :total="topicPage.totalCount"
          @current-change="pageChange">
      </el-pagination>
    </div>

    <!--无数据时 -->
    <div class='no-data'>
      <el-empty :image-size="200" description="暂无专题活动" v-if="nodataVisible">
        <el-button icon="el-icon-plus" type="primary" @click="clickCreateTopic">创建专题活动</el-button>
      </el-empty>
    </div>
    <!--添加活动弹出框-->
    <el-dialog title="选择需要加入本专题的活动" :visible.sync="addActivityDialogVisible" width='700px'>
      <div class='activity-dialog-box'>
        <el-tabs v-model="actQueryStatus" @tab-click="chooseActivity">
          <el-tab-pane v-bind:label="item.title" v-bind:name="item.value" v-for="(item) in activityStatusList"
                       v-bind:key=item.value></el-tab-pane>
        </el-tabs>
        <el-table
            ref="multipleTable"
            :data="useableActivities"
            tooltip-effect="dark"
            :show-header="false"
            empty-text="暂无可添加的活动"
            max-height="360"
            style="width: 100%; height: 360px"
            @selection-change="handleSelectActivities">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column width="450">
            <template slot-scope="props">
              <el-row class="row-name">
                <el-col><span>{{ props.row.name }}</span></el-col>
              </el-row>
              <el-row class="row-time">
                <el-col>
                  <span>投票时间：{{ props.row.startTime|convertTime }} - {{ props.row.endTime|convertTime }}</span>
                </el-col>
              </el-row>
              <el-row class="row-time">
                <el-col><span>创建时间：{{ props.row.createTime|convertTime }}</span></el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column
              label="状态" width="120" align="right">
            <template slot-scope="scope">
              <span v-bind:class="scope.row.status |convertActStatusColorClass(scope.row.startTime,scope.row.endTime)">
                {{ scope.row.status | convertActStatus(scope.row.startTime,scope.row.endTime) }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer table-footer">
          <el-button type="primary" style="margin-top: 20px" @click="addActivityTopic">添   加</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 发布活动弹窗-->
    <publishDialog v-if="operateTopic" :visible.sync="publishDialogVisible" :title="operateTopic.title"
                   :url="operateTopic.h5Url" publishObj="专题"></publishDialog>
    <!--删除专题弹窗 -->
    <deleteDialog v-if="operateTopic" :visible.sync="deleteDialogVisible" :delItem="operateTopic" title="删除专题"
                  msg="该操作会移除活动并删除专题" :content="operateTopic.title" @del="delTopic"></deleteDialog>
  </div>
</template>

<script>
import publishDialog from '../../../components/publishDialog.vue'
import deleteDialog from '../../../components/deleteDialog.vue'
import {ActivityUtil} from '@/js/commonUtil'
import {ajaxUtil} from '@/js/ajaxUtil.js'
import {api} from '@/js/api.js'
import store from '@/store/store.js'
import {messages} from '@/js/messages'
import {StorageUtil} from "@/js/auth";

export default {
  name: 'topic',
  components: {
    publishDialog,
    deleteDialog
  },
  data() {
    return {
      activityStatus: 'first',//弹框nav
      addActivityDialogVisible: false,
      publishDialogVisible: false,
      deleteDialogVisible: false,
      operateTopic: null,//操作的专题对象
      nodataVisible: false,
      pageVisible: false,
      activityStatusList: [{title: '全部', value: '0'}, {title: '未开始', value: '1'}, {title: '进行中', value: '2'},
        {title: '已结束', value: '3'}, {title: '已关闭', value: '4'}],

      pageNo: 1, //当前页码
      pageCount: 3, //每页显示的专题数量，固定为3
      useableActivities: [], //可选活动列表
      selectedActivityIds: [],
      actQueryStatus: '0',
      actQueryTopicId: 0,
      topicPage: {
        pageNo: 1,
        pageCount: 3,
        totalCount: 9,
        totalPage: 3,
        resultList: []
      },
      topicListQueryParams: null
    }
  },
  beforeMount() {
    //初始化数据
    let lastTimeQueryParams = StorageUtil.getObject('topicListQueryParams');
    StorageUtil.removeObject('topicListQueryParams');
    if (lastTimeQueryParams && lastTimeQueryParams.query) {
      this.pageNo = lastTimeQueryParams.pageNo;
      this.pageCount = lastTimeQueryParams.pageCount;
      this.topicPage.totalCount = lastTimeQueryParams.totalCount;
    } else {
      this.pageNo = 1;
    }
    this.queryTopics();

  },
  methods: {
    toSettingAct(actId) {
      if (this.topicListQueryParams) {
        StorageUtil.storeObject('topicListQueryParams', this.topicListQueryParams)
      }
      ActivityUtil.toSettingAct('BasicSet', actId);
    },
    queryTopics() {
      let url = api.topic.query.replace('{userId}', store.state.user.id).replace('{pageNo}', this.pageNo).replace('{pageCount}', this.pageCount);
      ajaxUtil.ajaxPage(url, null, 'GET', null, data => {
        this.pageNo = this.pageNo > data.totalPage ? data.pageNo : this.pageNo;
        this.topicPage = data;
        if (data.totalCount == 0) {
          this.nodataVisible = true;
          this.pageVisible = false;
        } else {
          this.nodataVisible = false;
          this.pageVisible = true;
        }
        this.topicListQueryParams = {
          pageNo: this.topicPage.pageNo,
          pageCount: this.topicPage.pageCount,
          totalCount: this.topicPage.totalCount
        }
      });
    },

    pageChange(pageNo) {
      this.pageNo = pageNo;
      this.queryTopics();
    },

    clickCreateTopic() {
      let addData = {createBy: store.state.user.id}
      ajaxUtil.ajaxJson(api.topic.add, addData, 'POST').then((data) => {
        //若成功，则跳转专题编辑页面
        messages.showSuccessMsg(messages.success.topicAdd);
        this.$router.push({path: '/topicedit', query: {id: data.id}})
      }, (err) => {
        messages.showErrorMsg(messages.getMsgByCode(err.data.errorCode));
      })
    },

    //更改活动在专题下的显示状态
    switchActShow(row) {
      let requestData = {topicId: row.topicId, activityId: row.activityId, showActivity: row.showActivity};
      ajaxUtil.ajaxJson(api.topic.updateActivityShowStatus, requestData, 'PUT').then(data => {
        messages.showSuccessMsg(messages.success.update)
      })
    },

    //移除专题中的活动
    removeAct(row) {
      let requestData = {topicId: row.topicId, activityIdList: [row.activityId]};
      ajaxUtil.ajaxJson(api.topic.removeActivities, requestData, 'PUT').then(data => {
        //成功刷新页面并提示
        this.queryTopics();
        messages.showSuccessMsg(messages.success.update);
      })
    },

    /**
     * 对某专题点击编辑,跳转编辑页面
     * @param topic
     */
    handleEditTopic(topic) {
      if (this.topicListQueryParams) {
        StorageUtil.storeObject('topicListQueryParams', this.topicListQueryParams)
      }
      this.$router.push({path: '/topicedit', query: {id: topic.id}})
    },

    /**
     * 对某专题点击添加活动,弹出添加活动的弹出窗口
     * @param topic
     */
    handleAddActivity(topic) {
      this.operateTopic = topic;
      this.addActivityDialogVisible = true;
      //加载活动数据
      this.actQueryStatus = '0';
      this.actQueryTopicId = topic.id;
      this.queryActivitiesForTopic();
    },

    /**
     * 对某专题点击发布,弹出发布专题的弹出窗口
     * @param topic
     */
    handlePublish(topic) {
      this.operateTopic = topic;
      this.publishDialogVisible = true;
    },
    /**
     * 对某专题点击删除,弹出添加删除专题的弹出窗口
     * @param topic
     */
    clickDeleteTopic(topic) {
      this.operateTopic = topic;
      this.deleteDialogVisible = true;
    },
    delTopic(topic) {
      this.deleteDialogVisible = false;
      ajaxUtil.ajaxJson(api.topic.deleteTopic.replace('{id}', topic.id), null, 'DELETE').then(data => {
        messages.showSuccessMsg(messages.success.delete);
        this.queryTopics();
      }, err => {
        messages.showErrorMsg(messages.error.delete);
      })
    },

    queryActivitiesForTopic() {
      let url = api.activity.queryForTopic.replace('{topicId}', this.actQueryTopicId).replace('{status}', this.actQueryStatus);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        this.useableActivities = data;
      }, err => {
        this.useableActivities = [];
      })
    },

    //选择活动列表中checkbox变化时
    handleSelectActivities(val) {
      this.selectedActivityIds = [];
      if (val.length != 0) {
        for (var i in val) {
          this.selectedActivityIds[i] = val[i].id;
        }
      }
    },

    //添加活动关联
    addActivityTopic() {
      if (!this.selectedActivityIds || this.selectedActivityIds.length <= 0) {
        messages.showErrorMsg('请选择要添加的活动');
        return false;
      }
      let requestData = {topicId: this.actQueryTopicId, activityIdList: this.selectedActivityIds};
      ajaxUtil.ajaxJson(api.topic.addActivities, requestData, 'PUT').then(data => {
        //添加成功后提示，刷新专题列表，隐藏活动选择弹出框
        messages.showSuccessMsg(messages.success.operation);
        this.queryTopics();
        this.addActivityDialogVisible = false;
      }, err => {
        messages.showErrorMsg(messages.error.operation);
      })
    },

    //点击活动筛选条件时
    chooseActivity(tab) {
      this.actQueryStatus = tab.name;
      this.queryActivitiesForTopic();
    }
  }
}
</script>
<style lang="scss" scoped>

.content-wrapper {
  padding: 30px;

  .topic-header {
    padding-bottom: 24px;
    box-sizing: border-box;
  }
}

.title {
  font-weight: bold;
  margin-right: 20px;
  color: #666;
}

.special-item-box {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  box-shadow: 0 1px 4px rgba(6, 6, 6, 0.1);

  ::v-deep .el-dropdown {
    width: 100px;
    text-align: center;
    margin-top: 5px;
  }
}

.special-act-list {
  margin-top: 20px;
}

.activity-list-box {
  width: 660px;
  height: 400px;
  overflow: auto;
  margin-top: 10px;

  li {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    width: 100%;
    margin-bottom: 15px;

    .act-info {
      padding-left: 20px;
      flex: 1;
    }

    .act-status {
      width: 100px;
      text-align: center;
    }
  }
}

.row-name {
  margin-bottom: 15px;
}

.row-time {
  color: #999999;
}

.no-data {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 50px;
}

.table-footer {
  margin-left: 45%;
  margin-right: 50%;
  text-align: center
}
</style>
