<template>
  <div class="ad-set">
    <el-form hide-required-asterisk label-position="left" label-width="100px">
      <el-form-item label="开屏功能">
        <div>
          <el-switch v-model="activity.settingAd.hasStart" @change="saveAdEvent"></el-switch>
        </div>
        <template v-if="activity.settingAd.hasStart">
          <div>
            <el-radio-group v-model="activity.settingAd.startType" @change="saveAdEvent">
              <el-radio :label="1">开屏广告</el-radio>
              <el-radio :label="2">开屏动画</el-radio>
            </el-radio-group>
          </div>
          <div class="start-ad" v-if="activity.settingAd.startType===1">
            <div class="upload-img">
              <el-upload
                  action="#"
                  :show-file-list="false"
                  accept="image/png, image/jpeg"
                  :http-request="value =>uploadFile(value,'startAd')">
                <div class='upload-preview'>
                  <img :src="activity.settingAd.startAd | appendImgUrl" alt="">
                </div>
                <div class="upload-operate">
                  <p>点击上传</p>
                </div>
              </el-upload>
            </div>
            <div class="file-del" @click="delFile('startAd')">
              默认
            </div>
          </div>
          <div class="start-anime" v-if="activity.settingAd.startType===2">
            <div class="anime-img">
              <div class="upload-img">
                <el-upload
                    action="#"
                    :show-file-list="false"
                    accept="image/png, image/jpeg"
                    :http-request="value =>uploadFile(value,'startAnime')">
                  <div class='upload-preview'>
                    <img :src="activity.settingAd.startAnime | appendImgUrl" alt="">
                  </div>
                  <div class="upload-operate">
                    <p>点击上传</p>
                  </div>
                </el-upload>
              </div>
              <div class="file-del" @click="delFile('startAnime')">
                默认
              </div>
            </div>
            <div class="anime-btn">
              <div class="upload-img">
                <el-upload
                    action="#"
                    :show-file-list="false"
                    accept="image/png, image/jpeg"
                    :http-request="value =>uploadFile(value,'startAnimeBtn')">
                  <div class='upload-preview'>
                    <img :src="activity.settingAd.startAnimeBtn | appendImgUrl" alt="">
                  </div>
                  <div class="upload-operate">
                    <p>点击上传</p>
                  </div>
                </el-upload>
              </div>
              <div class="file-del" @click="delFile('startAnimeBtn')">
                默认
              </div>
            </div>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="弹窗广告">
        <div>
          <el-switch v-model="activity.settingAd.hasEject" @change="saveAdEvent"></el-switch>
        </div>
        <template v-if="activity.settingAd.hasEject">
          <div class="eject-ad">
            <div class="upload-img">
              <el-upload
                  action="#"
                  :show-file-list="false"
                  accept="image/png, image/jpeg"
                  :http-request="value =>uploadFile(value,'ejectAd')">
                <div class='upload-preview'>
                  <img :src="activity.settingAd.ejectAd | appendImgUrl" alt="">
                </div>
                <div class="upload-operate">
                  <p>点击上传</p>
                </div>
              </el-upload>
            </div>
            <div class="file-del" @click="delFile('ejectAd')">
              默认
            </div>
          </div>
          <el-input class="ad-input" placeholder="请输入广告跳转链接" v-model.trim="activity.settingAd.ejectLink"
                    maxlength="500" @change="saveAdEvent">
          </el-input>
        </template>
      </el-form-item>
      <el-form-item label="底部广告">
        <div>
          <el-switch v-model="activity.settingAd.hasBottom" @change="saveAdEvent"></el-switch>
        </div>
        <template v-if="activity.settingAd.hasBottom">
          <div class="bottom-ad">
            <div class="upload-img">
              <el-upload
                  action="#"
                  :show-file-list="false"
                  accept="image/png, image/jpeg"
                  :http-request="value =>uploadFile(value,'bottomAd')">
                <div class='upload-preview'>
                  <img :src="activity.settingAd.bottomAd | appendImgUrl" alt="">
                </div>
                <div class="upload-operate">
                  <p>点击上传</p>
                </div>
              </el-upload>
            </div>
            <div class="file-del" @click="delFile('bottomAd')">
              默认
            </div>
          </div>
          <el-input class="ad-input" placeholder="请输入广告跳转链接" v-model.trim="activity.settingAd.bottomLink"
                    maxlength="500" @change="saveAdEvent">
          </el-input>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {ActivityUtil, CommonUtil} from "@/js/commonUtil"
import {OssFileUploadUtil} from "@/js/ossFileUploadUtil";
import {messages} from "@/js/messages";
import {api} from "@/js/api";
import {ajaxUtil} from "@/js/ajaxUtil";

export default {
  name: 'adSet',
  data() {
    return {
      startAdDef: 'public/admin/ad/start_ad.png',
      startAnimeDef: 'public/admin/ad/anime_ad.png',
      startAnimeBtnDef: 'public/admin/ad/anime_btn.png',
      ejectAdDef: 'public/admin/ad/eject_ad.png',
      bottomAdDef: 'public/admin/ad/bottom_ad.png',
      activity: {
        id: 0,
        settingAd: {
          hasStart: false,
          startType: 1,
          startAd: '',
          startAnime: '',
          startAnimeBtn: '',
          hasEject: false,
          ejectAd: '',
          ejectLink: '',
          hasBottom: false,
          bottomAd: '',
          bottomLink: '',
        }
      }
    }
  },
  beforeMount() {
    this.getActivity();
  },
  methods: {
    getActivity() {
      ActivityUtil.get(ActivityUtil.getCurrentSettingActId(), data => {
        let {startAd, startAnime, startAnimeBtn, ejectAd, bottomAd} = data.settingAd
        if (!startAd) {
          data.settingAd.startAd = this.startAdDef
        }
        if (!startAnime) {
          data.settingAd.startAnime = this.startAnimeDef
        }
        if (!startAnimeBtn) {
          data.settingAd.startAnimeBtn = this.startAnimeBtnDef
        }
        if (!ejectAd) {
          data.settingAd.ejectAd = this.ejectAdDef
        }
        if (!bottomAd) {
          data.settingAd.bottomAd = this.bottomAdDef
        }
        CommonUtil.copyProperty(data, this.activity);
      })
    },

    delFile(key) {
      this.activity.settingAd[key] = this[key + 'Def']
      this.saveAdEvent()
    },
    uploadFile(option, key) {
      this.isProcess = true;
      //上传文件
      OssFileUploadUtil.uploadImg(option.file, {
        progress: (p, checkpoint) => {
          // checkpoint参数用于记录上传进度，断点续传上传时将记录的checkpoint参数传入即可。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
          this.percentage = p * 100;
        },
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
      }, (res) => {//成功回调
        this.isProcess = false;
        this.percentage = 0;
        this.activity.settingAd[key] = res.name;
        this.saveAdEvent()
      }, (err) => {//失败回调
        //错误提示
        this.isProcess = false;
        this.percentage = 0;
        messages.showErrorMsg(messages.error.upload);
      });
    },

    saveAdEvent() {
      let body = CommonUtil.copyObject(this.activity);
      ActivityUtil.update(body, ['settingAd'], null, () => {
        ActivityUtil.refreshH5();
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.ad-set {
  .upload-img {
    position: relative;
    overflow: hidden;
    border-radius: 5px;

    .upload-preview {
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .upload-operate {
      display: none;
    }
  }

  .file-del {
    box-sizing: border-box;
    margin-left: 10px;
    padding: 10px;
    cursor: pointer;
  }

  .file-del:hover {
    color: #409eff;
  }

  .upload-img:hover {
    .upload-operate {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ad-input {
    width: 600px;
    margin-top: 10px;
  }

  .start-ad {
    display: flex;
    align-items: center;

    .upload-img {
      width: 130px;
      height: 210px;

      .upload-preview {
        width: 130px;
        height: 210px;
      }
    }
  }

  .start-anime {

    .anime-img {
      display: flex;
      align-items: center;

      .upload-img {
        width: 130px;
        height: 210px;

        .upload-preview {
          width: 130px;
          height: 210px;
        }
      }
    }

    .anime-btn {
      display: flex;
      align-items: center;

      .upload-img {
        width: 130px;
        height: 40px;

        .upload-preview {
          width: 130px;
          height: 40px;
        }
      }
    }

  }

  .eject-ad {
    display: flex;
    align-items: center;

    .upload-img {
      width: 180px;
      height: 100px;

      .upload-preview {
        width: 180px;
        height: 100px;
      }
    }
  }

  .bottom-ad {
    display: flex;
    align-items: center;

    .upload-img {
      width: 180px;
      height: 100px;

      .upload-preview {
        width: 180px;
        height: 100px;
      }
    }
  }
}
</style>
