import {QrCode} from '@/js/qrcodeUtil.js';

let config = {
    //图片地址host
    // ossHost: 'https://img.ytsg.com/',
    ossHost: process.env.VUE_APP_OSS_URL,
    customerService: {//客服信息
        phone: '13391716260',//客服电话
        weixinCode: QrCode.url('4124325436', 150),//客服微信号生成的二维码(或者微信名片二维码)
    },

    //活动管理二级导航 name属性为活动状态查询的参数值
    actManageMenu: [
        {title: '全部', name: '0', id: 1},
        {title: '未开始', name: '1', id: 2},
        {title: '进行中', name: '2', id: 3},
        {title: '已结束', name: '3', id: 4},
        {title: '已关闭', name: '4', id: 5},
    ],

    //活动设置页面的导航 name属性表示路由页面的名称(这里是下级默认选中菜单)
    actSetMenu: [
        {
            title: '基本信息',
            name: 'BasicSet',
            line: false,
            id: 1,
            sonMenus: [
                {title: '基本设置', name: 'BasicSet', id: 1},
                {title: '活动介绍', name: 'ContentSet', id: 2},
            ]
        },
        {
            title: '页面设置',
            name: 'PageSet',
            line: false,
            id: 2,
            sonMenus: [
                {title: '页面设置', name: 'PageSet', id: 1},
                {title: '显示设置', name: 'DisplaySet', id: 2},
                {title: '底部设置', name: 'MenuSet', id: 3}
            ]
        },
        {
            title: '选手设置',
            name: 'PlayerSet',
            line: false,
            id: 3,
            sonMenus: [
                {title: '选手管理', name: 'PlayerSet', id: 1},
                {title: '选手分组', name: 'PlayerGroupSet', id: 2},
                {title: '选手报名', name: 'PlayerEntry', id: 3},
                // {title: '评论管理', name: 'PlayerComment', id: 4},
            ]
        },
        {
            title: '投票设置',
            name: 'VoteSet',
            line: true,
            id: 4,
            sonMenus: [
                {title: '投票设置', name: 'VoteSet', id: 1},
                {title: '防刷票设置', name: 'VoteSecureSet', id: 2},
            ]
        },
        {title: '报名设置', name: 'EntrySet', id: 7, line: false},
        // {
        //     title: '抽奖设置', name: 'RaffleSet', id: 8, line: false,
        //     sonMenus: [
        //         {title: '基础设置', name: 'RaffleSet', id: 1},
        //         {title: '中奖列表', name: 'RaffleList', id: 2},
        //     ]
        // },
        {
            title: '高级设置', name: 'AdSet', id: 6, line: true,
            sonMenus: [
                {title: '广告设置', name: 'AdSet', id: 1},
                {title: '分享设置', name: 'ShareSet', id: 2},
                {title: '活动动态', name: 'NewsSet', id: 3},
                {title: '现场大屏', name: 'ScreenSet', id: 4},
            ]
        },
        //新
        {
            title: '投票统计',
            name: 'Statistics',
            line: false,
            id: 7,
            sonMenus: [
                {title: '投票统计', name: 'Statistics', id: 1},
                {title: '投票日志', name: 'LogVote', id: 2},
                {title: '票数排行', name: 'Ranking', id: 3},
            ]
        },
    ],

}

export {config}
