<template>
  <el-dialog :visible.sync="dialogVisible" :width="width">
    <div class='publishMask'>
      <div class='left-code fl'>
        <p>微信扫码查看{{ publishObj }}</p>
        <div class='qrcode-wrapper'>
          <img :src="getQrCode()" alt="" class='code'>
          <div class='download' @click="toQrCode()">
            <p>自定义下载二维码</p>
          </div>
        </div>
        <div class='downtext'><i></i>
          <a download="code.png" :href="qrUrl" class='el-icon-download'>下载二维码</a></div>
      </div>
      <div class='right-msg fl'>
        <h2>本{{ publishObj }}地址</h2>
        <div class='address-box'>
          <el-input v-model="url" class='box-input'></el-input>
          <el-button type="primary" class='box-btn' v-on:click="copyUrl">复制链接</el-button>
        </div>
        <p class='desc'>请将本网址放到公众号自定义菜单或微信文章的阅读原文处发布{{ publishObj }}</p>
<!--        <router-link to='' class='whylink'>如何在公众号上发布{{ publishObj }}?</router-link>-->
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {QrCode} from "../js/qrcodeUtil";
import {LocalStorageUtil} from "../js/auth";
import {messages} from '../js/messages';

export default {
  name: 'topicAct',
  data() {
    return {
      qrUrl: "",
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '720px'
    },
    publishObj: {
      type: String,
      default: '活动'
    },
    url: {
      type: String,
      default: ''
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    copyUrl() {
        this.$copyText(this.url).then(function (e) {
            messages.showSuccessMsg(messages.success.copy)
        }, function (e) {
            messages.showErrorMsg(messages.error.copy)
        })
    },
    getQrCode() {
      this.qrUrl = QrCode.url(this.url, 300);
      return this.qrUrl;
    },
    toQrCode() {
      LocalStorageUtil.storeString("activityH5Url", this.url);
      this.$router.push({name: 'Qrcode'});
    }
  }
}
</script>
<style lang="scss" scoped>
.publishMask {
  overflow: hidden;

  .left-code {
    width: 180px;
    height: 250px;
    text-align: center;
    background: url('../assets/codeBg.png') no-repeat center center;
    background-size: 100% 100%;
    padding-top: 15px;
    .downtext {
      text-align: center;
      color: #409EFF;
      cursor: pointer;
      margin-top: 30px;
    }
  }
  .right-msg {
    margin-left: 40px;
    margin-top: 20px;

    h2 {
      font-size: 14px;
      font-weight: normal;
    }

    .address-box {
      position: relative;
      margin-top: 20px;
      height: 40px;
      width: 450px;

      .box-input {
        width: 350px;

        ::v-deep .el-input__inner {
          border-radius: 4px 0 0 4px !important;
        }
      }

      ::v-deep .el-button {
        border-radius: 0px 4px 4px 0px !important;
      }
    }

    .desc {
      margin-top: 10px;
      font-size: 12px;
      margin-bottom: 50px;
    }

    .whylink {
      color: $globalTheme;
    }
  }
}

.qrcode-wrapper {
  width: 130px;
  height: 130px;
  position: relative;
  background: #eee;
  display: inline-block;
  margin-top: 10px;
  .code {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
  }
  .download {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    display: none;
    p {
      font-size: 12px;
      color: #fff;
      line-height: 130px;
    }
  }
  &:hover{
    .download{
      display: block;
    }
  }
}

.el-icon-download {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
</style>
