<template>
  <div class="login-container">
    <el-form class="login-form" auto-complete="on" label-position="left">
      <div class="login-content">
          <div class="grid-content">
            <!--登录和注册-->
            <div v-show="loginShow">
                <div class="title">
                  <h2>Hello,欢迎登录元点评选</h2>
                  <h5>Welcome to the yuandian system</h5>
                </div>
                <el-tabs class="activeModel" v-model="activeName" @tab-click="handleLoginTab">
                  <!--登录模块-->
                  <el-tab-pane label="用户登录" name="loginTab">
                    <el-form hide-required-asterisk ref="loginForm" :model="loginFormData" :rules="rulesLogin">
                      <el-form-item label="" prop="username" style="margin-bottom: 25px;margin-top: 10px;">
                        <el-input
                            placeholder="请输入手机号"
                            v-model.trim="loginFormData.username"
                            ref="username"
                            name="username"
                            @input="val => loginFormData.username = replaceNumber(val)"
                            clearable maxlength="11">
                          <i slot="prefix" class="el-input__icon el-icon-user"></i>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="" prop="password" style="margin-bottom: 25px;">
                        <el-input
                            placeholder="请输入密码"
                            ref="password"
                            name="password"
                            v-model="loginFormData.password"
                            @input="val => loginFormData.password = replaceChinese(val)"
                            show-password maxlength="15">
                          <i slot="prefix" class="el-input__icon el-icon-key"></i>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="" prop="code" style="margin-bottom: 25px;" v-if="showValidateCode">
                        <el-input
                            placeholder="请输入图形验证码"
                            v-model.trim="loginFormData.code"
                            ref="code"
                            name="code"
                            @input="val => loginFormData.code = replaceLetterOrNumber(val)"
                            maxlength="6" autocomplete="off" class='validate-input'>
                          <img slot="append" v-bind:src='validateCodeUrl' class="validate-code-img"
                               v-on:click="onValidateCodeClick"/>
                        </el-input>
                      </el-form-item>
                      <el-button :loading="loading" type="primary"
                                 class="loginBtn" @click="handleLogin">登&nbsp;&nbsp;录
                      </el-button>
                      <div style="cursor: pointer;" @click="forgetShowFunc">忘记密码</div>
                    </el-form>
                  </el-tab-pane>

                  <!--注册模块-->
                  <el-tab-pane label="用户注册" name="regTab">
                    <el-form hide-required-asterisk ref="regForm" :model="regFormData" :rules="rulesReg">
                      <el-form-item label="" prop="username" style="margin-bottom: 25px;margin-top: 10px;">
                        <el-input
                            placeholder="请输入手机号"
                            v-model.trim="regFormData.username"
                            ref="username"
                            name="username"
                            @input="val => regFormData.username = replaceNumber(val)"
                            clearable autocomplete="off" maxlength="11">
                          <i slot="prefix" class="el-input__icon el-icon-user"></i>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="" prop="password" style="margin-bottom: 25px;">
                        <el-input
                            placeholder="请输入密码"
                            v-model="regFormData.password"
                            ref="password"
                            name="password"
                            @input="val => regFormData.password = replaceChinese(val)"
                            show-password autocomplete="off" maxlength="15">
                          <i slot="prefix" class="el-input__icon el-icon-key"></i>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="" prop="code" style="margin-bottom: 25px;">
                        <el-input
                            placeholder="请输入验证码"
                            v-model.trim="regFormData.code"
                            ref="code"
                            name="code"
                            @input="val => regFormData.code = replaceNumber(val)"
                            number maxlength="4" autocomplete="off">
                          <el-button slot="append" style="color: #409eff;background-color: #ffffff;padding: 11px 20px"
                                     @click="regSendCode">{{ regCodeBtnStr }}
                          </el-button>
                        </el-input>
                      </el-form-item>
                      <el-button type="primary" :loading="regLoading" class="loginBtn" @click="handleReg">注&nbsp;&nbsp;册
                      </el-button>
                      <div style="cursor: pointer;">
                        <el-checkbox label="" v-model="agreementRead" name="type"
                                     style="margin-right: 3px"></el-checkbox>
                        我已阅读并同意
                        <el-link type="primary" style="margin-top: -5px;" :underline="false">《用户登录注册协议》
                        </el-link>
                      </div>
                    </el-form>
                  </el-tab-pane>
                </el-tabs>
            </div>

            <!--忘记密码 -->
            <div v-show="forgetShow" style="width: 80%; margin: 144px auto 0;">
              <template>
                <el-form hide-required-asterisk ref="forgetForm" :model="forgetFormData" :rules="rulesForGet">
                  <div class="forgetTitle">修改密码</div>
                  <div>
                    <el-form-item label="" prop="phone" style="margin-bottom: 25px;margin-top: 10px;">
                      <el-input
                          placeholder="请输入手机号"
                          v-model.trim="forgetFormData.phone"
                          ref="phone"
                          name="phone"
                          @input="val => forgetFormData.username = replaceNumber(val)"
                          clearable autocomplete="off" maxlength="11">
                        <i slot="prefix" class="el-input__icon el-icon-user"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="" prop="password" style="margin-bottom: 25px;">
                      <el-input
                          placeholder="请输入密码"
                          v-model="forgetFormData.password"
                          ref="password"
                          name="password"
                          @input="val => forgetFormData.password = replaceChinese(val)"
                          show-password autocomplete="off" maxlength="15">
                        <i slot="prefix" class="el-input__icon el-icon-key"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="" prop="smsValidateCode">
                      <el-input
                          placeholder="请输入验证码"
                          v-model.trim="forgetFormData.smsValidateCode"
                          ref="smsValidateCode"
                          name="smsValidateCode"
                          @input="val => forgetFormData.smsValidateCode = replaceNumber(val)"
                          clearable maxlength="4" autocomplete="off">
                        <el-button slot="append" style="color: #409eff;background-color: #ffffff;padding: 11px 20px"
                                   @click="forGetSendCode">{{ forgetCodeBtnStr }}
                        </el-button>
                      </el-input>
                    </el-form-item>
                    <el-button type="primary" :loading="resetLoading" class="loginBtn" @click="handleResetPassword">确&nbsp;&nbsp;定
                    </el-button>
                    <div style="cursor: pointer;" @click="loginShowFunc">返回登录</div>
                  </div>
                </el-form>
              </template>
            </div>
          </div>
      </div>

    </el-form>
  </div>
</template>

<script>
import {ajaxUtil} from "@/js/ajaxUtil.js"
import {api} from "@/js/api"
import store from '@/store/store'
import {CommonUtil} from '@/js/commonUtil'
import {MD5Util} from '@/js/MD5Util'
import {encode} from '@/js/aesencryp'
import {AuthUtil} from '@/js/auth'
import {messages} from '@/js/messages'
import {config} from '@/js/config'

export default {
  name: 'Login',
  data() {
    return {
      carouses: [
        config.ossHost + 'files/images/userwebfront/login/login2.png',
        config.ossHost + 'files/images/userwebfront/login/login3.png',
      ],
      activeName: 'loginTab',
      regCodeBtnStr: "获取验证码",
      forgetCodeBtnStr: "获取验证码",//23s后获取
      regCodeTimeClock: '',
      forGetTimeClock: "",

      loginFailedTimes: 0,//登陆失败次数
      showValidateCode: false,//是否显示图形验证码
      validateCode: '',
      agreementRead: true,

      loginFormData: {
        username: '',
        password: '',
        code: ''
      },
      regFormData: {
        username: '',
        password: '',
        code: "",
      },
      forgetFormData: {
        phone: '',
        password: '',
        smsValidateCode: "",
      },
      loginShow: true,
      forgetShow: false,
      loading: false,
      regLoading: false,
      resetLoading: false, //重置密码按钮加载中状态
      redirect: undefined,

      rulesLogin: {
        username: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur'}
        ],
        password: [{required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 15, message: '请输入6~15位密码', trigger: 'blur'}],
      },
      rulesReg: {
        username: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur'}
        ],
        password: [{required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 15, message: '请输入6~15位密码', trigger: 'blur'}],
        code: [{required: true, message: '请输入验证码', trigger: 'blur'},
          {min: 4, max: 4, message: '验证码必须为4位', trigger: 'blur'}],
      },
      rulesForGet: {
        phone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur'}
        ],
        password: [{required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 15, message: '请输入6~15位密码', trigger: 'blur'}],
        smsValidateCode: [{required: true, message: '请输入验证码', trigger: 'blur'},
          {min: 4, max: 4, message: '验证码必须为4位', trigger: 'blur'}],
      },
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  computed: {
    validateCodeUrl: function () {
      return "http://" + window.location.host + api.graphCode.replace('{code}', this.validateCode);
    }
  },
  created() {
    store.setLoginPage(true);

    //注册回车提交事件
    var this_ = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key == 13) {
        if (this_.forgetShow) {
          this_.handleResetPassword();
        } else if (this_.loginShow) {
          if (this_.activeName == 'loginTab') {
            this_.handleLogin();
          } else {
            this_.handleReg();
          }
        }
      }
    }
  },
  beforeDestroy() {
    store.setLoginPage(false);
  },
  methods: {
    handleLogin() {
      //验证用户名密码
      this.$refs["loginForm"].validate(valid => {
        if (!valid) {
          return false;
        }

        //验证图形验证码
        if (this.showValidateCode && this.validateCode != this.loginFormData.code.toUpperCase()) {
          messages.showErrorMsg(messages.error.loginCode)

          return;
        }

        //调用登陆
        this.loading = true;
        let userLogin = CommonUtil.copyObject(this.loginFormData);
        userLogin.phone = this.loginFormData.username;
        userLogin.password = MD5Util.hex_md5(userLogin.password)
        ajaxUtil.ajaxLogin(api.customer.login, userLogin, 'POST').then((data => {
          //保存token
          store.setToken(data);
          this.loading = false;
          this.loginFailedTimes = 0;
          this.showValidateCode = false;
          //获取登陆用户信息
          ajaxUtil.ajaxJson(api.customer.getLoginCustomerInfo, null, 'GET').then((data) => {
            store.setUser(data);
            //跳转首页
            this.$router.push('/user');
          })

        }), (err => {
          //处理登陆失败
          this.loading = false;
          messages.showErrorMsg(messages.error.login)

          if (this.loginFailedTimes >= 2) {
            this.validateCode = generateValidateCode();
            this.showValidateCode = true;
          }
          this.loginFailedTimes = this.loginFailedTimes + 1;
        }))


      })
    },
    handleReg() {
      this.$refs["regForm"].validate(valid => {
        if (!valid) {
          return false
        } else {
          if (!this.agreementRead) {
            messages.showErrorMsg(messages.error.registerAgreement)
            return false;
          }
          this.regLoading = true;

          //调用注册
          let userReg = CommonUtil.copyObject(this.regFormData);
          userReg.phone = this.regFormData.username;
          userReg.password = MD5Util.hex_md5(userReg.password)
          userReg.smsValidateCode = userReg.code
          ajaxUtil.ajaxJson(api.customer.register, userReg, 'POST').then((data => {
            //注册成功直接登陆
            let userLogin = {phone: userReg.phone, password: userReg.password}
            ajaxUtil.ajaxLogin(api.customer.login, userLogin, 'POST').then((data => {
              //保存token
              store.setToken(data);
              this.regLoading = false;
              //获取登陆用户信息
              ajaxUtil.ajaxJson(api.customer.getLoginCustomerInfo, null, 'GET').then((data) => {
                store.setUser(data);
                //跳转首页
                this.$router.push('/user');
              })
            }), (err => {
              //处理登陆失败
              this.regLoading = false;
              messages.showErrorMsg(messages.error.login)
            }))
          }), (err => {
            //处理注册失败
            this.regLoading = false;
            var errorMsg = (err == null || err.data == null) ? null : messages.getMsgByCode(err.data.errorCode);
            errorMsg = errorMsg != null ? errorMsg : '注册失败';
            messages.showErrorMsg(errorMsg);
          }))

        }

      })
    },
    handleResetPassword() {
      this.$refs.forgetForm.validate(valid => {
        if (!valid) {
          return false
        } else {
          this.resetLoading = true

          let resetData = CommonUtil.copyObject(this.forgetFormData);
          resetData.password = MD5Util.hex_md5(resetData.password)
          ajaxUtil.ajaxJson(api.customer.resetPassword, resetData, 'PUT').then(data => {
            this.resetLoading = false;

            //成功提示并返回登陆框
            messages.showSuccessMsg(messages.success.reset)
            this.loginShowFunc()

          }, (err) => {
            this.resetLoading = false;
            var errorMsg = (err == null || err.data == null) ? null : messages.getMsgByCode(err.data.errorCode);
            errorMsg = errorMsg != null ? errorMsg : messages.error.reset;
            messages.showErrorMsg(errorMsg);
          })
        }
      })
    },
    regSendCode() {
      if (this.regCodeTimeClock !== "") {
        return;
      }
      this.$refs["regForm"].validateField('username', errMsg => {
        if (errMsg) {
          return;
        }
        let regUuid = AuthUtil.getSpecifyUuid("reg");
        let validateToken = encode(regUuid);
        let regCodeRequest = {phone: this.regFormData.username, validateToken: validateToken}

        ajaxUtil.ajaxJson(api.sms.customerReg, regCodeRequest, 'POST').then(() => {
          messages.showSuccessMsg(messages.success.validateCodeSend);

          let timer_num = 60;
          let this_ = this;
          this.regCodeTimeClock = setInterval(function () {
            timer_num--;
            this_.regCodeBtnStr = timer_num + "s后获取";
            if (timer_num === 0) {
              clearInterval(this_.regCodeTimeClock);
              this_.regCodeTimeClock = "";
              this_.regCodeBtnStr = "获取验证码";
            }
          }, 1000)
        }, (error) => {
          messages.showErrorMsg(messages.error.validateCodeSend)
        })
      });
    },
    forGetSendCode() {
      if (this.forGetTimeClock !== "") {
        return;
      }
      this.$refs["forgetForm"].validateField('phone', errMsg => {
        if (errMsg) {
          return;
        }

        let resetUuid = AuthUtil.getSpecifyUuid("forgetpwd");
        let validateToken = encode(resetUuid);
        let sendCodeRequest = {phone: this.forgetFormData.phone, validateToken: validateToken}
        ajaxUtil.ajaxJson(api.sms.customerResetPassword, sendCodeRequest, 'POST').then(() => {
          messages.showSuccessMsg(messages.success.validateCodeSend)
          let timer_num = 60;
          let this_ = this;
          this.forGetTimeClock = setInterval(function () {
            timer_num--;
            this_.forgetCodeBtnStr = timer_num + "s后获取";
            if (timer_num === 0) {
              clearInterval(this_.forGetTimeClock);
              this_.forGetTimeClock = "";
              this_.forgetCodeBtnStr = "获取验证码";
            }
          }, 1000)
        }, (error) => {
          messages.showErrorMsg(messages.error.validateCodeSend)
        })
      })
    },

    handleLoginTab(tab) {
      if (tab.name === "regTab") {
        this.regFormData = {
          username: '',
          password: '',
          code: "",
        }
        this.$refs["loginForm"].clearValidate();
      } else {
        this.loginFormData = {
          username: '',
          password: '',
          code: ''
        };
        this.$refs["regForm"].clearValidate();
      }
    },
    forgetShowFunc() {
      this.forgetFormData = {
        phone: '',
        password: '',
        smsValidateCode: "",
      }
      this.loginShow = false;
      this.forgetShow = true;
      this.$refs.forgetForm.clearValidate();
    },
    loginShowFunc() {
      this.loginFormData = {
        username: '',
        password: '',
        code: ''
      };
      this.$refs.loginForm.clearValidate();
      this.forgetShow = false;
      this.loginShow = true;
    },
    onValidateCodeClick() {//点击获取图形验证码
      this.validateCode = generateValidateCode();
    },
    replaceNumber(val) {
      return val.replace(/[^0-9]/g, '');
    },
    replaceChinese(val) {
      return val.replace(/[\u4E00-\u9FA5]/g, "");
    },
    replaceLetterOrNumber(val) {
      return val.replace(/[^0-9a-zA-Z]/g, '');
    }
  }
}

const codeSequence = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
  'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W',
  'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

function generateValidateCode() { //生成4位验证码（字母及数字组合）
  var result = '';
  for (var i = 0; i < 4; i++) {
    result = result + codeSequence[parseInt(Math.random() * (codeSequence.length))]
  }
  return result;
}
</script>


<style lang="scss" scoped>
$bg: #dcdde0;
$dark_gray: #889aa4;
$light_gray: #ffff;

.login-container {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
  padding: 50px 0;
  background-image: url(../../../assets/official/login-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .login-content {
    box-sizing: border-box;
    padding: 50px 20px;
    position: relative;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 5px 20px 0 rgba(51, 152, 255, .1);
    border-radius: 6px;
    display: table;
    background-image: url(../../../assets/official/login-bg2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .login-form {
    position: relative;
    width: 1200px;
    max-width: 100%;
    padding: 76px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .grid-content {
    padding-bottom: 50px;
    width: 50%;
    float: right;

    .title{
      text-align: center;
      h2{
        color: #4D91FF;
      }
    }
  }

  .bg-purple {
    width: 470px;
    height: 574px;
  }

}

.activeModel {
  width: 80%;
  margin: 80px auto 0;
}

.loginBtn {
  width: 100% !important;
  height: 40px;
  font-size: 16px;
  margin: 20px 0 30px;
  box-shadow: 0 7px 27px 0 rgba(51, 152, 255, .3);
}

.forgetTitle {
  font-size: 18px;
  color: #333;
  padding-top: 5px;
  padding-bottom: 40px;
  letter-spacing: 3px;
  text-align: center
}

.el-input {
  float: left;
}

.validate-input {
  ::v-deep .el-input-group__append {
    padding: 0px;
    background: #fff;
  }

  .validate-code-img {
    width: 120px;
    height: 34px;
  }
}
</style>
