<template>
    <div class="content-wrapper"> <!--活动创建页面-->
        <el-form hide-required-asterisk label-width="170px" label-position="left" ref="activityForm" :model="activity" :rules="rules">
            <el-form-item label="活动名称" prop="name">
                <el-input placeholder="请输入活动名称" v-model.trim="activity.name" class="form-item" maxlength="40"></el-input>
            </el-form-item>
            <el-form-item label="活动时间">
              <dateRange ref="dateRange" :start.sync="activity.startTime" :end.sync="activity.endTime"></dateRange>
            </el-form-item>
            <el-form-item label="活动介绍" prop="introduce">
                <div class='indrouceBasic'>
                    <div class='edit-flex'>
                        <Tinymce ref="tinymce" v-model="activity.introduce"  :height="500" :width='380'/>
                        <editStylebox :height=600 :richTextType=2 @afterInit="initIntroduceModel" @chooseRichTextModel="chooseRichTextModel"></editStylebox>
                    </div>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button class="button-width" @click="cancel">取消</el-button>
                <el-button type="primary" @click="onSubmit" >立即创建</el-button>
            </el-form-item>
        </el-form>

    </div>

</template>

<script>
    import Tinymce from '@/components/tinymce'
    import editStylebox from '@/components/editStylebox.vue'
    import {ActivityUtil} from '@/js/commonUtil'
    import {messages} from '@/js/messages'
    import dateRange from '@/components/dateRange.vue'
    import {CommonUtil} from "@/js/commonUtil";

    export default {
        name: 'AddActivity',
        data(){
            return {
                activity:{
                    name:null,
                    startTime:CommonUtil.plusDate(new Date(),1,true),
                    endTime:CommonUtil.plusDate(new Date(),8,true),
                    introduce:''
                },
                rules: {
                  name: [{required: true, message: '请输入活动名称', trigger: 'blur'}]
                },
                introduceRichTextModel:[]
            }
        },
        components:{
            Tinymce,
            editStylebox,
            dateRange
        },
        created(){

        },
        methods:{
            cancel(){
                history.back();
            },
            onSubmit(){
              this.$refs["activityForm"].validate(valid => {
                if (valid) {
                  this.$refs["dateRange"].validate(()=>{
                    if(this.activity.introduce==null||this.activity.introduce==''){
                      messages.showErrorMsg('活动介绍不能为空');
                    }else {
                      ActivityUtil.add(this.activity, data=>{
                        ActivityUtil.toSettingAct('BasicSet', data.id);
                      })
                    }
                  })
                }
              })
            },
            initIntroduceModel(richTextList){
                if(this.activity.introduce==''&&richTextList&&richTextList.length>0){
                    this.activity.introduce=richTextList[0].content;
                }
            },
            chooseRichTextModel(richText){
                this.$nextTick(()=>{
                    this.activity.introduce=(this.activity.introduce?this.activity.introduce:'')+richText;
                    this.$refs["tinymce"].resetValue(this.activity.introduce);
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .indrouceBasic{
        overflow: hidden;
        // width:650px;
        text-align: center;
    }
    .form-item{
        width: 683px;
    }
</style>
