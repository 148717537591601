<template>
  <div class="content-wrapper">
    <div class="set-wrapper">
      <div class='set-left'>
        <!--预览-->
        <div class='look-iframe' style='overflow:hidden'>
          <iframe id="topicH5" :src='topic.h5Url' frameborder='0' height='100%' width='100%'></iframe>
        </div>
        <div class='set-left-btn'>
          <el-button size='medium' class='refesh-btn' @click="refreshH5">刷新</el-button>
          <el-button type="primary" size='medium' @click='showPublishDialog =true'>发布专题</el-button>
        </div>
      </div>
      <div class='set-right'>
        <div>
          <div class='content'>
            <div class='basic'>
              <el-form hide-required-asterisk ref="topicRef" label-position="left" label-width="150px" :model="topic">
                <el-form-item label="专题名称" prop="title"
                              :rules="[{required: true, message: '请输入专题名称', trigger: 'blur'}]">
                  <el-input placeholder="请输入专题名称" v-model.trim='topic.title' maxlength="30" @change="updateTopic"
                            style='max-width: 500px;'></el-input>
                </el-form-item>
                <el-form-item label="专题插图">
                  <imageContainer :isEditVisible.sync="showImgDialog" :height="180" :width="120" :maxImageNum="1"
                                  shadowDes="去设置"
                                  :images="[topic.bannerUrl]"></imageContainer>
                </el-form-item>
                <el-form-item label="专题配色">
                  <colorChoose :color="topic.backgroundColor" v-on:clickBtn="changeColor('')"
                               v-on:changeColor="changeColor"></colorChoose>
                </el-form-item>
                <el-form-item label="仅显示活动名称">
                  <el-switch v-model='topic.onlyShowActName' @change="changeActBannerShow"
                             active-color="#409EFF">
                  </el-switch>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!--发布专题弹窗-->
          <publishDialogMini :visible.sync="showPublishDialog" publishObj="专题" :url="topic.h5Url"></publishDialogMini>
          <!--------------------------------------------专题插图弹框------------------------------------------->
          <resourceDialog title="插图选择" :visible.sync="showImgDialog" :resourceType="6" :urlValue="topic.bannerUrl"
                          v-on:changeResource="changeBanner"></resourceDialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resourceDialog from '@/components/resourceDialog.vue'
import imageContainer from '@/components/imageContainer.vue'
import colorChoose from '@/components/colorChoose.vue'
import publishDialogMini from '@/components/publishDialogMini.vue'
import {ajaxUtil} from '@/js/ajaxUtil.js'
import {api} from '@/js/api.js'
import {messages} from '@/js/messages'
import {StorageUtil} from "@/js/auth";

export default {
  name: 'topicEdit',
  components: {
    resourceDialog,
    imageContainer,
    colorChoose,
    publishDialogMini
  },
  data() {
    return {
      topic: {},
      showPublishDialog: false,
      showImgDialog: false,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'Topic') {
      let topicListQueryParams = StorageUtil.getObject('topicListQueryParams');
      if (topicListQueryParams) {
        topicListQueryParams.query = true;
        StorageUtil.storeObject('topicListQueryParams', topicListQueryParams);
      }
    } else {
      StorageUtil.removeObject('topicListQueryParams');
    }
    next()
  },
  beforeMount() {
    //加载topic数据
    this.getTopic(this.$route.query.id);
  },

  methods: {
    refreshH5() {
      document.getElementById('topicH5').src = this.topic.h5Url;
    },
    getTopic(id) {
      let url = api.topic.getById.replace('{id}', id)
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        data.onlyShowActName = !data.showActivityBanner;
        this.topic = data;
      })
    },

    changeBanner(target) {
      this.topic.bannerUrl = target.url;
      this.updateTopic();
    },

    changeColor(color) {
      this.topic.backgroundColor = color;
      this.updateTopic();
    },

    changeActBannerShow() {
      this.topic.showActivityBanner = !this.topic.onlyShowActName;
      this.updateTopic();
    },

    updateTopic() {
      this.$refs["topicRef"].validate(valid => {
        if (valid) {
          let requestData = {
            id: this.topic.id, title: this.topic.title, bannerUrl: this.topic.bannerUrl,
            backgroundColor: this.topic.backgroundColor, showActivityBanner: this.topic.showActivityBanner
          }
          ajaxUtil.ajaxJson(api.topic.update, requestData, 'PUT', null, true).then(data => {
            messages.showSuccessMsg(messages.success.update);
            this.refreshH5();
          })
        }
      })
    },

  }
}
</script>
<style lang="scss" scoped>
.set-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 24px;

  .set-left {
    width: 350px;
    height: 77vh;
    max-height: 660px !important;
    // border:1px solid #eee;
    margin-right: 50px;
    position: relative;

    .look-iframe {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 50px;
      background: #f6f6f6;
      border: 1px solid #eee;
    }

    .set-left-btn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .refesh-btn {
      width: 98px;
    }
  }

  .set-right {
    flex: 1;
    height: 100%;
    //  border:1px solid #eee;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    .previewBtn {
      position: absolute;
      right: 0;
      top: 10px;
      z-index: 20;
    }
  }
}
</style>
