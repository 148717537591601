<template>
  <div class="player-set">
    <div class="header">
      <div class="row">
        <div class="col">
          <el-button type="primary" icon="el-icon-plus" size='small' @click='addPlayer'>添加选手</el-button>
          <el-button type="primary" icon="el-icon-plus"
                     plain size='small' @click="clickBatchImport">批量添加
          </el-button>
          <!--          <el-upload v-else action="https://jsonplaceholder.typicode.com/posts/"-->
          <!--                     :http-request="uploadPlayerExcel" accept=".xls, .xlsx" multiple :limit="1" :show-file-list=false>-->
          <!--            <el-button type="primary" icon="el-icon-plus" plain size='small'>批量添加</el-button>-->
          <!--          </el-upload>-->
          <el-button type="primary" icon="el-icon-set-up" plain size='small' @click='resetNum'>重置编号</el-button>
          <el-button disabled size='small' type="primary" icon="el-icon-upload">批量导入选手封面</el-button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <el-input style="width: 180px;margin-right: 10px;" size='small' v-model="searchKey"
                    placeholder="选手名称、编号" :clearable="true"
                    @keyup.enter.native="onSearchKeyChange"
                    @change="onSearchKeyChange"></el-input>
          <el-select style="width: 180px;margin-right: 10px;" size='small' v-show="groupOptions.length>1"
                     v-model="currentGroupId"
                     placeholder="请选择分组" @change="onSearchKeyChange">
            <el-option
                v-for="item in groupOptions"
                :key="item.value"
                :label="item.des"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button type="primary" plain size='small' @click='exportPlayerData'>导出选手</el-button>
        </div>
        <div class="col">
          <el-dropdown style="width: 100px;" trigger="click" @command="selectSortCondition">
             <span class="el-dropdown-link">
                 {{ selectedSortCondition.name }}
               <i class="el-icon-arrow-down el-icon--right"></i>
             </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in sortConditions" v-bind:key="index" :command="item">
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown style="width: 70px;" trigger="click" @command="selectShowCondition">
            <span class="el-dropdown-link">
                {{ selectedShowCondition.name }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in showConditions" v-bind:key="item.id" :command="item">
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown style="width: 120px;" trigger="click" @command="selectOtherCondition">
            <span class="el-dropdown-link">
                {{ selectedOtherCondition.name }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in otherConditions" v-bind:key="item.id" :command="item">
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="list">
      <el-table ref="multipleTable" :data="playersPage.resultList" tooltip-effect="dark"
                @selection-change="handleSelectionChange"
                @select-all="()=>this.playersPage.resultList.length<=0?this.$refs.multipleTable.clearSelection():''">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column>
          <template slot="header">
            <el-dropdown style="width: 100px;" trigger="click" @command="bindMultiChange">
             <span class="el-dropdown-link">
                 批量操作
               <i class="el-icon-arrow-down el-icon--right"></i>
             </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index) in multiOptions" v-bind:key="index" :command="item">
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-slot="{row,$index}">
            <div class="player" @click="handleEditPlayer($index)">
              <div class="avatar">
                <el-avatar v-if="row.imageUrl" size="medium" :src="row.imageUrl|appendImgUrl"></el-avatar>
                <el-avatar v-else size="medium" icon="el-icon-user-solid"></el-avatar>
                <div class='avatar-clock' v-if='row.locked&&row.show'>
                  <img src='@/assets/clock.png' alt="">
                </div>
                <div class='avatar-hidden' v-if='!row.show'>
                  <img src='@/assets/hidden.png' alt="">
                </div>
              </div>
              <div class="info">
                <div class="base">
                  <div class="num">{{ row.num }}号</div>
                  <div class="name">{{ row.name }}</div>
                </div>
                <div class="blurb">{{ row.intro }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="playerGroups.length>1"
                         align="center" prop="groupName" label="选手分组" width="120">
        </el-table-column>
        <el-table-column align="center" prop="voteNum" label="票数" width='120'></el-table-column>
        <el-table-column align="center" prop="viewNum" label="浏览量" width='120'></el-table-column>
        <el-table-column align="center" label="操作" width='100'>
          <template v-slot="{row,$index}">
            <el-dropdown trigger="click" placement="bottom">
                    <span class="el-dropdown-link">
                      <i class="el-icon-more"></i>
                    </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleEditPlayer($index)">
                  编辑
                </el-dropdown-item>
                <el-dropdown-item>
                  头像导出
                </el-dropdown-item>
                <el-dropdown-item divided @click.native="bindStatisticsChange(row)">
                  数据统计
                </el-dropdown-item>
                <el-dropdown-item divided @click.native="handleLockPlayer(row)">
                  {{ row.locked == 0 ? '封禁选手' : '解封选手' }}
                </el-dropdown-item>
                <el-dropdown-item @click.native="handleShowPlayer(row)">
                  {{ row.show == 0 ? '显示选手' : '隐藏选手' }}
                </el-dropdown-item>
                <el-dropdown-item divided @click.native="handleDeletePlayer(row)">
                  删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class='pagination-box'>
      <el-pagination background layout="total, prev, pager, next" :page-size="playersPage.pageCount"
                     :total="playersPage.totalCount" :current-page="playersPage.pageNo"
                     @current-change="pageChange">
      </el-pagination>
    </div>
    <addPlayer :dialogVisible=addPlayerShow :options='playerDialogOption'
               @close='addPlayerSuccess' @continue="continueEditPlayerEvent">
    </addPlayer>
    <!--批量导入选手弹出窗(在存在多个分组的情况下) -->
    <el-dialog title="批量添加选手" :visible.sync="batchImportPlayerDialog"
               width="30%" top="30vh" :close-on-click-modal="false">
      <el-form label-width="90px" class="import-player">
        <el-form-item label="选手分组" v-if="playerGroups&&playerGroups.length>1">
          <el-select style="width:300px;" v-model="importGroupId" placeholder="请选择分组">
            <el-option v-for="item in playerGroups" :key="item.value" :label="item.des" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择文件">
          <div>
            <el-upload v-if="batchImportPlayerDialog" style="display: inline-block;"
                       action="https://jsonplaceholder.typicode.com/posts/"
                       accept=".xls, .xlsx" multiple :limit="2" :show-file-list=false
                       :on-change="chooseFile" :auto-upload="false">
              <div class="upload-body">
                <span v-if="file">{{ file.name }}</span>
                <span v-else>+上传选手资料</span>
                <span class="tip">请先下载选手模板，按模板格式填写信息导入</span>
              </div>
            </el-upload>
          </div>
          <div>
            <el-button type="text" icon="el-icon-download" @click="downloadModel">下载批量模板</el-button>
          </div>
        </el-form-item>
        <div class="operate">
          <el-button @click="batchImportPlayerDialog = false" class="button">取 消</el-button>
          <el-button type="primary" @click="uploadPlayerExcel({file:file})" class="button">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!--选手操作弹窗（隐藏，锁定，删除） -->
    <el-dialog :visible.sync="operationDialogVisible" width="24%" top="34vh"
               :close-on-click-modal="false" @close="closeOperationDialog">
      <div slot="title">
        <div class="span-box align-left">
          <span class="dialog-title marRight6">{{ operationDialogTitle }}</span>
          <span style="color: red">{{ operationDialogMsg }}</span>
        </div>
      </div>
      <el-row>
        <el-col :span="4">
          <div class='playerInfo'>
            <div class='playerInfo-avatar-box'>
              <el-avatar size="medium" :src="selectedPlayer.imageUrl|appendImgUrl" shape="square" fit="fill"
                         v-if="selectedPlayer.imageUrl!=null&&selectedPlayer.imageUrl!=''"></el-avatar>
              <el-avatar size="medium" shape="square" fit="fill" icon="el-icon-user-solid" v-else></el-avatar>
            </div>
          </div>
        </el-col>
        <el-col :span="20" class="dialog-playerinfo">
          <p class="gray-font">{{ selectedPlayer.num }}号</p>
          <p class="playername">{{ selectedPlayer.name }}</p>
        </el-col>
      </el-row>
      <el-row class="dialog-foot marTop30">
        <el-button @click="operationDialogVisible = false" class="button">取 消</el-button>
        <el-button type="primary" class="button" @click="confirmOperation">确 定</el-button>
      </el-row>
    </el-dialog>
    <!--选手重置编号弹窗 -->
    <el-dialog :visible.sync="resetNumDailogVisible" width="20%" top="34vh" title="重置编号"
               :close-on-click-modal="false" @close="closeOperationDialog">
      <div class="reset-player-num">
        <div class="reset-tip">
          <i class="el-icon-chat-line-round"></i>
          该操作将对所有选手按添加时间重新编号
        </div>
        <div class="reset-type">
          <div class="title">
            重置方式
          </div>
          <el-radio-group v-model="resetNumType">
            <el-radio :label="1">时间正序</el-radio>
            <el-radio :label="2">时间倒序</el-radio>
          </el-radio-group>
        </div>
        <div class="reset-btn">
          <el-button @click="resetNumDailogVisible = false" class="button">取 消</el-button>
          <el-button type="primary" @click="confirmResetNum" class="button">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <player-statistics :visible="isStatistics" :player="currentPlayer" @close='isStatistics=false'></player-statistics>
  </div>
</template>

<script>
import addPlayer from '@/components/addPlayer.vue'
import PlayerStatistics from './playerStatistics.vue'
import store from '@/store/store'
import {config} from '@/js/config'
import {api} from '@/js/api'
import {ajaxUtil} from '@/js/ajaxUtil'
import {messages} from '@/js/messages'
import {ActivityUtil} from '@/js/commonUtil'
import * as XLSX from 'xlsx';
import moment from "moment";

export default {
  name: 'playerSet',
  data() {
    return {
      activityId: ActivityUtil.getCurrentSettingActId(),
      actName: '',
      addPlayerShow: false,
      searchKey: '',
      sortConditions: [{id: 1, name: '编号正序'}, {id: 4, name: '编号倒序'}, {id: 5, name: '票数正序'}, {
        id: 2,
        name: '票数倒序'
      }],
      showConditions: [{id: 0, name: '全部'}, {id: 1, name: '显示'}, {id: 2, name: '隐藏'}],
      otherConditions: [{id: 0, name: '全部筛选'}, {id: 1, name: '无封面'}, {id: 2, name: '有封面'},
        {id: 3, name: '禁封选手'}, {id: 4, name: '无简要介绍'}, {id: 5, name: '有简要介绍'},
        {id: 6, name: '无详细介绍'}, {id: 7, name: '有详细介绍'}, {id: 8, name: '封面无链接'}, {
          id: 9,
          name: '封面有链接'
        }],
      selectedSortCondition: '',
      selectedShowCondition: '',
      selectedOtherCondition: '',

      pageVisible: false,
      pageNo: 1, //当前页码
      pageCount: 10, //每页显示的专题数量，固定为10
      multiOperationVisible: false,
      playersPage: {pageNo: 1, pageCount: 10, totalCount: 0, totalPage: 0, resultList: []},
      isStatistics: false,
      currentPlayer: 0,
      selectedPlayerIds: [],
      playerDialogOption: {},
      batchImportPlayerDialog: false,
      playerGroups: [],
      groupOptions: [{value: 0, des: '全部'}],
      currentGroupId: 0,
      importGroupId: 0,
      file: null,

      selectedPlayer: '', //单选操作选中的选手
      operationType: 0, //1.显示状态 2.锁定状态 3,删除状态
      operationDialogVisible: false, //单选操作的弹出
      operationDialogTitle: '封禁选手', //单选操作的title
      operationDialogMsg: '改操作后将不可向选手投票', //单选操作的msg
      resetNumDailogVisible: false,
      resetNumType: 1, //重置方式 1时间正序 2时间倒叙
      multiOptions: [
        {value: 'show', name: '显示'},
        {value: 'hide', name: '隐藏'},
        {value: 'del', name: '删除'},
      ]
    }
  },
  components: {
    addPlayer,
    PlayerStatistics
  },

  beforeMount() {
    let {activityId, actName,groupId} = this.$route.query;
    if (activityId) {
      this.activityId = activityId
    }
    if (actName) {
      this.actName = actName
    }
    if (groupId) {
      this.currentGroupId = groupId
    }
    this.operationDialogVisible = false;
    this.resetNumDailogVisible = false;
    this.getPlayerGroups();
    this.selectedSortCondition = this.sortConditions[0];
    this.selectedShowCondition = this.showConditions[0];
    this.selectedOtherCondition = this.otherConditions[0];
    this.searchKey = '';
    this.pageNo = 1;
    this.queryPlayers(this.pageNo);

  },
  methods: {
    //查询选手
    queryPlayers(pageNo) {
      let url = api.player.query.replace('{activityId}', this.activityId)
          .replace('{sortType}', this.selectedSortCondition.id).replace('{showType}', this.selectedShowCondition.id)
          .replace('{filterType}', this.selectedOtherCondition.id).replace('{searchKey}', this.searchKey)
          .replace('{pageNo}', pageNo).replace('{pageCount}', this.pageCount);
      if (this.currentGroupId) {
        url = url + '&groupId=' + this.currentGroupId;
      }
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        this.pageNo = this.pageNo > data.totalPage ? data.pageNo : this.pageNo;
        this.playersPage = data;
        if (data.totalCount == 0) {
          this.pageVisible = false;
        } else {
          if (data.resultList == null || data.resultList.length == 0) {
            this.pageNo = pageNo - 1;
            this.queryPlayers(this.pageNo);
          } else {
            this.pageVisible = true;
          }
        }
        if (this.playersPage.resultList.length <= 0) {
          this.$refs.multipleTable.clearSelection();
        }
      })
    },
    getPlayerGroups() {
      let url = api.player.groupSimpleList.replace('{activityId}', ActivityUtil.getCurrentSettingActId());
      ajaxUtil.ajaxPage(url, null, 'GET', null, data => {
        if (data.length) {
          //导入
          this.playerGroups = data
          this.importGroupId = data[0].value;
          //选择
          if (data.length > 1) {
            for (let i = 0; i < data.length; i++) {
              this.groupOptions.push(data[i])
            }
          }
        }
      })
    },
    clickBatchImport() {
      this.file = null;
      this.batchImportPlayerDialog = true;
    },
    chooseFile(file, fileList) {
      //在单文件上传时,on-change只会触发一次,为避免该问题,故将limit改为2,并在on-change事件里把老的文件删除
      //从而达到既是单文件上传,也能多次触发on-change事件的目的
      if (fileList.length > 1) {
        fileList.shift();
      }
      this.file = fileList.length == 0 ? null : fileList[0].raw;
    },
    uploadPlayerExcel(option) {
      if (!option || !option.file) {
        messages.showErrorMsg('请选择文件');
        return false;
      }
      this.batchImportPlayerDialog = false;
      const formData = new FormData()
      formData.append('file', option.file);
      let url = api.player.importExcel.replace('{activityId}', this.activityId)
          .replace('{groupId}', this.importGroupId).replace('{updateBy}', store.state.user.id);
      ajaxUtil.ajaxForm(url, formData, 'POST', null, 'multipart/form-data', true).then(data => {
        messages.showSuccessMsg('导入成功' + data.successNum + '条，失败' + data.failNum + '条');
        if (data.successNum == 0 && data.failNum > 0) {
          this.getPlayerGroups();
        }
        if (data.successNum > 0) {
          this.pageChange(1);
        }
      })
      this.file = null;
    },
    //导出活动数据
    exportPlayerData() {
      let actId = this.activityId;
      let actName = this.actName;
      const headers = ['编号', '选手名称', '分组', '简介', '详情', '创建时间', '当前票数']
      let url = api.player.ranking.replace('{activityId}', actId);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        const table = data.resultList.map(
            item => {
              //转换时间
              let createTime = moment(parseInt(item.createTime)).format('YYYY-MM-DD HH:mm:ss');
              //转换富文本
              let content = item.content;
              if (content) {
                const reg = /<\/?[^>]+(>|$)/g;
                content = content.replace(reg, '')
              }
              return [item.num, item.name, item.groupName, item.intro, content, createTime, item.voteNum]
            }
        )
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...table])
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
        XLSX.writeFile(workbook, actId + '_' + actName + '_选手.xlsx')
      })
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      this.queryPlayers(this.pageNo);
    },

    bindMultiChange(item) {
      let ids = this.selectedPlayerIds;
      if (!ids || !ids.length) {
        messages.warning('请选择选手')
        return
      }
      switch (item.value) {
        case 'show':
          this.updatePlayerStatus(ids, 1, true);
          break;
        case 'hide':
          this.updatePlayerStatus(ids, 1, false);
          break;
        case 'del':
          this.updatePlayerStatus(ids, 3, true);
          break;
      }
    },

    selectSortCondition(item) {
      this.selectedSortCondition = item;
      this.pageNo = 1;
      this.queryPlayers(this.pageNo);
    },

    selectShowCondition(item) {
      this.selectedShowCondition = item;
      this.pageNo = 1;
      this.queryPlayers(this.pageNo);
    },

    selectOtherCondition(item) {
      this.selectedOtherCondition = item;
      this.pageNo = 1;
      this.queryPlayers(this.pageNo);
    },

    //搜索输入变化处理
    onSearchKeyChange() {
      this.pageNo = 1;
      this.queryPlayers(this.pageNo);
    },

    //列表多选框改变事件处理
    handleSelectionChange(val) {
      this.selectedPlayerIds = [];
      for (var i in val) {
        this.selectedPlayerIds.push(val[i].id);
      }
      //若选择的数组为空，即未选择任何项
      if (val.length == 0) {
        this.multiOperationVisible = false;
      } else {
        this.multiOperationVisible = true;
      }
    },

    //点击新增选手
    addPlayer() {
      this.playerDialogOption = {type: 0, groupId: 0};
      this.addPlayerShow = true;
    },

    addPlayerSuccess() {
      this.addPlayerShow = false;
      this.queryPlayers(this.pageNo);
    },

    //选手操作记录(即选手日志)
    bindStatisticsChange(player) {
      this.isStatistics = true
      this.currentPlayer = player
    },

    //修改锁定状态
    handleLockPlayer(player) {
      let status = player.locked == 0 ? true : false;
      this.operationType = 2;
      if (status) {//封禁选手
        this.selectedPlayer = player;
        this.operationDialogTitle = '封禁选手';
        this.operationDialogMsg = '该操作后将不可向该选手进行投票';
        this.operationDialogVisible = true;
      } else {
        let ids = [player.id]
        this.updatePlayerStatus(ids, 2, status);
      }
    },

    //修改显示状态
    handleShowPlayer(player) {
      let status = player.show == 0 ? true : false;
      this.operationType = 1;
      if (!status) {//隐藏选手
        this.selectedPlayer = player;
        this.operationDialogTitle = '隐藏选手';
        this.operationDialogMsg = '该操作后选手将不再出现在选手列表中';
        this.operationDialogVisible = true;
      } else {
        let ids = [player.id];
        this.updatePlayerStatus(ids, 1, status);
      }
    },

    //删除选手
    handleDeletePlayer(player) {
      this.selectedPlayer = player;
      this.operationType = 3;
      this.operationDialogTitle = '删除选手';
      this.operationDialogMsg = '该操作后当前选手将永久删除';
      this.operationDialogVisible = true;
    },

    //修改选手状态（显示/隐藏，删除，锁定）
    updatePlayerStatus(ids, type, status) {
      if (type == 0) {
        return;
      }
      let request = {
        activityId: this.activityId, ids: ids, type: type,
        typeVal: status, updateBy: store.state.user.id
      }
      ajaxUtil.ajaxJson(api.player.updateStatus, request, 'PUT', null, true).then(data => {
        messages.showSuccessMsg(messages.success.update);
        ActivityUtil.refreshH5(false);
        this.queryPlayers(this.pageNo)
      })
    },

    //点击修改选手
    handleEditPlayer(index) {
      let {resultList, pageNo, totalPage} = this.playersPage;
      let existUp = false, existNext = true;
      let playerSize = resultList.length;
      let player = resultList[index];
      if (index === 0) {
        if (pageNo > 1) {
          existUp = true
        }
        existNext = index < playerSize
      } else {
        existUp = true
        if (index === playerSize) {
          existNext = pageNo < totalPage
        } else {
          existNext = true
        }
      }
      this.playerDialogOption = {type: 1, playerId: player.id,groupId: player.groupId, index: index, existUp: existUp, existNext: existNext}
      this.addPlayerShow = true;
    },
    //继续编辑选手
    continueEditPlayerEvent({currentPlayerIndex, continueType}) {
      let {resultList, pageCount, pageNo, totalPage, totalCount} = this.playersPage;
      let playerSize = resultList.length;
      let continueIndex = 0, continuePage = 0, existUp = false, existNext = true;

      //上一个
      if (continueType === 'up') {
        existNext = true
        if (currentPlayerIndex === 0 && pageNo > 1) {
          continueIndex = pageCount - 1
          continuePage = pageNo - 1
          existUp = true
        } else if (currentPlayerIndex > 0) {
          let upIndex = currentPlayerIndex - 1
          let player = resultList[upIndex];
          if (upIndex === 0) {
            existUp = pageNo > 1
          } else {
            existUp = true
          }
          this.playerDialogOption = {
            type: 1,
            playerId: player.id,
            groupId: player.groupId,
            index: upIndex,
            existUp: existUp,
            existNext: existNext
          }
          return
        } else {
          return false
        }
      } else { //下一个
        existUp = true
        if (currentPlayerIndex === playerSize - 1 && pageNo < totalPage) {
          continueIndex = 0
          continuePage = pageNo + 1
          existNext = totalCount > (pageNo * pageCount + 1)
        } else if (currentPlayerIndex < playerSize - 1) {
          let nextIndex = currentPlayerIndex + 1
          let player = resultList[nextIndex];
          if (nextIndex === playerSize - 1) {
            existNext = pageNo < totalPage
          } else {
            existNext = true
          }
          this.playerDialogOption = {
            type: 1,
            playerId: player.id,
            index: nextIndex,
            existUp: existUp,
            existNext: existNext
          }
          return
        } else {
          return false
        }
      }
      let url = api.player.query.replace('{activityId}', this.activityId)
          .replace('{sortType}', this.selectedSortCondition.id).replace('{showType}', this.selectedShowCondition.id)
          .replace('{filterType}', this.selectedOtherCondition.id).replace('{searchKey}', this.searchKey)
          .replace('{pageNo}', continuePage).replace('{pageCount}', pageCount);
      if (this.currentGroupId) {
        url = url + '&groupId=' + this.currentGroupId;
      }
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        let playerList = data.resultList;
        if (playerList && playerList.length) {
          this.playersPage = data;
          this.pageNo = data.pageNo
          let currentPlayer = playerList[continueIndex];
          this.playerDialogOption = {
            type: 1,
            playerId: currentPlayer.id,
            index: continueIndex,
            existUp: existUp,
            existNext: existNext
          }
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      })
    },

    resetNum() {
      this.resetNumDailogVisible = true;
    }
    ,
    confirmResetNum() {
      this.resetNumDailogVisible = false;
      let req = {activityId: this.activityId, type: this.resetNumType};
      ajaxUtil.ajaxJson(api.player.resetNum, req, 'PUT', null, true).then(data => {
        messages.showSuccessMsg(messages.success.operation);
        ActivityUtil.refreshH5(false);
        this.queryPlayers(this.pageNo)
      })
    }
    ,
    downloadModel() {
      location.href = config.ossHost + 'public/admin/download/yuandian.zip';
    }
    ,
    closeOperationDialog() {
      this.selectedPlayer = '';
      this.operationType = 0;
      this.operationDialogTitle = '';
      this.operationDialogMsg = '';
    }
    ,
    confirmOperation() {
      this.operationDialogVisible = false;
      let ids = [this.selectedPlayer.id];
      if (this.operationType == 1) { //隐藏选手
        this.updatePlayerStatus(ids, this.operationType, false);
      } else { //锁定或删除选手
        this.updatePlayerStatus(ids, this.operationType, true);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.player-set {
  .el-dropdown-link:hover {
    cursor: pointer !important;
    color: #409eff;
  }

  .header {
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding-bottom: 15px;

      .col {
        display: flex;
        align-items: center;

        .el-button {
          margin-right: 10px;
          margin-left: 0;
        }
      }
    }
  }

  .list {
    margin-bottom: 20px;

    .el-dropdown {
      color: #909399;
    }

    .player {
      display: flex;
      justify-items: center;
      color: #a1a1a1;

      .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .avatar-clock, .avatar-hidden {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, .5);
          text-align: center;
          line-height: 42px;
        }
      }

      .info {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;

        .base {
          display: flex;

          .num {
            width: 60px;
          }

          .name {
            color: #000;
          }
        }
      }
    }

    .player:hover {
      cursor: pointer;
      color: #409eff;

      .info .base .name {
        color: #409eff;
      }
    }
  }

  .pagination-box {
    display: flex;
    justify-content: right;
  }

  .import-player {
    .upload-body {
      width: 300px;
      height: 130px;
      border: 1px dashed #d4dfeb;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #409eff;

      .tip {
        color: #999999;
      }
    }

    .operate {
      display: flex;
      justify-content: right;
    }
  }
}


.dialog-foot {
  text-align: center;

  .button {
    width: 98px;
  }
}

.gray-font {
  color: #999;
}

.dialog-title {
  font-size: 16px;
  color: #303133;
}

.dialog-playerinfo {
  padding-top: 3px;

  .playername {
    margin-top: 2px;
  }
}

.reset-player-num {
  .reset-tip {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    border: #409eff 1px solid;
    background: rgba(64, 158, 255, 0.1);
    color: #409eff;
  }

  .reset-type {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 30px 0;
    box-sizing: border-box;

    .title {
      width: 80px;
      text-align: left;
    }
  }

  .reset-btn {
    box-sizing: border-box;
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
