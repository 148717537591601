<template>
    <div v-if="dialogVisible">
      <el-dialog :title="title" :visible.sync="dialogVisible" class='bgimgDialog' :width="width">
        <div class='bgimg-content'>
          <div v-if="resourceLabels.length>0||canUpload" class='bgimg-header clear'>
            <el-tabs v-if="resourceLabels.length>0" v-model="currentLabelDes" class='fl' @tab-click="chooseQueryParam">
              <el-tab-pane v-for="(item,index) in resourceLabels" v-bind:label="item.des" v-bind:name="item.des"
                           :key="index"></el-tab-pane>
            </el-tabs>
            <div  class="upload-demo fr">
              <el-upload v-if="canUpload"
                         action="https://jsonplaceholder.typicode.com/posts/"
                         accept="image/png, image/jpeg"
                         :before-upload="handleBeforeUpload"
                         :http-request="uploadFile"
                         multiple
                         :limit="1"
                         :show-file-list = false>
                <el-button size="small" type="primary" :loading="isLoding">点击上传</el-button>
                <!--<el-progress v-if="progressShow" type="circle" :percentage="progressPercent">
                </el-progress>-->
              </el-upload>
            </div>
          </div>
          <!--活动分享图标-->
          <ul v-if='resourceType==1' class='shareimg-list clear scrollStyle'
              v-infinite-scroll="search" infinite-scroll-throttle-delay="500"
              infinite-scroll-disabled="busy" infinite-scroll-distance="5">
            <li class='cursorPointer' v-for="item in resultList" :key='item.id' @click="changeResource(item)"><img :src="item.url|appendImgUrl"></li>
          </ul>
          <!--轮播图-->
          <ul v-else-if='resourceType==2' class='swiperimg-list clear scrollStyle'
              v-infinite-scroll="search" infinite-scroll-throttle-delay="500"
              infinite-scroll-disabled="busy" infinite-scroll-distance="5">
            <li class='cursorPointer' v-for="item in resultList" :key='item.id' @click="changeResource(item)"><img :src="item.url|appendImgUrl"></li>
          </ul>
          <!--选手边框图-->
          <ul v-else-if="resourceType==3" class='border-img-list clear scrollStyle'
              v-infinite-scroll="search" infinite-scroll-throttle-delay="500"
              infinite-scroll-disabled="busy" infinite-scroll-distance="5">
            <li class='cursorPointer' v-for="item in resultList" :key='item.id' @click="changeResource(item)"><img :src="item.url|appendImgUrl"></li>
          </ul>
          <!--背景图-->
          <ul v-else-if='resourceType==4' class='bgimg-list clear scrollStyle'
              v-infinite-scroll="search" infinite-scroll-throttle-delay="500"
              infinite-scroll-disabled="busy" infinite-scroll-distance="5">
            <li class='cursorPointer' v-for="item in resultList" :key='item.id' @click="changeResource(item)"><img :src="item.url|appendImgUrl"></li>
          </ul>
          <!--背景音乐-->
          <ul v-else-if="resourceType==5" class='music-img-list clear scrollStyle'
              v-infinite-scroll="search" infinite-scroll-throttle-delay="500"
              infinite-scroll-disabled="busy" infinite-scroll-distance="5">
            <li class='cursorPointer' v-for="item in resultList" :key='item.id' @click="changeResource(item)">
              <div class='music-name'>
                <i class='el-icon-video-play music-icon'></i>
                <p>{{item.resourceName}}</p>
              </div>
              <button>使用</button>
            </li>
          </ul>
          <!--专题插图-->
          <ul v-else-if='resourceType==6' class='bgimg-list clear scrollStyle'
              v-infinite-scroll="search" infinite-scroll-throttle-delay="500"
              infinite-scroll-disabled="busy" infinite-scroll-distance="5">
            <li class='cursorPointer' v-for="item in resultList" :key='item.id' @click="changeResource(item)"><img :src="item.url|appendImgUrl"></li>
          </ul>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import {OssFileUploadUtil} from '@/js/ossFileUploadUtil'
    import {ajaxUtil} from '@/js/ajaxUtil'
    import {CommonUtil} from '@/js/commonUtil'
    import {api} from '@/js/api'
    import {messages} from '@/js/messages'
    import infiniteScroll from 'vue-infinite-scroll'
    import Vue from 'vue';

    export default {
        name: 'resourceDialog',
        components: {
            //ElProgress
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            width: {
                type: String,
                default: '1000px'
            },
            canUpload: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: ''
            },
            resourceType: {
                type: Number,
                default: 0
            },
            defaultOrderBy:{
              type: String,
              default: 'id-asc-unique'
            },
            urlKey:{
              type: Object,
              default: null
            },
            urlValue :{
              type: String,
              default: null
            }
        },
        data() {
            return {
                busy:false,
                resultList: [],
                progressShow: false,
                progressPercent: 0,
                file: null,
                isLoding:false,
                resourceLabels:[],
                currentLabel:null,
                currentLabelDes:null,
                queryParam:{
                  resourceType:null,
                  labelId:0,
                  needAggregation:false,
                  supportSearchAfter:true,
                  pageCount:20,
                  pagingType:1,
                  searchBeforeAfter:null,
                  resetOrderBy:null
                },
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.visible
                },
                set(val) {
                    this.$emit('update:visible', val)
                }
            }
        },
        mounted(){
            this.initResourceLabels();
            this.research();
        },
        watch: {
          'resourceType': {
            handler(newValue, oldValue) {
              this.initResourceLabels();
              this.research();
            },
          }
        },
        methods: {
            changeResource(resource){
              if(resource.url != this.urlValue){
                resource.resourceType = this.resourceType;
                if(resource.id){
                  //更新资源热度+1
                  ajaxUtil.ajaxJson(api.systemresource.incrementHot.replace('{id}',resource.id),null,'PUT');
                }
                this.$emit('changeResource', resource);
                Vue.nextTick(()=>{
                  this.$emit('update:visible', false);
                });
              }
            },
            initResourceLabels(){
              let labels = [];
              if (this.resourceType==1||this.resourceType==2||this.resourceType==3||this.resourceType==4){
                labels.push({des:'全部',value:0,name:'labelId'});
                labels.push({des:'热门',value:'hot-desc;id-desc-unique',name:'resetOrderBy'});
                labels.push({des:'最新',value:'id-desc-unique',name:'resetOrderBy'});
              }else if (this.resourceType==6){
                labels.push({des:'全部',value:0,name:'labelId'});
              }
              this.resourceLabels=labels;
              this.currentLabel=labels.length>0?labels[0]:null;
              this.currentLabelDes=labels.length>0?labels[0].des:null;
              if(this.resourceType==4||this.resourceType==6){
                //获取后台的分类标签
                ajaxUtil.ajaxJson(api.systemresource.labels.replace('{resourceType}',this.resourceType),null,'GET').then(data=>{
                  data.forEach(item=>{
                    labels.push({des:item.des,value:item.value,name:'labelId'});
                  })
                  this.resourceLabels=labels;
                })
              }
            },
            research(){
              this.queryParam.pagingType=1;
              this.search();
            },
            search() {
              this.busy=true;
              this.queryParam.resourceType=this.resourceType;
              if(this.currentLabel){
                this.queryParam.labelId=null;
                this.queryParam.resetOrderBy=null;
                this.queryParam[this.currentLabel.name]=this.currentLabel.value;
              }else {
                this.queryParam.labelId=null;
                this.queryParam.resetOrderBy=null;
              }
              if(!this.queryParam.resetOrderBy){
                this.queryParam.resetOrderBy=this.defaultOrderBy;
              }
              let bol=this.queryParam.pagingType==1;
              let paramStr=CommonUtil.getAllProperty(this.queryParam);
              ajaxUtil.ajaxJson(api.systemresource.query+'?'+paramStr, null, "GET").then(page => {
                if(paramStr==CommonUtil.getAllProperty(this.queryParam)){
                  if(page.resultList.length>0){
                    this.queryParam.pagingType=3;
                    this.queryParam.searchBeforeAfter = page.searchAfter;
                  }
                  if(bol){
                    this.resultList=page.resultList;
                  }else {
                    this.resultList.push(...page.resultList);
                    if(this.resultList.length==0){
                      this.queryParam.pagingType=1;
                      this.queryParam.searchBeforeAfter=null;
                    }
                  }
                  //若为最后一页,则设置为true,不再继续假装,防止一直触发无效的查询请求
                  //若不是最后一页,则设置为false,则可继续触发滚动加载事件
                  this.busy = page.resultList.length<page.pageCount;
                }else {
                  this.busy=false
                }
              },err=>{
                this.busy = false
              })
            },
            chooseQueryParam(tab) {
              this.currentLabel = this.resourceLabels[tab.index];
              this.currentLabelDes=this.currentLabel.des;
              this.research();
            },
            handleBeforeUpload(file) {
                //todo 上传文件之前的处理,如格式判断,文件大小限制等
                this.file = file;
                this.isLoding = true;
            },
            uploadFile(option) {
                this.progressShow = true;
                //上传文件
                OssFileUploadUtil.uploadImg(option.file, {
                    progress: (p, checkpoint) => {
                        // checkpoint参数用于记录上传进度，断点续传上传时将记录的checkpoint参数传入即可。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
                        console.log(p)
                        this.progressPercent = p * 100;
                    },
                    parallel: 4,
                    // 设置分片大小。默认值为1 MB，最小值为100 KB。
                    partSize: 1024 * 1024,
                }, (res) => {//成功回调
                    this.progressShow = false;
                    this.isLoding = false;
                    this.changeResource({url:res.name})
                }, (err) => {//失败回调
                    this.progressShow = false;
                    //错误提示
                    messages.showErrorMsg(messages.error.upload);
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
  $height: 450px;
    .bgimgDialog {
        ::v-deep .el-dialog__body {
            padding: 0 15px 20px !important
        }
        .bgimg-list {
            height: $height;
            overflow: auto;
            li {
                width: 120px;
                height: 160px;
                background: #eee;
                padding: 10px;
                float: left;
                margin-right: 20px;
                margin-bottom: 20px;
                border-radius: 5px;
                /*&:nth-of-type(4n){
                    margin-right:0;
                }*/
                img {
                    width: 100%;
                    height:100%;
                }
            }
        }
        .shareimg-list{
           height: $height;
            overflow: auto;
            li {
                width: 100px;
                height: 100px;
                background: #eee;
                padding: 10px;
                float: left;
                margin-right: 20px;
                margin-bottom: 20px;
                border-radius: 5px;
                /*&:nth-of-type(4n){
                    margin-right:0;
                }*/
                img {
                    width: 100%;
                    height:100%;
                }
            }
        }
        .swiperimg-list{
             height: $height;
            overflow: auto;
            li {
                width: 200px;
                height: 140px;
                background: #eee;
                padding: 10px;
                float: left;
                margin-right: 20px;
                margin-bottom: 20px;
                border-radius: 5px;
                /*&:nth-of-type(4n){
                    margin-right:0;
                }*/
                img {
                    width: 100%;
                    height:100%;
                }
            }
        }

        .border-img-list {
            height: $height;
            overflow: auto;
            li {
                width: 120px;
                height: 120px;
                background: #eee;
                float: left;
                margin-right: 20px;
                margin-bottom: 20px;
                padding: 10px;
                img {
                    width: 100%;
                    height: 100%
                }
            }
        }

        .music-img-list {
            height: $height;
            overflow: auto;
            background: #fff;

            li {
                width: 200px;
                height: 140px;
                float: left;
                margin-right: 15px;
                margin-bottom: 20px;
                background: #fff;
                border: 1px solid $globalTheme;

                .music-name {
                    width: 100%;
                    height: 110px;
                    text-align: center;
                    padding-top: 30px;
                    box-sizing: border-box;
                }

                button {
                    width: 100%;
                    height: 30px;
                    border: none;
                    background: $globalTheme;
                    color: #fff;
                }
                .music-icon {
                    color: $globalTheme;
                    font-size: 30px;
                }
            }
        }
    }
    .upload-demo{
        width: 500px;
        height: 54px;
        text-align: right;
        ::v-deep .el-upload-list{
            
        }
    }
</style>
