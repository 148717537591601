<template>
  <el-dialog :visible.sync="dialogVisible" :width="width" :close-on-click-modal="false"
             custom-class="previewDialog">
    <div class='act-publish-wrapper'>
      <div class='act-iframe fl'>
        <iframe :src='url' frameborder='0' height='100%' width='100%'></iframe>
      </div>
      <div class='act-indrouce fl'>
        <div class="act-name">
          <span>{{ title }}</span>
          <el-divider></el-divider>
        </div>
        <div class="act-qrcode">
          <div class='qrcode-wrapper'>
             <img :src="getQrCode()" alt="" class='code'>
             <div class='download' @click="toQrCode()">
                <p>下载二维码</p>
             </div>
          </div>
          <p style="margin-top: 10px">扫码参与{{ publishObj }}</p>
        </div>
        <div class="act-url">
          <div>本{{ publishObj }}网址</div>
          <div title="点击复制地址" class="wrapper" v-on:click="copyUrl">
            <el-input type="textarea" :rows="2" :readonly="true" :value="url" ref="url">
            </el-input>
          </div>
          <div>请将本网址放到公众号自定义菜单或微信文章的阅读原文处发布{{ publishObj }}</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {QrCode} from '../js/qrcodeUtil.js'
import {LocalStorageUtil} from '../js/auth'
import {messages} from '../js/messages'

export default {
  name: 'topicAct',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '800px'
    },
    title: {
      type: String,
      default: ''
    },
    publishObj: {
      type: String,
      default: '活动'
    },
    url: {
      type: String,
      default: ''
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    copyUrl() {
        this.$copyText(this.url).then(function (e) {
            messages.showSuccessMsg(messages.success.copy)
        }, function (e) {
            messages.showErrorMsg(messages.error.copy)
        })
    },
    getQrCode() {
      return QrCode.url(this.url, 300);
    },
    toQrCode() {
      LocalStorageUtil.storeString("activityH5Url", this.url);
      this.$router.push({name: 'Qrcode'});
    }
  }
}
</script>
<style lang="scss" scoped>
.act-publish-wrapper {
  overflow: hidden;
  width: 100%;
  height: 600px;

  .act-iframe {
    width: 330px;
    height: 100%;
    border: 1px solid #eee;
    overflow: hidden;
  }

  .act-indrouce {
    width: 51%;
    margin-left: 30px;
    height: 100%;
    position: relative;
    padding-top: 5%;
    .act-name {
      font-size: 16px;
      margin-bottom: 30px;
    }

    .act-qrcode {
      margin-top: 20px;
      width: 100%;
      height: 250px;
      color: #606266;
      text-align: center;
      .qrcode-wrapper{
         width: 200px;
         height: 200px;
         position: relative;
         background:#eee;
         display: inline-block;
        .code {
          width: 100%;
          height: 100%;
          cursor: pointer;
          position:relative;
        }
        .download{
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.7);
          position: absolute;
          top:0;
          left: 50%;
          transform: translateX(-50%);
          cursor: pointer;
          display: none;
          p{
            font-size: 14px;
            color:#fff;
            line-height: 200px;
          }
        }
        &:hover{
           .download{
             display: block;
           }
         }
      }
    }

    .act-url {
      margin: 20px 15px 0;

      .wrapper {
        margin-top: 6px;
        margin-bottom: 6px;
      }
    }
  }
}
</style>
