<template>
  <div class="edit-style-box" :style="{height:height+'px',width:width+'px'}">
    <p>{{ title}}</p>
    <div class='edit-style-list scrollStyle'>
      <ul>
        <li v-for="item in richTextModel" :key=item.id @click="chooseRichTextModel(item)">
          <div class='style-box-item'>
            <showRichText :richText="item.content"></showRichText>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {ajaxUtil} from '@/js/ajaxUtil'
import {api} from '@/js/api'
import showRichText from '@/components/showRichText.vue'

export default {
  name: 'editStylebox',
  data() {
    return {
      title: '',
      richTextModel: [],
    }
  },
  components: {
    showRichText
  },
  props: {
    height: {
      type: Number,
      default: 600
    },
    width: {
      type: Number,
      default: 285
    },
    richTextType: {//1:选手详情富文本模板,2:活动介绍富文本模板,3:动态模板
      type: Number,
      default: 2
    }
  },
  created() {
    switch (this.richTextType) {
      case 1:
        this.title = "选手详情模板"
        break;
      case 2:
        this.title = "活动介绍模板"
        break;
      case 3:
        this.title = "动态模板"
        break;
    }
    this.getRichTextModel();
  },
  methods: {
    getRichTextModel() {
      let url = api.richtextmodel.query + '?richTextType=' + this.richTextType;
      ajaxUtil.ajaxJson(url, null, 'GET').then(page => {
        console.log(page)
        this.richTextModel = page.resultList;
        this.$emit('afterInit', this.richTextModel);
      })
    },
    chooseRichTextModel(richText) {
      this.activeId = richText.id;
      this.$emit('chooseRichTextModel', richText.content)
    }
  }
}
</script>
<style lang="scss" scoped>
.edit-style-box {
  width: 285px;
  box-sizing: border-box;
  border: 1px solid #eee;
  background: #f9faff;
  overflow: hidden;
  position: relative;

  p {
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    background: #f9faff;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(238, 238, 238, 0.6);
  }

  .edit-style-list {
    width: 100%;
    height: 100%;
    overflow: auto;
    margin-top: 40px;
  }

  ul {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 20px 15px;

    li {
      width: 100%;
      height: 100px;
      border-radius: 4px;
      border: 1px solid #eee;
      margin-bottom: 20px;
      background: #fff;
      cursor: pointer;
      box-shadow: 2px 2px 2px #eee;
      box-sizing: border-box;
      padding: 10px;
      overflow: hidden;

      &:hover {
        border: 1px solid #409EFF
      }
    }

    .style-box-item {
      ::v-deep h2 {
        font-size: 14px !important;
        margin-bottom: 5px;

      }

      ::v-deep h1 {
        font-size: 14px !important;
        margin-bottom: 5px;
      }

      ::v-deep h3 {
        font-size: 14px !important;
        margin-bottom: 5px;
      }

      ::v-deep p {
        font-size: 12px;
        text-align: left;
      }
    }
  }
}
</style>
