const api = {
    systemresource: {
        query: '/api/systemresource/query',
        labels: '/api/systemresource/labels?resourceType={resourceType}',
        incrementHot: '/api/systemresource/hot?id={id}'
    },
    richtextmodel: {
        query: '/api/richtextmodel/query',
        // adds: '/api/richtextmodel/addList',
    },
    activitymodel: {
        query: '/api/activitymodel/query',
        labels: '/api/activitymodel/labels'
    },
    activity: {
        query: '/api/activity/query',
        add: '/api/activity/add',
        get: '/api/activity/get',
        update: '/api/activity/update',
        getStatics :'/api/activity/getActivityStatics?activityId={activityId}',
        defaultStyle: '/api/activity/default-style',
        updateVirtualData: '/api/activity/update-virtual-data',
        clearPlayerData: '/api/activity/clear-player-data',
        updateStatus: '/api/activity/update-status',
        queryForTopic: '/api/activity/queryForTopic?topicId={topicId}&status={status}',
        blacklist: {
            get: '/api/activity/blacklist/get?activityId={activityId}',
            update: '/api/activity/blacklist/update'
        }
    },
    activityNews: {
        query: '/api/activitynews/query?activityId={activityId}&showType={showType}',
        add: '/api/activitynews/add',
        update: '/api/activitynews/update',
        batchDelete: '/api/activitynews/batchDelete',
        updateShowStatus: '/api/activitynews/updateShowStatus',
        get: '/api/activitynews/{id}'
    },
    customer: {
        login: '/api/customer/login',
        register: '/api/customer/register',
        getLoginCustomerInfo: '/api/customer/getLoginCustomerInfo',
        resetPassword: '/api/customer/password',
        logout: '/api/customer/logout',
        getDetail: '/api/customer/getDetail?id={id}',
    },
    sms: {
        customerReg: '/api/sms/customer/reg',
        customerResetPassword: '/api/sms/customer/updatePassword',
    },
    systemnews: {
        allTypes: '/api/systemnews/type/getAll',
        query: '/api/systemnews/query?type={type}&searchKey={searchKey}&pageNo={pageNo}&pageCount={pageCount}',
        getById: '/api/systemnews/{id}',
    },
    topic: {
        query: '/api/topic/query?userId={userId}&pageNo={pageNo}&pageCount={pageCount}',
        add: '/api/topic/add',
        updateActivityShowStatus: '/api/topic/updateActivityShowStatus',
        removeActivities: '/api/topic/removeActivities',
        getById: '/api/topic/{id}',
        update: '/api/topic/update',
        deleteTopic: '/api/topic/{id}/delete',
        addActivities: '/api/topic/addActivities',
    },
    oss: {
        getTempToken: '/api/oss/getTempToken',
    },
    player: {
        groupList: '/api/player/group/list?activityId={activityId}',
        addGroup: '/api/player/group/add',
        updateGroup: '/api/player/group/update',
        deleteGroup: '/api/player/group/delete',
        groupSimpleList: '/api/player/group/simpleList?activityId={activityId}',
        updateVotingSetting: '/api/player/group/update-voting-setting',
        ranking: '/api/player/getRankingList?activityId={activityId}',
        query: '/api/player/list?activityId={activityId}&sortType={sortType}&showType={showType}&filterType={filterType}&searchKey={searchKey}&pageNo={pageNo}&pageCount={pageCount}',
        entryList: '/api/player/entry/list?activityId={activityId}&status={status}&searchKey={searchKey}&pageNo={pageNo}&pageCount={pageCount}',
        entryAll: '/api/player/entry/all?activityId={activityId}',
        entryExamine: '/api/player/entry/examine',
        getNextPlayerNum: '/api/player/getNextPlayerNum?activityId={activityId}',
        add: '/api/player/add',
        updateStatus: '/api/player/status',
        info: '/api/player/{id}',
        detail: '/api/player/detail?id={id}',
        update: '/api/player/update',
        resetNum: '/api/player/initNum',
        importExcel: '/api/player/importExcel?activityId={activityId}&groupId={groupId}&updateBy={updateBy}',
        log: '/api/player/log',
        groupSorts: '/api/player/group/sorts'
    },
    area: {
        getAllProvinces: '/api/area/getAllProvinces',
        getCitiesByProvince: '/api/area/getCitiesByProvince?provinceCode={provinceCode}'
    },
    statistics: {
        getActivityVoteStatistics: '/api/statistics/getActivityVoteStatistics?activityId={activityId}&day={day}',
        getActivityViewStatistics: '/api/statistics/getActivityViewStatistics?activityId={activityId}&day={day}',
        getPlayerVoteStatistics: '/api/statistics/getPlayerVoteStatistics?playerId={playerId}&day={day}',
        getPlayerViewStatistics: '/api/statistics/getPlayerViewStatistics?playerId={playerId}&day={day}',
        getVoteLogList: '/api/statistics/getVoteLogList',
    },
    graphCode: '/api/graphCode?code={code}',
    screen: {
        getScreenAct:'',
        getScreenPlayer:'',
    }
};

export {api}
