<template>
  <div class="act-bottom">
    <el-form hide-required-asterisk ref="bottomForm" label-position="left" label-width="100px"
             :model="activity.settingShowBottom">
      <el-form-item label="底部版权">
        <div class="copyright-radio">
          <el-radio-group v-model="activity.settingShowBottom.copyrightStatus.index"
                          @change="saveCopyrightEvent">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="2">隐藏</el-radio>
            <el-radio :label="3">自定义</el-radio>
          </el-radio-group>
        </div>
        <div class="copyright-custom" v-if="activity.settingShowBottom.copyrightStatus.index===3">
          <el-form-item label="版权信息">
            <el-input v-model.trim="activity.settingShowBottom.copyrightCustomName"
                      minlength="1" maxlength="50" placeholder="请输入版权信息"
                      @change="saveCopyrightEvent"></el-input>
          </el-form-item>
          <el-form-item label="链接网址">
            <el-input v-model.trim="activity.settingShowBottom.copyrightCustomUrl"
                      maxlength="254" placeholder="请输入链接网址"
                      @change="saveCopyrightEvent"></el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="底部菜单">
        <div class="nav-list" id="navList">
          <div class="nav-item" v-for="(item,index) in activity.navList" :key="index"
               v-dragging="{ item: item, list: activity.navList, group: 'item'}">
            <el-checkbox v-model="item.hasShow" :disabled="item.disabled" @change="bindNavShowChange(item)">
            </el-checkbox>
            <div :class="{'item-name':true,'font-b':item.hasShow,'font-h':item.disabled}">
              <el-input v-if="item.edit" :ref="'nav_'+item.code" v-focus minlength="1" maxlength="200"
                        v-model.trim="item.name" @blur="editNavNameEvent(item,false)">
              </el-input>
              <div v-else class="blur" @click="editNavNameEvent(item,true)">
                <span v-if="item.code==='custom'">自定义</span>
                <span v-else>{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-custom" v-if="navCustom.hasShow">
          <el-form-item label="菜单图标">
            <div class="custom-upload">
              <el-upload action="#"
                         accept="image/png, image/jpeg"
                         :before-upload="handleBeforeUpload"
                         :http-request="uploadFile"
                         :limit="1"
                         :show-file-list=false>
                <div class="nav-icon">
                  <img v-if="navCustom.uicon" :src="navCustom.uicon|appendImgUrl">
                  <div v-else class="icon-def"><i class="el-icon-plus"></i></div>
                </div>
              </el-upload>
              <el-button class="nav-del" type="text" @click="bindNavDelIconChange">默认图标</el-button>
            </div>
          </el-form-item>
          <el-form-item label="菜单名称">
            <el-input v-model.trim="navCustom.name" maxlength="6"
                      placeholder="自定义菜单名称"
                      @change="saveNavEvent"></el-input>
          </el-form-item>
          <el-form-item label="链接网址">
            <el-input v-model.trim="navCustom.link"
                      maxlength="254" placeholder="自定义菜单地址"
                      @change="saveNavEvent"></el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="设置首页">
        <div class='home-list' style='margin-top:0'>
          <div class="home-item" v-for="(item,index) in activity.navList" :key="index" v-show="homeFilter(item)">
            <el-radio v-model="navCode" :disabled="!item.hasShow" :label="item.code" @input="saveNavEvent">
              {{ item.name }}
            </el-radio>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {ActivityUtil} from "@/js/commonUtil"
import {messages} from "@/js/messages";
import {OssFileUploadUtil} from "@/js/ossFileUploadUtil";

export default {
  name: 'menuSet',
  data() {
    return {
      navCustom: {},
      navCode: '',
      oldData: '',
      isLoding: false,
      activity: {
        id: 0,
        settingShowBottom: {
          copyrightStatus: {
            index: 1
          },
          copyrightCustomName: null,
          copyrightCustomUrl: null,
        },
        navList: []
      },
    }
  },
  beforeMount() {
    this.getActivity();
  },
  mounted() {
    this.$dragging.$on('dragend', (v) => {
      this.saveNavEvent()
    })
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.querySelector("input").focus()
      }
    }
  },
  methods: {
    getActivity() {
      ActivityUtil.get(ActivityUtil.getCurrentSettingActId(), data => {
        let navList = data.navList;
        for (let i = 0; i < navList.length; i++) {
          let {code, hasHome} = navList[i];
          //补充字段
          navList[i].disabled = false
          navList[i].edit = false
          if (code === 'custom') {
            this.navCustom = navList[i]
          }
          if (hasHome) {
            this.navCode = code
          }
        }
        this.activity = data
        this.navCountFilter()
        this.oldData = JSON.stringify(this.activity)
      })
    },
    saveCopyrightEvent() {
      let {copyrightStatus, copyrightCustomName} = this.activity.settingShowBottom
      //从非自定义版权,更改为自定义版权的时候,若未填写自定义版权名称,则不予提交
      if (copyrightStatus.index === 3 && !copyrightCustomName) {
        messages.showErrorMsg('请输入版权信息');
        return false;
      }
      this.$refs["bottomForm"].validate(valid => {
        if (valid) {
          ActivityUtil.update(this.activity, ['settingShowBottom'], null, () => {
            ActivityUtil.refreshH5();
          })
        }
      })
    },

    //导航
    bindNavShowChange(item) {
      let {code, hasShow, hasHome} = item
      if (!hasShow) {
        if (code === 'vote') {
          item.hasShow = true
          messages.showErrorMsg('不能关闭投票页面');
          return false;
        }
        if (hasHome) {
          this.navCode = 'vote'
        }
      }
      this.navCountFilter()
      this.saveNavEvent()
    },
    navCountFilter() {
      let {navList, settingEntry} = this.activity;
      let hideList = [];
      let showCount = 0;
      for (let i = 0; i < navList.length; i++) {
        let {hasShow, code} = navList[i];
        if (code === 'entry' && !settingEntry.status) {
          navList[i].disabled = true
          continue;
        }
        if (hasShow) {
          showCount += 1
        } else {
          hideList.push(navList[i])
        }
      }
      for (let i = 0; i < hideList.length; i++) {
        hideList[i].disabled = showCount > 4
      }
    },
    editNavNameEvent(item, edit) {
      item.edit = edit
      if (!edit) {
        this.saveNavEvent()
      }
    },
    handleBeforeUpload(file) {
      //todo 上传文件之前的处理,如格式判断,文件大小限制等
      this.file = file;
      this.isLoding = true;
    },
    uploadFile(option) {

      //上传文件
      OssFileUploadUtil.uploadImg(option.file, {
        progress: (p, checkpoint) => {
          // checkpoint参数用于记录上传进度，断点续传上传时将记录的checkpoint参数传入即可。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
          console.log(p)
          this.progressPercent = p * 100;
        },
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
      }, (res) => {//成功回调
        this.isLoding = false;
        this.navCustom.uicon = res.name
        this.saveNavEvent()
      }, (err) => {//失败回调
        //错误提示
        messages.showErrorMsg(messages.error.upload);
      });
    },
    bindNavDelIconChange() {
      let uicon = this.navCustom.uicon;
      if (uicon) {
        this.navCustom.uicon = ''
        this.saveNavEvent()
      }
    },
    homeFilter(item) {
      const showList = ['blurb', 'vote', 'rank', 'entry']
      let code = item.code;
      return showList.indexOf(code) > -1;
    },
    saveNavEvent() {
      //验证数据
      let navCode = this.navCode;
      let activity = this.activity;
      let navList = activity.navList;
      for (let i = 0; i < navList.length; i++) {
        let {name, code, hasShow} = navList[i];
        if (!code) {
          return false
        }
        if (!name || name.length > 200) {
          messages.showErrorMsg('请输入有效菜单名称（1~200个字符）');
          return false;
        }
        if (code === navCode) {
          if (!hasShow) {
            navList[i].navCode = 'vote'
          }
          navList[i].hasHome = true
        } else {
          navList[i].hasHome = false
        }
        //投票页关闭
        if (code === 'vote') {
          if (!navCode) {
            navList[i].hasHome = true
          }
          if (!hasShow) {
            messages.showErrorMsg('不能关闭投票页面');
            return false;
          }
        }

      }
      const newData = JSON.stringify(activity)
      //不用更新

      if (this.oldData === newData) {
        return false
      }
      this.$refs["bottomForm"].validate(valid => {
        if (valid) {
          ActivityUtil.update(activity, ['navList'], null, () => {
            this.oldData = JSON.stringify(activity)
            ActivityUtil.refreshH5();
          })
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.act-bottom {
  .el-input {
    width: 600px;
  }

  .copyright-custom {
    margin-top: 20px;

    .el-form-item {
      margin-top: 10px;
    }
  }

  .nav-list {
    display: flex;
    align-items: center;

    .nav-item {
      display: flex;
      align-items: center;

      .item-name {
        color: #606266;
      }

      .font-b {
        color: #409eff;
      }

      .font-h {
        color: #c0c4cc;
      }

      .el-input {
        width: 120px;
        padding: 0 10px;
      }

      .blur {
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        width: 90px;
      }
    }
  }

  .nav-custom {
    margin-top: 20px;

    .el-form-item {
      margin-top: 10px;
    }

    .custom-upload {
      box-sizing: border-box;
      display: flex;
      align-items: center;

      ::v-deep .el-upload {
        display: block;
      }

      .nav-icon {
        position: relative;

        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 5px;


        img {
          display: block;
          width: 100%;
          height: 100%;

        }

        .icon-def {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          box-sizing: border-box;
          border: #409eff 1px dashed;
        }
      }

      .nav-icon:hover:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        content: "设置图片";
      }

      .nav-del {
        margin-left: 20px;
      }
    }


  }

  .home-list {
    display: flex;
    align-items: center;

    .home-item {
      width: 104px;
    }
  }


  .template-img {
    width: 120px;
    height: 180px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .tp-img-active {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(48, 38, 38, 0.5);
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 180px;
      cursor: pointer;
    }
  }

  .border-img {
    width: 120px;
    height: 120px;
    background: #eee;
    border-radius: 10px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    .border-img-active {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(48, 38, 38, 0.5);
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 120px;
      cursor: pointer;
      border-radius: 10px;
    }
  }

  .music-box {
    width: 120px;
    height: 40px;
    border-radius: 5px;
    background: #eee;
    color: #999;
    text-align: center;
    position: relative;

    .music-box-active {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      border-radius: 5px;
      color: #fff;
    }
  }

  .color-wrapper {
    li {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      float: left;
      margin-right: 5px;
      cursor: pointer;
    }
  }

  .defalutbtn {
    margin-left: 30px;
  }

  //弹出框样式
  .bgimgDialog {
    ::v-deep .el-dialog__body {
      padding: 0 15px 20px !important
    }

    .bgimg-list {
      height: 430px;
      overflow: auto;

      li {
        width: 100px;
        height: 120px;
        background: #eee;
        padding: 15px;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;

        img {
          width: 100px;
          height: 120px
        }
      }
    }

    .border-img-list {
      height: 430px;
      overflow: auto;
      background: #eee;

      li {
        width: 120px;
        height: 120px;
        background: #fff;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;

        img {
          width: 100%;
          height: 100%
        }
      }
    }

    .music-img-list {
      height: 430px;
      overflow: auto;
      background: #fff;

      li {
        width: 200px;
        height: 140px;
        float: left;
        margin-right: 15px;
        margin-bottom: 20px;
        background: #fff;
        border: 1px solid $globalTheme;

        .music-name {
          width: 100%;
          height: 110px;
          text-align: center;
          padding-top: 30px;
          box-sizing: border-box;
        }

        button {
          width: 100%;
          height: 30px;
          border: none;
          background: $globalTheme;
          color: #fff;
        }

        .music-icon {
          color: $globalTheme;
          font-size: 30px;
        }
      }
    }
  }

  .checkbox {
    padding-right: 40px;
    float: left;
  }

  .help-votes-box {
    margin-top: 20px;

    .box-name {
      margin-right: 20px;
    }

    .radio {
      padding-right: 10px;
    }
  }
}
</style>
