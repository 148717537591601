<template>
  <div class="app-header">
    <div class="hd-body">
      <div class="hd-left">
        <img src="../assets/logo-black.png" alt="">
      </div>
      <div class="hd-middle">
        <el-menu :default-active="activeMenu" active-text-color="#409eff" mode="horizontal" router>
          <el-menu-item :index=item.path v-for='(item,idx) in topNav' :key='idx' :route=item.path>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="hd-right">
        <div v-if="state.user.id" class="create-activity" @click="clickAddActivity"></div>
        <div class="user-vip">
          <img src="../assets/user/hd-vip.png" alt="">
          <div>会员</div>
        </div>
        <div class="sys-msg">
          <i class="el-icon-bell"></i>
          <div>消息</div>
        </div>
        <el-dropdown class="user-info" placement="bottom">
          <div>
            <el-avatar :size="40" :src="state.user.wxAvatar" v-if='state.user.wxAvatar'></el-avatar>
            <el-avatar icon="el-icon-user-solid" v-else></el-avatar>
          </div>
          <el-dropdown-menu slot="dropdown" style="width: 150px;padding: 5px;">
            <div style="display: flex;justify-content: space-between;align-items: center;padding:10px 5px;">
              <div>
                <el-avatar :size="40" :src="state.user.wxAvatar" v-if='state.user.wxAvatar'></el-avatar>
                <el-avatar icon="el-icon-user-solid" v-else></el-avatar>
              </div>
              <div>
                <div>{{ state.user.phone }}</div>
                <div></div>
              </div>
            </div>
            <el-dropdown-item icon="el-icon-lock" v-if="state.user.id" @click.native="clickResetPwd">设置密码
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-warning-outline" @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="reSetPwdDialog" :modal-append-to-body='false'
               style="text-align:center;width:50%;margin: auto" :close-on-click-modal="false">
      <el-form hide-required-asterisk ref="forgetForm" label-position="left" :model="forgetFormData"
               :rules="rulesForGet" style="text-align:left;">
        <el-form-item label="手机号" :label-width="reSetFromWidth">
          <el-input v-model="forgetFormData.phone" autocomplete="off" style="width: 90%;"
                    :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="reSetFromWidth" prop="password">
          <el-input v-model="forgetFormData.password"
                    ref="password"
                    name="password"
                    placeholder="新密码" show-password autocomplete="off" style="width: 90%" minlength="6"
                    maxlength="15"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" :label-width="reSetFromWidth" prop="againPassword">
          <el-input v-model="forgetFormData.againPassword"
                    ref="againPassword"
                    name="againPassword"
                    placeholder="确认新密码" show-password autocomplete="off" style="width: 90%" minlength="6"
                    maxlength="15"></el-input>
        </el-form-item>
        <el-form-item label="验证码" :label-width="reSetFromWidth" prop="smsValidateCode">
          <el-input
              placeholder="请输入验证码"
              v-model.trim="forgetFormData.smsValidateCode"
              ref="smsValidateCode"
              name="smsValidateCode"
              @input="val => forgetFormData.smsValidateCode = val.replace(/[^0-9]/g, '')"
              maxlength="4" autocomplete="off" style="width: 90%">
            <el-button slot="append" style="color: #409eff;background-color: #ffffff;"
                       @click="forGetSendCode">{{ forgetCodeBtnStr }}
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align:center;margin-bottom: 30px;">
        <el-button type="primary" @click="handleForGet" :loading="loading" style="width:40%">确&nbsp;&nbsp;定
        </el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import {config} from '@/js/config'
import store from '@/store/store'
import {ajaxUtil} from '@/js/ajaxUtil'
import {api} from '@/js/api'
import {MD5Util} from '@/js/MD5Util'
import {encode} from '@/js/aesencryp'
import {messages} from '@/js/messages'
import {AuthUtil} from '@/js/auth'
import {CommonUtil, ActivityUtil} from '@/js/commonUtil'

export default {
  name: 'Header',
  data() {
    let againPasswordRule = (rule, value, callback) => {
      if (value !== this.forgetFormData.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      state: store.state,
      topNav: [
        {name: '用户中心', path: '/usercenter', iconStyle: 'icon-de-home', id: 1},
        {name: '活动管理', path: '/activity', iconStyle: 'icon-de-calendar', id: 2},
        {name: '活动模板', path: '/activitymodel', iconStyle: 'icon-de-layout', id: 3},
        {name: '专题活动', path: '/topic', iconStyle: 'icon-de-flag', id: 4},
        {name: '定制服务', path: '/customize', iconStyle: 'icon-de-pencil', id: 5},
        {name: '帮助中心', path: '/help', iconStyle: 'icon-de-customer', id: 6},
      ],

      avatarUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      reSetPwdDialog: false,
      reSetFromWidth: "100px",
      forgetCodeBtnStr: "获取验证码",//23s后获取
      forGetTimeClock: "",
      forgetFormData: {
        phone: '',
        password: '',
        againPassword: '',
        smsValidateCode: "",
      },
      loading: false,
      actName: '',
      rulesForGet: {
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 15, message: '请输入6~15位密码', trigger: 'blur'}],
        againPassword: [
          {required: true, message: '请输入确认密码', trigger: 'blur'},
          {min: 6, max: 15, message: '请输入6~15位密码', trigger: 'blur'},
          {validator: againPasswordRule, trigger: 'blur'}],
        smsValidateCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {min: 4, max: 4, message: '验证码必须为4位', trigger: 'blur'}],
      }
    }
  },
  computed: {
    activeMenu() {
      //根据当前的一级路径,匹配对应的一级菜单,若匹配成功,则更新currentRoute,并返回对应的一级菜单路径
      let menu = this.findMenu();
      if (menu) {
        store.setCurrentRoute(menu.path, this.$route.name)
        return menu.path;
      }
      //若匹配失败,则返回原来的一级菜单路径
      return store.state.currentRoute.firstPath;
    }
  },
  created() {
    this.forgetFormData = {
      phone: this.state.user.phone,
      password: '',
      againPassword: '',
      smsValidateCode: "",
    }
  },
  methods: {
    //根据一级路径来匹配对应的一级菜单
    findMenu(path) {
      if (!path) {
        path = '/' + this.$route.path.substring(1).split('/')[0];
      }
      if (path == '/activity') {
        path = '/activity';
      }
      for (let i = 0; i < this.topNav.length; i++) {
        if (this.topNav[i].path == path) {
          return this.topNav[i];
        }
      }
      return null;
    },
    //点击下拉中重置密码，打开重置密码弹出框
    clickResetPwd() {
      this.forgetFormData = {
        phone: this.state.user.phone,
        password: '',
        againPassword: '',
        smsValidateCode: "",
      }
      this.reSetPwdDialog = true;
      if (this.$refs.forgetForm !== undefined) {
        this.$refs.forgetForm.clearValidate();
      }
    },

    logout() {
      ajaxUtil.ajaxJson(api.customer.logout, null, 'PUT').then(data => {
        store.removeToken();
        store.removeUser();
        this.$router.push('/login');
      }, (data) => {
        store.removeToken();
        store.removeUser();
        this.$router.push('/login');
      })
    },

    forGetSendCode() {
      if (this.forGetTimeClock !== "") {
        return;
      }
      if (this.forgetFormData.password === '') {
        this.$refs.forgetForm.validateField('password');
        return;
      } else if (this.forgetFormData.againPassword === '') {
        this.$refs.forgetForm.validateField('againPassword');
        return;
      } else if (this.forgetFormData.password !== this.forgetFormData.againPassword) {
        this.$refs.forgetForm.validateField('againPassword', function () {
          return "两次输入密码不一致"
        })
        return;
      }

      let resetUuid = AuthUtil.getSpecifyUuid("resetpwd");
      let validateToken = encode(resetUuid);
      let sendCodeRequest = {phone: this.forgetFormData.phone, validateToken: validateToken}
      ajaxUtil.ajaxJson(api.sms.customerResetPassword, sendCodeRequest, 'POST').then(() => {
        messages.showSuccessMsg(messages.success.validateCodeSend)
        let timer_num = 60;
        let this_ = this;
        this.forGetTimeClock = setInterval(function () {
          timer_num--;
          this_.forgetCodeBtnStr = timer_num + "s后获取";
          if (timer_num === 0) {
            clearInterval(this_.forGetTimeClock);
            this_.forGetTimeClock = "";
            this_.forgetCodeBtnStr = "获取验证码";
          }
        }, 1000)
      }, (error) => {
        messages.showErrorMsg(messages.error.validateCodeSend)
      })

    },
    handleForGet() {
      this.$refs.forgetForm.validate(valid => {
        if (!valid) {
          return false
        } else {
          this.loading = true;
          let resetData = CommonUtil.copyObject(this.forgetFormData);
          resetData.password = MD5Util.hex_md5(resetData.password)
          ajaxUtil.ajaxJson(api.customer.resetPassword, resetData, 'PUT').then(data => {
            this.loading = false;

            //成功提示并隐藏弹出框
            messages.showSuccessMsg(messages.success.reset)
            this.reSetPwdDialog = false;
          }, (err) => {
            this.loading = false;
            var errorMsg = (err == null || err.data == null) ? null : messages.getMsgByCode(err.data.errorCode);
            errorMsg = errorMsg != null ? errorMsg : messages.error.reset;
            messages.showErrorMsg(errorMsg);
          })
        }
      })
    },


    //点击创建活动
    clickAddActivity() {
      this.$router.push({path: '/activitymodel'})
    },
    clickCreateTopic() {
      let addData = {createBy: store.state.user.id}
      ajaxUtil.ajaxJson(api.topic.add, addData, 'POST').then((data) => {
        //若成功，则跳转专题编辑页面
        messages.showSuccessMsg(messages.success.topicAdd);
        this.$router.push({path: '/topicEdit', query: {id: data.id}})
      }, (err) => {
        messages.showErrorMsg(messages.getMsgByCode(err.data.errorCode));
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.app-header {
  z-index: 2000;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;

  .hd-body {
    width: 72%;
    min-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    .hd-left {
      width: 180px;

      img {
        width: 120px;
      }
    }

    .hd-middle {
      width: 800px;

      .el-menu.el-menu--horizontal {
        border: none;
      }

      .el-menu-item {
        font-size: 16px;
      }

      .el-menu--horizontal > .el-menu-item {
        height: 70px;
        line-height: 70px;
      }
    }

    .hd-right {
      width: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .create-activity {
        background-image: url(../assets/user/hd-create.png);
        background-size: cover;
        background-repeat: no-repeat;
        width: 121px;
        height: 36px;
        cursor: pointer;
      }

      .create-activity:hover {
        opacity: 0.8;
      }

      .user-vip {
        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .sys-msg {
        cursor: pointer;

        i {
          font-size: 24px;
          color: #409EFF;
          font-weight: bolder;
          margin-bottom: 2px;
        }
      }

      .user-info {
        cursor: pointer;
      }
    }
  }
}

</style>
