<template>
  <div class="player-entry">
    <div class="tip">
      <i class="el-icon-chat-line-round"></i> 应相关部门要求，报名选手必须符合相关规定且需主办方审核，否则造成违法行为由主办方负责
    </div>
    <div class="header">
      <div class="row">
        <div class="col">
          <el-input style="width: 180px;margin-right: 10px;" size='small' v-model="searchKey"
                    placeholder="选手名称" :clearable="true"
                    @keyup.enter.native="onSearchKeyChange"
                    @change="onSearchKeyChange"></el-input>
          <el-button type="primary" plain size='small' @click='exportEntryData'>导出报名</el-button>
        </div>
        <div class="col">
          <el-dropdown style="width: 120px;" trigger="click" @command="bindStatusChange">
            <span class="el-dropdown-link">
                {{ selectedStatus.name }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in statusConditions" v-bind:key="item.id" :command="item">
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="list">
      <el-table ref="multipleTable" :data="entryPage.resultList" tooltip-effect="dark"
                @selection-change="handleSelectionChange"
                @select-all="()=>this.entryPage.resultList.length<=0?this.$refs.multipleTable.clearSelection():''">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column>
          <template slot="header">
            <el-dropdown style="width: 100px;" trigger="click" @command="bindMultiChange"
                         :disabled="!selectedEntryIds.length">
             <span class="el-dropdown-link">
                 批量操作
               <i class="el-icon-arrow-down el-icon--right"></i>
             </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index) in multiOptions" v-bind:key="index" :command="item">
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-slot="{row,$index}">
            <div class="player" @click="bindEntryChange($index)">
              <div class="info">
                <div class="name">{{ row.name }}</div>
                <div class="blurb">{{ row.blurb }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="voteNum" label="审核状态" width='120'>
          <template v-slot="{row}">
            <span v-if="row.status===1">已通过</span>
            <span v-else-if="row.status===2">未通过</span>
            <span v-else-if="row.status===3">已禁用</span>
            <span v-else>待审核</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="报名时间" width='250'>
          <template v-slot="{row}">
            {{ row.createTime|convertTime }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width='100'>
          <template v-slot="{row}">
            <el-dropdown trigger="click" placement="bottom">
                    <span class="el-dropdown-link">
                      <i class="el-icon-more"></i>
                    </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="bindEntryChange(row)">
                  详情
                </el-dropdown-item>
                <el-dropdown-item divided @click.native="updateEntryStatus([row.id],1)">
                  通过
                </el-dropdown-item>
                <el-dropdown-item @click.native="updateEntryStatus([row.id],2)">
                  拒绝
                </el-dropdown-item>
                <el-dropdown-item @click.native="updateEntryStatus([row.id],3)">
                  封禁
                </el-dropdown-item>
                <el-dropdown-item divided @click.native="updateEntryStatus([row.id],4)">
                  删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class='pagination-box'>
      <el-pagination background layout="total, prev, pager, next" :page-size="entryPage.pageCount"
                     :total="entryPage.totalCount" :current-page="entryPage.pageNo"
                     @current-change="pageChange">
      </el-pagination>
    </div>

    <!--报名详情 -->
    <el-dialog title="报名详情" class="info" :visible.sync="showEntryInfo" width="800px"
               :close-on-click-modal="false" @close="showEntryInfo=false">
      <div v-for="(value,index) in currentEntry.customForm" :key="index">
        <template v-if="value[0]==='group'">
          <div class="form-row" v-if="playerGroups.length">
            <div class="row-label">
              {{ value[1] }}
            </div>
            <div class="row-content">
              <div>{{ groupFilter(Number(value[5])) }}</div>
            </div>
          </div>
        </template>
        <template v-else-if="value[0]==='name'">
          <div class="form-row">
            <div class="row-label">{{ value[1] }}</div>
            <div class="row-content">
              <div>{{ value[5] }}</div>
            </div>
          </div>
        </template>
        <template v-else-if="value[0]==='blurb'">
          <div class="form-row">
            <div class="row-label">{{ value[1] }}</div>
            <div class="row-content">
              <div>{{ value[5] }}</div>
            </div>
          </div>
        </template>
        <template v-else-if="value[0]==='custom'">
          <div class="form-row" v-if="value[3]!==2">
            <div class="row-label">{{ value[1] }}</div>
            <div class="row-content">
              <div>{{ value[5] }}</div>
            </div>
          </div>
        </template>
        <template v-else-if="value[0]==='video'">
          <div class="form-row" v-if="value[3]!==2">
            <div class="row-label">{{ value[1] }}</div>
            <div class="row-content">
              <!--代码-->
              <div class="row-video" v-html="value[5]"></div>
            </div>
          </div>
        </template>
        <template v-else-if="value[0]==='image'">
          <div class="form-row" v-if="value[3]!==2">
            <div class="row-label">{{ value[1] }}</div>
            <div class="row-content" style="display: flex">
              <img class="row-img" v-for="item in value[5]" :src="item|appendImgUrl" :key="item" alt="">
            </div>
          </div>
        </template>
      </div>
      <el-row class="form-foot">
        <el-button type="warning" @click="updateEntryStatus([currentEntry.id],3)">
          封禁
        </el-button>
        <el-button type="danger" @click="updateEntryStatus([currentEntry.id],2)">
          拒绝
        </el-button>
        <el-button type="primary" @click="updateEntryStatus([currentEntry.id],1)">
          通过
        </el-button>
        <el-button @click="showEntryInfo = false">关 闭</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {api} from '@/js/api'
import {ajaxUtil} from '@/js/ajaxUtil'
import {messages} from '@/js/messages'
import {ActivityUtil} from '@/js/commonUtil'
import store from "@/store/store";
import * as XLSX from 'xlsx';
import moment from "moment";

export default {
  name: 'raffleList',
  data() {
    return {
      activityId: ActivityUtil.getCurrentSettingActId(),
      searchKey: '',
      statusConditions: [
        {id: '', name: '全部状态'},
        {id: 0, name: '待审核'},
        {id: 1, name: '已通过'},
        {id: 2, name: '已拒绝'},
        {id: 3, name: '已封禁'}],
      selectedStatus: {id: '', name: '全部状态'},
      pageNo: 1, //当前页码
      pageCount: 10, //每页显示的专题数量，固定为10
      entryPage: {pageNo: 1, pageCount: 10, totalCount: 0, totalPage: 0, resultList: []},
      currentPlayer: 0,
      selectedEntryIds: [],
      playerDialogOption: {},
      batchImportPlayerDialog: false,
      playerGroups: [],
      groupOptions: [{value: 0, des: '全部'}],
      currentGroupId: 0,
      importGroupId: 0,
      currentEntry: {}, //单选操作选中的选手
      showEntryInfo: false, //单选操作的弹出
      multiOptions: [
        {value: 1, name: '通过'},
        {value: 2, name: '拒绝'},
        {value: 3, name: '封禁'},
        {value: 4, name: '删除'},
      ]
    }
  },

  beforeMount() {
    let {activityId, actName} = this.$route.query;
    if (activityId) {
      this.activityId = activityId
    }
    if (actName) {
      this.actName = actName
    }
    this.showEntryInfo = false;
    this.resetNumDailogVisible = false;
    this.getPlayerGroups();
    this.searchKey = '';
    this.pageNo = 1;
    this.getEntryList(this.pageNo);

  },
  methods: {
    //查询选手
    getEntryList(pageNo) {
      let url = api.player.entryList.replace('{activityId}', this.activityId)
          .replace('{status}', this.selectedStatus.id).replace('{searchKey}', this.searchKey)
          .replace('{pageNo}', pageNo).replace('{pageCount}', this.pageCount);
      if (this.currentGroupId) {
        url = url + '&groupId=' + this.currentGroupId;
      }
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        this.pageNo = this.pageNo > data.totalPage ? data.pageNo : this.pageNo;
        this.entryPage = data;
        if (data.totalCount == 0) {
          this.pageVisible = false;
        } else {
          if (data.resultList == null || data.resultList.length == 0) {
            this.pageNo = pageNo - 1;
            this.getEntryList(this.pageNo);
          } else {
            this.pageVisible = true;
          }
        }
        if (this.entryPage.resultList.length <= 0) {
          this.$refs.multipleTable.clearSelection();
        }
      })
    },
    getPlayerGroups() {
      let url = api.player.groupSimpleList.replace('{activityId}', ActivityUtil.getCurrentSettingActId());
      ajaxUtil.ajaxPage(url, null, 'GET', null, data => {
        if (data.length) {
          //导入
          this.playerGroups = data
          this.importGroupId = data[0].value;
          //选择
          if (data.length > 1) {
            for (let i = 0; i < data.length; i++) {
              this.groupOptions.push(data[i])
            }
          }
        }
      })
    },
    groupFilter(val) {
      let groups = this.playerGroups;
      for (let i = 0; i < groups.length; i++) {
        let {value, des} = groups[i];
        if (val === value) {
          return des
        }
      }
      return ''
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      this.getEntryList(this.pageNo);
    },

    bindMultiChange(item) {
      let ids = this.selectedEntryIds;
      if (!ids || !ids.length) {
        messages.warning('请选择选手')
        return
      }
      this.updateEntryStatus(ids, item.value)
    },

    bindStatusChange(item) {
      this.selectedStatus = item;
      this.pageNo = 1;
      this.getEntryList(this.pageNo);
    },

    //搜索输入变化处理
    onSearchKeyChange() {
      this.pageNo = 1;
      this.getEntryList(this.pageNo);
    },

    //列表多选框改变事件处理
    handleSelectionChange(val) {
      this.selectedEntryIds = [];
      for (const i in val) {
        this.selectedEntryIds.push(val[i].id);
      }
    },

    //点击新增选手
    addPlayer() {
      this.playerDialogOption = {type: 0, groupId: 0};
      this.addPlayerShow = true;
    },

    //修改
    updateEntryStatus(ids, status) {
      let tips = '是否'
      switch (status) {
        case 1:
          tips += '通过当前报名申请？'
          break
        case 2:
          tips += '拒绝当前报名申请？'
          break
        case 3:
          tips += '封禁当前报名申请？'
          break
        case 4:
          tips += '删除当前报名申请？'
          break
      }
      this.$confirm(tips, '提示', {
        type: 'warning'
      }).then(() => {
        let request = {
          activityId: this.activityId,
          ids: ids, status: status,
          updateBy: store.state.user.id
        }
        ajaxUtil.ajaxJson(api.player.entryExamine, request, 'PUT', null, true).then(data => {
          messages.showSuccessMsg(messages.success.update);
          ActivityUtil.refreshH5(false);
          this.getEntryList(this.pageNo)
          this.showEntryInfo = false
        })
      }).catch(() => {
        console.log("已取消")
      });
    },

    //点击修改选手
    bindEntryChange(row) {
      let customForm = JSON.parse(row.customForm)
      for (let i = 0; i < customForm.length; i++) {
        let label = customForm[i][0];
        let content = customForm[i][5];
        if (label === 'image' && content && content.length) {
          customForm[i][5] = content.split(',')
        }
      }
      row.customForm = customForm
      this.currentEntry = row
      this.showEntryInfo = true
    },
    //继续编辑选手
    continueEditPlayerEvent({currentPlayerIndex, continueType}) {
      let {resultList, pageCount, pageNo, totalPage, totalCount} = this.entryPage;
      let playerSize = resultList.length;
      let continueIndex = 0, continuePage = 0, existUp = false, existNext = true;

      //上一个
      if (continueType === 'up') {
        existNext = true
        if (currentPlayerIndex === 0 && pageNo > 1) {
          continueIndex = pageCount - 1
          continuePage = pageNo - 1
          existUp = true
        } else if (currentPlayerIndex > 0) {
          let upIndex = currentPlayerIndex - 1
          let player = resultList[upIndex];
          if (upIndex === 0) {
            existUp = pageNo > 1
          } else {
            existUp = true
          }
          this.playerDialogOption = {
            type: 1,
            playerId: player.id,
            index: upIndex,
            existUp: existUp,
            existNext: existNext
          }
          return
        } else {
          return false
        }
      } else { //下一个
        existUp = true
        if (currentPlayerIndex === playerSize - 1 && pageNo < totalPage) {
          continueIndex = 0
          continuePage = pageNo + 1
          existNext = totalCount > (pageNo * pageCount + 1)
        } else if (currentPlayerIndex < playerSize - 1) {
          let nextIndex = currentPlayerIndex + 1
          let player = resultList[nextIndex];
          if (nextIndex === playerSize - 1) {
            existNext = pageNo < totalPage
          } else {
            existNext = true
          }
          this.playerDialogOption = {
            type: 1,
            playerId: player.id,
            index: nextIndex,
            existUp: existUp,
            existNext: existNext
          }
          return
        } else {
          return false
        }
      }
      let url = api.player.query.replace('{activityId}', this.activityId)
          .replace('{sortType}', this.selectedSortCondition.id).replace('{showType}', this.selectedShowCondition.id)
          .replace('{filterType}', this.selectedOtherCondition.id).replace('{searchKey}', this.searchKey)
          .replace('{pageNo}', continuePage).replace('{pageCount}', pageCount);
      if (this.currentGroupId) {
        url = url + '&groupId=' + this.currentGroupId;
      }
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        let playerList = data.resultList;
        if (playerList && playerList.length) {
          this.entryPage = data;
          let currentPlayer = playerList[continueIndex];
          this.playerDialogOption = {
            type: 1,
            playerId: currentPlayer.id,
            index: continueIndex,
            existUp: existUp,
            existNext: existNext
          }
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      })
    },

    resetNum() {
      this.resetNumDailogVisible = true;
    },
    confirmResetNum() {
      this.resetNumDailogVisible = false;
      let req = {activityId: this.activityId, type: this.resetNumType};
      ajaxUtil.ajaxJson(api.player.resetNum, req, 'PUT', null, true).then(data => {
        messages.showSuccessMsg(messages.success.operation);
        ActivityUtil.refreshH5(false);
        this.getEntryList(this.pageNo)
      })
    },
    //导出活动数据
    exportEntryData() {
      let actId = this.activityId;
      let actName = this.actName;
      let url = api.player.entryAll.replace('{activityId}', actId);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        let headers = ['编号', '报名时间', '审核']
        const table = data.map(
            item => {
              let customForm = JSON.parse(item.customForm)
              const isHeaders = headers.length === 3
              //转换时间
              let createTime = moment(parseInt(item.createTime)).format('YYYY-MM-DD HH:mm:ss');
              let examine = ''
              switch (item.status) {
                case 1:
                  examine = '已通过'
                  break;
                case 2:
                  examine = '未通过'
                  break;
                case 3:
                  examine = '已禁用'
                  break;
                default:
                  examine = '待审核'
              }
              let rows = [item.id, createTime, examine]
              for (let i = 0; i < customForm.length; i++) {
                let key = customForm[i][0];
                let label = customForm[i][1];
                let content = customForm[i][5];
                if (key === 'image' || key === 'video') {
                  continue;
                } else {
                  if (isHeaders) {
                    headers.push(label)
                  }
                  if (key === 'group') {
                    content = this.groupFilter(content)
                  }
                  rows.push(content)
                }
              }
              return rows
            }
        )
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...table])
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
        XLSX.writeFile(workbook, actId + '_' + actName + '_报名选手.xlsx')
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.player-entry {
  .tip {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 30px;
    border-radius: 5px;
    border: #409eff 1px solid;
    background: rgba(64, 158, 255, 0.1);
    color: #409eff;
  }

  .el-dropdown-link:hover {
    color: #409eff;
  }

  .header {
    margin-top: 10px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding-bottom: 15px;

      .col {
        display: flex;
        align-items: center;

        .el-button {
          margin-right: 10px;
          margin-left: 0;
        }
      }
    }
  }

  .list {
    margin-bottom: 20px;

    .el-dropdown {
      color: #909399;
    }

    .player {
      display: flex;
      justify-items: center;
      color: #a1a1a1;

      .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .avatar-clock, .avatar-hidden {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, .5);
          text-align: center;
          line-height: 42px;
        }
      }

      .info {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;

        .base {
          display: flex;

          .num {
            width: 60px;
          }

          .name {
            color: #000;
          }
        }
      }
    }

    .player:hover {
      cursor: pointer;
      color: #409eff;

      .info .base .name {
        color: #409eff;
      }
    }
  }

  .pagination-box {
    display: flex;
    justify-content: right;
  }

  .info {
    .form-row {
      display: flex;
      padding: 10px 0;
      box-sizing: border-box;

      .row-label {
        font-weight: bold;
        min-width: 80px;
        box-sizing: border-box;
        padding-right: 10px;
      }

      .row-content {
        .row-img {
          width: 120px;
          height: 120px;
          object-fit: contain;
        }

        .row-video {
          width: 160px;
          height: 90px;

          ::v-deep iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .form-foot {
      display: flex;
      justify-content: right;
    }
  }
}


</style>
