<template>
  <div class="template-body" id="templateBody">
    <div class='template-tab'>
      <div class='tab-left '>
        <el-tabs v-if="industryLabels&&industryLabels.length>0" v-model="currentIndustryIdValue" @tab-click="research">
          <el-tab-pane label="行业" name="行业" :disabled="true"></el-tab-pane>
          <el-tab-pane v-bind:label="item.des" v-bind:name="''+item.value"
                       v-for="(item) in industryLabels" v-bind:key="item.value"></el-tab-pane>
        </el-tabs>
        <el-tabs v-if="styleLabels&&styleLabels.length>0" v-model="currentStyleIdValue" @tab-click="research">
          <el-tab-pane label="风格" name="风格" :disabled="true"></el-tab-pane>
          <el-tab-pane v-bind:label="item.des" v-bind:name="''+item.value"
                       v-for="(item) in styleLabels" v-bind:key="item.value"></el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="canSearch" class='tab-right'>
        <el-input placeholder="请输入模板名称、ID"
                  suffix-icon="el-icon-search"
                  maxlength="100"
                  v-model.trim="actName"
                  @change="onSearch">
        </el-input>
      </div>
    </div>
    <div class="template-list scrollStyle">
      <div class="template-item" v-for='(item,index) in resultList' :key="item.id">
        <div class="template-img">
          <el-image style="height: 100%;width: 100%;" :lazy="true" :src="item.coverImageUrl | appendImgUrl"
                    fit="fit"></el-image>
        </div>
        <div class='template-num'>ID:{{ item.id }}</div>
        <div class="template-title">{{ item.name }}</div>
        <div v-if="batName" class="template-float">
          <el-button style="margin-top: calc(50% + 32px);" type="primary" @click='clickModel(item,index)' round>{{
              batName
            }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ajaxUtil} from '../js/ajaxUtil'
import {api} from '../js/api'
import {CommonUtil, ActivityUtil} from '../js/commonUtil'

export default {
  name: 'activityModelList',
  props: {
    width: {
      type: Number,
      default: 260
    },
    coverHeight: {
      type: Number,
      default: 460
    },
    nameHeight: {
      type: Number,
      default: 40
    },
    canSearch: {
      type: Boolean,
      default: true
    },
    batName: {
      type: String,
      default: null
    },
    defaultOrderBy: {
      type: String,
      default: 'hot-desc;id-asc-unique'
    }
  },
  data() {
    return {
      actName: '',
      industryLabels: [{des: '全部', value: 0}],
      styleLabels: [{des: '全部', value: 0}],
      currentIndustryIdValue: '0',
      currentStyleIdValue: '0',
      busy: false,//是否禁用滚动加载事件,用于控制滚动加载组件的重复触发问题
      resultList: [],
      queryParam: {
        keyword: null,
        activityModelIndustryId: 0,
        activityModelStyleId: 0,
        needAggregation: false,
        supportSearchAfter: true,
        pageCount: 20,
        pagingType: 1,
        searchBeforeAfter: null,
        resetOrderBy: null
      },
      enterIndex: -1,
      operateModel: {}
    }
  },
  mounted() {
    this.calculateDynamicWidth();
    this.getLabels();
    this.research();
  },
  methods: {
    calculateDynamicWidth() {
      const templateEl = document.getElementById('templateBody'); // 获取包裹元素的DOM节点
      const templateWidth = templateEl.offsetWidth; // 获取包裹元素的宽度

    },
    clickModel(model, index) {
      this.$emit('clickModel', model);
    },
    getLabels() {
      ajaxUtil.ajaxJson(api.activitymodel.labels, null, "GET").then(data => {
        if (data.industryLabels && data.industryLabels.length > 0) {
          this.industryLabels = [{des: '全部', value: 0}];
          this.industryLabels.push(...data.industryLabels);
        }
        if (data.styleLabels && data.styleLabels.length > 0) {
          this.styleLabels = [{des: '全部', value: 0}];
          this.styleLabels.push(...data.styleLabels);
        }
      })
    },
    onSearch() {
      if (!CommonUtil.compare(this.actName, this.queryParam.keyword)) {
        this.queryParam.keyword = this.actName;
        this.research()
      }
    },
    research() {
      this.queryParam.pagingType = 1;
      this.search();
    },
    search() {
      this.busy = true;//设置为true,禁止滚动加载事件,防止重复触发
      this.queryParam.activityModelIndustryId = this.currentIndustryIdValue;
      this.queryParam.activityModelStyleId = this.currentStyleIdValue;
      if (!this.queryParam.resetOrderBy) {
        this.queryParam.resetOrderBy = this.defaultOrderBy;
      }
      let bol = this.queryParam.pagingType == 1;
      let paramStr = CommonUtil.getAllProperty(this.queryParam);
      ajaxUtil.ajaxJson(api.activitymodel.query + '?' + paramStr, null, "GET").then(page => {
        if (paramStr == CommonUtil.getAllProperty(this.queryParam)) {
          //查询完毕后先检查一次参数是否变化,若未变化才进行处理,防止重复的查询请求导致数据异常
          if (page.resultList.length > 0) {
            this.queryParam.pagingType = 3;
            this.queryParam.searchBeforeAfter = page.searchAfter;
          }
          if (bol) {
            this.resultList = page.resultList;
          } else {
            this.resultList.push(...page.resultList);
            if (this.resultList.length == 0) {
              this.queryParam.pagingType = 1;
              this.queryParam.searchBeforeAfter = null;
            }
          }
          //若为最后一页,则设置为true,不再继续假装,防止一直触发无效的查询请求
          //若不是最后一页,则设置为false,则可继续触发滚动加载事件
          this.busy = page.resultList.length < page.pageCount;
        } else {
          this.busy = false
        }
      }, err => {
        this.busy = false
      })
    },
    addActivity() {
      if (this.operateModel.modelActivityId) {
        ActivityUtil.add({sourceActivityId: this.operateModel.modelActivityId}, data => {
          ActivityUtil.toSettingAct('BasicSet', data.id);
        })
      }
    },
    addClass(e, index) {
      e.target.className = 'template-img slideup';
      this.enterIndex = index
    },
    removeClass(e) {
      e.target.className = 'template-img slidedown';
      this.enterIndex = -1;
    },
  }
}
</script>
<style lang="scss">
.template-body {
  box-sizing: border-box;
}

.template-tab {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 20px 30px 5px 30px;
  z-index: 999;
  background-color: #fff;
}

.template-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  padding: 15px 30px 0 30px;


  .template-item {
    width: calc(20% - 20px) !important;
    margin-bottom: 20px;
    margin-right: 25px;
    border-radius: 10px;
    background-size: 100% 100%;
    overflow: hidden;
    box-shadow: 0 6px 8px rgba(0, 0, 0, .1);
    transition: all .4s;
    position: relative;
    text-align: center;

    .template-num {
      position: absolute;
      left: 0;
      top: 15px;
      color: #fff;
      display: block;
      width: 65px;
      height: 25px;
      line-height: 25px;
      background-color: rgba(0, 0, 0, .2);
      margin-right: 6px;
      border-radius: 0 20px 20px 0;
    }

    .template-img {
      width: 100%;
      height: calc(100vh / 14 * 5);
      min-height: 300px;
      overflow: hidden;
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-bottom: 0;
      -o-object-fit: cover;
      object-fit: cover;
      position: relative;
      display: inline-block;
    }

    .template-title {
      font-size: 16px;
      color: #818181;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .template-float {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 999;
      background-color: rgba(0, 0, 0, .4);
      text-align: center;
      display: none;
    }
  }

  .template-item:nth-child(5n) {
    margin-right: 0 !important;
  }

  .template-item:hover {
    transform: translateY(-10px);

    .template-float {
      display: block;
    }
  }
}

.tab-left {
  ul li {
    width: 80px;
    height: 40px;
    line-height: 40px;
    float: left;
    color: #666
  }

  .typeTitle {
    text-align: left;
    color: #999;
  }
}
</style>
