<template>
  <div class="tinymce-editor">
    <Editor :id="id" v-model="content" :init="init"></Editor>
  </div>
</template>

<script>
import tinymce from "tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver/theme";
import "tinymce/icons/default/icons";
import "tinymce/plugins/image";
import "tinymce/plugins/axupimgs";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/indent2em";
import "tinymce/plugins/media";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";
import "tinymce/plugins/fullscreen";
import {config} from "@/js/config";
import {OssFileUploadUtil} from "@/js/ossFileUploadUtil";

export default {
  name: "TinyEditor",
  components: {Editor},
  props: {
    id: {
      type: String,
      default: function () {
        return 'tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 500,
    },
    height: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      content: "",
      fileList: [],
      allImageList: [],
      init: {
        language_url: "/tinymce/langs/zh_CN.js", // 语言包位置，因为放在public下所以可以省略public
        selector: `#${this.id}`, //tinymce的id
        // auto_focus: 'element1',
        language: "zh_CN", //语言类型
        skin_url: "/tinymce/skins/ui/oxide",
        width: this.width,
        height: this.height, //编辑器高度
        min_height: 400,
        highlight_on_focus: true,
        // contextmenu_never_use_native: true,//5.0.1
        draggable_modal: true,
        //inline: true,
        // content_style: "p {margin: 2px 0;}",
        browser_spellcheck: true, // 拼写检查
        elementpath: false, //禁用编辑器底部的状态栏
        statusbar: false, // 隐藏编辑器底部的状态栏
        paste_data_images: true, // 允许粘贴图像
        menubar: false, //最顶部文字信息
        // mode: "textareas",
        placeholder: "请输入...",
        // forced_root_block: '', // 删除在tinymce中自动添加的p标签
        // force_br_newlines : true,
        // force_p_newlines : false,
        preview_styles: "font-size color",
        plugins: "indent2em link advlist lists image axupimgs media code fullscreen", //就可以增加上面引入的插件，加入下面这一行就可以在toolbar栏显示相应插件。
        branding: false, //是否禁用“Powered by TinyMCE”
        toolbar: [
          "undo redo bold italic underline forecolor backcolor fontsizeselect fontselect " +
          "styleselect indent2em lineheight alignleft aligncenter alignright alignjustify bullist numlist " +
          "link unlink image axupimgs media removeformat code fullscreen"], //工具栏
        toolbar_mode: "wrap",
        //toolbar_sticky: true,
        image_caption: true,
        convert_urls: false,// 上传图片文件路径变为相对路径的解决方法
        images_upload_handler: (blobInfo, success, failure, progress) => {
          this.uploadFile(blobInfo, success, failure, progress);
        },
        //file_picker_callback: "",
        fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 28px 32px 36px 48px",
        font_formats: "微软雅黑='微软雅黑'; 宋体='宋体'; 黑体='黑体'; 仿宋='仿宋'; 楷体='楷体'; 隶书='隶书'; 幼圆='幼圆'; 方正舒体='方正舒体'; 方正姚体='方正姚体'; 等线='等线'; 华文彩云='华文彩云'; 华文仿宋='华文仿宋'; 华文行楷='华文行楷'; 华文楷体='华文楷体'; 华文隶书='华文隶书'; Andale Mono=andale mono,times; Arial=arial; Arial Black=arial black;avant garde; Book Antiqua=book antiqua;palatino; Comic Sans MS=comic sans ms; Courier New=courier new;courier; Georgia=georgia; Helvetica=helvetica; Impact=impact;chicago; Symbol=symbol; Tahoma=tahoma;arial; sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms; Verdana=verdana;geneva; Webdings=webdings; Wingdings=wingdings",
        tabfocus_elements: "tinymce",
        importcss_append: true,
      },
    };
  },
  watch: {
    value(newVal) {
      this.content = newVal ? newVal : '';
    },
    content(newValue) {
      this.$emit("input", newValue);
    }
  },
  //获取焦点光标到最后面keepLastIndex (obj, window) {if (window.getSelection) { //ie11 10 9 ff safariobj.focus(); //解决ff不获取焦点无法定位问题var range = window.getSelection(); //创建rangerange.selectAllChildren(obj); //range 选择obj下所有子内容range.collapseToEnd(); //光标移至最后} else if (document.selection) { //ie10 9 8 7 6 5var range = document.selection.createRange(); //创建选择对象range.moveToElementText(obj); //range定位到objrange.collapse(false); //光标移至最后range.select();}}
  mounted() {
    tinymce.init({});
  },
  methods: {
    getContent() {
      var cnt = tinymce.editors['tinymce'].getContent();
      //console.log(cnt);
    },
    //自定义上传函数
    uploadFile(blobInfo, success, failure, progress) {
      OssFileUploadUtil.uploadImg(blobInfo.blob(), {
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
      }, (res) => {//成功回调
        let url = config.ossHost + res.name;
        success(url);
      }, (err) => {//失败回调
        failure('上传出错')
      });
    },
  },
  destroyed() {
  },
};
</script>
<style lang="scss" scoped>

</style>
