<template>
  <div class="mask-wrapper" id='op'>
    
    <div class='poster-content'>
        <posterDefalut :activity="activity" :h5Url="h5Url" v-if='currentIndex == 10'></posterDefalut>
        <posterOne :activity="activity" :h5Url="h5Url" v-if='currentIndex == 1'></posterOne>
        <posterTwo :activity="activity" :h5Url="h5Url" v-if='currentIndex == 2'></posterTwo>
        <posterThree :activity="activity" :h5Url="h5Url" v-if='currentIndex == 3'></posterThree>
        <div class='poster-btn-wrapper'>
          <el-button style='width:117px' @click='closePoster'>关闭</el-button>
          <el-dropdown @command='choosePoster'>
            <el-button >
              {{activePoster}}<i class="el-icon-arrow-down el-icon--right" ></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item  :class='currentIndex==10?"active-on":""' v-if="banners.length>0" command="10">
                  <span>默认海报</span>
              </el-dropdown-item>
              <el-dropdown-item  :class='currentIndex==1?"active-on":""' command="1"><span >系统海报1</span></el-dropdown-item>
              <el-dropdown-item   :class='currentIndex==2?"active-on":""' command="2"><span>系统海报2</span></el-dropdown-item>
              <el-dropdown-item   :class='currentIndex==3?"active-on":""' command="3"><span >系统海报3</span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="primary" style='width:117px' @click='savePoster'>保存海报</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import posterDefalut from './posterDefalut.vue'
import posterOne from './posterOne.vue'
import posterTwo from './posterTwo.vue'
import posterThree from './posterThree.vue'
import Canvas2Image from '../js/Canvas2Image.js'
import html2canvas from 'html2canvas'
import {ActivityUtil} from "@/js/commonUtil";
export default {
  name: 'About',
  data(){
    return {
        activity:'',
        banners:[],
      currentIndex: 1,//海报ID
      activePoster:'系统海报1',
      posterList:[{name:'默认海报',id:10},{name:'系统海报1',id:1},{name:'系统海报2',id:2},{name:'系统海报3',id:3}]
    }
  },
    props:['h5Url'],
  components:{
    posterDefalut,
    posterOne,
    posterTwo,
    posterThree
  },
    beforeMount(){
        this.currentIndex = 1;
        this.activePoster= this.posterList[1].name;
        ActivityUtil.get(ActivityUtil.getCurrentSettingActId(),data=>{
            this.activity = data;
            this.banners = data.settingShufflingImages
            if(this.banners.length==0){
                this.currentIndex = 1;
            }else{
                this.activePoster= this.posterList[0].name;
                this.currentIndex = 10;
            }
        });
    },
  methods:{
    closePoster(){
      this.$emit('closePoster')
    },
    choosePoster(e){
      this.posterList.map(item=>{
        if(item.id == e){
          this.currentIndex = e;
          this.activePoster = item.name
        }
      })
    },
    savePoster(){
        let posterID = this.currentIndex == 10?0: this.currentIndex;
        let elementArr = document.getElementById(`poster${posterID}`)
        this.onCanvas(elementArr)
    },
    onCanvas(elem){
      let width = elem.offsetWidth;
      let height = elem.offsetHeight;
      let canvas = document.createElement("canvas");
      let scale =1;
      canvas.width = width* scale;
      canvas.height = height*scale;
      canvas.getContext('2d').scale(scale,scale);
      let opts = {
          scale:scale,
          canvas:canvas,
          logging: true,
          width:width,
          height:height,
          backgroundColor: null,
          allowTaint:false,
          useCORS:true,//允许加载跨域的图片
          tainttest:true, //检测每张图片都已经加载完成
      }  
      html2canvas(elem,opts).then((canvas)=>{
        Canvas2Image.saveAsPNG(canvas,undefined,undefined,'活动海报')
      })
    }, 
  }
}
</script>
<style lang="scss" scoped>
.mask-wrapper{
  width: 100%;
  height: 100%;
  background:rgba(0,0,0,.3);
  position: fixed;
  top:0;
  left:0;
  z-index: 2000;
  overflow: auto;
  .poster-content{
    width: 376px;
    height: 747px;
    position:absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    .poster-btn-wrapper{
      width: 100%;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  } 
}
   .active-on{
    background:#ecf5ff;
    color:#409EFF;
  }
</style>
