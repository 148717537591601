<template>
    <div class="voteSet-wrapper">
        <div class='content'>
            <!---------------------------------------防刷票设置--------------------------------------------------->
            <div class='basic'>
                <el-form hide-required-asterisk label-position="left" label-width="100px">
                    <el-form-item label="验证码">
                        <el-switch v-model="activity.settingVotingRules.verificationCodeProtective" @change="changeVotingRules"> </el-switch>
                    </el-form-item>
                    <el-form-item label="智能防刷">
                        <el-switch v-model="activity.settingVotingRules.intelligentProtective" @change="changeVotingRules"> </el-switch>
                    </el-form-item>
                    <el-form-item label="可投区域">
                        <div class="block">
                            <el-cascader v-model="currentAddress" :options="addressOptions" @change="chooseAddress"></el-cascader>
                        </div>
                    </el-form-item>
                    <el-form-item label="黑名单防刷">
                        <div class='help-votes-box' style='margin-top:0'>
                            <el-radio-group v-model="activity.settingVotingRules.blacklistLevel.index" @change="changeVotingRules">
                                <el-radio :label="1">关闭</el-radio>
                                <el-radio :label="2">低</el-radio>
                                <el-radio :label="3">中</el-radio>
                                <el-radio :label="4">高</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="投票时间限制">
                        <el-switch v-model="activity.settingVotingRules.limitPeriodOfTime" @change="changeVotingRules"> </el-switch>
                        <el-time-select v-if="activity.settingVotingRules.limitPeriodOfTime" style='width:160px;margin-left: 20px'
                            placeholder="开始时间" v-model="activity.settingVotingRules.startPeriodOfTime" @change="changeVotingRules"
                            :picker-options="{start: '00:00',step: '00:15',end: '23:45',maxTime: activity.settingVotingRules.endPeriodOfTime}">
                        </el-time-select>
                        <el-time-select v-if="activity.settingVotingRules.limitPeriodOfTime" style='width:160px;margin-left: 20px'
                            placeholder="截止时间" v-model="activity.settingVotingRules.endPeriodOfTime" @change="changeVotingRules"
                            :picker-options="{start: '00:00',step: '00:15',end: '23:45',minTime: activity.settingVotingRules.startPeriodOfTime}">
                        </el-time-select>
                    </el-form-item>
                </el-form>
                <el-form label-position="left" label-width="100px" @submit.native.prevent>
                  <el-form-item label="封禁IP">
                    <el-input type="textarea" :rows="5" placeholder="请输入要封禁的IP，一行一个IP" @keyup.enter.native="lineFeed"
                              v-model.trim="ipBlacklist.ipBlacklistStr" @blur="updateBlacklists">
                    </el-input>
                  </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store/store'
    import {api} from '@/js/api'
    import {ActivityUtil, CommonUtil} from "@/js/commonUtil";
    import {ajaxUtil} from "@/js/ajaxUtil";
    import {messages} from "@/js/messages";
    export default {
        name: 'voteSecureSet',
        data(){
            return {
                activity:{
                    id:0,
                    settingVotingRules:{
                        verificationCodeProtective:true,
                        intelligentProtective:true,
                        limitArea:'',
                        blacklistLevel:{
                            index:3
                        },
                        limitPeriodOfTime:false,
                        startPeriodOfTime:null,
                        endPeriodOfTime:null
                    }
                },
                ipBlacklist:{
                    activityId:0,
                    ipBlacklists:[],
                    ipBlacklistStr:null
                },
                addressOptions:[],
                currentAddress:[]
            }
        },
        beforeMount(){
          this.getAllProvinces();
          this.getActivity();
          this.getBlacklists();
        },
        methods:{
            getActivity(){
              ActivityUtil.get(ActivityUtil.getCurrentSettingActId(),data=>{
                CommonUtil.copyProperty(data, this.activity);
                if(!this.activity.settingVotingRules.limitArea||this.activity.settingVotingRules.limitArea.length!=6){
                  //无限制区域,或区域代码非6位(不合法),则认为无限制
                  this.currentAddress=['']
                }else {
                  if(parseInt(this.activity.settingVotingRules.limitArea.substring(2))==0){
                    //限制区域后4位均为0,则为省代码,限制范围为省
                    this.currentAddress=[this.activity.settingVotingRules.limitArea,'']
                  }else {
                    //限制区域后4位不全为0,则为城市代码或区域代码(限暂不考虑区域代码)
                    let provinceCode=this.activity.settingVotingRules.limitArea.substring(0,2)+'0000';
                    this.currentAddress=[provinceCode,this.activity.settingVotingRules.limitArea]
                  }
                }
              })
            },
            getAllProvinces(){
              //一次性加载所有的省份和城市,防止动态加载时活动返回的城市代码时,因为还没加载城市列表的缘故而无法展示
              if(!this.addressOptions||this.addressOptions.length==0){
                ajaxUtil.ajaxJson(api.area.getAllProvinces,null,'GET').then(provinces=>{
                  ajaxUtil.ajaxJson(api.area.getCitiesByProvince.replace('{provinceCode}',''),null,'GET').then(cities=>{
                    let provinceCity={};
                    cities.forEach(item=>{
                      let list=provinceCity[item.provinceCode];
                      if(list==null||list==undefined){
                        list=[{value:'', label:'全部'}];
                      }
                      list.push({value:item.code, label:item.name});
                      provinceCity[item.provinceCode]=list;
                    })
                    this.addressOptions=[{value:'', label:'全部'}];
                    provinces.forEach(item=>{
                      let province = {value:item.code, label:item.name, children:[]};
                      let list=provinceCity[item.code];
                      if(list){
                        province.children=list;
                      }
                      this.addressOptions.push(province);
                    })
                  })
                })
              }
            },
            changeVotingRules(){
              if(this.activity.settingVotingRules.limitPeriodOfTime){
                if(!this.activity.settingVotingRules.startPeriodOfTime&&!this.activity.settingVotingRules.endPeriodOfTime){
                  messages.showErrorMsg('请选择开始时间和截止时间')
                  return false;
                }else if(!this.activity.settingVotingRules.startPeriodOfTime){
                  messages.showErrorMsg('请选择开始时间')
                  return false;
                }else if(!this.activity.settingVotingRules.endPeriodOfTime){
                  messages.showErrorMsg('请选择截止时间')
                  return false;
                }
              }
              ActivityUtil.update(this.activity, ['settingVotingRules'],null,()=>{
                  ActivityUtil.refreshH5();
              });
            },
            getBlacklists(){
              ajaxUtil.ajaxJson(api.activity.blacklist.get.replace('{activityId}',ActivityUtil.getCurrentSettingActId()),
                  null,'GET').then(data=>{
                if(data.ipBlacklists&&data.ipBlacklists.length>0){
                  data.ipBlacklistStr=data.ipBlacklists.join('\n');
                }
                CommonUtil.copyProperty(data,this.ipBlacklist)
              })
            },
            updateBlacklists(){
              if(this.ipBlacklist.ipBlacklistStr==null||this.ipBlacklist.ipBlacklistStr.length==0){
                this.ipBlacklist.ipBlacklists=[];
              }else {
                let blacklists = this.ipBlacklist.ipBlacklistStr.split('\n');
                let filterBlackLists=[];
                for (let i = 0; i < blacklists.length; i++) {
                  let item=blacklists[i];
                  if(item.length>20){
                    messages.showErrorMsg('ip长度超限制')
                    return false;
                  }
                  if(filterBlackLists.indexOf(item)<0){
                    filterBlackLists.push(item);
                  }
                }
                if(filterBlackLists.length>1000){
                  messages.showErrorMsg('ip数量超限制')
                  return false;
                }
                this.ipBlacklist.ipBlacklists=filterBlackLists;
              }
              this.ipBlacklist.updateBy=store.state.user.id;
              ajaxUtil.ajaxJson(api.activity.blacklist.update,this.ipBlacklist,'PUT',null,true).then(data=>{
                if(data.change){
                  messages.showSuccessMsg(messages.success.operation);
                }
              });
            },
            chooseAddress(){
              if(this.currentAddress.length==1){
                this.activity.settingVotingRules.limitArea=this.currentAddress[0];
              }else if(this.currentAddress.length==2){
                this.activity.settingVotingRules.limitArea=this.currentAddress[1]?this.currentAddress[1]:this.currentAddress[0];
              }
              this.changeVotingRules();
            },
            lineFeed(){
                if(this.ipBlacklist.ipBlacklistStr==null||this.ipBlacklist.ipBlacklistStr.length==0
                    ||this.ipBlacklist.ipBlacklistStr.endsWith('\n')){
                  return false;
                }
                let blacklists=this.ipBlacklist.ipBlacklistStr.split('\n');
                if(blacklists.length==0){
                  return false;
                }
                if(blacklists[blacklists.length-1].length>20){
                  messages.showErrorMsg('ip长度超限制')
                  return false;
                }
                this.ipBlacklist.ipBlacklistStr+='\n';
            }
        }
    }
</script>
<style lang="scss" scoped>
    .voteSet-wrapper{
        .help-votes-box{
            margin-top: 20px;
            .box-name{
                margin-right: 20px;
            }
        }

    }

</style>
