<template>
  <div class="voteSet-wrapper">
    <div class='content'>
      <!---------------------------------------投票设置--------------------------------------------------->
      <div class='basic'>
        <el-form hide-required-asterisk label-position="left" label-width="100px">
          <el-form-item label="周期设置">
            <div class='help-votes-box' style='margin-top:0'>
              <el-radio-group v-model="activity.settingVotingRules.votingCycleType.index" @change="changeVotingRules">
                <el-radio :label="1">固定次数</el-radio>
                <el-radio :label="2">周期次数</el-radio>
              </el-radio-group>
            </div>
            <div class='help-votes-box' style='margin-top:0;'>
              <el-row>
                <el-col :span="6.5">{{
                    activity.settingVotingRules.votingCycleType.index == 1 ? '每个微信号整个活动期间只能投'
                        : '每个微信号每天可以投'
                  }}
                </el-col>
                <el-col :span="2.5" style="margin: 0 10px;">
                  <numberInput :styleObj="{width:'60px'}" :maxlength="2" @change="changeVotingRules"
                               :value.sync="activity.settingVotingRules.votingNumber" :replaceMin="1" :replaceMax="20"
                               :rule="{required:{value:true,message:'不能为空'},
                                                 min:{value:1,message:'必须大于0'}}"></numberInput>
                </el-col>
                <el-col :span="0.9">次</el-col>
                <el-col v-if="activity.settingVotingRules.votingScopeType.index==1
                                    &&activity.settingVotingRules.chooseType.index==1
                                    &&activity.settingVotingRules.votingNumber>1" :span='2.6'>，可投同一选手
                </el-col>
                <el-col v-if="activity.settingVotingRules.votingScopeType.index==1
                                    &&activity.settingVotingRules.chooseType.index==1
                                    &&activity.settingVotingRules.votingNumber>1" :span="2.5" style="margin: 0 10px;">
                  <numberInput :styleObj="{width:'60px'}" :maxlength="2" @change="changeVotingRules"
                               :value.sync="activity.settingVotingRules.singleVotingNumber" :replaceMin="1"
                               :replaceMax="20"
                               :rule="{required:{value:true,message:'不能为空'},
                                                 min:{value:1,message:'必须大于0'}}"></numberInput>
                </el-col>
                <el-col v-if="activity.settingVotingRules.votingScopeType.index==1
                                    &&activity.settingVotingRules.chooseType.index==1
                                    &&activity.settingVotingRules.votingNumber>1" :span="1">票
                </el-col>
              </el-row>
            </div>
          </el-form-item>
          <el-form-item v-if="!playerGroupList||playerGroupList.length<=1" label="勾选规则">
            <div class='help-votes-box' style='margin-top:0'>
              <el-radio-group v-model="activity.settingVotingRules.chooseType.index" @change="changeVotingRules">
                <el-radio :label="1">单选</el-radio>
                <el-radio :label="2">多选</el-radio>
              </el-radio-group>
            </div>
            <div v-show="activity.settingVotingRules.chooseType.index==2" class='help-votes-box' style='margin-top:0'>
              <el-row>
                <el-col :span="2.5">最少选择</el-col>
                <el-col :span="2.5" style="margin: 0 10px;">
                  <numberInput :styleObj="{width:'60px'}" :maxlength="2" @change="changeVotingRules"
                               :value.sync="activity.settingVotingRules.minVotingPlayers" :replaceMin="1"
                               :replaceMax="10"
                               :rule="{required:{value:true,message:'不能为空'},
                                                 min:{value:1,message:'必须大于0'},
                                                 max:{value:activity.settingVotingRules.maxVotingPlayers, message:'最小值错误'}}"></numberInput>
                </el-col>
                <el-col :span="2" class="marRight50">位选手</el-col>
                <el-col :span="2.5">最多选择</el-col>
                <el-col :span="2.5" style="margin: 0 10px;">
                  <numberInput :styleObj="{width:'60px'}" :maxlength="2" @change="changeVotingRules"
                               :value.sync="activity.settingVotingRules.maxVotingPlayers" :replaceMin="1"
                               :replaceMax="20"
                               :rule="{required:{value:true,message:'不能为空'},
                                                 min:{value:activity.settingVotingRules.minVotingPlayers<=0
                                                 ? 1 : activity.settingVotingRules.minVotingPlayers, message:'最大值错误'}}"></numberInput>
                </el-col>
                <el-col :span="2">位选手</el-col>
              </el-row>
            </div>
          </el-form-item>
          <el-form-item v-else label="投票规则">
            <div class='help-votes-box' style='margin-top:0'>
              <el-radio-group v-model="activity.settingVotingRules.votingScopeType.index" @change="changeVotingRules">
                <el-radio :label="1">按活动</el-radio>
                <el-radio :label="2">单分组</el-radio>
                <el-radio :label="3">多分组</el-radio>
              </el-radio-group>
            </div>
            <div class='help-votes-box' style='margin-top:0'>
              {{
                activity.settingVotingRules.votingScopeType.index == 1
                    ? '按活动投票规则时，分组只起区分选手作用(可以跨分组勾选选手)'
                    : (activity.settingVotingRules.votingScopeType.index == 2
                        ? '按单个分组投票规则时，投票人必须满足任意一个分组的投票规则(不能跨分组勾选选手)'
                        : '按多个分组投票规则时，投票人只需满足任意一个分组或多个分组投票规则(可以跨分组勾选选手)')
              }}
            </div>
            <el-form-item v-if="activity.settingVotingRules.votingScopeType.index==1" label="勾选规则">
              <div class='help-votes-box' style='margin-top:0'>
                <el-radio-group v-model="activity.settingVotingRules.chooseType.index" @change="changeVotingRules">
                  <el-radio :label="1">单选</el-radio>
                  <el-radio :label="2">多选</el-radio>
                </el-radio-group>
              </div>
              <div v-show="activity.settingVotingRules.chooseType.index==2" class='help-votes-box' style='margin-top:0'>
                <el-row>
                  <el-col :span="2.5">最少选择</el-col>
                  <el-col :span="2.5" style="margin: 0 10px;">
                    <numberInput :styleObj="{width:'60px'}" :maxlength="2" @change="changeVotingRules"
                                 :value.sync="activity.settingVotingRules.minVotingPlayers" :replaceMin="1"
                                 :replaceMax="10"
                                 :rule="{required:{value:true,message:'不能为空'},
                                                 min:{value:1,message:'必须大于0'},
                                                 max:{value:activity.settingVotingRules.maxVotingPlayers, message:'最小值错误'}}"></numberInput>
                  </el-col>
                  <el-col :span="2" class="marRight50">位选手</el-col>
                  <el-col :span="2.5">最多选择</el-col>
                  <el-col :span="2.5" style="margin: 0 10px;">
                    <numberInput :styleObj="{width:'60px'}" :maxlength="2" @change="changeVotingRules"
                                 :value.sync="activity.settingVotingRules.maxVotingPlayers" :replaceMin="1"
                                 :replaceMax="10"
                                 :rule="{required:{value:true,message:'不能为空'},
                                                 min:{value:activity.settingVotingRules.minVotingPlayers<=0
                                                 ? 1 : activity.settingVotingRules.minVotingPlayers, message:'最大值错误'}}"></numberInput>
                  </el-col>
                  <el-col :span="2">位选手</el-col>
                </el-row>
              </div>
            </el-form-item>
            <div v-else class='help-votes-box' style='margin-top:0'>
              <el-table :data="playerGroupList" style="width: 100%">
                <el-table-column align="center" label="分组名" prop="name"></el-table-column>
                <el-table-column align="center" label="投票规则" width="250">
                  <template slot-scope="scope">
                    <!--{{ scope.row.chooseType.index==1?'单选投票':
                    '此组最少选择'+scope.row.minVotingPlayers+'位选手，最多选择'+scope.row.maxVotingPlayers+'位选手' }}-->
                    <el-row v-if="scope.row.chooseType.index==1">
                      <el-col><span>单选投票</span></el-col>
                    </el-row>
                    <el-row v-if="scope.row.chooseType.index!=1">
                      <el-col><span>{{ '此组最少选择' + scope.row.minVotingPlayers + '位选手' }}</span></el-col>
                    </el-row>
                    <el-row v-if="scope.row.chooseType.index!=1">
                      <el-col><span>{{ '此组最多选择' + scope.row.maxVotingPlayers + '位选手' }}</span></el-col>
                    </el-row>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="逻辑关系">或者</el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button type="primary" :plain="true" icon="el-icon-edit"
                               @click="clickPlayerGroup(scope.row)"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
          <el-form-item label="温馨提示">
            <el-switch v-model="activity.settingVotingRules.hasTip" @change="changeVotingRules"></el-switch>
            <el-input placeholder="请输入温馨提示"
                      v-if="activity.settingVotingRules.hasTip"
                      v-model="activity.settingVotingRules.customTip" type="textarea" :row='4'
                      @change="changeVotingRules"
                      maxlength="500">
            </el-input>
          </el-form-item>
          <el-form-item label="自定义规则">
            <el-switch v-model="activity.settingVotingRules.hasRule" @change="changeVotingRules"></el-switch>
            <el-input placeholder="请输入自定义描述"
                      v-if="activity.settingVotingRules.hasRule"
                      @change="changeVotingRules"
                      v-model="activity.settingVotingRules.customRule" type="textarea" :row='4'
                      maxlength="500">
            </el-input>
          </el-form-item>
          <!--<el-form-item label="关注后报名"> 此版本不做-->
          <!--<div class='help-votes-box' style='margin-top:0'>-->
          <!--<el-radio-group v-model="sycleSet">-->
          <!--<el-radio :label="1">单选</el-radio>-->
          <!--<el-radio :label="2">多选</el-radio>-->
          <!--</el-radio-group>-->
          <!--</div>-->
          <!--</el-form-item>-->
          <!--<el-form-item label="关注后投票">-->
          <!--<el-switch v-model="isAttention"> </el-switch>-->
          <!--</el-form-item>-->
        </el-form>
      </div>
      <!--分组投票规则设置的弹出窗 -->
      <el-dialog
          title="编辑分组规则"
          :visible.sync="playerGroupDialogVisible"
          width="30%"
          top="30vh"
          :close-on-click-modal="false">
        <el-row class="marBottom30">
          <el-col :span="5"><span>分组名</span></el-col>
          <span>{{ operatePlayerGroup.name }}</span>
        </el-row>
        <el-form ref="dialogFrom" :model="operatePlayerGroup">
          <el-row style="width: 70%">
            <el-col :span="7"><span>投票类型</span></el-col>
            <el-radio-group v-model="operatePlayerGroup.chooseType.index">
              <el-radio :label="1">单选投票</el-radio>
              <el-radio :label="2">多选投票</el-radio>
            </el-radio-group>
            <div v-if="playerGroupDialogVisible&&operatePlayerGroup.chooseType.index==2" class='help-votes-box'
                 style="height:40px;line-height: 40px;">
              <el-row style="margin:auto">
                <el-col :offset="7" :span="2.5">最少选择</el-col>
                <el-col :span="2.5" style="margin: 0 10px;">
                  <numberInput :styleObj="{width:'60px'}" :maxlength="2" :variable="false" :prop="'minVotingPlayers'"
                               :value.sync="operatePlayerGroup.minVotingPlayers" :replaceMin="1" :replaceMax="10"
                               :rule="{required:{value:true,message:'不能为空'},
                                                 min:{value:1,message:'必须大于0'},
                                                 max:{value:operatePlayerGroup.maxVotingPlayers, message:'最小值错误'}}"></numberInput>
                </el-col>
                <el-col :span="4">位选手</el-col>
              </el-row>
            </div>
            <div v-if="playerGroupDialogVisible&&operatePlayerGroup.chooseType.index==2" class='help-votes-box'
                 style='height:40px;line-height: 40px;'>
              <el-row style="margin:auto">
                <el-col :offset="7" :span="2.5">最多选择</el-col>
                <el-col :span="2.5" style="margin: 0 10px;">
                  <numberInput :styleObj="{width:'60px'}" :maxlength="2" :variable="false" :prop="'maxVotingPlayers'"
                               :value.sync="operatePlayerGroup.maxVotingPlayers" :replaceMin="1" :replaceMax="10"
                               :rule="{required:{value:true,message:'不能为空'},
                                                 min:{value:operatePlayerGroup.minVotingPlayers<=0
                                                 ? 1 : operatePlayerGroup.minVotingPlayers, message:'最大值错误'}}"></numberInput>
                </el-col>
                <el-col :span="4">位选手</el-col>
              </el-row>
            </div>
          </el-row>
        </el-form>
        <el-row class="dialog-foot marTop30">
          <el-button @click="playerGroupDialogVisible = false" class="button">取 消</el-button>
          <el-button type="primary" @click="changePlayerGroupVotingRules" class="button">确 定</el-button>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {api} from '@/js/api'
import store from '@/store/store'
import {ActivityUtil, CommonUtil} from "@/js/commonUtil"
import {ajaxUtil} from "@/js/ajaxUtil"
import numberInput from "@/components/numberInput.vue";
import {messages} from "@/js/messages";

export default {
  name: 'voteSet',
  data() {
    return {
      activity: {
        id: 0,
        settingVotingRules: {
          votingCycleType: {
            index: 1
          },
          votingNumber: 1,
          singleVotingNumber: 1,
          votingScopeType: {
            index: 1
          },
          chooseType: {
            index: 1
          },
          minVotingPlayers: 1,
          maxVotingPlayers: 3,
          verificationCodeProtective: true,
          intelligentProtective: true,
          limitArea: null,
          blacklistLevel: {
            index: 3
          },
          limitPeriodOfTime: false,
          startPeriodOfTime: null,
          endPeriodOfTime: null,
          hasTip: false,
          customTip: '',
          hasRule: false,
          customRule: '',
          baseRule: '',
        }
      },
      settingShowElement: {},
      playerGroupList: [],
      operatePlayerGroup: {
        id: 0,
        name: '',
        chooseType: {index: 1},
        minVotingPlayers: 1,
        maxVotingPlayers: 3
      },
      playerGroupDialogVisible: false
    }
  },
  components: {
    numberInput
  },
  beforeMount() {
    this.getPlayerGroupList();
    this.getActivity();
  },
  methods: {
    getActivity() {
      ActivityUtil.get(ActivityUtil.getCurrentSettingActId(), data => {
        this.settingShowElement = data.settingShowElement
        CommonUtil.copyProperty(data, this.activity);
      })
    },
    changeVotingRules() {
      let body = CommonUtil.copyObject(this.activity);
      let {
        votingCycleType,
        votingScopeType,
        chooseType,
        minVotingPlayers,
        maxVotingPlayers,
        votingNumber,
        singleVotingNumber
      } = body.settingVotingRules
      let {playerAppellation, votesUnit, playerUnit} = this.settingShowElement
      //如果不是按活动设置,或者按活动单选投票,则去除这两项参数,表示不修改这两项参数(避免用户先输入不合法的结果,然后直接切换为单项或分组投票)
      if (votingScopeType.index !== 1 || chooseType.index === 1) {
        body.settingVotingRules.minVotingPlayers = 0;
        body.settingVotingRules.maxVotingPlayers = 0;
      }
      if (votingNumber > 20) {
        body.settingVotingRules.votingNumber = 20;
      }
      if (singleVotingNumber > votingNumber) {
        body.settingVotingRules.singleVotingNumber = votingNumber;
      }
      let ruleContent = ''
      if (votingCycleType.index === 1) {//整个活动期间
        ruleContent += ('每个微信号投票期间只能投' + votingNumber + '次');
      } else {//按周期
        ruleContent += '每个微信号每天可以投' + votingNumber + '次';
      }
      if (votingScopeType.index === 1) {
        if (chooseType.index === 1 && votingNumber > 1) {
          ruleContent += ('，可投同一' + playerAppellation + singleVotingNumber + votesUnit);
        } else if (chooseType.index === 2) {
          ruleContent += ('，最少选择' + minVotingPlayers + playerUnit + playerAppellation +
              '，最多选择' + maxVotingPlayers + playerUnit + playerAppellation)
        }
      }
      body.settingVotingRules.baseRule = ruleContent;
      ActivityUtil.update(body, ['settingVotingRules'], null, () => {
        ActivityUtil.refreshH5();
      })
    },
    getPlayerGroupList() {
      let url = api.player.groupList.replace('{activityId}', ActivityUtil.getCurrentSettingActId());
      ajaxUtil.ajaxJson(url, null, 'GET').then(resultList => {
        this.playerGroupList = resultList;
      })
    },
    clickPlayerGroup(playerGroup) {
      CommonUtil.copyProperty(playerGroup, this.operatePlayerGroup);
      this.playerGroupDialogVisible = true;
    },
    changePlayerGroupVotingRules() {
      let {id, chooseType} = this.operatePlayerGroup
      if (id) {
        this.$refs["dialogFrom"].validate(valid => {
          if (valid) {
            this.operatePlayerGroup.updateBy = store.state.user.id;
            this.operatePlayerGroup.activityId = ActivityUtil.getCurrentSettingActId();
            if (chooseType.index !== 2) {
              this.operatePlayerGroup.minVotingPlayers = 0;
              this.operatePlayerGroup.maxVotingPlayers = 0;
            }
            this.playerGroupDialogVisible = false;
            ajaxUtil.ajaxJson(api.player.updateVotingSetting, this.operatePlayerGroup, 'PUT', null, true).then(data => {
              if (data.change) {
                messages.showSuccessMsg(messages.success.operation);
                ActivityUtil.refreshH5(false);
                this.getPlayerGroupList();
              }
            })
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.voteSet-wrapper {
  .help-votes-box {
    margin-top: 20px;

    .box-name {
      margin-right: 20px;
    }
  }

}

.dialog-foot {
  text-align: center;

  .button {
    width: 98px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
</style>
