import axios from 'axios'
import Vue from 'vue'
import {AuthUtil} from './auth'
import {messages} from './messages'
import store from '@/store/store'
import router from '@/router/index.js'
axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.prototype.$axios = axios;

const ajaxUtil ={
     /***
     * @url   //请求的url 可以带参数
     * @method //http请求的类型
     * @data  //post的数据 可以为空
     * loading: 是否出现加载动画， true 为不加载，不传值默认加载
     * 使用方法：
     * ajaxUtil.ajaxJson(url,data,'get',true).then(res=>{},err=>{})
    */
    ajaxJson:function(url,data,method,loading, showDefaultMsg){

        //加载动画
        //  let loadingInstance = null;
        //  if(loading==null || loading){
        //      loadingInstance =Loading.service({
        //          lock: true,
        //          text: '加载中',
        //          spinner: 'el-icon-loading',
        //          background: 'rgba(0, 0, 0, 0.6)'
        //      });
        //  }

        return new Promise((resolve,reject)=>{
            const instance = axios.create({
                //baseUrl: baseUrl,
                timeout:60000,
                responseType:'json',
                headers:{
                 token: AuthUtil.getToken(),
                 'Content-Type':'application/json; charset=UTF-8',
                    'Client-Type':2
                }
            })
            instance({
                url:encodeURI(url),
                method:method,
                data:JSON.stringify(data),})
                .then((res)=>{
                    //加载动画隐藏
                    // if(loadingInstance!=null){
                    //     loadingInstance.close()
                    // }

                    //处理数据
                    if(res.data.status == 200){
                        resolve(res.data.data)
                    }else{
                        alert('success fail:' + JSON.stringify(res))
                        reject(res.data.data)
                    }
               }).catch((err)=>{
                //加载动画隐藏
                // if(loadingInstance!=null){
                //     loadingInstance.close()
                // }

                //处理异常
                var errResponse = err.response;
                var errData = errResponse==null?null: errResponse.data;
                var customError = errData == null? null: errData.data;

                if(errData!=null && errData.status==401){
                    store.removeToken();
                    store.removeUser();
                    router.push('/login');
                }

                if(showDefaultMsg||reject == null){
                    if(customError == null){
                        messages.showErrorMsg('服务内部异常')
                    }else{
                        messages.showErrorMsgByCode(customError.errorCode)
                    }

                }else{
                    reject(errData)
                }

            })
        })

    },
    /**
     * 针对分页操作列表,可通过该方法分页
     * <p>AB同时登录,B在删除数据,同时A在翻页,导致最后A翻到了空白页</p>
     * @param url
     * @param data
     * @param method
     * @param loading
     * @param successCallback
     */
    ajaxPage:function(url, data, method, loading, successCallback){
        this.ajaxJson(url,data,method,loading).then(page=>{
            if(page.totalPage>=1 && page.pageNo>page.totalPage){
                let needRepeat=false;
                let isSearchAfter=(url.indexOf('?') != -1&&url.indexOf('supportSearchAfter=true') != -1)||(data&&data.supportSearchAfter);
                if(isSearchAfter){
                    if (url.indexOf('?') != -1&&(url.indexOf('pagingType=2') != -1||url.indexOf('pagingType=3') != -1)) {
                        url = url.replace('pagingType=2', 'pagingType=4').replace('pagingType=3', 'pagingType=4');
                        needRepeat=true;
                    }
                    if(data&&data.pagingType&&(data.pagingType==2||data.pagingType==3)){
                        data.pagingType=4;
                        needRepeat=true;
                    }
                }else {
                    if (url.indexOf('?') != -1 && url.indexOf('pageNo=' + page.pageNo) != -1) {
                        url = url.replace('pageNo=' + page.pageNo, 'pageNo=' + page.totalPage);
                        needRepeat=true;
                    }
                    if(data&&data.pageNo){
                        data.pageNo=page.totalPage;
                        needRepeat=true;
                    }
                }
                if(needRepeat){
                    this.ajaxJson(url,data,method,loading).then(lastPage=>{
                        successCallback(lastPage);
                    })
                }else {
                    successCallback(page);
                }
            }else {
                successCallback(page);
            }
        })
    },
    /**
     * form表单提交
     * @param url
     * @param formData
     * @param method
     * @param loading
     * @param contentType 上传文件到后台时,传参'multipart/form-data'
     * @returns {Promise<unknown>}
     */
    ajaxForm:function(url,formData,method,loading, contentType,showDefaultMsg){
        return new Promise((resolve,reject)=>{
            const instance = axios.create({
                timeout:120000,
                responseType:'json',
                headers:{
                    token: AuthUtil.getToken(),
                    'Content-Type':contentType?contentType:'application/x-www-form-urlencode; charset=UTF-8',
                    'Client-Type':2
                },
                onUploadProgress:e=>{
                    let pre =Math.floor(e.loaded/e.total*100);
                    console.log(pre)
                    // e.loaded 已经上传的字节数据，e.total 字节数据  转换为1-100的比例值 赋值个pre
                }
            })
            instance({
                url:encodeURI(url),
                method:method,
                data:formData,})
                .then((res)=>{
                    //加载动画隐藏
                    // if(loadingInstance!=null){
                    //     loadingInstance.close()
                    // }

                    //处理数据
                    if(res.data.status == 200){
                        resolve(res.data.data)
                    }else{
                        reject(res.data.data)
                    }
                }).catch((err)=>{
                //加载动画隐藏
                // if(loadingInstance!=null){
                //     loadingInstance.close()
                // }

                //处理异常
                var errResponse = err.response;
                var errData = errResponse==null?null: errResponse.data;
                var customError = errData == null? null: errData.data;

                if(errData!=null && errData.status==401){
                    store.removeToken();
                    store.removeUser();
                    router.push('/login');
                }

                if(showDefaultMsg||reject == null){
                    if(customError == null){
                        messages.showErrorMsg('服务内部异常')
                    }else{
                        messages.showErrorMsgByCode(customError.errorCode)
                    }

                }else{
                    reject(errData)
                }

            })
        })
    },
    /***
    * 登录请求
    *
    *
   */
   ajaxLogin: function(url,data,method){
    return new Promise((resolve,reject)=>{
       const instance = axios.create({
           timeout: 60000,
           responseType:'json',
           headers:{
               'Content-Type':'application/json; charset=UTF-8',
               'Client-Type':2
           }
       })
       instance({
           url:encodeURI(url),
           method:method,
           data:JSON.stringify(data)
        })
           .then((res)=>{
               if(res.data.status == 200){
                   resolve(res.data.data)
               }else{
                   reject(res.data.data)
               }
           }).catch((err)=>{
               reject(err)
       })
    })
  },
}
export {ajaxUtil}
