import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/official/login/Login.vue'
import Official from '../views/official/official.vue'
import Index from '../views/official/index/webIndex.vue'
import About from '../views/official/about/About.vue'
import product from '../views/official/product/product.vue'
import templateCenter from '../views/official/template/template.vue'
import User from '../views/user/user.vue'
import UserCenter from '../views/user/usercenter/userCenter.vue'
import Topic from '../views/user/topic/topic.vue'
import TopicEdit from '../views/user/topic/topicEdit.vue'
import Activity from '../views/user/activity/activity.vue'
import ActivityModel from '../views/user/activitymodel/activityModel.vue'
import Customize from '../views/user/customize/customize.vue'
import Help from '../views/user/help/help.vue'
import HelpInfo from '../views/user/help/helpInfo.vue'
import ActivityList from '../views/user/activity/list/list.vue'
import AddActivity from '../views/user/activity/add/addActivity.vue'
import LogOperate from '../views/user/activity/setup/log/operate.vue'
import LogVote from '../views/user/activity/setup/log/vote.vue'
import Setup from '../views/user/activity/setup/setup.vue'
import BasicSet from '../views/user/activity/setup/actinfoset/basicSet.vue'
import ContentSet from '../views/user/activity/setup/actinfoset/contentSet.vue'
import NewsSet from '../views/user/activity/setup/advanced/newsSet.vue'
import ShareSet from '../views/user/activity/setup/advanced/shareSet.vue'
import PageSet from '../views/user/activity/setup/actpageset/pageSet.vue'
import DisplaySet from '../views/user/activity/setup/actpageset/displaySet.vue'
import MenuSet from '../views/user/activity/setup/actpageset/menuSet.vue'
import PlayerGroupSet from '../views/user/activity/setup/actplayerset/playerGroupSet.vue'
import PlayerSet from '../views/user/activity/setup/actplayerset/playerSet.vue'
import PlayerEntry from '../views/user/activity/setup/actplayerset/entry.vue'
import VoteSet from '../views/user/activity/setup/actvoteset/voteSet.vue'
import VoteSecureSet from '../views/user/activity/setup/actvoteset/voteSecureSet.vue'
import Ranking from '../views/user/activity/setup/ranking/ranking.vue'
import Statistics from '../views/user/activity/setup/statistics/statistics.vue'
import AdSet from '../views/user/activity/setup/advanced/adSet.vue'
import ScreenSet from '../views/user/activity/setup/advanced/screenSet.vue'
import EntrySet from '../views/user/activity/setup/entry/entry.vue'
import RaffleSet from '../views/user/activity/setup/raffle/set.vue'
import RaffleList from '../views/user/activity/setup/raffle/list.vue'
import OperateLog from '../views/user/log/operateLog.vue'
import QrCode from '../views/user/qrcode/qrcode.vue'
import error from '../views/error.vue'
import Screen from '../views/screen/index.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'Official',
        component: Official,
        redirect: '/index',
        children: [
            {
                name: 'Index',
                path: '/index',
                component: Index
            },
            {
                name: 'About',
                path: '/about',
                component: About
            },
            {
                name: 'product',
                path: '/product',
                component: product
            },
            {
                name: 'templateCenter',
                path: '/templateCenter',
                component: templateCenter
            },
            {
                name: 'Login',
                path: '/login',
                component: Login
            },
        ]
    },
    {
        name: 'User',
        path: '/user',
        component: User,
        redirect: '/usercenter',
        children: [
            {
                name: 'error',
                path: '/error',
                component: error
            },
            {
                name: 'UserCenter',
                path: '/usercenter',
                component: UserCenter
            },
            {
                name: 'Activity',
                path: '/activity',
                component: Activity,
                redirect: '/activity/list',
                children: [
                    {
                        name: 'ActivityList',
                        path: 'list',
                        component: ActivityList
                    },
                    {
                        name: 'AddActivity',
                        path: 'add',
                        component: AddActivity
                    },
                    {
                        name: 'Setup',
                        path: '/act',
                        component: Setup,
                        redirect: 'basicSet',
                        children: [
                            {
                                name: 'BasicSet',
                                path: 'basicSet',
                                component: BasicSet
                            },
                            {
                                name: 'ContentSet',
                                path: 'contentSet',
                                component: ContentSet
                            },
                            {
                                name: 'NewsSet',
                                path: 'newsSet',
                                component: NewsSet
                            },
                            {
                                name: 'ShareSet',
                                path: 'shareSet',
                                component: ShareSet
                            },
                            {
                                name: 'PageSet',
                                path: 'pageSet',
                                component: PageSet
                            },
                            {
                                name: 'DisplaySet',
                                path: 'displaySet',
                                component: DisplaySet
                            },
                            {
                                name: 'MenuSet',
                                path: 'menuSet',
                                component: MenuSet
                            },
                            {
                                name: 'PlayerGroupSet',
                                path: 'playerGroupSet',
                                component: PlayerGroupSet
                            },
                            {
                                name: 'PlayerSet',
                                path: 'playerSet',
                                component: PlayerSet
                            },{
                                name: 'PlayerEntry',
                                path: 'PlayerEntry',
                                component: PlayerEntry
                            },
                            {
                                name: 'VoteSecureSet',
                                path: 'voteSecureSet',
                                component: VoteSecureSet
                            },
                            {
                                name: 'VoteSet',
                                path: 'voteSet',
                                component: VoteSet
                            },
                            {
                                name: 'Statistics',
                                path: 'statistics',
                                component: Statistics
                            },
                            {
                                name: 'Ranking',
                                path: 'ranking',
                                component: Ranking
                            },
                            {
                                name: 'LogOperate',
                                path: 'log/operate',
                                component: LogOperate
                            },
                            {
                                name: 'LogVote',
                                path: 'log/vote',
                                component: LogVote
                            },
                            {
                                name: 'AdSet',
                                path: 'AdSet',
                                component: AdSet
                            },
                            {
                                name: 'EntrySet',
                                path: 'EntrySet',
                                component: EntrySet
                            },
                            {
                                name: 'ScreenSet',
                                path: 'ScreenSet',
                                component: ScreenSet
                            },{
                                name: 'RaffleSet',
                                path: 'RaffleSet',
                                component: RaffleSet
                            },{
                                name: 'RaffleList',
                                path: 'RaffleList',
                                component: RaffleList
                            },
                        ]
                    },
                ]
            },
            {
                name: 'Topic',
                path: '/topic',
                component: Topic
            },
            {
                name: 'TopicEdit',
                path: '/topicedit',
                component: TopicEdit
            },
            {
                name: 'ActivityModel',
                path: '/activitymodel',
                component: ActivityModel
            },
            {
                name: 'Customize',
                path: '/customize',
                component: Customize
            },
            {
                name: 'Help',
                path: '/help',
                component: Help
            },
            {
                name: 'HelpInfo',
                path: '/helpInfo',
                component: HelpInfo,
            },
            {
                name: 'OperateLog',
                path: 'operateLog',
                component: OperateLog
            },
            {
                name: 'Qrcode',
                path: '/qrcode',
                component: QrCode
            },
        ]
    },{
        name: 'Screen',
        path: '/screen',
        component: Screen,
        children: []
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
