<template>
  <div>
    <div class='content'>
      <!--页面设置-->
      <div class='basic'>
        <el-form label-position="left" label-width="80px">
          <el-form-item label="轮播图">
            <div class="banner-list">
              <el-popover
                  v-for="(item, index) in activity.settingShufflingImages" :key="index"
                  placement="bottom"
                  width="300"
                  trigger="hover">
                <div slot>
                  <div style="padding: 4px 0;">跳转网址</div>
                  <el-input v-model="item.jumpUrl" placeholder="请输入内容" @change="updateShufflingImg(index)"></el-input>
                </div>
                <div slot="reference" class="banner-item">
                  <div v-if="item.shufflingImageUrl" class="item-img">
                    <img :src="item.shufflingImageUrl | appendImgUrl" alt=""/>
                  </div>
                  <div class='item-operate'>
                    <div @click='delShufflingImg(index)' class="operate-del">
                      <i class="el-icon-delete"></i>
                    </div>
                    <div class="operate-update" @click='changeShufflingImg(index)'>
                      <p>更换轮播图</p>
                    </div>
                  </div>
                </div>
              </el-popover>
              <div v-if="20>activity.settingShufflingImages.length" class="banner-add" @click='addShufflingImg'>
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="活动模板">
            <imageContainer :isEditVisible.sync="isTemolate" :height="180" :width="120" :maxImageNum="1"
                            noneDes="更换模板" shadowDes="更换模板"
                            :images="[activity.modelCoverImageUrl]"></imageContainer>
          </el-form-item>
          <el-form-item label="配色方案">
            <colorChoose :color="activity.settingStyle.backgroundColor" @changeColor="changeBackgroundColor"
                         @clickBtn="defaultStyle('backgroundColor')"></colorChoose>
          </el-form-item>
          <el-form-item label="背景图片">
            <imageContainer :isEditVisible.sync="isBgImg" :height="180" :width="120" :plusBorder="1"
                            :maxImageNum="1" shadowDes="去设置" btnName1="默认"
                            :btnName2="activity.settingStyle.backgroundImageUrl?'清除':''"
                            @clickBtn1="defaultStyle('backgroundImageUrl')"
                            @clickBtn2="clearStyle('backgroundImageUrl')"
                            :images="[activity.settingStyle.backgroundImageUrl]"></imageContainer>
          </el-form-item>
          <el-form-item label="边框">
            <imageContainer :isEditVisible.sync="isBorderImg" :height="120" :width="120" :plusBorder="1"
                            :maxImageNum="1" shadowDes="去设置" btnName1="默认"
                            :btnName2="activity.settingStyle.borderImageUrl?'清除':''"
                            @clickBtn1="defaultStyle('borderImageUrl')" @clickBtn2="clearStyle('borderImageUrl')"
                            :images="[activity.settingStyle.borderImageUrl]"></imageContainer>
          </el-form-item>
          <el-form-item label="背景音乐">
            <imageContainer :isEditVisible.sync="isMusicBox" :height="40" :width="120" :maxImageNum="1"
                            :noneDes="activity.settingStyle.backgroundMusicUrl?'已设置':'去设置'"
                            shadowDes="去设置" btnName1="默认"
                            :btnName2="activity.settingStyle.backgroundMusicUrl?'清除':''"
                            @clickBtn1="defaultStyle('backgroundMusicUrl')"
                            @clickBtn2="clearStyle('backgroundMusicUrl')"
                            :images="['']"></imageContainer>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--------------------------------------------模板选择弹窗------------------------------------------->
    <el-dialog title="模板选择" :visible.sync="isTemolate" width="1100px" class='templateDialog'>
      <activityModelList :canSearch="false" :width="200" :coverHeight="350" batName="更换模板"
                         @clickModel="changeModel"></activityModelList>
    </el-dialog>
    <!--------------------------------------------轮播图弹框------------------------------------------->
    <resourceDialog title="轮播图选择" :visible.sync="isShufflingImg" :resourceType="2" width="972px"
                    @changeResource="changeResource"></resourceDialog>
    <!--------------------------------------------背景图片弹框------------------------------------------->
    <resourceDialog title="背景选择" :visible.sync="isBgImg" :resourceType="4"
                    @changeResource="changeResource"></resourceDialog>
    <!--------------------------------------------边框弹出框------------------------------------------->
    <resourceDialog title="选择边框" :visible.sync="isBorderImg" :resourceType="3" :canUpload="false"
                    @changeResource="changeResource"></resourceDialog>
    <!-----------------------------------------音乐弹出框------------------------------->
    <resourceDialog title="选择音乐" :visible.sync="isMusicBox" :resourceType="5" width="930px"
                    :canUpload="false" @changeResource="changeResource"></resourceDialog>
  </div>
</template>

<script>
import {ActivityUtil, CommonUtil} from '@/js/commonUtil'
import {ajaxUtil} from '@/js/ajaxUtil'
import {api} from '@/js/api'
import resourceDialog from '@/components/resourceDialog.vue'
import imageContainer from '@/components/imageContainer.vue'
import colorChoose from '@/components/colorChoose.vue'
import activityModelList from '@/components/actiivityModelList.vue'

export default {
  name: 'pageSet',
  components: {
    resourceDialog,
    imageContainer,
    colorChoose,
    activityModelList
  },
  data() {
    return {
      activity: {
        id: 0,
        activityModelId: 0,
        modelCoverImageUrl: null,
        settingShufflingImages: [],
        shufflingImages: [],
        settingStyle: {
          backgroundColor: null,
          backgroundImageUrl: null,
          borderImageUrl: null,
          backgroundMusicUrl: null
        }
      },
      changeShufflingImagIndex: -1,
      isShufflingImg: false,//更换轮播图弹出框
      isTemolate: false,//更换模板弹出框
      isBgImg: false,//更换背景图片弹出框
      isBorderImg: false,//显示边框弹出框
      isMusicBox: false,//显示音乐弹框
    }
  },
  beforeMount() {
    this.getActivity();
  },
  methods: {
    getActivity() {
      ActivityUtil.get(ActivityUtil.getCurrentSettingActId(), data => {
        CommonUtil.copyProperty(data, this.activity);
      })
    },
    delShufflingImg(index) {
      if (!this.isShufflingImg) {
        this.changeShufflingImagIndex = -1;
        this.activity.settingShufflingImages.splice(index, 1);
        this.activity.shufflingImages.splice(index, 1);
        ActivityUtil.update(this.activity, ['settingShufflingImages'], null, () => {
          ActivityUtil.refreshH5();
        });
      }
    },
    updateShufflingImg(index) {
      if (!this.isShufflingImg) {
        this.changeShufflingImagIndex = index;
        ActivityUtil.update(this.activity, ['settingShufflingImages'], null, () => {
          ActivityUtil.refreshH5();
        });
      }
    },
    addShufflingImg() {
      this.isShufflingImg = true
      this.changeShufflingImagIndex = -1;
    },
    changeShufflingImg(index) {
      console.log(index)
      this.isShufflingImg = true
      this.changeShufflingImagIndex = index;
    },
    changeResource(resource) {
      if (this.isShufflingImg) {//更换轮播图
        if (this.changeShufflingImagIndex < 0) {//新增轮播图
          this.activity.settingShufflingImages.push({shufflingImageUrl: resource.url})
        } else {//修改轮播图
          this.activity.settingShufflingImages[this.changeShufflingImagIndex].shufflingImageUrl = resource.url;
        }
        this.activity.shufflingImages = this.activity.settingShufflingImages.map(item => item.shufflingImageUrl);
        ActivityUtil.update(this.activity, ['settingShufflingImages'], null, () => {
          if (this.changeShufflingImagIndex < 0) {
            this.changeShufflingImagIndex = this.activity.settingShufflingImages.length - 1;
          }
          ActivityUtil.refreshH5();
        });
      } else {
        if (this.isBorderImg) {//更换边框图
          this.activity.settingStyle.borderImageUrl = resource.url;
        } else if (this.isBgImg) {//更换背景图
          this.activity.settingStyle.backgroundImageUrl = resource.url;
        } else if (this.isMusicBox) {//更换音乐资源
          this.activity.settingStyle.backgroundMusicUrl = resource.url;
        }
        ActivityUtil.update(this.activity, ['settingStyle'], null, () => {
          ActivityUtil.refreshH5();
        });
      }
    },
    defaultStyle(settingStyleFieldName) {
      let body = {
        activityId: this.activity.id
      };
      body[settingStyleFieldName] = true;
      ajaxUtil.ajaxJson(api.activity.defaultStyle, body, 'PUT').then(data => {
        if (data.change) {
          ActivityUtil.refreshH5(true, data => {
            CommonUtil.copyProperty(data, this.activity);
          });
        }
      })
    },
    clearStyle(settingStyleFieldName) {
      this.activity.settingStyle[settingStyleFieldName] = '';
      ActivityUtil.update(this.activity, ['settingStyle'], null, () => {
        ActivityUtil.refreshH5();
      });
    },
    changeBackgroundColor(color) {
      if (!CommonUtil.compare(this.activity.settingStyle.backgroundColor, color)) {
        this.activity.settingStyle.backgroundColor = color;
        ActivityUtil.update(this.activity, ['settingStyle'], null, () => {
          ActivityUtil.refreshH5();
        });
      }
    },
    changeModel(model) {
      this.isTemolate = false;
      if (model && model.id && model.id != this.activity.activityModelId) {
        this.activity.activityModelId = model.id;
        ActivityUtil.update(this.activity, ['activityModelId'], null, () => {
          ActivityUtil.refreshH5(true, data => {
            CommonUtil.copyProperty(data, this.activity);
          });
        });
      }
    }
  }
}
</script>
<style scoped lang='scss'>
.templateDialog {
  ::v-deep .el-dialog__body {
    padding: 10px 20px 30px !important;
  }
}

.upload-swiper-img-box {
  ::v-deep .icon {
    &:hover {
      color: #409EFF;
    }
  }
}

.banner-list {
  display: flex;
  flex-wrap: wrap;

  .banner-item {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid transparent;
    background-position: 50%;
    border-radius: 4px;
    overflow: hidden;
    height: 120px;
    width: 200px;
    box-sizing: border-box;

    .item-img {
      width: 100%;
      height: 100%;
      background-color: #f5f6f8;
      text-align: center;

      img {
        width: auto;
        height: 100%;
      }
    }

    .item-operate {
      background: rgba(48, 38, 38, 0.5);
      color: #fff;
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      font-size: 14px;
      text-align: center;
      cursor: pointer;

      .operate-update {
        text-align: center;
      }

      .operate-update:hover {
        font-weight: bold;
        color: #409EFF;
      }

      .operate-del {
        height: 30px;
        position: absolute;
        text-align: right;
        right: 12px;
        top: 0;
      }

      .operate-del:hover {
        color: #409EFF;
        font-weight: bold;
      }
    }
  }

  .banner-item:hover {
    .item-operate {
      display: flex;
    }
  }

  .banner-add {
    height: 120px;
    width: 200px;
    box-sizing: border-box;
    text-align: center;
    border: 1px dashed #e8e5e5;
    cursor: pointer;

    i {
      font-size: 36px;
      color: #8c939d;
      line-height: 120px;
    }
  }


  .noneBackground {
    background: #f5f6f8;
  }

  .img-active {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    font-size: 14px;
    text-align: center;
    display: table;
    cursor: pointer;

    .change {
      width: 100%;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }

    .icon {
      height: 30px;
      position: absolute;
      text-align: right;
      right: 12px;
      top: 0;
      z-index: 99999999999999;
    }
  }

  .checkBtn {
    cursor: pointer;
    margin-left: 20px;
  }
}
</style>
