<template>
  <div class="raffle-set">
    <el-form hide-required-asterisk label-position="left" label-width="100px">
      <el-form-item label="在线抽奖">
        <div>
          <el-switch disabled v-model="activity.settingRaffle.status"></el-switch>
          <span style="margin-left: 20px;">应相关部门要求，抽奖功能需审核才能开启，请联系客服</span>
        </div>
      </el-form-item>
      <!--      <template v-if="activity.settingRaffle.status">-->
      <template>
        <el-form-item label="重复中奖">
          <el-switch v-model="activity.settingRaffle.hasRepeat" @change="saveRaffleEvent"></el-switch>
        </el-form-item>
        <el-form-item label="奖品设置">
          <div class="prize-title">
            <i class="el-icon-warning-outline"></i>
            <span>中奖概率总和必须为100%，奖品概率设置为0该奖品不会被抽中</span>
          </div>
          <el-table :data="prizeList" size="mini" style="width: 800px;">
            <el-table-column prop="prizeName" label="奖品名称" show-overflow-tooltip/>
            <el-table-column prop="winningProbability" align="center" label="奖品概率" width="150">
              <template v-slot="{row}">
                {{ row.winningProbability }}%
              </template>
            </el-table-column>
            <el-table-column prop="winningNum" align="center" width="150" label="中奖数量"/>
            <el-table-column prop="prizeNum" align="center" width="150" label="奖品数量"/>
            <el-table-column label="操作" align="center" width="120">
              <template v-slot="{row,$index}">
                <el-button type="text" @click="bindUpdatePrizeChange(row)">编辑</el-button>
                <el-button type="text" @click="bindDelPrizeChange($index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button icon="el-icon-plus" size="medium" type="primary" style="margin-top: 16px;"
                     @click="bindAddPrizeChange">添加奖品
          </el-button>
        </el-form-item>
        <el-form-item label="用户信息" class="entry-custom-form">
          <el-radio-group v-model="activity.settingRaffle.enterUserType" @input="saveRaffleEvent">
            <el-radio :label="0">抽奖前填写</el-radio>
            <el-radio :label="1">抽奖后填写</el-radio>
          </el-radio-group>

          <div v-for="(item,index) in userForm" :key="index">
            <div class="custom-form-item">
              <div class="item-title">
                <el-input size="small" v-model="item[0]" placeholder="请输入内容" @change="saveRaffleEvent"/>
              </div>
              <div class="item-layout">
                <el-radio-group v-model="item[1]" size="small" @input="saveRaffleEvent">
                  <el-radio-button :label="0">选填</el-radio-button>
                  <el-radio-button :label="1">必填</el-radio-button>
                </el-radio-group>
              </div>
              <div class="item-del">
                <el-button size="small" type="danger" plain
                           @click="bindDelUserChange(index)">删除
                </el-button>
              </div>
            </div>
          </div>
          <el-button icon="el-icon-plus" size="medium" type="primary"
                     @click="bindAddUserChange">添加表单
          </el-button>
        </el-form-item>
        <el-form-item label="抽奖说明">
          <tiny-editor :width="800" :height="650" ref="tinymce" v-model="activity.settingRaffle.blurb"></tiny-editor>
          <el-button type="primary" style='margin-top: 20px' @click="saveRaffleEvent">保存说明</el-button>
        </el-form-item>
      </template>
    </el-form>
    <!--报名详情 -->
    <el-dialog title="奖品设置" :visible.sync="showPrizeForm" width="500px"
               :close-on-click-modal="false" @close="showPrizeForm=false">
      <el-form :model="prizeForm" label-width="100px" :rules="prizeRules" ref="prizeForm">
        <el-form-item label="奖品名称" prop="prizeName">
          <el-input v-model="prizeForm.prizeName"></el-input>
        </el-form-item>
        <el-form-item label="中奖概率" prop="winningProbability">
          <el-input-number v-model="prizeForm.winningProbability"
                           :min="0" :max="100" precision="4" :controls="false"/>
          <span style="margin-left: 16px;">%</span>
        </el-form-item>
        <el-form-item label="奖品数量" prop="prizeNum">
          <el-input-number :step="1" step-strictly v-model="prizeForm.prizeNum"
                           :min="1" :max="99999999" :controls="false"/>
        </el-form-item>
        <div style="display: flex;justify-content: right;">
          <el-button @click="showPrizeForm=false">取消</el-button>
          <el-button type="primary" @click="bindSubmitPrizeChange">保存</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {ActivityUtil, CommonUtil} from "@/js/commonUtil"
import TinyEditor from "@/components/tinymce.vue";
import {messages} from "@/js/messages";

export default {
  name: 'raffleSet',
  components: {TinyEditor},
  data() {
    return {
      userForm: [],
      showPrizeForm: false,
      prizeForm: {},
      prizeRules: {
        prizeName: [{required: true, message: '请填写奖品名称', trigger: 'blur'}],
        winningProbability: [{required: true, message: '请填写中奖概率', trigger: 'blur'}],
        prizeNum: [{required: true, message: '请填写奖品数量', trigger: 'blur'}],
      },
      prizeList: [],
      typeCode: ['group', 'video', 'image'],
      activity: {
        id: 0,
        settingRaffle: {
          status: 0,
          hasRepeat: false,
          enterUserType: 0,
          userForm: null,
          blurb: null,
        },
        settingPrizes: []
      }
    }
  },
  beforeMount() {
    this.getActivity();
  },
  methods: {
    getActivity() {
      ActivityUtil.get(ActivityUtil.getCurrentSettingActId(), data => {
        let {userForm} = data.settingRaffle
        if (userForm) {
          this.userForm = JSON.parse(userForm)
        } else {
          this.userForm = [
            ["联系方式", 0],
            ["寄奖地址", 0],
          ]
        }
        const settingPrizes = data.settingPrizes;
        if (settingPrizes) {
          this.prizeList = settingPrizes
        }
        CommonUtil.copyProperty(data, this.activity);
      })
    },

    saveRaffleEvent() {
      this.activity.settingRaffle.userForm = JSON.stringify(this.userForm)
      let body = CommonUtil.copyObject(this.activity);
      ActivityUtil.update(body, ['settingRaffle'], null, () => {
        ActivityUtil.refreshH5();
      })
    },
    bindAddUserChange() {
      let length = this.userForm.length;
      if (length > 10) {
        return
      }
      this.userForm.push(["默认名称", 0])
      this.saveRaffleEvent()
    },
    bindDelUserChange(index) {
      this.userForm.splice(index, 1)
      this.saveRaffleEvent()
    },
    bindAddPrizeChange() {
      const prizeList = this.prizeList;
      if (prizeList.length > 20) {
        return
      }
      this.prizeForm = {}
      this.showPrizeForm = true
    },
    bindUpdatePrizeChange(prizeForm) {
      this.prizeForm = prizeForm
      this.showPrizeForm = true
    },
    bindDelPrizeChange(index) {
      this.prizeList.splice(index, 1)
      this.savePrizeEvent()
    },
    bindSubmitPrizeChange() {
      this.$refs.prizeForm.validate(valid => {
        let prizeForm = this.prizeForm;
        if (valid && prizeForm) {
          //计算中奖概率
          const prizeList = this.prizeList;
          let sumProbability = prizeForm.id ? 0 : prizeForm.winningProbability
          for (let i = 0; i < prizeList.length; i++) {
            const {winningProbability} = prizeList[i]
            sumProbability += winningProbability
          }
          if (sumProbability > 100) {
            messages.warning('奖品中奖总概率不得超过100%')
            return
          }
          //添加
          if (!prizeForm.id) {
            this.prizeList.push(prizeForm)
          }
          this.savePrizeEvent()
          this.showPrizeForm = false
        }
      })
    },
    savePrizeEvent() {
      this.activity.settingPrizes = this.prizeList
      let body = CommonUtil.copyObject(this.activity);
      ActivityUtil.update(body, ['settingPrizes'], null, () => {
        ActivityUtil.refreshH5();
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.raffle-set {
  .prize-title {
    i {
      color: #409eff;
      margin-right: 8px;
    }
  }

  .entry-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 20px;

    .item-title {
      width: 80px;
    }

    .el-input {
      width: 200px;
    }
  }

  .entry-custom-form {
    .custom-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .item-title {
        width: 130px;
      }

      .item-type {
        margin-left: 20px;
        width: 130px;
      }

      .item-layout {
        margin-left: 20px;
      }

      .item-status {
        margin-left: 20px;
      }

      .item-del {
        margin-left: 20px;
      }
    }
  }
}
</style>
