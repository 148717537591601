<template>
  <div class="pageSet-wrapper">
    <div class='content'>
      <!--------------------------------------------显示设置------------------------------------------->
      <div class='basic'>
        <el-form hide-required-asterisk label-position="left" label-width="100px">
          <el-form-item label="分组列数">
            <el-radio-group v-model="activity.settingShowVoting.playerGroupColumns" @change="changeShowVoting">
              <el-radio :label="1">一列</el-radio>
              <el-radio :label="2">二列</el-radio>
              <el-radio :label="3">三列</el-radio>
              <el-radio :label="4">四列</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选手列数">

            <el-radio-group v-model="activity.settingShowVoting.playerColumns"
                            @change="changeShowVoting('defaultShowDirection')">
              <el-radio :label="1">一列</el-radio>
              <el-radio :label="2">二列</el-radio>
              <el-radio :label="3">三列</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="单列显示" v-if="activity.settingShowVoting.playerColumns===1">
            <el-radio-group v-model="activity.settingShowVoting.playerShowDirection.index" @change="changeShowVoting">
              <el-radio :label="1">左右</el-radio>
              <el-radio :label="2">上下</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选手图片" v-else>
            <el-radio-group v-model="activity.settingShowVoting.playerAvatarLayout.index" @change="changeShowVoting">
              <el-radio :label="1">对齐</el-radio>
              <el-radio :label="2">瀑布流</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选手排序">
            <el-radio-group v-model="activity.settingShowVoting.playerSortType.index" @change="changeShowVoting">
              <el-radio :label="1">编号正序</el-radio>
              <el-radio :label="2">票数倒序</el-radio>
              <el-radio :label="3">时间倒序</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="活动介绍">
            <el-checkbox-group v-model="checkShowIntroduce.checkedModel" @change="changeShowIntroduce">
              <div class='checkbox'>
                <el-checkbox label="hasActivityName">活动名称</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasActivityNews">活动动态</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasVotingRules">投票规则</el-checkbox>
                <el-tooltip class="item" effect="dark" content="控制活动介绍页面的投票规则是否显示" placement="bottom">
                  <i class='el-icon-question problem-icon'></i>
                </el-tooltip>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasActivityData">活动数据</el-checkbox>
                <el-tooltip class="item" effect="dark" content="控制活动介绍页面的投票统计是否显示" placement="bottom">
                  <i class='el-icon-question problem-icon'></i>
                </el-tooltip>
              </div>
              <div class='checkbox' v-if="this.activity.settingShowIntroduce.hasActivityData">
                <el-checkbox label="hasTotalVotes">总票数</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasCountdown">活动倒计时</el-checkbox>
              </div>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="投票页面">
            <el-checkbox-group v-model="checkShowVoting.checkedModel" @change="changeShowVoting" class='clear'>
              <div class='checkbox'>
                <el-checkbox label="hasActivityName">活动名称</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasVotingRules">投票规则</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerSearch">选手搜索</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasRanking">查看排行</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasEntry">在线报名</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerNumber">选手编号</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerCover">选手封面</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerName">选手名称</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerSynopsis">选手简介</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerVotes">选手票数</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasVotingButton">投票按钮</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerDetails">选手详情</el-checkbox>
                <el-tooltip class="item" effect="dark" content="控制选手详情页面是否显示" placement="bottom">
                  <i class='el-icon-question problem-icon'></i>
                </el-tooltip>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasActivityData">活动数据</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasTotalVotes">总票数</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasCountdown">活动倒计时</el-checkbox>
              </div>
              <div class='checkbox' v-if="groupNum>1">
                <el-checkbox label="hasPlayerGroup">选手分组</el-checkbox>
              </div>
              <div class='checkbox' v-if="groupNum>1&&activity.settingShowVoting.hasPlayerGroup">
                <el-checkbox label="showAllGroup">全部按钮</el-checkbox>
                <el-tooltip class="item" effect="dark" content="当勾选后,点击【全部】查看活动全部选手" placement="bottom">
                  <i class='el-icon-question problem-icon'></i>
                </el-tooltip>
              </div>
            </el-checkbox-group>
            <el-row :gutter="20" v-if="activity.settingShowVoting.hasPlayerName">
              <el-col :span="10">
                <div class='help-votes-box'>
                  选手名称行数
                  <div style="display: inline-block;">
                    <el-form ref="btnName" :model="activity.settingShowPlayer">
                      <el-form-item style="display: inline-block;width:160px;margin-left: 20px">
                        <el-input-number v-model.trim="activity.settingShowPlayer.nameRow" :step-strictly="true"
                                         :min="1" :max="9" placeholder="请输入内容" @change="changeShowPlayer"/>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </el-col>
              <el-col :span="10">
                <div class='help-votes-box'>
                  <span>选手名称对齐方式</span>
                  <el-tooltip class="item" effect="dark" content="控制投票页面选手名称对齐方式" placement="bottom"
                              style='margin:0 20px 0 4px'>
                    <i class='el-icon-question problem-icon'></i>
                  </el-tooltip>
                  <el-radio-group v-model="activity.settingShowPlayer.nameAlign"
                                  @change="changeShowPlayer">
                    <el-radio label="left">左对齐</el-radio>
                    <el-radio label="center">居中</el-radio>
                    <el-radio label="right">右对齐</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-if="activity.settingShowVoting.hasPlayerSynopsis">
              <el-col :span="10">
                <div class='help-votes-box'>
                  选手简介行数
                  <div style="display: inline-block;">
                    <el-form ref="btnName" :model="activity.settingShowPlayer">
                      <el-form-item style="display: inline-block;width:160px;margin-left: 20px">
                        <el-input-number v-model.trim="activity.settingShowPlayer.blurbRow" :step-strictly="true"
                                         :min="1" :max="9" placeholder="请输入内容" @change="changeShowPlayer"/>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </el-col>
              <el-col :span="10">
                <div class='help-votes-box'>
                  <span>选手简介对齐方式</span>
                  <el-tooltip class="item" effect="dark" content="控制投票页面选手简介对齐方式" placement="bottom"
                              style='margin:0 20px 0 4px'>
                    <i class='el-icon-question problem-icon'></i>
                  </el-tooltip>
                  <el-radio-group v-model="activity.settingShowPlayer.blurbAlign"
                                  @change="changeShowPlayer">
                    <el-radio label="left">左对齐</el-radio>
                    <el-radio label="center">居中</el-radio>
                    <el-radio label="right">右对齐</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="选手详情">
            <el-checkbox-group v-model="checkShowPlayer.checkedModel" @change="changeShowPlayer" class='clear'>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerNumber">选手编号</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerCover">选手封面</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerName">选手名称</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerSynopsis">选手简介</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasVotingButton">投票按钮</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasCanvassingButton">拉票按钮</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerData">选手数据</el-checkbox>
                <el-tooltip class="item" effect="dark" content="控制选手详情页面的投票数据是否显示" placement="bottom">
                  <i class='el-icon-question problem-icon'></i>
                </el-tooltip>
              </div>
              <div class='checkbox' v-if="this.activity.settingShowPlayer.hasPlayerData">
                <el-checkbox label="hasPlayerVotes">选手票数</el-checkbox>
              </div>
            </el-checkbox-group>
            <el-row :gutter="20" v-if="activity.settingShowPlayer.hasPlayerName">
              <el-col :span="10">
                <div class='help-votes-box'>
                  选手名称行数
                  <div style="display: inline-block;">
                    <el-form :model="activity.settingShowPlayer">
                      <el-form-item style="display: inline-block;width:160px;margin-left: 20px">
                        <el-input-number v-model.trim="activity.settingShowPlayer.infoNameRow" :step-strictly="true"
                                         :min="1" :max="9" placeholder="请输入内容" @change="changeShowPlayer"/>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </el-col>
              <el-col :span="10">
                <div class='help-votes-box'>
                  <span>选手名称对齐方式</span>
                  <el-tooltip class="item" effect="dark" content="控制选手详情页面选手名称对齐方式" placement="bottom"
                              style='margin:0 20px 0 4px'>
                    <i class='el-icon-question problem-icon'></i>
                  </el-tooltip>
                  <el-radio-group v-model="activity.settingShowPlayer.infoNameAlign"
                                  @change="changeShowPlayer">
                    <el-radio label="left">左对齐</el-radio>
                    <el-radio label="center">居中</el-radio>
                    <el-radio label="right">右对齐</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-if="activity.settingShowPlayer.hasPlayerSynopsis">
              <el-col :span="10">
                <div class='help-votes-box'>
                  选手简介行数
                  <div style="display: inline-block;">
                    <el-form :model="activity.settingShowPlayer">
                      <el-form-item style="display: inline-block;width:160px;margin-left: 20px">
                        <el-input-number v-model.trim="activity.settingShowPlayer.infoBlurbRow" :step-strictly="true"
                                         :min="1" :max="9" placeholder="请输入内容" @change="changeShowPlayer"/>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </el-col>
              <el-col :span="10">
                <div class='help-votes-box'>
                  <span>选手简介对齐方式</span>
                  <el-tooltip class="item" effect="dark" content="控制选手详情页面选手简介对齐方式" placement="bottom"
                              style='margin:0 20px 0 4px'>
                    <i class='el-icon-question problem-icon'></i>
                  </el-tooltip>
                  <el-radio-group v-model="activity.settingShowPlayer.infoBlurbAlign"
                                  @change="changeShowPlayer">
                    <el-radio label="left">左对齐</el-radio>
                    <el-radio label="center">居中</el-radio>
                    <el-radio label="right">右对齐</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="10">
                <div class='help-votes-box'>
                  拉票按钮名称
                  <div style="display: inline-block;">
                    <el-form ref="btnName" :model="activity.settingShowPlayer">
                      <el-form-item style="display: inline-block;width:160px;margin-left: 20px"
                                    prop="canvassingButtonName"
                                    :rules="[{required: true, message: '请输入拉票按钮名称', trigger: 'blur'}]">
                        <el-input v-model.trim="activity.settingShowPlayer.canvassingButtonName"
                                  maxlength="6" placeholder="请输入内容" @change="changeShowPlayer"></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </el-col>
              <el-col :span="10">
                <div class='help-votes-box'>
                  <span>选手图片</span>
                  <el-tooltip class="item" effect="dark" content="控制选手详情页面选手图片展示方式" placement="bottom"
                              style='margin:0 20px 0 4px'>
                    <i class='el-icon-question problem-icon'></i>
                  </el-tooltip>
                  <el-radio-group v-model="activity.settingShowPlayer.playerImagesShowType.index"
                                  @change="changeShowPlayer">
                    <el-radio :label="1">轮播展示</el-radio>
                    <el-radio :label="2">九宫格展示</el-radio>
                    <el-radio :label="3">竖向展示</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>


          </el-form-item>
          <el-form-item label="排行榜">
            <el-checkbox-group v-model="checkShowRanking.checkedModel" @change="changeShowRanking">
              <div class='checkbox'>
                <el-checkbox label="hasActivityName">活动名称</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerNumber">选手编号</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerName">选手名称</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerCover">选手封面</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerSynopsis">选手简介</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasPlayerVotes">选手票数</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasVotingRules">投票规则</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasActivityData">活动数据</el-checkbox>
              </div>
              <div class='checkbox'>
                <el-checkbox label="hasCountdown">活动倒计时</el-checkbox>
              </div>
              <div class='checkbox' v-if="groupNum>1">
                <el-checkbox label="hasPlayerGroup">选手分组</el-checkbox>
              </div>
              <div class='checkbox' v-if="groupNum>1&&activity.settingShowRanking.hasPlayerGroup">
                <el-checkbox label="showAllGroup">全部按钮</el-checkbox>
                <el-tooltip class="item" effect="dark" content="当勾选后，点击【全部】查看活动全部选手票数排名"
                            placement="bottom">
                  <i class='el-icon-question problem-icon'></i>
                </el-tooltip>
              </div>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {ajaxUtil} from '@/js/ajaxUtil'
import {api} from '@/js/api'
import {ActivityUtil, CommonUtil} from "@/js/commonUtil";

export default {
  name: 'displaySet',
  data() {
    return {
      activity: {
        id: 0,
        settingShowIntroduce: {
          hasActivityName: false,
          hasActivityNews: false,
          hasVotingRules: false,
          hasActivityData: false,
          hasTotalVotes: false,
          hasCountdown: false
        },
        settingShowVoting: {
          hasActivityName: false,
          hasVotingRules: false,
          hasPlayerSearch: false,
          hasRanking: false,
          hasPlayerNumber: false,
          hasPlayerCover: false,
          hasPlayerName: false,
          hasPlayerSynopsis: false,
          hasPlayerVotes: false,
          hasVotingButton: false,
          hasPlayerDetails: false,
          hasActivityData: false,
          hasTotalVotes: false,
          hasCountdown: false,
          hasPlayerGroup: false,
          showAllGroup: false,
          playerGroupColumns: 4,
          playerColumns: 2,
          playerSortType: {
            index: 1
          },
          playerShowDirection: {
            index: 2
          },
          playerAvatarLayout: {
            index: 1
          }
        },
        settingShowPlayer: {
          hasPlayerNumber: false,
          hasPlayerCover: false,
          hasPlayerName: false,
          hasPlayerSynopsis: false,
          hasVotingButton: false,
          hasCanvassingButton: false,
          hasPlayerData: false,
          hasPlayerVotes: false,
          canvassingButtonName: null,
          nameRow: 1,
          blurbRow: 1,
          nameAlign: 'center',
          blurbAlign: 'center',
          infoNameRow: 1,
          infoBlurbRow: 1,
          infoNameAlign: 'center',
          infoBlurbAlign: 'center',
          playerImagesShowType: {
            index: 1
          }
        },
        settingShowRanking: {
          hasActivityName: false,
          hasPlayerNumber: false,
          hasPlayerVotes: false,
          hasPlayerName: false,
          hasPlayerCover: false,
          hasPlayerSynopsis: false,
          hasPlayerGroup: false,
          hasVotingRules: false,
          hasActivityData: false,
          hasCountdown: false,
          showAllGroup: false
        }
      },
      checkShowIntroduce: {
        keys: ['hasActivityName', 'hasActivityNews', 'hasVotingRules', 'hasActivityData', 'hasTotalVotes', 'hasCountdown'],
        checkedModel: []
      },
      checkShowVoting: {
        keys: ['hasActivityName', 'hasVotingRules', 'hasPlayerSearch', 'hasRanking','hasEntry', 'hasPlayerNumber',
          'hasPlayerCover', 'hasPlayerName', 'hasPlayerSynopsis', 'hasPlayerVotes', 'hasVotingButton',
          'hasPlayerDetails', 'hasActivityData', 'hasTotalVotes', 'hasCountdown', 'showAllGroup', 'hasPlayerGroup'],
        checkedModel: []
      },
      checkShowPlayer: {
        keys: ['hasPlayerNumber', 'hasPlayerCover', 'hasPlayerName', 'hasPlayerSynopsis', 'hasVotingButton',
          'hasCanvassingButton', 'hasPlayerData', 'hasPlayerVotes'],
        checkedModel: []
      },
      checkShowRanking: {
        keys: ['hasActivityName', 'hasPlayerNumber', 'hasPlayerVotes','hasPlayerName', 'hasPlayerCover', 'hasPlayerSynopsis',
          'hasPlayerGroup', 'hasVotingRules', 'hasActivityData', 'hasCountdown', 'showAllGroup'],
        checkedModel: []
      },
      groupNum: 0
    }
  },
  beforeMount() {
    this.getActivity();
    this.getPlayerGroupNum();
  },
  methods: {
    getActivity() {
      ActivityUtil.get(ActivityUtil.getCurrentSettingActId(), data => {
        CommonUtil.copyProperty(data, this.activity);
        this.checkShowIntroduce.checkedModel = CommonUtil.objToCheckboxGroupArr(
            this.activity.settingShowIntroduce, this.checkShowIntroduce.keys);
        this.checkShowVoting.checkedModel = CommonUtil.objToCheckboxGroupArr(
            this.activity.settingShowVoting, this.checkShowVoting.keys);
        this.checkShowPlayer.checkedModel = CommonUtil.objToCheckboxGroupArr(
            this.activity.settingShowPlayer, this.checkShowPlayer.keys);
        this.checkShowRanking.checkedModel = CommonUtil.objToCheckboxGroupArr(
            this.activity.settingShowRanking, this.checkShowRanking.keys);
      })
    },
    getPlayerGroupNum() {
      let url = api.player.groupSimpleList.replace('{activityId}', ActivityUtil.getCurrentSettingActId());
      ajaxUtil.ajaxPage(url, null, 'GET', null, data => {
        this.groupNum = data.length;
      })
    },
    /**
     * 活动介绍页的显示设置发生变化
     */
    changeShowIntroduce() {
      //从有活动数据改为无活动数据时,自动取消总票数
      if (this.activity.settingShowIntroduce.hasActivityData
          && this.checkShowIntroduce.checkedModel.indexOf('hasActivityData') < 0
          && this.activity.settingShowIntroduce.hasTotalVotes) {
        for (let i = 0; i < this.checkShowIntroduce.checkedModel.length; i++) {
          if (this.checkShowIntroduce.checkedModel[i] == 'hasTotalVotes') {
            this.checkShowIntroduce.checkedModel.splice(i, 1);
            break;
          }
        }
        this.activity.settingShowIntroduce.hasTotalVotes = false;
      }
      //从无活动数据改为有活动数据时,自动勾上总票数
      if (!this.activity.settingShowIntroduce.hasActivityData
          && this.checkShowIntroduce.checkedModel.indexOf('hasActivityData') >= 0
          && !this.activity.settingShowIntroduce.hasTotalVotes) {
        this.checkShowIntroduce.checkedModel.push('hasTotalVotes');
        this.activity.settingShowIntroduce.hasTotalVotes = true;
      }
      CommonUtil.checkboxGroupArrToObjBol(this.activity.settingShowIntroduce,
          this.checkShowIntroduce.keys, this.checkShowIntroduce.checkedModel);
      ActivityUtil.update(this.activity, ['settingShowIntroduce'], null, () => {
        ActivityUtil.refreshH5();
      })
    },
    /**
     * 投票页的显示设置发生变化
     */
    changeShowVoting(param) {
      let settingShowVoting = this.activity.settingShowVoting;
      if (param === 'defaultShowDirection') {
        if (settingShowVoting.playerColumns > 1) {
          settingShowVoting.playerShowDirection.index = 2;
        }
      }
      CommonUtil.checkboxGroupArrToObjBol(settingShowVoting,
          this.checkShowVoting.keys, this.checkShowVoting.checkedModel);
      ActivityUtil.update(this.activity, ['settingShowVoting'], null, () => {
        ActivityUtil.refreshH5();
      })
    },
    /**
     * 选手详情页的显示设置发生变化
     */
    changeShowPlayer() {
      //从有选手数据改为无选手数据时,自动取消选手票数
      if (this.activity.settingShowPlayer.hasPlayerData
          && this.checkShowPlayer.checkedModel.indexOf('hasPlayerData') < 0
          && this.activity.settingShowPlayer.hasPlayerVotes) {
        for (let i = 0; i < this.checkShowPlayer.checkedModel.length; i++) {
          if (this.checkShowPlayer.checkedModel[i] == 'hasPlayerVotes') {
            this.checkShowPlayer.checkedModel.splice(i, 1);
            break;
          }
        }
        this.activity.settingShowPlayer.hasPlayerVotes = false;
      }
      //从无选手数据改为有选手数据时,自动勾上选手票数
      if (!this.activity.settingShowPlayer.hasPlayerData
          && this.checkShowPlayer.checkedModel.indexOf('hasPlayerData') >= 0
          && !this.activity.settingShowPlayer.hasPlayerVotes) {
        this.checkShowPlayer.checkedModel.push('hasPlayerVotes');
        this.activity.settingShowPlayer.hasPlayerVotes = true;
      }
      this.$refs["btnName"].validate(valid => {
        if (valid) {
          CommonUtil.checkboxGroupArrToObjBol(this.activity.settingShowPlayer,
              this.checkShowPlayer.keys, this.checkShowPlayer.checkedModel);
          ActivityUtil.update(this.activity, ['settingShowPlayer'], null, () => {
            ActivityUtil.refreshH5();
          })
        }
      })
    },
    /**
     * 排行页的显示设置发生变化
     */
    changeShowRanking() {
      //从有选手分组改为无选手分组时,自动取消全部
      if (this.activity.settingShowRanking.hasPlayerGroup
          && this.checkShowRanking.checkedModel.indexOf('hasPlayerGroup') < 0
          && this.activity.settingShowRanking.showAllGroup) {
        for (let i = 0; i < this.checkShowRanking.checkedModel.length; i++) {
          if (this.checkShowRanking.checkedModel[i] == 'showAllGroup') {
            this.checkShowRanking.checkedModel.splice(i, 1);
            break;
          }
        }
        this.activity.settingShowRanking.showAllGroup = false;
      }
      //从无选手分组改为有选手分组时,自动勾上全部
      if (!this.activity.settingShowRanking.hasPlayerGroup
          && this.checkShowRanking.checkedModel.indexOf('hasPlayerGroup') >= 0
          && this.groupNum > 1 && !this.activity.settingShowRanking.showAllGroup) {
        this.checkShowRanking.checkedModel.push('showAllGroup');
        this.activity.settingShowRanking.showAllGroup = true;
      }
      CommonUtil.checkboxGroupArrToObjBol(this.activity.settingShowRanking,
          this.checkShowRanking.keys, this.checkShowRanking.checkedModel);
      ActivityUtil.update(this.activity, ['settingShowRanking'], null, () => {
        ActivityUtil.refreshH5();
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pageSet-wrapper {
  .template-img {
    width: 120px;
    height: 180px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .tp-img-active {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(48, 38, 38, 0.5);
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 180px;
      cursor: pointer;
    }
  }

  .border-img {
    width: 120px;
    height: 120px;
    background: #eee;
    border-radius: 10px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    .border-img-active {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(48, 38, 38, 0.5);
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 120px;
      cursor: pointer;
      border-radius: 10px;
    }
  }

  .music-box {
    width: 120px;
    height: 40px;
    border-radius: 5px;
    background: #eee;
    color: #999;
    text-align: center;
    position: relative;

    .music-box-active {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      border-radius: 5px;
      color: #fff;
    }
  }

  .color-wrapper {
    li {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      float: left;
      margin-right: 5px;
      cursor: pointer;
    }
  }

  .defalutbtn {
    margin-left: 30px;
  }

  //弹出框样式
  .bgimgDialog {
    ::v-deep .el-dialog__body {
      padding: 0 15px 20px !important
    }

    .bgimg-list {
      height: 430px;
      overflow: auto;

      li {
        width: 100px;
        height: 120px;
        background: #eee;
        padding: 15px;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;

        img {
          width: 100px;
          height: 120px
        }
      }
    }

    .border-img-list {
      height: 430px;
      overflow: auto;
      background: #eee;

      li {
        width: 120px;
        height: 120px;
        background: #fff;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;

        img {
          width: 100%;
          height: 100%
        }
      }
    }

    .music-img-list {
      height: 430px;
      overflow: auto;
      background: #fff;

      li {
        width: 200px;
        height: 140px;
        float: left;
        margin-right: 15px;
        margin-bottom: 20px;
        background: #fff;
        border: 1px solid $globalTheme;

        .music-name {
          width: 100%;
          height: 110px;
          text-align: center;
          padding-top: 30px;
          box-sizing: border-box;
        }

        button {
          width: 100%;
          height: 30px;
          border: none;
          background: $globalTheme;
          color: #fff;
        }

        .music-icon {
          color: $globalTheme;
          font-size: 30px;
        }
      }
    }
  }

  .checkbox {
    padding-right: 40px;
    float: left;
  }

  .problem-icon {
    margin-left: 6px;
    color: #cccccc;
    font-size: 14px;
  }

  .help-votes-box {
    margin-top: 20px;

    .box-name {
      margin-right: 20px;
    }
  }
}
</style>
