<template>
  <div class="content-wrapper">
    <el-row :span="12">
      <el-tabs value="300" @tab-click="tabClick" size="medium" class="qr-code-tabs">
        <el-tab-pane
            v-for="(item) in statusOptions"
            v-bind:key="item.value"
            :label="item.label" :name="item.value.toString()">
        </el-tab-pane>
      </el-tabs>
    </el-row>

    <el-row :span="12" style="float: left">
      <span style="padding-right: 5px">尺寸</span>
      <el-input v-model="qrCodeSize" onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                onafterpaste="this.value=this.value.replace(/[^\d]/g,'') "
                maxlength="4" class='search-with-input' style="width: 80px"></el-input>
      <span style="padding-left: 5px">px</span>
      <el-button type="primary" class='search-with-btn' style="margin-left: 20px" @click="getQrCode">生成
      </el-button>
    </el-row>

    <el-row :span="12" style="margin-top: 80px">
      <img :src="qrUrl" alt="">
    </el-row>
    <el-row :span="12" :style="{'padding-left':paddingLeftPx}">
      <span style="margin-right: 20px">{{ qrCodeSizeStr }}&nbsp;*&nbsp;{{ qrCodeSizeStr }}</span>
      <a download="code.png" :href="qrUrl" class="qr-code-download"><strong>下载</strong></a>
    </el-row>
  </div>
</template>

<script>
import {QrCode} from '../../../js/qrcodeUtil.js'
import {LocalStorageUtil} from '../../../js/auth'
import store from '../../../store/store'
import {StorageUtil} from "@/js/auth";

export default {
  name: 'QrCode',
  data() {
    return {
      qrCodeSize: "300",
      qrCodeSizeStr: "300",
      paddingLeftPx: "90px",
      activityUrl: "",//二维码具体内容
      qrUrl: "",//二维码图片
      statusOptions: [{
        value: "300",
        label: '小尺寸'
      }, {
        value: "400",
        label: '中尺寸'
      }, {
        value: "500",
        label: '大尺寸'
      }],
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'ActivityList') {
      let actListQueryParams=StorageUtil.getObject('actListQueryParams');
      if(actListQueryParams){
        actListQueryParams.query=true;
        StorageUtil.storeObject('actListQueryParams',actListQueryParams);
      }
      StorageUtil.removeObject('topicListQueryParams');
    }else if (to.name === 'Topic') {
      let topicListQueryParams=StorageUtil.getObject('topicListQueryParams');
      if(topicListQueryParams){
        topicListQueryParams.query=true;
        StorageUtil.storeObject('topicListQueryParams',topicListQueryParams);
      }
      StorageUtil.removeObject('actListQueryParams');
    }else {
      if(!to.path.startsWith('/activity/')){
        StorageUtil.removeObject('actListQueryParams');
      }
      if(to.name!='TopicEdit'){
        StorageUtil.removeObject('topicListQueryParams');
      }
    }
    next()
  },
  mounted() {
    let activityUrl = LocalStorageUtil.getString("activityH5Url");
    if (activityUrl == null || activityUrl === "") {
      return;
    }
    this.activityUrl = activityUrl;
    this.getQrCode();
  },
  methods: {
    tabClick(tab) {
      this.qrCodeSize = tab.name;
      this.getQrCode();
    },
    /**
     * 生成二维码
     **/
    getQrCode() {
      if (!this.isNumber()) {
        this.qrCodeSize = "300";
      }
      this.qrUrl = QrCode.url(this.activityUrl, this.qrCodeSize);
      this.qrCodeSizeStr = this.qrCodeSize;
      this.paddingLeftPx = this.qrCodeSizeStr / 3 + "px";
    },
    /**
     * 数字类型校验
     * @returns {boolean}
     */
    isNumber() {
      let regPos = /^\d+(\.\d+)?$/; //非负浮点数
      let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
      return regPos.test(this.qrCodeSize) || regNeg.test(this.qrCodeSize);
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-code-tabs {
  float: left;
  padding-bottom: 20px
}

.qr-code-download {
  cursor: pointer;
  color: #595959;
  text-decoration: none;
}
</style>
