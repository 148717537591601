<template>
  <div class="content-wrapper">
    <div class="help-info">
      <div class="help-info-header">
        <p class="help-info-title">{{ infoData.title }}</p>
        <p class="help-info-time"><img src="@/assets/time.png">{{ infoData.createTime | convertTime }}</p>
      </div>
      <div class="help-info-content">
        <showRichText :richText="infoData.content"></showRichText>
      </div>
      <div class="help-info-bat">
        <div class="el-button-group">
          <button class="el-button el-button--medium el-button--primary"
                  style="width:400px;text-align:left;padding-left:0;display: flex;margin-right: 20px"
                  v-if="infoData.previous!=null" v-on:click="clickPrevious">
            <i class="el-icon-arrow-left el-icon--left" style="width:12px;padding-left:8px;"></i>
            <span class="overflow"
                  style="display:block;width:380px;text-align: center;">上一篇：{{ infoData.previous.title }}</span>
          </button>
          <button class="el-button el-button--medium el-button--primary"
                  style="width:400px;text-align:right;padding-right:0;display: flex;" v-if="infoData.next!=null"
                  v-on:click="clickNext">
          <span class="overflow"
                style="display:block;width:380px;text-align: center;">下一篇：{{ infoData.next.title }}</span>
            <i class="el-icon-arrow-right el-icon--right" style="width:12px;padding-right:8px;"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ajaxUtil} from '@/js/ajaxUtil.js'
import {api} from '@/js/api.js'
import showRichText from '@/components/showRichText.vue'

export default {
  name: 'helpInfo',
  components: {
    showRichText
  },
  data() {
    return {
      infoData: {}
    }
  },

  beforeMount() {
    //初始化数据
    this.getInfo(this.$route.query.id);
  },
  methods: {
    clickPrevious() {
      let systemNewsId = this.infoData.previous.id;
      this.$router.push({path: '/helpInfo', query: {id: systemNewsId}})
      this.getInfo(systemNewsId);
    },

    clickNext() {
      let systemNewsId = this.infoData.next.id;
      this.$router.push({path: '/helpInfo', query: {id: systemNewsId}})
      this.getInfo(systemNewsId);
    },

    getInfo(id) {
      var url = api.systemnews.getById.replace('{id}', id);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        this.infoData = data;
      })
    },
  }
}
</script>
<style lang="scss">
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.help-info {
  box-sizing: border-box;
  padding: 30px 20px;
}

.help-info-header {
  border-bottom: 1px solid rgb(245, 245, 245);

  .help-info-title {
    font-size: 18px;
    font-weight: 700;
    color: rgb(51, 51, 51);
    padding-bottom: 10px;
  }

  .help-info-time {
    font-size: 14px;
    color: rgb(153, 153, 153);
    padding-bottom: 15px;

    img {
      width: 12px;
      top: 0.5px;
      position: relative;
      padding-right: 6px;
    }
  }
}

.help-info-content {
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgb(245, 245, 245);
}

.help-info-bat {
  text-align: center;
  padding-top: 50px;
}
</style>
