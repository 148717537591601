<template>
  <div class='error'>
     <img src="../assets/4043.png" alt="" class='error-img'>
     <p class='error-txt'>对不起，我跑丢了....</p>
  </div>
</template>

<script>
export default {
  name:'error',
  data() {
    return {

    }
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style scoped lang='scss'>
.error{
  background: #fff;
   width: 100%;
   height: 100%;
   text-align: center;
   padding-top: 240px;
   .error-txt{
       font-size: 16px;

   }
}
</style>
